import { TypedEvent } from "@/common/events/typed-event";
import {
  INotificationService,
  MessageData,
  NotificationType,
  INotificationData,
} from "./notification-service.interface";
import { ITypedEvent } from "@/common/events/ityped-event";

export class NotificationData implements INotificationData {
  type: NotificationType;
  messageData: MessageData;
  resolveFn: () => void;
}

export class NotificationService implements INotificationService {
  private _showNotifiactionEvent: TypedEvent<NotificationData> =
    new TypedEvent<NotificationData>();

  notifiactionDisplayTime = 4000;
  shortNotifiactionDisplayTime = 1000;
  waitBetweenNotifications = 500;
  isAutoCloseEnabled: boolean = true;

  async check(messageData: MessageData): Promise<void> {
    await new Promise<void>((resolve) => {
      const notificationData = new NotificationData();
      notificationData.type = "check";
      notificationData.messageData = messageData;
      notificationData.resolveFn = resolve;
      this._showNotifiactionEvent.emit(notificationData);
    });
  }

  async info(messageData: MessageData): Promise<void> {
    await new Promise<void>((resolve) => {
      const notificationData = new NotificationData();
      notificationData.type = "info";
      notificationData.messageData = messageData;
      notificationData.resolveFn = resolve;
      this._showNotifiactionEvent.emit(notificationData);
    });
  }

  async error(messageData: MessageData): Promise<void> {
    await new Promise<void>((resolve) => {
      const notificationData = new NotificationData();
      notificationData.type = "error";
      notificationData.messageData = messageData;
      notificationData.resolveFn = resolve;
      this._showNotifiactionEvent.emit(notificationData);
    });
  }

  async warn(messageData: MessageData): Promise<void> {
    await new Promise<void>((resolve) => {
      const notificationData = new NotificationData();
      notificationData.type = "warning";
      notificationData.messageData = messageData;
      notificationData.resolveFn = resolve;
      this._showNotifiactionEvent.emit(notificationData);
    });
  }

  async notify(notificationData: NotificationData): Promise<void> {
    await new Promise<void>((resolve) => {
      notificationData.resolveFn = resolve;
      this._showNotifiactionEvent.emit(notificationData);
    });
  }

  // Internal Notification Component communication (not part of the exposed interface):
  // ============================================================================================
  get showNotifiaction(): ITypedEvent<NotificationData> {
    return this._showNotifiactionEvent;
  }
}
