import { FailedReason } from "@/common/results/failed-reason";
import { IModelFacade } from "../../../../backend-wrapper/model-facade.interface";
import { ITreeNodeLoader } from "../trees/tree-node-loader.interface";
import { TreeNodeVm } from "../trees/tree-node-vm";
import { ValueResult } from "@/common/results/value-result";

export class MeasureNodeLoader implements ITreeNodeLoader {
  private readonly _modelFacade: IModelFacade;
  private readonly _semanticModelId: string;

  constructor(modelFacade: IModelFacade, semanticModelId: string) {
    this._modelFacade = modelFacade;
    this._semanticModelId = semanticModelId;
  }

  async loadAsync(_id: string): Promise<ValueResult<TreeNodeVm[], FailedReason>> {
    const kpis = await this._modelFacade.getKpisAsync(this._semanticModelId);

    if (kpis.succeeded === false) {
      return ValueResult.createFromError(kpis.failedReason);
    }

    const result = [];

    for (const kpi of kpis.value) {
      const treeItem = new TreeNodeVm(kpi.id, kpi.name);
      treeItem.value = kpi;

      result.push(treeItem);
    }

    return ValueResult.createFromValue(result);
  }
}
