import { DashboardVm } from "../dashboard-vm";
import { SharedDashboardStateVm } from "@/features/dashboard/view-models/shared/shared-dashboard-state-vm";
import { DashboardFiltersListVm } from "@/features/dashboard/view-models/dashboard-filters-list-vm";
import { KpiTileBuilder } from "./kpi-tile-builder";
import { SwiperVm } from "@/common/components/swiper-vm";
import { DashboardSettings } from "@/features/dashboard-shared/dashboard-settings";
import { CloneHelper } from "@/common/object-helper/clone-helper";
import { DashboardId } from "@/features/dashboard-shared/dashboard-id";

export class DashboardBuilder {
  private _kpiBuilder: KpiTileBuilder = new KpiTileBuilder();

  createDashboard(dashboardId: DashboardId): DashboardVm {
    const result = new DashboardVm();

    result.dashboardId = dashboardId;
    result.filters = new DashboardFiltersListVm();
    result.sharedState = new SharedDashboardStateVm();

    return result;
  }

  clone(original: DashboardVm, cloneDbSettings?: DashboardSettings): DashboardVm {
    const clone = new DashboardVm();
    clone.dashboardId = original.dashboardId; //object identity is okay here
    clone.dashboardFm = CloneHelper.clone(original.dashboardFm);

    clone.sharedState = original.sharedState.clone();
    clone.filters = original.filters.clone();
    if (cloneDbSettings) {
      clone.dbSettings = cloneDbSettings;
    } else {
      clone.dbSettings = original.dbSettings.clone();
    }
    clone.originalScaleIdx = original.originalScaleIdx;

    clone.scrollGroupSwiperVms = this._cloneSwiper(original.scrollGroupSwiperVms);

    clone.deltaValuesSwiperVm = new SwiperVm(
      original.deltaValuesSwiperVm.numberOfItemsToDisplay,
      original.deltaValuesSwiperVm.activeIndex
    );
    clone.kpiTileVms = original.kpiTileVms.map((kpi) =>
      this._kpiBuilder.clone(
        kpi,
        clone.scrollGroupSwiperVms[kpi.scrollGroupId],
        clone.deltaValuesSwiperVm,
        clone.filters,
        clone.dbSettings,
        original.dashboardId.publishedApplicationId,
        clone.sharedState
      )
    );

    clone.cloneVsRoot(original);

    clone.isInitialized = original.isInitialized;
    return clone;
  }

  private _cloneSwiper(originalSwiper: { [key in number]: SwiperVm }): {
    [key in number]: SwiperVm;
  } {
    const clones: { [key in number]: SwiperVm } = {};

    for (const key in originalSwiper) {
      const origSwiper = originalSwiper[key];
      clones[key] = new SwiperVm(
        origSwiper.numberOfItemsToDisplay,
        origSwiper.activeIndex,
        origSwiper.infiniteLoop
      );
    }

    return clones;
  }
}
