import { NumberFormatMode } from "@/common/formatting/number-format-mode";
import { ValueFormatter } from "@/common/formatting/value-formatter";
import { ValueFormatResources } from "@/common/formatting/value-format-resources.interface";
import { BusinessColor } from "@/common/formatting/business-color";
import { roundToDecimal } from "@/common/formatting/rounding";
import { SparklineCommon, SparklineMode } from "./sparkline-common";
import { BarAnimationState } from "./animations/bar-animation-state";
import { BarAnimation } from "./animations/bar-animation";
import { PeriodVm } from "@/features/dashboard/view-models/period-vm";

export class SparkBarValueVm {
  isPositive: boolean = null;
  dynamicColorValue: number = null;
  period: PeriodVm = null;
  value: number = null;
  prevValue: number = null;
  isAnyPercentageType: boolean = false;
  sparklineDeviationValue: number = null;
  formatter: ValueFormatter = new ValueFormatter();

  constructor(other?: SparkBarValueVm) {
    if (!other) return;

    this.updateFrom(other);
  }

  updateFrom(other?: SparkBarValueVm) {
    this.isPositive = other.isPositive;
    this.dynamicColorValue = other.dynamicColorValue;
    this.period = other.period.clone();
    this.value = other.value;
    this.isAnyPercentageType = other.isAnyPercentageType;
    this.sparklineDeviationValue = other.sparklineDeviationValue;
  }

  getDeviationValueAndUnit(
    scaleFactor: number,
    invertSign: boolean,
    locale: string,
    formatTexts: ValueFormatResources
  ): string {
    return SparkBarValueVm.getFormattedDeviation(
      this.formatter,
      this.sparklineDeviationValue,
      scaleFactor,
      invertSign,
      locale,
      formatTexts
    );
  }

  static getFormattedDeviation(
    formatter: ValueFormatter,
    sparklineDeviationValue: number,
    scaleFactor: number,
    invertSign: boolean,
    locale: string,
    formatTexts: ValueFormatResources
  ): string {
    if (!sparklineDeviationValue) {
      return "";
    }

    formatter.setCulture(formatTexts, locale);
    let deviation = sparklineDeviationValue;

    const scaleFactorSign = Math.sign(scaleFactor);
    if (scaleFactorSign < 0) deviation *= -1;

    if (invertSign) deviation *= -1;

    const format = "%";
    const showSign = true;
    const numberFormatMode = NumberFormatMode.Detailed;
    const result = formatter.formatValue(deviation, format, showSign, numberFormatMode);
    return result[0] + " " + result[1];
  }

  getBarAnimation(barAnimationState: BarAnimationState): BarAnimation {
    const maxHeight = barAnimationState.maxHeight;
    const availableHeight = barAnimationState.availableHeight;
    const barOrientation = barAnimationState.barOrientation;
    const index = barAnimationState.index;
    const sparklineState = barAnimationState.sparklineState;
    const sparklinesDisplayState = barAnimationState.sparklinesDisplayState;
    const prevAnimation = barAnimationState.prevAnimation;
    const defaultTransition = barAnimationState.defaultTransition;

    const scaleFactor =
      sparklinesDisplayState.mode !== "global" || this.isAnyPercentageType
        ? 1
        : barAnimationState.scaleFactor ?? 1;
    const barWidth = sparklinesDisplayState.barWidth;
    const barMargin = sparklinesDisplayState.barMargin;
    const weatherColor = this._getWeather(
      maxHeight,
      barAnimationState,
      sparklinesDisplayState.mode
    );
    const minHeight = barOrientation === "mixed" ? 4 : 2;
    const barHeight = this._getBarHeight(
      this.value / scaleFactor,
      maxHeight,
      availableHeight,
      minHeight
    );
    const x = barMargin + index * (barMargin + barWidth);
    const positiveMax =
      this._getBarHeight(
        sparklineState.positiveMax / scaleFactor,
        maxHeight,
        availableHeight,
        minHeight
      ) / 2;
    const negativeMax =
      this._getBarHeight(
        sparklineState.negativeMax / scaleFactor,
        maxHeight,
        availableHeight,
        minHeight
      ) / 2;
    const barAnimation = new BarAnimation(
      availableHeight,
      barOrientation,
      weatherColor,
      barWidth,
      x,
      positiveMax,
      negativeMax
    );
    barAnimation.globalSparklinesEnabled = barAnimationState.globalSparklinesEnabled;
    const durations: number[] = [];
    const heights: number[] = [];

    if (sparklinesDisplayState.mode === "disabled") {
      const duration = SparklineCommon.maxDurations.enabledToDisabled;
      const height_1 =
        prevAnimation && sparklinesDisplayState.preMode !== sparklinesDisplayState.mode
          ? prevAnimation.barHeight
          : barHeight;
      const height_2 = 0;

      durations.push(duration);
      heights.push(height_1);
      heights.push(height_2);
    } else {
      if (prevAnimation && defaultTransition) {
        const height_1 =
          prevAnimation.orientation === "negative"
            ? prevAnimation.barHeight * -1
            : prevAnimation.barHeight;
        const height_2 = this._getBarHeight(
          sparklineState.localSparklineAvg / scaleFactor,
          maxHeight,
          availableHeight,
          minHeight
        );
        const height_3 = (this.isPositive ? 1 : -1) * Math.abs(barHeight);

        heights.push(height_1);
        heights.push(height_2);
        heights.push(height_3);

        const maxAvgGapHeight = this._getBarHeight(
          sparklineState.localSparklineAvgMaxGap / scaleFactor,
          maxHeight,
          availableHeight,
          minHeight
        );
        const duration_1 = SparklineCommon.maxDurations.enabledToAvg;
        const duration_2 = this._getAvgDuration(height_2, height_3, maxAvgGapHeight);

        durations.push(duration_1);
        durations.push(Math.min(duration_2, SparklineCommon.maxDurations.avgToEnabled));
      } else {
        const duration = defaultTransition
          ? Math.floor(
              Math.abs(barHeight / availableHeight) *
                SparklineCommon.maxDurations.disabledToEnabled
            )
          : SparklineCommon.maxDurations.enabledToEnabled;
        const height_1 = prevAnimation
          ? (prevAnimation.orientation === "negative" ? -1 : 1) * prevAnimation.barHeight
          : 0;
        const height_2 = (this.isPositive ? 1 : -1) * Math.abs(barHeight);

        durations.push(
          Math.min(duration, SparklineCommon.maxDurations.disabledToEnabled)
        );
        heights.push(height_1);
        heights.push(height_2);
      }
    }

    barAnimation.init(durations, heights);
    return barAnimation;
  }

  private _getAvgDuration(
    startHeight: number,
    endHeight: number,
    maxAvgGapHeight: number
  ): number {
    const gap = Math.abs(endHeight - startHeight);
    return Math.floor(
      (gap / maxAvgGapHeight) * SparklineCommon.maxDurations.avgToEnabled
    );
  }

  private _getBarHeight(
    value: number,
    maxValue: number,
    availableHeight: number,
    minHeight: number = 2
  ): number {
    const valPercent = (value * 100) / maxValue;
    const barHeight = roundToDecimal((availableHeight * valPercent) / 100);

    return Math.abs(barHeight) >= 0 && Math.abs(barHeight) < minHeight
      ? minHeight
      : barHeight;
  }

  private _getWeather(
    globalMax: number,
    barAnimationState: BarAnimationState,
    mode: SparklineMode
  ): string {
    const maxAbsValue =
      mode === "global" ? globalMax : barAnimationState.sparklineState.localSparklineMax;
    return BusinessColor.determineBusinessColor({
      value: this.value,
      max: maxAbsValue,
      factor: barAnimationState.factor,
      invertSign: barAnimationState.invertSign,
      biColorThresholds: barAnimationState.biColorThresholds,
      dynamicValue: this.dynamicColorValue,
      scaleFactor: barAnimationState.scaleFactor,
    });
  }
}
