import { FailedReason } from "@/common/results/failed-reason";
import { ValueResult } from "@/common/results/value-result";
import { IUniqueValidator } from "./unique-validator.interface";
import { IApplicationFacade } from "../backend-wrapper/application-facade.interface";
import { exists } from "@/common/object-helper/null-helper";
import { NameUniqueValidator } from "./name-unique-validator";

export class ApplicationNameUniqueValidator implements IUniqueValidator {
  private readonly _nameUniqueValidator: NameUniqueValidator;

  get isLastSpecifiedValueUnique(): boolean {
    return this._nameUniqueValidator.isLastSpecifiedValueUnique;
  }

  constructor(
    private _applicationFacade: IApplicationFacade,
    private _selectedApplicationGroupId?: string
  ) {
    this._nameUniqueValidator = new NameUniqueValidator(() => this._retrieveNamesAsync());
  }

  private async _retrieveNamesAsync(): Promise<ValueResult<string[], FailedReason>> {
    if (!exists(this._selectedApplicationGroupId)) {
      return ValueResult.createFromValue([]);
    }

    return await this._applicationFacade.getAllApplicationNamesFor(
      this._selectedApplicationGroupId
    );
  }

  async isValueUniqueAsync(value: string): Promise<ValueResult<boolean, FailedReason>> {
    return await this._nameUniqueValidator.isValueUniqueAsync(value);
  }
}
