<script lang="ts">
import PortalTile from "./tile.vue";
import { useTileCloning } from "./tile-cloning-cpsl";
import { useHelpText } from "@/services/help-text-service/help-text-cpsl";
import {
  TileGrid,
  Tile,
  TileGridService,
  TileGridConfiguration,
  defaultTileGridConfiguration,
  TileConfiguration,
  SimpleDefaultPlacementStrategy,
} from "@bissantz/tile-grid";

import { IDisposable } from "@/common/disposable.interface";
import { IBackgroundChangeService } from "@/services/background-change-service.interface";
import { IMediaQueries } from "@/common/styles/media-queries.interface";
import { appResources } from "@/app-resources";
import { PortalTileVm } from "./view-models/portal-tile-vm";
import { PortalSelectionVm } from "./view-models/portal-selection-vm";
import { SharedPageState } from "./view-models/shared/shared-page-state";
import { PortalPageVm } from "./view-models/portal-page-vm";
import { ClickHelper } from "@/common/events/click-helper";
import { TouchHelper } from "@/common/events/touch-helper";
import { IStatusBarService } from "@/services/status-bar-service.interface";
import { IZoomService } from "@/services/zoom-service.interface";
import { PortalCommon } from "./portal-common";
import { gridConstants } from "./portal-grid/grid-common";
import { backgroundClicked } from "@/common/validation-helper";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";
import * as scrollHelper from "@/common/scroll-helpers";
import { dragHandle } from "@bissantz/tile-grid";
import { GridCollisionHandler } from "./portal-grid/grid-collision-handler";

import {
  reactive,
  defineComponent,
  PropType,
  computed,
  onBeforeUnmount,
  onMounted,
  onBeforeMount,
  nextTick,
  ref,
  inject,
  watch,
} from "vue";
import { exists } from "@/common/object-helper/null-helper";
import { IScalable } from "@/common/formatting/scalable.interface";

class PortalPageState {
  textResources = appResources.portalTexts;
  isScrollToTileAllowed = false;

  gridLayout: TileConfiguration[] = [];
  sharedPageState = new SharedPageState();
  dashboardSelection = new DashboardSelection();

  disposableListeners: IDisposable[] = [];

  tileGridService: TileGridService = null;
  dragHandleClass = dragHandle;
  tileGridConfig: TileGridConfiguration = Object.assign(
    {},
    defaultTileGridConfiguration,
    {
      colNum: 12,
      verticalScroll: true,
      bottomFreeRows: gridConstants.grid.bottomFreeRows,
      rowHeight: gridConstants.grid.rowHeight,
      isResizable: false,
      minColWidthPx: gridConstants.grid.minColWidth,
      useStyleCursor: false,
      margin: [
        gridConstants.grid.marginHorizontalPx,
        gridConstants.grid.marginVerticalPx,
      ],
      verticalCompact: true,
      layoutStrategy: new SimpleDefaultPlacementStrategy(),
      layoutCollisionHandler: new GridCollisionHandler(),
    }
  );

  documentClickHelper = new ClickHelper();
  documentTouchHelper = new TouchHelper();
}

export default defineComponent({
  components: {
    PortalTile,
    TileGrid,
    Tile,
  },

  emits: [PortalCommon.portal_dashboardSelectionChanged.name],

  props: {
    portalPageVm: { type: Object as PropType<PortalPageVm>, required: true },
    isShowing: { type: Boolean, required: true },
    isEditing: { type: Boolean, default: false },
    portalSelection: {
      type: Object as PropType<PortalSelectionVm>,
      default: null,
      required: true,
    },
  },

  setup: function (props, context) {
    //
    // Injections:
    const backgroundChangeService: IBackgroundChangeService = inject(
      "backgroundChangeService"
    );
    const mediaQueries: IMediaQueries = inject("mediaQueries");
    const statusBarService: IStatusBarService = inject("statusBarService");
    const zoomService: IZoomService = inject("zoomService");

    // DOM refs:
    const ref_tileGrid = ref<InstanceType<typeof TileGrid>>();
    const ref_cloneTile = ref<InstanceType<typeof Tile>>();
    const ref_cloneDragArea = ref<HTMLDivElement>();

    // non-reactives:
    // -----------------
    const helpTextCpsl = useHelpText();

    // State
    const state = reactive(new PortalPageState()) as PortalPageState;

    const cloningCpsl = useTileCloning(
      props.portalPageVm,
      computed<TileConfiguration[]>(() => state.gridLayout),
      computed<TileGridService>(() => state.tileGridService),
      ref_tileGrid,
      ref_cloneDragArea,
      ref_cloneTile,
      computed<boolean>(() => props.isEditing)
    );

    //
    // Life Cycle:
    // --------------------
    onBeforeMount(async () => {
      state.documentClickHelper.touchHelper = state.documentTouchHelper;
      state.documentClickHelper.setOnClickAction(setDefaultBackground);
      state.documentTouchHelper.setTapAction(setDefaultBackground);
      state.disposableListeners.push(zoomService.factorChanged.on(handleZoomChanged));
    });

    onMounted(async () => {
      await init();
      state.tileGridService?.refreshGrid();
    });

    // TODO: better not use setTimeout-Loop, instead computed + watcher
    //             seems cleaner and more vue idiomatic
    function watchDashboardsAndRefreshGrid(): void {
      const shownTiles = props.portalPageVm.shownTiles;
      const allDashboardsInitialised =
        shownTiles.filter(
          (tile) =>
            !exists(tile.dashboardVm) ||
            tile.dashboardVm.isVsSyncReady ||
            tile.dashboardVm.error
        ).length === shownTiles.length;

      if (allDashboardsInitialised) {
        setTimeout(() => {
          state.tileGridService?.refreshGrid();
          state.isScrollToTileAllowed = true;
        }, 900);
      } else {
        setTimeout(() => watchDashboardsAndRefreshGrid(), 200);
      }
    }

    onBeforeUnmount(() => {
      state.disposableListeners.map((disposer) => disposer.dispose());
      document.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("touchend", onTouchEnd);
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mouseup", onMouseUp);
    });

    async function init(): Promise<void> {
      const collisionHandler = state.tileGridConfig
        .layoutCollisionHandler as GridCollisionHandler;
      collisionHandler.tgService = state.tileGridService as TileGridService;
      collisionHandler.gridRef = ref_tileGrid?.value?.$el as HTMLElement;

      document.addEventListener("touchstart", onTouchStart);
      document.addEventListener("touchend", onTouchEnd);
      document.addEventListener("mousedown", onMouseDown);
      document.addEventListener("mouseup", onMouseUp);

      state.gridLayout = initGridLayout();

      handleZoomChanged({ oldFactor: null, newFactor: zoomService.factor });
    }

    //
    // Computeds:
    // --------------------
    const errorMessage = computed<string>(() => {
      if (!props.portalPageVm.hasNoTiles) {
        return null;
      }

      return state.textResources.errorNoTiles;
    });

    const needUpdateScalingContext = computed<boolean>(() => {
      return (
        state.sharedPageState.portaScalingContext.length !==
        props.portalPageVm.shownTiles.length
      );
    });

    //
    // Functions:
    // --------------------
    function onPortalScaledValuesInitialized(portalTile: PortalTileVm): void {
      const newValues = portalTile.portalScaledValueCopies;
      if (!newValues || !newValues.length) return;

      state.sharedPageState.portaScalingContext.push(...newValues);
      props.portalPageVm.shownTiles.map((dbTile) =>
        dbTile.tryApplyScalingContext(state.sharedPageState.portaScalingContext)
      );
    }

    function initGridLayout(): TileConfiguration[] {
      let gridItemLayouts = props.portalPageVm.tileLayout;

      gridItemLayouts.push(cloningCpsl.state.cloneTileConfiguration);

      // don't animate the initial placement of the tiles
      nextTick(() => {
        props.portalPageVm.shownTiles.map((tile) => {
          tile.showTile = true;
          setTimeout(() => {
            tile.disableTransitions = false;
          }, 500);
        });
      });

      return gridItemLayouts;
    }

    function handleZoomChanged(ev: { oldFactor: number; newFactor: number }): void {
      state.tileGridService?.setZoom(ev.newFactor);
      const collisionHandler = state.tileGridConfig
        .layoutCollisionHandler as GridCollisionHandler;
      collisionHandler.enableZoomHandling({
        factor: ev.newFactor,
        previousFactor: ev.oldFactor,
      });
    }

    function setDefaultBackground(event: UIEvent): void {
      if (backgroundClicked(event)) {
        state.dashboardSelection.selectedDashboardTileId = null;
        state.dashboardSelection.selectedKpiTileId = null;
        backgroundChangeService.setDefaultBackgroundColor();
        statusBarService.updateBackgroundColor(null);
      }
    }

    function onSwiperStarted(): void {
      state.dashboardSelection.selectedElementIndex = null;
    }

    function onShownTilesChanged(
      newValues: PortalTileVm[],
      oldValues: PortalTileVm[]
    ): void {
      const deletedTiles = getDeletedTiles(newValues, oldValues);
      if (deletedTiles.length === 0) {
        return;
      }

      const scalingContexts = getScalingContexts(deletedTiles);
      deleteScalingContexts(scalingContexts);
    }

    function getDeletedTiles(
      newValues: PortalTileVm[],
      oldValues: PortalTileVm[]
    ): PortalTileVm[] {
      if (oldValues.length > newValues.length) {
        return oldValues.filter((ov) => !newValues.find((nv) => nv === ov));
      }
      return [];
    }

    function getScalingContexts(portalTileVms: PortalTileVm[]): IScalable[] {
      const scalingContexts: IScalable[] = [];
      portalTileVms.forEach((v) => scalingContexts.push(...v.portalScaledValueCopies));
      return scalingContexts;
    }

    function deleteScalingContexts(scalingContexts: IScalable[]): void {
      scalingContexts.forEach((scalingContext) => deleteScalingContext(scalingContext));
    }

    function deleteScalingContext(scalableValue: IScalable): void {
      const indexToRemove = state.sharedPageState.portaScalingContext.findIndex(
        (portalScalingContext) => portalScalingContext === scalableValue
      );
      if (indexToRemove === -1) {
        return;
      }

      state.sharedPageState.portaScalingContext.splice(indexToRemove, 1);
    }

    //
    // Watcher:
    // --------------------
    watch(() => state.tileGridConfig.colNum, onNumColumnsChanged, { immediate: true });
    function onNumColumnsChanged() {
      state.sharedPageState.numberOfGridColumns = state.tileGridConfig.colNum;
    }

    watch(
      [
        () => state.dashboardSelection.selectedDashboardTileId,
        () => state.dashboardSelection.selectedKpiTileId,
      ],
      () => {
        onSelectionChanged();
      }
    );
    function onSelectionChanged(): void {
      context.emit(
        PortalCommon.portal_dashboardSelectionChanged.name,
        state.dashboardSelection.selectedDashboardTileId,
        state.dashboardSelection.selectedKpiTileId
      );
    }

    watch(
      () => state.dashboardSelection.backgroundColor,
      () => {
        onSelectedKpiChanged();
      }
    );
    function onSelectedKpiChanged(): void {
      if (state.dashboardSelection.backgroundColor) {
        const bgColor = state.dashboardSelection.backgroundColor;
        backgroundChangeService.setBackgroundColor(bgColor);
        statusBarService.updateBackgroundColor(bgColor);
      } else {
        backgroundChangeService.setDefaultBackgroundColor();
        statusBarService.updateBackgroundColor(null);
      }
    }

    watch(() => props.isShowing, onIsShowing, { immediate: true });
    async function onIsShowing(): Promise<void> {
      if (!props.isShowing || state.sharedPageState.wasShown) {
        return;
      }

      state.sharedPageState.wasShown = true;
      watchDashboardsAndRefreshGrid();
    }

    watch(() => props.portalPageVm.shownTiles, onShownTilesChanged);

    async function scrollToTile(
      element: HTMLElement,
      childClassNames: string[]
    ): Promise<void> {
      if (!state.isScrollToTileAllowed) {
        return;
      }

      await nextTick();
      scrollHelper.scrollToChild(
        element,
        childClassNames,
        state.tileGridService as TileGridService
      );
    }

    function onMouseDown(event: MouseEvent): void {
      state.dashboardSelection.selectedElementIndex = null;
      if (backgroundClicked(event) && mediaQueries.isMouse) {
        state.documentClickHelper.mouseDown(event);
      }
    }

    function onMouseUp(event: MouseEvent): void {
      if (backgroundClicked(event) && mediaQueries.isMouse) {
        state.documentClickHelper.mouseUp(event);
      }
    }

    function onTouchStart(event: TouchEvent): void {
      state.dashboardSelection.selectedElementIndex = null;
      if (backgroundClicked(event)) {
        state.documentTouchHelper.touchStart(event);
      }
    }

    function onTouchEnd(event: TouchEvent): void {
      if (backgroundClicked(event)) {
        state.documentTouchHelper.touchEnd(event);
      }
    }

    return {
      // data
      helpTextCpsl,

      // reactive:
      state,
      cloningCpsl,
      ref_tileGrid,
      ref_cloneTile,
      ref_cloneDragArea,

      // Computed:
      errorMessage,
      needUpdateScalingContext,

      // Methods:
      onPortalScaledValuesInitialized,
      scrollToTile,
      onSwiperStarted,
    };
  },
});
</script>

<template>
  <div class="portalPageComponent" v-if="portalPageVm">
    <div v-if="portalPageVm.isLoading" class="interactProtect" />
    <div v-if="errorMessage" class="pageErrorBox">{{ errorMessage }}</div>
    <div
      v-else
      class="tile-grid-portal"
      v-on:mousemove="cloningCpsl.handleMousemove_OnGrid"
      v-on:mouseleave="cloningCpsl.handleMouseleave_OnGrid"
    >
      <TileGrid
        ref="ref_tileGrid"
        v-bind:tileGridConfiguration="state.tileGridConfig"
        v-bind:tileGridLayout.sync="state.gridLayout"
        v-on:tile-grid-service="state.tileGridService = $event"
      >
        <Tile
          v-for="tileVm in $props.portalPageVm.shownTiles"
          v-bind:key="tileVm.id"
          v-bind:data-id="tileVm.id"
          v-bind:layoutTile="tileVm.tileConfig"
          v-bind:class="{
            showTile: tileVm.showTile,
            disableTransitons: tileVm.disableTransitions,
          }"
          v-on:move="cloningCpsl.state.isNormalDragging = true"
          v-on:mouseup.native="cloningCpsl.handleDragend_AtTile"
        >
          <PortalTile
            v-bind:tileVm="tileVm"
            v-bind:isEditing="$props.isEditing"
            v-bind:sharedPageState="state.sharedPageState"
            v-bind:dashboardSelection="state.dashboardSelection"
            v-bind:class="{ opaqueTile: cloningCpsl.state.isCloneDragging }"
            v-bind:needUpdatePortalScalingContext="needUpdateScalingContext"
            v-on:common_focusElementChanged="scrollToTile"
            v-on:portal_scaledValueInitialized="onPortalScaledValuesInitialized"
            v-on:portalTile_deleteTile="cloningCpsl.onDeleteTile"
            v-on:portalTile_clone="cloningCpsl.onAutomaticClone"
            v-on:mouseenter.native="cloningCpsl.handleMouseenter_AtTile(tileVm)"
            v-on:mousedown.native="cloningCpsl.handleMousedown_AtTile"
            v-on:swiper-started="onSwiperStarted"
            v-on="$listeners"
          />
        </Tile>
        <Tile
          ref="ref_cloneTile"
          class="cloneTile disableTransitons"
          v-bind:key="'cloneTile'"
          v-bind:layoutTile="cloningCpsl.state.cloneTileConfiguration"
          v-on:move="cloningCpsl.handleDragmove_AtCloneTile"
          v-on:mouseup.native="cloningCpsl.handleDragend_AtCloneTile"
          v-on:mousedown.native="cloningCpsl.handleMousedown_AtCloneTile"
        >
          <div
            ref="ref_cloneDragArea"
            class="cloneTileContent"
            v-bind:data-helpText="helpTextCpsl.clonePortalTileText()"
            v-bind:class="[state.dragHandleClass]"
          />
        </Tile>
      </TileGrid>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../common/styles/media-queries.less";

.portalPageComponent {
  height: calc(100% - 4px);
  width: 100%;
  padding: 2px 0;
  position: relative;

  .interactProtect {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .tile-grid-portal {
    .bissantz-tile.cloneTile {
      opacity: 1;

      .cloneTileContent {
        height: calc(100% - 28px);
        width: 100%;
        cursor: copy;
      }
    }
  }

  .pageErrorBox {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="less">
.portalPageComponent {
  width: 100%;

  .tile-grid-portal {
    height: 100%;

    .tile-grid-frame {
      margin: 0;
    }

    // grid override styles
    .bissantz-tile-grid {
      background: none;

      .bissantz-tile.bissantz-placeholder-tile {
        background-color: var(--color_Weather0);
      }

      .bissantz-tile:not(.bissantz-placeholder-tile) {
        background: none;
        border: none;
      }

      .bissantz-tile {
        @keyframes blendInAnimation {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        opacity: 0;

        .opaqueTile {
          opacity: 0.3;
        }

        &.disableTransitons {
          transition: none;
        }

        &.showTile {
          animation: blendInAnimation;
          animation-duration: 0.4s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}
</style>
