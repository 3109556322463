import { SimpleWatchExpression } from "@/common/helper/vue-helper";
import { IVmWatchList } from "@/services/view-state-service/contract/vm-watch-list.interface";
import { DashboardVm } from "../../view-models/dashboard-vm";
import { StructureElementsVm } from "../../view-models/structure-elements-vm";

export class DashboardVmWatchList implements IVmWatchList {
  private _viewModel: DashboardVm = null;

  get(viewModel: DashboardVm): SimpleWatchExpression<unknown>[] {
    this._viewModel = viewModel;

    const watchList: SimpleWatchExpression<unknown>[] = [];
    const dashboardVm = this._viewModel;

    watchList.push(() => dashboardVm.sharedState.kpiScaleIndex);
    watchList.push(() => dashboardVm.sharedState.hiddenColumn);
    watchList.push(() => dashboardVm.sharedState.sparklineState.mode);
    watchList.push(() => dashboardVm.shownKpiTileVms.length);
    watchList.push(() => dashboardVm.filters.filters.length);

    dashboardVm.kpiTileVms.forEach((kpiTileVm) => {
      kpiTileVm.valueGroupVms.forEach((valueGroupVm) => {
        watchList.push(() => valueGroupVm.periodSwiperVm.activeIndex);
        watchList.push(() => valueGroupVm.structureElementsVm.isVisible);
        watchList.push(() => valueGroupVm.valueGroupSwiperVm.activeIndex);
        watchList.push(() => valueGroupVm.deltaValuesSwiperVm.activeIndex);
        valueGroupVm.kpiValues.forEach((kpiValue) =>
          watchList.push(() => kpiValue.excludedFromScaling)
        );
        if (valueGroupVm.structureElementsVm.isVisible) {
          const drillWatchList = this._getDrillsWatchList(
            valueGroupVm.structureElementsVm
          );
          watchList.push(...drillWatchList);
        }
      });
    });

    return watchList;
  }

  private _getDrillsWatchList(
    structureElementsVm: StructureElementsVm
  ): SimpleWatchExpression<unknown>[] {
    const watchList: SimpleWatchExpression<unknown>[] = [];

    if (structureElementsVm.structureElementsSwiperVm) {
      watchList.push(() => structureElementsVm.structureElementsSwiperVm.activeIndex);
    }
    watchList.push(() => structureElementsVm.sortedColumn);
    watchList.push(() => structureElementsVm.sortType);
    structureElementsVm.structureElementsListVms.forEach((structureElementsList) => {
      watchList.push(() => structureElementsList.isPercentageMode);
      if (structureElementsList.deltaValuesSwiperVm) {
        watchList.push(() => structureElementsList.deltaValuesSwiperVm.activeIndex);
      }
    });

    structureElementsVm.currentElementVms.forEach((elementVm) => {
      watchList.push(() => elementVm.nextStructureElements.isVisible);
      if (elementVm.nextStructureElements.isVisible) {
        const drillWatchList = this._getDrillsWatchList(elementVm.nextStructureElements);
        watchList.push(...drillWatchList);
      }
    });

    return watchList;
  }
}
