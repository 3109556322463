<script lang="ts">
/**
 * USAGE:
 *
 * Create the Swiper template with the mandatory properties
 * and the component you want to swipe:
 * <Swiper
 *  v-bind:swiperVm="swiperVm"
 *  v-bind:itemsVms="myItems"
 *   <MyComponent
 *     slot="swipeItems"
 *     slot-scope="props"
 *   >
 *   </MyComponent>
 * </Swiper>
 *
 * Properties for the swiper:
 *  - swiperVm (SwiperVm.ts): the SwiperVm.
 *  - itemsVms (Any array of viewModels for yor component).
 *
 * Optional properties for the swiper:
 *  - blockSwipe (boolean): it allows you to block the swiper.
 *
 *  Optional Swiper properties for your component:
 * - swipeItem: is the viewModel of the swipe item
 * - index: is the swipeItem index
 * - realIndex: is the index of the swipeItem based
 *      on your input viewModels array.
 * - activeIndex: is the index of the current active swipeItem.
 * - activeItem: the view model of the current active swipeItem.
 *
 * Optional Swiper properties for your component Usage:
 * Example:
 *   <MyComponent
 *     slot="swipeItems"
 *     slot-scope="props"
 *     v-bind:myComponentVm="props.swipeItem"
 *     v-bind:myFlag="myMethod(props.realIndex)"
 *   >
 *   </MyComponent>
 *
 * Optional events:
 * - swiper-started: the user started swiping.
 * - swiper-moved: the user is moving the swiper.
 * - swiper-stopped: the user stopped moving the swiper.
 * - swiper-finished: after the user stopped moving the
 *      swiper, the auto scroll is started, and it scrolls until the
 *      swipe is complete. after completing the swipe the
 *      'swiper-finished' event is triggered.
 *
 * Optional events Usage:
 * <Swiper
 *  v-bind:swiperVm="swiperVm"
 *  v-bind:itemsVms="myItems"
 *  v-on:swiper-started="myMethod"
 *  v-on:swiper-moved="myMethod"
 *  v-on:swiper-stopped="myMethod"
 *  v-on:swiper-finished="myMethod">
 *  ...
 * </Swiper>
 *
 * Optional Useful SwiperVm properties:
 * - scrolledPixel (number): indicates the number of pixels
 *      the user moved the swiper.
 * - isMoving (boolean): the user is moving the swiper.
 * - isSwiping (boolean): indicates if the auto swipe is on (only after the
 *      user finished moving the swiper).
 *
 * Optional Useful SwiperVm properties Usage:
 * Example:
 * @Watch("swiperVm.isMoving || swiperVm.isSwiping")
 * myMethod(): void { ... }
 */

import { reactive, watch, computed, defineComponent, PropType, onMounted } from "vue";
import { SwiperVm } from "./swiper-vm";
import { SwiperEvents } from "./swiper-events";
import { ref, onBeforeUnmount } from "vue";
import { Unobserver, resizeHelper } from "@/common/resize-helper";
import { getGUID } from "@/common/helper/guid-helper";
import { useCompAddress } from "@/common/composables/use-comp-address.cpsl";

export const compType = "swiper";

const throttleMove_ms = 20;

export default defineComponent({
  emits: [
    SwiperEvents.swiperStarted,
    SwiperEvents.swiperMoved,
    SwiperEvents.swiperStopped,
    SwiperEvents.swiperfinished,
  ],

  props: {
    swiperVm: { type: Object as PropType<SwiperVm | null>, required: true },
    itemsVms: { type: Array as PropType<any[]>, required: true },
    blockSwipe: Boolean,
    zoomFactor: { type: Number, required: false, default: 1 },
    alignItems: { type: String, default: "center" },
  },

  setup(props, context) {
    const ref_swiperComponent = ref<HTMLDivElement | null>(null);
    const ref_swiperSection = ref<HTMLDivElement | null>(null);
    const compId = getGUID();
    const { isElementFromType } = useCompAddress(ref_swiperComponent, compType);

    const state = reactive({
      swipeItems: [] as any[],
      throttleMoveTimeoutId: null as ReturnType<typeof setTimeout>,
      blockScroll: null as boolean,
      unobserveCallback: null as Unobserver,
      dynamicWidth: null as number,
    });

    //
    // Life Cycle:
    // --------------------
    init();

    onMounted(() => {
      props.swiperVm.initialized = true;
      state.unobserveCallback = resizeHelper.observe(
        ref_swiperComponent.value,
        setDynamicWidth
      );
    });

    onBeforeUnmount(() => {
      _removeEndEvents();
      _disableAnimation();
      state.unobserveCallback?.();
    });

    //
    // Computeds:
    // --------------------
    const swiperStyle = computed(() => {
      return {
        "--alignItems": props.alignItems,
        "--itemWidth": widthPerItem_percent.value,
      };
    });

    const swiperSectionStyle = computed(() => {
      return {
        "--xTranslate": xTranslate_px.value,
        // TODO: remove --activeIndex after removing sharing.
        "--activeIndex": props.swiperVm.activeIndex,
      };
    });

    const widthPerItem_percent = computed<string>(() => {
      const percent = state?.dynamicWidth
        ? 100 / props.swiperVm.numberOfItemsToDisplay
        : 100;

      return percent + "%";
    });

    const xTranslate_px = computed<string>(() => {
      if (!state?.dynamicWidth) {
        return "0";
      }

      const swiperVm = props.swiperVm;
      const widthPerItem_px = state.dynamicWidth / swiperVm.numberOfItemsToDisplay;
      const resultValue = swiperVm.scrolledPixel - widthPerItem_px * swiperVm.activeIndex;

      return resultValue + "px";
    });

    //
    // Functions:
    // --------------------
    function init() {
      initializeItems();
      props.swiperVm.setCallbacks(_updateData, _enableAnimation, _disableAnimation);
    }

    function setDynamicWidth(entry: ResizeObserverEntry): void {
      state.dynamicWidth = entry.contentRect.width;
    }

    function onMouseDown(ev: MouseEvent): void {
      if (!isThisSwiper(ev)) {
        return;
      }
      if (_isBlocked(ev)) {
        return;
      }
      if (!_isLeftDownInEvent(ev)) {
        return;
      }
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
      ref_swiperComponent.value.addEventListener("mouseup", onMouseUp);
      start(ev.clientX, ev.clientY);
    }

    function onMouseMove(ev: MouseEvent): void {
      if (_isBlocked(ev)) {
        return;
      }
      if (!_isLeftDownInEvent(ev) && props.swiperVm.swipeStarted) {
        onMouseUp(ev);
        return;
      }

      move(ev.clientX, ev.clientY, ev);
    }

    function onMouseUp(ev: MouseEvent): void {
      if (_isBlocked(ev)) {
        return;
      }
      stop(ev.clientX);
    }

    function onTouchStart(ev: TouchEvent) {
      if (!isThisSwiper(ev)) {
        return;
      }
      if (_isBlocked(ev)) {
        return;
      }
      document.addEventListener("touchmove", onTouchMove);
      document.addEventListener("touchend", onTouchEnd);
      start(ev.changedTouches[0].clientX, ev.changedTouches[0].clientY);
    }

    function onTouchMove(ev: TouchEvent) {
      if (_isBlocked(ev)) {
        return;
      }
      move(ev.changedTouches[0].clientX, ev.changedTouches[0].clientY, ev);
    }

    function onTouchEnd(ev: TouchEvent) {
      if (_isBlocked(ev)) {
        return;
      }
      stop(ev.changedTouches[0].clientX);
    }

    function start(positionX: number, positionY: number) {
      _startEndEventsListener();
      context.emit(SwiperEvents.swiperStarted);
      _setItemWidth();
      props.swiperVm.startScroll(positionX, positionY);
    }

    function move(positionX: number, positionY: number, ev: UIEvent) {
      if (!props.swiperVm.swipeStarted) {
        return;
      }
      throttleMove(positionX, positionY, ev);
    }

    function throttleMove(positionX: number, positionY: number, ev: UIEvent): void {
      if (state.blockScroll) {
        return;
      }
      state.blockScroll = true;

      props.swiperVm.scroll(positionX, positionY);

      const scrollThresholdHit = Math.abs(props.swiperVm.scrolledPixel) > 5;

      if (scrollThresholdHit) {
        context.emit(SwiperEvents.swiperMoved);
        _preventScrollingOnTile(ev);
      }
      state.throttleMoveTimeoutId = setTimeout(() => {
        state.blockScroll = false;
      }, throttleMove_ms);
    }

    function stop(positionX: number) {
      _removeEndEvents();
      clearTimeout(state.throttleMoveTimeoutId);
      state.blockScroll = false;
      props.swiperVm.stopScroll(positionX);
      context.emit(SwiperEvents.swiperStopped);
    }

    function initializeItems(): void {
      state.swipeItems = props.swiperVm.getSwipeItems(props.itemsVms);
    }

    function _setItemWidth(): void {
      const swiper = ref_swiperComponent.value;
      if (swiper) {
        const rect = swiper.getBoundingClientRect();
        props.swiperVm.itemWidth = rect.width;
      }
    }

    function _startEndEventsListener(): void {
      setTimeout(() => {
        if (props.swiperVm.swipeStarted || props.swiperVm.isMoving)
          _startEndEventsListener();
        else _removeEndEvents();
      }, 400);
    }

    function _removeEndEvents(): void {
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      ref_swiperComponent.value?.removeEventListener("mouseup", onMouseUp);
    }

    function _updateData(): void {
      context.emit(SwiperEvents.swiperfinished);
    }

    function _enableAnimation(): void {
      const swiperSection = ref_swiperSection.value;
      if (swiperSection)
        swiperSection.addEventListener("transitionend", _transitionCompleted);
    }

    function _disableAnimation(): void {
      const swiperSection = ref_swiperSection.value;
      if (swiperSection)
        swiperSection.removeEventListener("transitionend", _transitionCompleted);
    }

    function _transitionCompleted(ev: TransitionEvent) {
      if (ev && ev.propertyName === "transform") props.swiperVm.animationCompleted();
    }

    function isThisSwiper(ev: UIEvent): boolean {
      const firstSwiper = findFirstSwiper(ev.target as HTMLElement);
      return firstSwiper === ref_swiperComponent.value;
    }

    function findFirstSwiper(element: HTMLElement): HTMLElement {
      if (!element) {
        throw new Error("No swiper was found. At least one swiper should exist.");
      }
      if (isElementFromType(element, compType)) {
        return element;
      }
      return findFirstSwiper(element.parentElement);
    }

    function _isLeftDownInEvent(evt: MouseEvent): boolean {
      return evt.buttons % 2 !== 0;
    }

    function _isBlocked(ev: UIEvent): boolean {
      if (props.blockSwipe) {
        props.swiperVm.cancelScroll();
        return true;
      }
      if (props.swiperVm.infiniteLoop && props.swiperVm.numberOfItems <= 3) {
        ev.stopPropagation();
        return true;
      }
      return false;
    }

    function _preventScrollingOnTile(ev: UIEvent): void {
      ev.preventDefault();
    }

    //
    // Watcher:
    // --------------------
    watch(() => props.itemsVms, initializeItems);

    return {
      // state & etc
      state,
      compId,
      ref_swiperSection,
      ref_swiperComponent,

      // computeds:
      swiperStyle,
      swiperSectionStyle,

      // functions:
      onMouseDown,
      onMouseMove,
      onTouchStart,
      onTouchMove,
    };
  },
});
</script>

<template>
  <div
    ref="ref_swiperComponent"
    class="swiper"
    v-bind:style="swiperStyle"
    v-on:mousedown="onMouseDown"
    v-on:mousemove="onMouseMove"
    v-on:touchstart="onTouchStart"
    v-on:touchmove="onTouchMove"
  >
    <div
      ref="ref_swiperSection"
      class="swiperSection"
      v-bind:class="{
        shifting: $props.swiperVm.isSwiping,
        swiperCursorLeftRight: !$props.blockSwipe && $props.itemsVms.length > 1,
      }"
      v-bind:style="swiperSectionStyle"
    >
      <div
        class="swipeItem"
        v-for="(swipeItem, index) in state.swipeItems"
        v-bind:key="index"
      >
        <slot
          name="swipeItems"
          v-bind:swipeItem="swipeItem"
          v-bind:index="index"
          v-bind:realIndex="$props.swiperVm.getRealIndex(index)"
          v-bind:activeIndex="$props.swiperVm.activeIndex"
          v-bind:activeItem="state.swipeItems[$props.swiperVm.activeIndex]"
        ></slot>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.swiper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  .swiperSection {
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    display: flex;
    align-items: var(--alignItems);
    width: 100%;

    transform: translateX(var(--xTranslate));

    .swipeItem {
      min-width: var(--itemWidth);
      height: 100%;
    }
  }

  .shifting {
    transition: transform 0.6s ease-out;
  }
}
</style>
