import { NotificationData, NotificationService } from "./notification-service";

const demoData = {
  fastCheck: {
    messageData: {
      title: "In Zwischenablage kopiert",
      message: "",
    },
    type: "check",
  } as NotificationData,
  check: {
    messageData: {
      title: "Import erfolgreich",
      message:
        "Die folgende Dateien wurden importiert:\n\n- AzureCosts.db\n- V3_IoT_kpiSwitchExample.db\n- 16299.db",
    },
    type: "check",
  } as NotificationData,
  error: {
    messageData: {
      title: "Teilen nicht möglich",
      message: `Ihr Browser unterstützt das Teilen von Dateien nicht.\n\nc:\\Users\\brachvogel\\Downloads\\2023-06-28 10_28_27-Clipboard.png`,
    },
    type: "error",
  } as NotificationData,
  errorImport: {
    messageData: {
      title: "Coudn't import files",
      message: `One or more files didn't have the file type '*db'. For some reason here (for demo purpose) is a much longer text. The actual error applies to:\n\nc:\\Users\\brachvogel\\Downloads\\2023-06-28 10_28_27-Clipboard.png\nc:\\Users\\brachvogel\\Downloads\\2023-06-28 10_28_27-Clipboard2.png\n\n`,
    },
    type: "error",
  } as NotificationData,
};

export function registerDemos(service: NotificationService) {
  document["notiRun"] = {
    toggleAutoClose() {
      service.isAutoCloseEnabled = !service.isAutoCloseEnabled;
    },
    fastCheck() {
      service.notify(demoData.fastCheck);
    },
    check() {
      service.notify(demoData.check);
    },
    error() {
      service.notify(demoData.error);
    },
    errorImport() {
      service.notify(demoData.errorImport);
    },
  };
  document["notiService"] = service;
  document["notiDemoData"] = demoData;
}

export function unregisterDemos() {
  document["notiRun"] = undefined;
  document["notiService"] = undefined;
  document["notiDemoData"] = undefined;
}
