<script lang="ts">
import { defineComponent, PropType } from "vue";
import Swiper from "@/common/components/swiper.vue";
import SortCell, { SortCellType } from "@/common/components/cell-basics/sort-cell.vue";
import type { CSSProperties } from "vue/types/jsx";
import { SwiperVm } from "@/common/components/swiper-vm";

export default defineComponent({
  components: { SortCell, Swiper },
  emits: [],
  props: {
    swiperVm: { type: Object as PropType<SwiperVm>, required: true },
    swipeItems: { type: Array as PropType<number[]>, required: true },
    zoomFactor: { type: Number, default: 1 },
    sortedIndex: { type: Number, required: true },
    binarySort: { type: Boolean, default: true },
    color: { type: String, required: true },
    clickAreaStyle: { type: Object as PropType<CSSProperties>, required: true },
    sortTypes: { type: Array as () => SortCellType[], required: true },
  },
  setup() {
    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------

    //
    // Functions:
    // --------------------

    //
    // Watcher:
    // --------------------

    return {};
  },
});
</script>

<template>
  <Swiper
    class="swipeable-sort-cell"
    v-bind:swiperVm="$props.swiperVm"
    v-bind:itemsVms="$props.swipeItems"
    v-bind:zoomFactor="$props.zoomFactor"
    v-on="$listeners"
  >
    <template v-slot:swipeItems="sProps">
      <SortCell
        v-bind:index="sProps.swipeItem"
        v-bind:sortedIndex="$props.sortedIndex"
        v-bind:binarySort="$props.binarySort"
        v-bind:color="$props.color"
        v-bind:clickAreaStyle="$props.clickAreaStyle"
        v-bind:sortType="$props.sortTypes[sProps.realIndex]"
        v-on="$listeners"
      />
    </template>
  </Swiper>
</template>

<style scoped lang="less">
.swipeable-sort-cell {
  width: 100%;
  height: 100%;
}
</style>
