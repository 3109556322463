<script lang="ts">
import XIcon from "@/common/components/icons/x-icon.vue";
import AppWizardDatasource from "./tabs/app-wizard-datasource.vue";
import AppWizardApplication from "./tabs/app-wizard-application.vue";
import AppWizardModelling from "./tabs/app-wizard-modelling.vue";
import AppWizardTabsOverview from "./helper-components/app-wizard-tabs-overview.vue";

import { appResources } from "@/app-resources";
import { DefaultStyles } from "@/common/styles/default-styles";
import { inject, reactive, defineComponent, computed, onBeforeUnmount } from "vue";
import { AppWizardSwitch } from "./app-wizard-switch";
import { AppWizardVm, WizardFinishedEventArgs } from "./view-models/app-wizard-vm";
import { useBackendDependencies } from "./backend-wrapper/backend-dependencies.cpsl";
import { useTransaction } from "@/common/transactions/transaction.cspl";
import { PortalSelectionVm } from "../portal/view-models/portal-selection-vm";
import { PortalVm } from "./view-models/portal-vm";
import { Application } from "@bissantz/smartforms";

export default defineComponent({
  components: {
    XIcon,
    AppWizardDatasource,
    AppWizardApplication,
    AppWizardModelling,
    AppWizardTabsOverview,
  },

  setup() {
    const appWizardSwitch: AppWizardSwitch = inject("appWizardSwitch");
    const portalSelection = inject("portalSelection") as PortalSelectionVm;
    const application = inject("application") as Application;

    const { connectionFacade, applicationFacade, modelFacade, portalFacade } =
      useBackendDependencies();
    const transactionFacade = useTransaction();

    const colorIcon = DefaultStyles.colorConstants.bgGray;

    const portalViewModel = new PortalVm(
      portalFacade,
      portalSelection.portalId,
      portalSelection.portalPageId
    );

    const appWizardViewModel = new AppWizardVm(
      connectionFacade,
      applicationFacade,
      modelFacade,
      transactionFacade,
      portalViewModel
    );

    const state = reactive({
      appWizardTabs: [
        {
          name: appResources.applicationWizardTexts.general_labelDatasource,
          component: "app-wizard-datasource",
          iconComponent: "database-icon", //Just placeholder --> user new icon if available (ROS)
        },
        {
          name: appResources.applicationWizardTexts.general_labelApplication,
          component: "app-wizard-application",
          iconComponent: "clone-icon", //Just placeholder --> user new icon if available (ROS)
        },
        {
          name: appResources.applicationWizardTexts.general_labelModelling,
          component: "app-wizard-modelling",
          iconComponent: "edit-icon", //Just placeholder --> user new icon if available (ROS)
        },
      ],
      activeTab: 0,
      appWizardVm: appWizardViewModel,
      activeTabViewModel: appWizardViewModel.dataSourceVm as unknown,
      isNavigatingToNextStep: false,
      disposableListeners: [],
    });

    state.disposableListeners.push(
      appWizardViewModel.wizardFinished.on(onWizardFinished)
    );

    onBeforeUnmount(() => {
      state.disposableListeners.map((disposer) => disposer.dispose());
      appWizardViewModel?.dispose();
    });

    const showTemplate = computed(() => {
      return !state.appWizardVm.isSendingDataToBackend && !state.isNavigatingToNextStep;
    });

    async function onWizardCancelled() {
      await state.appWizardVm.cancel();
      appWizardSwitch.enabled = false;
    }

    async function onWizardStepConfirmed() {
      state.isNavigatingToNextStep = true;

      const newStepNumber = state.activeTab + 1;

      state.activeTabViewModel = await state.appWizardVm.getViewModelForStep(
        newStepNumber
      );

      state.activeTab = newStepNumber;

      state.isNavigatingToNextStep = false;
    }

    async function onWizardFinished(e: WizardFinishedEventArgs) {
      if (e.wizardSucceeded && e.portalTileHasBeenCreated) {
        await reloadPortalAsync();
      }
    }

    async function reloadPortalAsync(): Promise<void> {
      await application.content.find((c) => c.contentId == "portal").navigate();
    }

    return {
      // general
      state,

      // refs:
      appWizardSwitch,
      colorIcon,

      //functions:
      onWizardCancelled: onWizardCancelled,
      onWizardStepConfirmed: onWizardStepConfirmed,

      // computed
      showTemplate,
    };
  },
});
</script>

<template>
  <div class="application-wizard">
    <div v-on:click="appWizardSwitch.enabled = false" class="close">
      <XIcon v-bind:initialColor="colorIcon" />
    </div>
    <AppWizardTabsOverview
      v-bind:activeTab="state.activeTab"
      v-bind:names="state.appWizardTabs.map((tab) => tab.name)"
      v-bind:iconComponent="state.appWizardTabs.map((tab) => tab.iconComponent)"
    />

    <component
      class="active-component"
      v-bind:is="state.appWizardTabs.map((tab) => tab.component)[state.activeTab]"
      v-bind:viewModel="state.activeTabViewModel"
      v-bind:showTabTemplate="showTemplate"
      v-on:confirmed="onWizardStepConfirmed"
      v-on:cancelled="onWizardCancelled"
    />
  </div>
</template>

<style lang="less" scoped>
@import "../../common/styles/base-styles.less";

.application-wizard {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  background-color: var(--color_bg_white);
  box-shadow: 0px 0px 12px 6px #0000000d;
  padding: 30px;
  border: none;
  width: clamp(200px, 80vw, 780px);
  height: 545px;
  border-radius: 6px;
  overflow: hidden;

  .close {
    position: absolute;
    right: 14px;
    top: 13px;
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
}
</style>
