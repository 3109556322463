import { Timeouts } from "./dom-event-helper";
import { TouchHelper } from "./touch-helper";

// This Helper class allows you to execute any method
// after a specific click action occurred.
//
// Available Actions:
// - Click (any mouse button)
// - Left Click
// - Right Click
// - Double Click (left button)
// - Long Click (left button)
//      mouse is down for a long time.
// - Long Click finished(left button)
//      mouse was down for a long time and is up again.
//
// How to use this Helper class:
//
// Create a new instance:
// this.clickHelper = new ClickHelper();
//
// Set the actions you need. Example:
// Left Click:
// this.clickHelper.setOnLeftClickAction(() => mymethod(event));
// this.clickHelper.seOnLeftClickAction(myMethod);
//
// (Optional)Set to ignore click actions if touch occurred:
// this.clickHelper.touchHelper = this.touchHelper;
//
// Link the onmousedown event with the clickHelper:
// onMouseDown(event: MouseEvent): void {
//  this.clickHelper.onMouseDown(event);
// }
//
// Link the onmouseup event with the clickHelper:
// onMouseUp(event: MouseEvent): void {
//  this.clickHelper.onMouseUp(event);
// }

export type MouseAction = (ev?: MouseEvent) => void;

export class ClickHelper {
  private _isClick: boolean = false;
  private _isLongClick: boolean = false;

  private _clickTimeoutId: NodeJS.Timeout = null;
  private _longClickTimeoutId: NodeJS.Timeout = null;
  private _clickCounter: number = 0;

  private _clickAction: MouseAction = null;
  private _leftClickAction: MouseAction = null;
  private _rightClickAction: MouseAction = null;
  private _doubleClickAction: MouseAction = null;
  private _longClickAction: MouseAction = null;
  private _longClickFinishedAction: MouseAction = null;

  touchHelper: TouchHelper = null;
  isMouseDown: boolean = false;

  private get _isTouch(): boolean {
    return this.touchHelper?.isTouch;
  }

  setOnClickAction(action: MouseAction): void {
    this._clickAction = action;
  }

  setOnLeftClickAction(action: MouseAction): void {
    this._leftClickAction = action;
  }

  setOnRightClickAction(action: MouseAction): void {
    this._rightClickAction = action;
  }

  setOnDoubleClickAction(action: MouseAction): void {
    this._doubleClickAction = action;
  }

  setOnLongClickAction(action: MouseAction): void {
    this._longClickAction = action;
  }

  setOnLongClickFinishedAction(action: MouseAction): void {
    this._longClickFinishedAction = action;
  }

  mouseDown(ev: MouseEvent = null): void {
    if (this._isTouch) return;

    this.isMouseDown = true;
    this._initClick();
    this._initLongClick(ev);
  }

  mouseUp(ev: MouseEvent = null): void {
    this.isMouseDown = false;
    clearTimeout(this._longClickTimeoutId);
    if (this._isTouch) return;

    if (this._isLongClick) {
      this._longClickFinishedAction(ev);
    }
    if (this._isClick) {
      if (this._doubleClickAction) {
        this._clickCounter++;
        if (this._clickCounter === 1) {
          this._onSimpleClick(ev);
        } else if (this._clickCounter === 2) {
          this._onDoubleClick(ev);
        }
        ev?.stopPropagation();
      } else {
        this._onClick(ev);
      }
    }
  }

  private _initClick(): void {
    if (
      !this._clickAction &&
      !this._leftClickAction &&
      !this._rightClickAction &&
      !this._doubleClickAction
    ) {
      return;
    }
    this._isClick = true;
    clearTimeout(this._clickTimeoutId);
    this._clickTimeoutId = setTimeout(() => (this._isClick = false), 250);
  }

  private _initLongClick(ev: MouseEvent): void {
    this._isLongClick = false;
    clearTimeout(this._longClickTimeoutId);
    if (this._longClickAction) {
      this._longClickTimeoutId = setTimeout(
        () => this._onLongClick(ev),
        Timeouts.longClickTime_ms
      );
    }
  }

  private _onSimpleClick(ev: MouseEvent): void {
    clearTimeout(this._clickTimeoutId);
    this._clickTimeoutId = setTimeout(() => {
      if (this._clickCounter === 1) {
        this._onClick(ev);
      }
      this._clickCounter = 0;
    }, Timeouts.doubleClickTime_ms);
  }

  private _onClick(ev: MouseEvent): void {
    if (this._clickAction) this._clickAction(ev);
    if (this._leftClickAction && this._isLeftClick(ev)) this._leftClickAction(ev);
    else if (this._rightClickAction && this._isRightClick(ev)) this._rightClickAction(ev);
  }

  private _onDoubleClick(ev: MouseEvent): void {
    this._clickCounter = 0;
    if (this._doubleClickAction) {
      this._doubleClickAction(ev);
    }
  }

  private _onLongClick(ev: MouseEvent): void {
    this._isLongClick = true;
    this._longClickAction(ev);
  }

  private _isRightClick(ev: MouseEvent): boolean {
    return ev?.button === 2;
  }

  private _isLeftClick(ev: MouseEvent): boolean {
    return ev?.button === 0;
  }
}
