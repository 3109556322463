export class DashboardSelection {
  private _selectedDashboardTileId: string = null;
  private _selectedKpiTileId: number = null;
  private _selectedElementIndex: number = null;
  private _backgroundColor: string = null;
  private _isBlocked: boolean = false;
  private _blockedTimeoutId: NodeJS.Timeout = null;

  set selectedDashboardTileId(value: string) {
    if (this.isBlocked) {
      return;
    }
    this._selectedDashboardTileId = value;
  }

  set selectedKpiTileId(value: number) {
    if (this.isBlocked) {
      return;
    }
    this._selectedKpiTileId = value;
  }

  set backgroundColor(value: string) {
    this._backgroundColor = value;
  }

  set selectedElementIndex(value: number) {
    this._selectedElementIndex = value;
  }

  get backgroundColor(): string {
    return this._backgroundColor;
  }

  get selectedDashboardTileId(): string {
    return this._selectedDashboardTileId;
  }

  get selectedKpiTileId(): number {
    return this._selectedKpiTileId;
  }

  get selectedElementIndex(): number {
    return this._selectedElementIndex;
  }

  get isBlocked(): boolean {
    return this._isBlocked;
  }

  isKpiSelected(dashboardTileId: string, kpiTileId: number): boolean {
    return (
      this.selectedDashboardTileId === dashboardTileId &&
      this.selectedKpiTileId === kpiTileId
    );
  }

  setSelectedKpi(dashboardTileId: string, kpiTileId: number): void {
    if (this.isBlocked) {
      return;
    }

    if (!this.isKpiSelected(dashboardTileId, kpiTileId)) {
      this.backgroundColor = null;
    }

    this.selectedKpiTileId = kpiTileId;
    this.selectedDashboardTileId = dashboardTileId;
  }

  blockSettingNewValues(): void {
    clearTimeout(this._blockedTimeoutId);
    this._isBlocked = true;
    this._blockedTimeoutId = setTimeout(() => {
      this._isBlocked = false;
    }, 900);
  }
}
