export const gridConstants = {
  minimalTileRows: 4, // number of rows for the smalles tile
  grid: {
    rowHeight: 52.8,
    bottomFreeRows: 4,
    minColWidth: 200,
    marginHorizontalPx: 40,
    marginVerticalPx: 0,
  },
};
