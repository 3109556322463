import debounce from "lodash/debounce";
import { IStatusBarService } from "../status-bar-service.interface";
import { appResources } from "@/app-resources";
import { removedFromBodyObserver } from "@/common/helper/removed-from-body-observer";

export class HelpTextService {
  watcherDebounceTime_ms = 200;
  watcherPointerUpTime_ms = 400;
  statusBarService: IStatusBarService = null;
  attributeName: string = "helptext";
  textsOfOverlappingElements: string[];
  private _lastTarget: HTMLElement = null;

  constructor(statusBarService: IStatusBarService) {
    this.statusBarService = statusBarService;
    this._addEventListeners();
    this.setOverlappingTexts();
  }

  setOverlappingTexts(): void {
    this.textsOfOverlappingElements = [
      appResources.helpTexts.excludeFromScaling,
      appResources.helpTexts.includeToScaling,
      appResources.helpTexts.drillElement,
      appResources.helpTexts.closeElementDrill,
      appResources.helpTexts.toggleElementInformationValue,
      appResources.helpTexts.changeElementDeviation,
      appResources.helpTexts.changePeriod,
    ];
  }

  updateHelpText(target: HTMLElement): void {
    this._unobserveLastTarget();

    if (!target) {
      return;
    }

    const kpiDrillInformation = this.statusBarService.getCurrentElementStatusText();
    const statusText = [kpiDrillInformation, ...this.getHelpTextAttributes(target)]
      .filter((text) => text.length > 0)
      .join(" | ");

    if (statusText.length > 0) {
      this.statusBarService.showText(statusText, true);
      this._observeTarget(target);
    } else {
      this.statusBarService.hideText();
    }
  }

  getHelpTextAttributes(element: HTMLElement | null, helpTexts: string[] = []): string[] {
    if (element == null) {
      return helpTexts;
    }

    const text = element?.dataset[this.attributeName];
    const isUniqueOverlappingText =
      this.textsOfOverlappingElements.includes(text) && !helpTexts.includes(text);

    if (text != null && (helpTexts.length === 0 || isUniqueOverlappingText)) {
      helpTexts.push(text);
    }

    return this.getHelpTextAttributes(element.parentElement, helpTexts);
  }

  private _addEventListeners(): void {
    window.addEventListener("pointerup", this._handlePoinerUp.bind(this), {
      passive: false,
    });
    window.addEventListener("pointermove", this._onDashboardChangeDebounced.bind(this));
  }

  private async _handlePoinerUp(ev: PointerEvent) {
    if (ev.target !== null && ev.pointerType === "mouse") {
      setTimeout(() => {
        this.updateHelpText(ev.target as HTMLElement);
      }, this.watcherPointerUpTime_ms);
    }
  }

  private _onDashboardChangeDebounced = debounce((ev: PointerEvent) => {
    if (ev.target !== null && ev.pointerType === "mouse") {
      this.updateHelpText(ev.target as HTMLElement);
    }
  }, this.watcherDebounceTime_ms);

  private _observeTarget(target: HTMLElement): void {
    this._lastTarget = target;
    removedFromBodyObserver.observe(target, this._onTargetRemoved.bind(this));
  }

  private _unobserveLastTarget(): void {
    if (!this._lastTarget) {
      return;
    }

    removedFromBodyObserver.unobserve(this._lastTarget);
    this._lastTarget = null;
  }

  private _onTargetRemoved(): void {
    // As soon as one target disappears, all the help texts are removed.
    // Not just the help texts from the target.
    const currentText = this.statusBarService.getCurrentElementStatusText();
    if (currentText.length === 0) {
      this.statusBarService.hideText();
    } else {
      this.statusBarService.showText(currentText);
    }
  }
}
