import { SharedSparklineState } from "../shared-sparkline-state";
import { computed } from "vue";

export function useAnimationCounter(sharedSparklineState: SharedSparklineState) {
  let _isAnimating = false;

  const isAnimating = computed({
    get(): boolean {
      return _isAnimating;
    },

    set(value: boolean) {
      if (_isAnimating === value) {
        sharedSparklineState.numAnimatingSparklines = 0;
        return;
      }

      _isAnimating = !_isAnimating;

      if (value) {
        sharedSparklineState.numAnimatingSparklines++;
      } else {
        sharedSparklineState.numAnimatingSparklines--;
      }
    },
  });

  return { isAnimating };
}
