import { clamp } from "lodash";
import { SwipeDetectionHelper } from "../swipe-detection-helper";

/**
 * There are 3 available modes:
 * Infinite Loop:
 *  ... | B | C | A | B | C | ...
 *
 * Non-infinite Loop (two options):
 *  | A | B | C |
 *
 *  - Option 1:
 *  the swiper stops after reaching the first or the
 *  last item.
 *
 *  - Option 2:
 *  the swiper jumps to the other end after reaching
 *  the first or the last item.
 *
 *
 * In order to have a valid Swipe, the user needs to swipe
 * a minimum amount of pixels. If the minimum is reached,
 * the swipe is valid and the next item will be displayed. If
 * the minimum is not reached, the swiper will go back to
 * the original item start position.
 *
 * Example:
 * The Users starts swiping:
 * |.....A...|
 *
 * The Users release the swiper:
 * |....A....| swiper goes back to the initial position.
 *
 * This time the user swipes a little bit more:
 * |.......A.|
 *
 * The User releases the swiper:
 * |....C....| swipe is valid, the swiper goes to the next item.
 *
 *
 * To be considered:
 * The infinite loop swiper version adds two items to the
 * view models array. That is the reason why there is an index and
 * a real index, or why the number of items does sometime not
 * correspond to the number of items of the view models array.
 *
 *
 * Number of items to display:
 *  The normal use case is to show one item:
 *  | A |
 *
 *  It is possible to display more than one item:
 *  | A  B |
 *
 *
 * USAGE:
 *
 * Create a swiperVm instance:
 *  const numberOfItemsToDisplay = 1;
 *  const initialActiveIndex = 1;
 *  const infiniteLoop = true;
 *  const swiperVm = new SwiperVm(numberOfItemsToDisplay, initialActiveIndex, infiniteLoop);
 *
 * Insert the swiperVm instance in to the Swiper component:
 *  <Swiper
 *  v-bind:swiperVm="swiperVm"
 *  v-bind:itemsVms="myItems"
 *   <MyComponent
 *     slot="swipeItems"
 *     slot-scope="props"
 *   >
 *   </MyComponent>
 * </Swiper>
 */

type SwiperCallbackFunction = () => void;

export class SwiperVm {
  itemWidth: number = null;
  scrolledPixel: number = null;
  isMoving: boolean = false;
  isSwiping: boolean = false;
  swipeStarted = false;
  numberOfItemsToDisplay: number = null;
  jumpToOtherEndEnabled: boolean = true;
  initialized: boolean = false;

  get swipeDirection(): SwipeDirection {
    return this._swipeDirection;
  }

  get isLeftRightSwipeClickEnabled(): boolean {
    if (this.infiniteLoop) {
      return this.numberOfItems >= 5;
    }
    return this.numberOfItems >= 3;
  }

  get activeIndex(): number {
    return this._activeIndex;
  }

  get infiniteLoop(): boolean {
    return this._infiniteLoop;
  }

  get numberOfItems(): number {
    return this._numberOfItems;
  }

  get validMinIndex(): number {
    return 0;
  }

  get validMaxIndex(): number {
    return this.numberOfItems - this.numberOfItemsToDisplay;
  }

  get centerOfDisplayedItems(): number {
    return Math.ceil(this.numberOfItemsToDisplay / 2);
  }

  get canGoLeft(): boolean {
    if (this.infiniteLoop || this.jumpToOtherEndEnabled) {
      return true;
    }

    return this.activeIndex > 0;
  }

  get canGoRight(): boolean {
    if (this.infiniteLoop || this.jumpToOtherEndEnabled) {
      return true;
    }

    return this.activeIndex < this.numberOfItems - 1;
  }

  private _activeIndex: number = null;
  private readonly _infiniteLoop: boolean = null;
  private _numberOfItems: number = null;
  private readonly _updateData: SwiperCallbackFunction[] = [];
  private readonly _enableAnimation: SwiperCallbackFunction[] = [];
  private readonly _disableAnimation: SwiperCallbackFunction[] = [];
  private _swipeDirection: SwipeDirection = null;
  private _startingX: number = null;
  private _swipeDetector: SwipeDetectionHelper = null;

  private set swipeDirection(direction: SwipeDirection) {
    if (this.isLeftRightSwipeClickEnabled) {
      this._swipeDirection = direction;
    } else if (this.infiniteLoop && this.numberOfItems <= 3) {
      this._swipeDirection = "none";
    } else if (!this.infiniteLoop && this.numberOfItems < 2) {
      this._swipeDirection = "none";
    } else {
      this._swipeDirection = "default";
    }
  }

  private get infiniteLoopOutOfRangeWidth(): number {
    return this.itemWidth / 8;
  }

  constructor(
    numberOfItemsToDisplay: number,
    activeIndex: number,
    infiniteLoop: boolean = true,
    jumpToOtherEndEnabled: boolean = true
  ) {
    this.numberOfItemsToDisplay = numberOfItemsToDisplay;
    this._activeIndex = activeIndex;
    this._numberOfItems = 0;
    this.scrolledPixel = 0;
    this.isSwiping = false;
    this.isMoving = false;
    this.itemWidth = 0;
    this.swipeStarted = false;
    this._infiniteLoop = infiniteLoop;
    this.jumpToOtherEndEnabled = jumpToOtherEndEnabled;
    this._swipeDetector = new SwipeDetectionHelper();
  }

  setSwipeDirectionLeft(): void {
    this.swipeDirection = "left";
  }

  setSwipeDirectionRight(): void {
    this.swipeDirection = "right";
  }

  setDefaultSwipeDirection(): void {
    this.swipeDirection = "right";
  }

  /**
   * It swipes to the immediate next item (one position).
   * The next Item can be to the right or to the left. It
   * depends on the swipeDirection property.
   */
  swipeNext(): void {
    if (this.infiniteLoop && this.numberOfItems <= 3) {
      return;
    }
    if (this._swipeDirection === "left" && this.isLeftRightSwipeClickEnabled) {
      this.swipeLeft();
    } else {
      this.swipeRight();
    }
  }

  /**
   * It swipes by one item to the left
   */
  swipeLeft(animateSwipe = true): void {
    this._swipe(-1, animateSwipe);
  }
  /**
   * It swipes by one item to the right
   */
  swipeRight(animateSwipe = true): void {
    this._swipe(1, animateSwipe);
  }

  /**
   * It swipes to the desired swipeItem position.
   * When displaying multiple items consider that the activeIndex is the
   * start index of the visible items.
   * @param index
   * @param animateSwipe
   * @returns
   */
  swipeTo(index: number, animateSwipe: boolean = true): void {
    if (!this._isSwiperInitialized) {
      this._activeIndex = index;
      return;
    }

    if (index === this.activeIndex || this.isSwiping || !this._indexIsValid(index)) {
      return;
    }

    if (animateSwipe) {
      this._enableAnimations();
      this.isSwiping = true;
    }

    this._activeIndex = index;

    if (!animateSwipe) {
      this._checkIndex();
    }
  }

  /**
   * It brings a specified swipeItem to the center of the displayed items.
   * If possible, if it can not be at center, it will bring the swipeItem to the
   * visible area.
   *
   * @param itemPosition the swipeItem index to bring to center
   * @param animateSwipe if the swipe should be animated or not
   */
  swipeToCenter(itemPosition: number, animateSwipe: boolean = true): void {
    const index = this.getIndexFor(itemPosition);
    this.swipeTo(index, animateSwipe);
  }

  /**
   * The real Index is the index of the original array, the one
   * that was used in getSwipeItems. swiper-vm.ts adds two
   * additional items to the original array when the infiniteLoop mode is on.
   */
  getRealIndex(index: number = this.activeIndex): number {
    if (this.infiniteLoop) {
      if (this.numberOfItems === 0) {
        return Math.max(index - 1, 0);
      } else {
        let initialIndex = index;
        if (initialIndex === 0) {
          initialIndex = this.numberOfItems - 2;
        } else if (initialIndex === this.numberOfItems - 1) {
          initialIndex = 1;
        }

        let newIndex = initialIndex - 1;
        if (newIndex < 0) {
          newIndex = this.numberOfItems - 3;
        }
        return newIndex;
      }
    }
    return index;
  }

  // BEGIN: swiper.vue methods
  //====================================================================================================================================================
  //====================================================================================================================================================

  /**
   * This method should be called only once by the swiper.vue.
   * It adds two additional items (copy of first and last)
   * only on the infiniteLoop mode. And initialized the swiper number
   * of items.
   * @returns the viewModels for the swipe Items (with additional
   *      items or not, depending on infinite loop mode).
   * @param data
   */
  getSwipeItems<T>(data: T[]): T[] {
    let newData: T[] = [];

    if (this.infiniteLoop) {
      this._numberOfItems = data.length + 2;
      const firstItem = data[0];
      const lastItem = data[data.length - 1];
      newData.push(lastItem);
      newData = newData.concat(data);
      newData.push(firstItem);
    } else {
      this._numberOfItems = data.length;
      newData = data;
    }

    this._correctActiveIndex();
    this._correctNumberOfItemsToDisplay();
    return newData;
  }

  /**
   * This method should be called only once by the swiper.vue.
   * It sets methods to be called when condition is true.
   * @param updateData
   * @param enableAnimation
   * @param disableAnimation
   */
  setCallbacks(
    updateData: SwiperCallbackFunction,
    enableAnimation: SwiperCallbackFunction,
    disableAnimation: SwiperCallbackFunction
  ): void {
    this._updateData.push(updateData);
    this._enableAnimation.push(enableAnimation);
    this._disableAnimation.push(disableAnimation);
  }

  /**
   *
   * This method should be called only by the swiper.vue
   */
  startScroll(startingX: number, startingY: number): void {
    if (this.isSwiping) return;

    this.swipeStarted = true;
    this._startingX = startingX;
    this._swipeDetector.setStartPosition(startingX, startingY);
    this.isMoving = false;
  }

  /**
   *
   * This method should be called only by the swiper.vue
   */
  scroll(newX: number, newY: number): void {
    if (this.isSwiping) return;

    this._swipeDetector.setEndPosition(newX, newY);

    if (this._swipeDetector.isVerticalSwipe) {
      this.stopScroll(newX);
      return;
    }

    let delta = newX - this._startingX;
    const deltaAbsolute = Math.abs(delta);

    if (deltaAbsolute > 10 && !this.isMoving) {
      this.isMoving = true;
    } else if (deltaAbsolute <= 10) {
      this.isMoving = false;
    }

    if (!this.infiniteLoop && this._itIsOutsideInfiniteLoop(delta))
      delta =
        delta > 0
          ? this.infiniteLoopOutOfRangeWidth
          : this.infiniteLoopOutOfRangeWidth * -1;

    if (deltaAbsolute >= this.itemWidth)
      delta = delta < 0 ? -1 * this.itemWidth : this.itemWidth;

    this.scrolledPixel = delta;
  }

  /**
   *
   * This method should be called only by the swiper.vue
   */
  stopScroll(endingX: number): void {
    this.swipeStarted = false;
    if (this.isMoving) {
      this.isMoving = false;
      const threshold = 0.17;
      const delta = endingX - this._startingX;
      const swipeCompleted = Math.abs(delta) >= this.itemWidth;
      const animateSwipe = !swipeCompleted;

      if (delta < this.itemWidth * -1 * threshold) this._swipe(1, animateSwipe);
      else if (delta > this.itemWidth * threshold) this._swipe(-1, animateSwipe);
    }

    this.scrolledPixel = 0;
  }

  /**
   *
   * This method should be called only by the swiper.vue
   */
  cancelScroll(): void {
    this.swipeStarted = false;
    this.isMoving = false;
  }

  /**
   *
   * This method should be called only by the swiper.vue
   */
  animationCompleted(): void {
    this._checkIndex();
  }

  // swiper.vue methods
  //====================================================================================================================================================
  //====================================================================================================================================================

  private _correctActiveIndex(): void {
    if (this.activeIndex >= this.numberOfItems) {
      this._activeIndex = this.numberOfItems - 1;
    }
  }

  private _correctNumberOfItemsToDisplay(): void {
    if (this._numberOfItems < this.numberOfItemsToDisplay) {
      this.numberOfItemsToDisplay = this._numberOfItems;
    }
  }

  private _swipe(direction: number, animateSwipe: boolean = true) {
    if (this.isSwiping) return;

    let newIndex = this.activeIndex;

    if (direction === 1) newIndex = this._getNextIndex(this.activeIndex);
    else if (direction === -1) newIndex = this._getPreviousIndex(this.activeIndex);

    this.swipeTo(newIndex, animateSwipe);
  }

  private _getNextIndex(index: number): number {
    index = index + 1;

    if (index >= this.numberOfItems) {
      return this.infiniteLoop || this.jumpToOtherEndEnabled ? 0 : index - 1;
    }

    return index;
  }

  private _getPreviousIndex(index: number): number {
    index = index - 1;

    if (index < 0) {
      return this.infiniteLoop || this.jumpToOtherEndEnabled
        ? this.numberOfItems - 1
        : index + 1;
    }

    return index;
  }

  private _checkIndex(): void {
    this._disableAnimations();
    if (this.infiniteLoop) {
      let newIndex = this.activeIndex;

      if (this.activeIndex == 0) newIndex = this.numberOfItems - 2;
      else if (this.activeIndex == this.numberOfItems - 1) newIndex = 1;

      if (newIndex != this.activeIndex) this._activeIndex = newIndex;
    }
    this._onUpdateData();
    this.isSwiping = false;
  }

  private _itIsOutsideInfiniteLoop(value: number): boolean {
    if (
      (this.activeIndex === 0 && value > 0) ||
      (this.activeIndex === this.numberOfItems - 1 && value < 0)
    )
      return this.infiniteLoopOutOfRangeWidth < Math.abs(value);

    return false;
  }

  private _enableAnimations(): void {
    for (let i = 0; i < this._enableAnimation.length; i++) {
      this._enableAnimation[i]();
    }
  }

  private _disableAnimations(): void {
    for (let i = 0; i < this._disableAnimation.length; i++) {
      this._disableAnimation[i]();
    }
  }

  private _onUpdateData(): void {
    for (let i = 0; i < this._updateData.length; i++) {
      this._updateData[i]();
    }
  }

  private _indexIsValid(index: number): boolean {
    return index >= this.validMinIndex && index <= this.validMaxIndex;
  }

  private get _isSwiperInitialized(): boolean {
    return this.validMaxIndex !== -1 && this.initialized;
  }

  /**
   * When displaying multiple items the active index is just the start of
   * the visible items. This function returns the start index for a given
   * item in the list so, that the item is visible. And if possible, at center.
   *
   * Example:
   * 3 Items visible
   * A [B C D] E F
   * getIndexFor(4) will return 2
   *
   * 4 is the item. At the beginning the start index is at position 1 (B)
   * and if we want to display the E we need to increase this by two.
   * @param index
   * @returns
   */
  private getIndexFor(index: number): number {
    if (this.numberOfItems < this.numberOfItemsToDisplay) {
      return this.validMinIndex;
    }

    return clamp(
      index - this.centerOfDisplayedItems + 1,
      this.validMinIndex,
      this.validMaxIndex
    );
  }
}

export type SwipeDirection = "left" | "none" | "right" | "default";
