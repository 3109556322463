export class SelectionResultVm {
  private _isOpen: boolean = false;
  private _hasBeenClosedExplicitly: boolean = false;
  private _numberOfSelectedItems: number = 0;

  get isOpen(): boolean {
    return this._isOpen;
  }

  set numberOfSelectedItems(value: number) {
    if (value === 1 && this._numberOfSelectedItems === 0) {
      // first item has been selected

      this._isOpen = true;
    } else if (value === 0 && this._numberOfSelectedItems === 1) {
      // the last item has been removed from selection
      if (this._hasBeenClosedExplicitly === false) {
        this._isOpen = false;
      }
    }

    this._numberOfSelectedItems = value;
  }

  toggle(): void {
    this._isOpen = !this._isOpen;

    this._hasBeenClosedExplicitly = !this._isOpen;
  }
}
