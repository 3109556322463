<script lang="ts">
import { BcButton } from "@bissantz/controls";
import { AppWizardCommon } from "../app-wizard-common";
import { computed, defineComponent } from "vue";

export default defineComponent({
  components: {
    BcButton,
  },

  emits: [AppWizardCommon.events.click],

  props: {
    buttonText: { type: String, required: true },
    isSelected: { type: Boolean, required: false, default: true },
    disabled: { type: Boolean, required: false, default: false },
    isOpaque: { type: Boolean, required: false, default: false },
    isHoverable: { type: Boolean, required: false, default: false },
  },

  setup(props, context) {
    const opaqueButton = computed(() => {
      if (!props.isOpaque) {
        return {};
      }
      return {
        "pointer-events": "none",
        opacity: "0.3",
      };
    });

    const hoverableCssClass = computed(() => {
      return props.isHoverable ? "hoverable" : "not-hoverable";
    });

    async function onWizardButtonClicked() {
      context.emit(AppWizardCommon.events.click);
    }

    return {
      opaqueButton,
      onWizardButtonClicked,
      hoverableCssClass,

      // emits:
      AppWizardCommon,
    };
  },
});
</script>

<template>
  <BcButton
    class="wizard-button"
    v-bind:class="[{ selected: $props.isSelected }, hoverableCssClass]"
    v-bind:style="opaqueButton"
    v-bind:disabled="$props.disabled"
    v-on:click="onWizardButtonClicked"
    outlined
  >
    {{ $props.buttonText }}
  </BcButton>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";

.wizard-button[type="button"] {
  padding: 6px 7px;
  letter-spacing: 1px;
  background-color: white;
  color: var(--color_headerText);
  border-color: var(--color_headerText);
  font-family: @fontRegular;

  &.hoverable&:hover,
  &.selected {
    background-color: white;
    color: var(--color_Weather3);
    border-color: var(--color_Weather3);
  }

  &.not-hoverable.outlined {
    cursor: unset;
  }
}
</style>
