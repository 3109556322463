import { ComponentEvent } from "@/common/events/component-event";

export class SharingCommon {
  static screenshot_rendered: ComponentEvent = new ComponentEvent(
    "screenshot_rendered",
    null
  );
  static screenshot_copied: ComponentEvent = new ComponentEvent(
    "screenshot_copied",
    null
  );
}
