class RemovedFromBodyObserver {
  private _mutationObserver: MutationObserver = null;
  private _handlers: Map<HTMLElement, () => void> = new Map();

  constructor() {
    this._mutationObserver = new MutationObserver(this._handleMutationEvent.bind(this));
  }

  observe(element: HTMLElement, callback: () => void): void {
    if (this._handlers.size === 0) {
      this._startObserving();
    }

    this._handlers.set(element, callback);
  }

  unobserve(element: HTMLElement): void {
    if (!this._handlers.has(element)) {
      return;
    }

    this._handlers.delete(element);

    if (this._handlers.size === 0) {
      this._mutationObserver.disconnect();
    }
  }

  private _startObserving(): void {
    this._mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
    });
  }

  private _handleMutationEvent(): void {
    for (const [element, callback] of this._handlers.entries()) {
      this._processElementCallBack(element, callback);
    }
  }

  private _processElementCallBack(element: HTMLElement, callback: () => void): void {
    if (document.body.contains(element)) {
      return;
    }

    this.unobserve(element);
    callback();
  }
}

export const removedFromBodyObserver = new RemovedFromBodyObserver();
