<script lang="ts">
import DialogErrorIcon from "@/common/components/icons/dialog-error-icon.vue";
import DialogWarningIcon from "@/common/components/icons/dialog-warning-icon.vue";
import DialogInfoIcon from "@/common/components/icons/dialog-info-icon.vue";
import DialogQuestionIcon from "@/common/components/icons/dialog-question-icon.vue";
import DialogCheckIcon from "@/common/components/icons/dialog-check-icon.vue";
import XIcon from "@/common/components/icons/x-icon.vue";
import HideAndShow from "@/common/components/hide-and-show.vue";

import {
  onMounted,
  ref,
  reactive,
  watch,
  computed,
  onBeforeUnmount,
  nextTick,
  PropType,
  defineComponent,
} from "vue";
import { NotificationData } from "./notification-service";
import { DefaultStyles } from "@/common/styles/default-styles";
import { existsAndNotEmpty } from "@/common/object-helper/null-helper";

// constatns:
const separatorColor = DefaultStyles.colorConstants.bgGray;
const infoColor = DefaultStyles.colorConstants.weatherPlus2;
const errorColor = DefaultStyles.colorConstants.weatherMinus2;
const questionColor = DefaultStyles.colorConstants.editYellow;
const warningColor = DefaultStyles.colorConstants.editYellow;
const checkColor = DefaultStyles.colorConstants.weatherPlus3;

export default defineComponent({
  components: {
    DialogErrorIcon,
    DialogWarningIcon,
    DialogInfoIcon,
    DialogQuestionIcon,
    DialogCheckIcon,
    XIcon,
    HideAndShow,
  },

  emits: ["closeClicked"],

  props: {
    notificationData: {
      type: Object as PropType<NotificationData | null>,
      default: null,
    },
  },

  setup(props, context) {
    const ref_notificationComponent = ref(null as HTMLDivElement);

    const state = reactive({
      backgroundColor: DefaultStyles.getHEXAlphaColor(
        DefaultStyles.colorConstants.bgWhite,
        0.85
      ),
      shadowColor: DefaultStyles.getHEXAlphaColor("#000000", 0.05),
      titleWidth: 0,
      messageWidth: 0,
      halfCompWidth: null as number,
      halfCompHeight: null as number,
      // Keep this local ResizeObserver, because notifications will be
      // moved to commom component libs:
      resizeObserver: new ResizeObserver(onComponentResize),
    });

    onMounted(() => {
      if (ref_notificationComponent.value) {
        state.resizeObserver.observe(ref_notificationComponent.value);
      }
    });

    onBeforeUnmount(() => {
      if (state.resizeObserver) {
        state.resizeObserver.disconnect();
      }
    });

    const cssVariables = computed(() => {
      const widthVal = Math.max(state.titleWidth, state.messageWidth);
      return {
        "--shadowColor": state.shadowColor,
        "--backgroundColor": state.backgroundColor,
        "--titleWidth": widthVal + "px",
        "--halfCompWidth": (state.halfCompWidth ?? 0) + "px",
        "--halfCompHeight": (state.halfCompHeight ?? 0) + "px",
      };
    });

    const hasMessage = computed(() => {
      return props.notificationData?.messageData?.message;
    });

    function onComponentResize() {
      // TODO: debounce resize events, would be a bit better.
      const cr = ref_notificationComponent.value.getBoundingClientRect();
      state.halfCompWidth = cr.width / 2;
      state.halfCompHeight = cr.height / 2;
    }

    watch(
      [() => props.notificationData],
      async () => {
        await nextTick();
        if (ref_notificationComponent.value) {
          state.resizeObserver.observe(ref_notificationComponent.value);
          updateTitleWidth();
          if (existsAndNotEmpty(props.notificationData.messageData.message)) {
            updateMessageWidth();
          }
        } else if (state.resizeObserver) {
          state.resizeObserver.disconnect();
        }
      },
      { deep: true }
    );

    function updateTitleWidth() {
      const realTitleElement = ref_notificationComponent.value.querySelector(
        ".hideAndShowBody"
      ) as HTMLElement;
      if (realTitleElement) {
        state.titleWidth = Math.ceil(
          realTitleElement.getBoundingClientRect().width + 48 + 19 + 15
        );
      }
    }

    function updateMessageWidth() {
      const messageElem = ref_notificationComponent.value.querySelector(
        ".message"
      ) as HTMLElement;

      messageElem.style.visibility = "hidden";
      messageElem.style.whiteSpace = "pre";
      messageElem.style.display = "inline";

      state.messageWidth = messageElem.getBoundingClientRect().width;

      messageElem.style.whiteSpace = "";
      messageElem.style.display = "";
      messageElem.style.visibility = "";
    }

    function onCloseClicked() {
      context.emit("closeClicked");
    }

    return {
      // refs:
      ref_notificationComponent,

      // constants:
      separatorColor,
      errorColor,
      warningColor,
      infoColor,
      questionColor,
      checkColor,

      // computed:
      hasMessage,
      cssVariables,

      // functions:
      onCloseClicked,
    };
  },
});
</script>

<template>
  <!-- We use <div> not <dialog> here, because notifications are supposed to be unobtrusive messages -->
  <Transition>
    <div
      v-if="$props.notificationData"
      ref="ref_notificationComponent"
      class="notificationComponent"
      v-bind:style="cssVariables"
    >
      <div class="header">
        <div v-if="hasMessage" class="close" v-on:click="onCloseClicked">
          <XIcon v-bind:initialColor="separatorColor" />
        </div>
        <div class="titleRow">
          <div class="icon">
            <DialogErrorIcon
              v-if="$props.notificationData.type === 'error'"
              v-bind:mainColor="errorColor"
            />
            <DialogWarningIcon
              v-else-if="$props.notificationData.type === 'warning'"
              v-bind:mainColor="warningColor"
            />
            <DialogInfoIcon
              v-else-if="$props.notificationData.type === 'info'"
              v-bind:mainColor="infoColor"
            />
            <DialogQuestionIcon
              v-else-if="$props.notificationData.type === 'question'"
              v-bind:mainColor="questionColor"
            />
            <DialogCheckIcon
              v-else-if="$props.notificationData.type === 'check'"
              v-bind:mainColor="checkColor"
            />
          </div>
          <div class="title">
            <HideAndShow>{{ $props.notificationData.messageData.title }}</HideAndShow>
          </div>
        </div>
      </div>
      <div v-if="hasMessage" class="separator" />
      <div
        v-if="hasMessage"
        class="message"
        v-text="$props.notificationData.messageData.message"
      />
    </div>
  </Transition>
</template>

<style lang="less" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.notificationComponent {
  --maxHeigh: 600px;
  margin-left: calc(50vw - var(--halfCompWidth));
  margin-top: calc(50vh - var(--halfCompHeight));

  max-width: 500px;
  width: var(--titleWidth);
  min-width: 150px;
  max-height: var(--maxHeigh);

  background-color: var(--backgroundColor);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 0px 12px 6px var(--shadowColor);
  padding: 20px;
  color: var(--color_neutralText);
  position: relative;

  .header {
    .close {
      position: absolute;
      right: 14px;
      top: 13px;
      height: 16px;
      width: 16px;
      cursor: pointer;
    }

    .titleRow {
      display: flex;
      align-items: center;
      padding-right: 15px;

      .icon {
        width: 48px;
        height: 48px;
        flex: 0 0 48px;
      }
      .title {
        font-size: 25px;
        padding-left: 19px;
      }
    }
  }

  .separator {
    margin-top: 13px;
    background-color: var(--color_bg-gray);
    height: 2px;
    width: 100%;
  }

  .message {
    white-space: break-spaces;
    margin-top: 1em;
    font-size: 18px;
    line-height: 1.5;
    overflow: auto;
    max-width: 100%;
    max-height: calc(var(--maxHeigh) - (48px + 15px + 18px + 40px));
  }
}
</style>
