import { TileGridService } from "@bissantz/tile-grid";

// It scrolls to a given child element if the child is not in view. If multiple child elements
// exist, it will scroll to the third one or the available ones, if fewer than three children
// exist. It can be any element, and it can be any child element.
export function scrollToChild(
  element: HTMLElement,
  childClassNames: string[],
  tileGridService: TileGridService
): void {
  if (_isElementInView(element, tileGridService)) {
    return;
  }

  const childElement = _getChildElement(element, childClassNames);

  if (!childElement) {
    return;
  }

  if (_isElementInView(childElement, tileGridService)) {
    return;
  }

  tileGridService.scrollIntoView(childElement);
}

function _isElementInView(element: Element, tileGridService: TileGridService): boolean {
  if (!element || !tileGridService) {
    return false;
  }

  const containerRect = _getContainerRect(tileGridService);
  const containerBottom = _getBottom(containerRect);
  const elementRect = element.getBoundingClientRect();
  const elementBottom = _getBottom(elementRect);

  return containerBottom >= elementBottom;
}

function _getChildElement(element: HTMLElement, childClassNames: string[]): Element {
  const elements = _findChildElementsWithClasses(element, childClassNames);

  if (!elements || elements.length === 0) {
    return null;
  }

  const numberOfItemsToDisplay = Math.min(3, elements.length);
  return elements[numberOfItemsToDisplay - 1];
}

function _getContainerRect(tileGridService: TileGridService): DOMRect {
  return tileGridService.getGridScrollFrame().getBoundingClientRect();
}

function _getBottom(elementRect: DOMRect): number {
  return elementRect.top + elementRect.height;
}

function _findChildElementsWithClasses(
  parentElement: HTMLElement,
  classNames: string[]
): HTMLElement[] {
  const selector = classNames.join("");
  return Array.from(parentElement.querySelectorAll(selector));
}
