<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    initialColor: { type: String, default: null },
    isMenuOpen: { type: Boolean, default: null },
    rotationDegree: { type: Number, default: null },
  },

  setup(props) {
    const colorVars = computed(() => {
      return {
        "--initial-color": props.initialColor,
      };
    });

    const usedRotation = computed(() => {
      if (!props.rotationDegree) return "rotate(0,15,15)";

      return "rotate(" + props.rotationDegree.toString() + ",15,15)";
    });

    return {
      colorVars,
      usedRotation,
    };
  },
});
</script>

<template>
  <div class="arrowIcon" v-bind:style="colorVars">
    <svg
      class="arrowSvg"
      v-bind:class="{ menuOpen: $props.isMenuOpen }"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <polyline
        points="12,6 17,15 12,24"
        v-bind:transform="usedRotation"
        fill="none"
      ></polyline>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.arrowIcon {
  height: 100%;
  cursor: pointer;
  svg {
    stroke: var(--initial-color);
    stroke-width: 3px;
    height: 100%;
    // polyline {
    //   // animation does not work properly: does not rotate around the specified point
    //   // firefox does not even animate this
    //   transition: rotate 0.5s;
    // }

    &.menuOpen {
      stroke: var(--hover-color);
    }
  }
}
</style>
