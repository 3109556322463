import { SimpleResult } from "@/common/results/simple-result";
import {
  IPortalViewStateDto,
  IPortalsServiceClient,
  PortalViewStateDto,
} from "@/common/service-clients/generated-clients";

export class ViewStateFacade {
  private _portalServiceClient: IPortalsServiceClient;

  constructor(portalServiceClient: IPortalsServiceClient) {
    this._portalServiceClient = portalServiceClient;
  }

  async getPortalVsAsync(
    portalViewStateId: string
  ): Promise<SimpleResult<IPortalViewStateDto, object | string>> {
    const result = { value: null, error: null };
    try {
      const portalViewStateDto = await this._portalServiceClient.getPortalViewState(
        portalViewStateId
      );
      result.value = portalViewStateDto;
    } catch (e) {
      result.error = e;
    }

    return result;
  }

  async updatePortalVsAsync(
    vsDto: IPortalViewStateDto
  ): Promise<SimpleResult<IPortalViewStateDto, string>> {
    const result = { value: null, error: null };
    try {
      result.value = await this._portalServiceClient.updatePortalViewState(
        vsDto.id,
        PortalViewStateDto.fromJS(vsDto),
        false
      );
    } catch (e) {
      result.error = e;
    }

    return result;
  }

  async createPortalVsAsync(
    vsDto: IPortalViewStateDto
  ): Promise<SimpleResult<IPortalViewStateDto, string>> {
    const result = { value: null, error: null };
    try {
      result.value = await this._portalServiceClient.createPortalViewState(
        vsDto.portalId,
        PortalViewStateDto.fromJS(vsDto)
      );
    } catch (e) {
      result.error = e;
    }
    return result;
  }
}
