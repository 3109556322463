import { Ref, watch, computed } from "vue";
import { SparklineVm, SharedSparklineState } from "@/features/dashboard-shared/sparkline";
import { SwiperVm } from "@/common/components/swiper-vm";
import { ValueVm } from "@/features/dashboard/view-models/value-vm";

export function usePeriodSparklinesSync(
  scaledValue: Ref<ValueVm>,
  kpiValues: Ref<ValueVm[]>,
  sharedSparklineState: SharedSparklineState,
  periodSwiperVm: Ref<SwiperVm>,
  periodSwiperActiveIndex: Ref<number>
) {
  //
  // Life Cycle:
  // --------------------

  //
  // Computeds:
  // --------------------
  const sparkline = computed<SparklineVm>(() => {
    if (!scaledValue) {
      return null;
    }

    if (sharedSparklineState.showSparklines && scaledValue.value.hasSparkline) {
      return scaledValue.value.sparkline;
    } else if (!sharedSparklineState.showSparklines) {
      const totalValuesWithSparklines = kpiValues.value.filter(
        (kpiValue) => kpiValue.hasSparkline
      ).length;
      if (totalValuesWithSparklines === kpiValues.value.length) {
        return scaledValue.value.sparkline;
      }
    }

    return null;
  });

  //
  // Functions:
  // --------------------
  function onPeriodSwiperChanged(): void {
    if (periodSwiperVm.value.isSwiping || periodSwiperVm.value.isMoving) {
      return;
    }

    const sparklineVm = sparkline.value;
    if (!sparklineVm) {
      return;
    }

    const newIndex =
      periodSwiperVm.value.numberOfItems - 1 - periodSwiperVm.value.activeIndex;

    const selection = sharedSparklineState.selection[sparklineVm.timeStructure];

    if (selection.historyIndex === newIndex) {
      return;
    }

    selection.historyIndex = newIndex;
    scrollSparklinesIfNecessary();

    if (!selection.isMouseHoverEnabled) {
      sharedSparklineState.scrollPosPixel = 0;
      selection.isMouseHoverEnabled = true;
    }
  }

  function scrollSparklinesIfNecessary(): void {
    const sparklineState = sharedSparklineState;
    const totalBarWidth = sparklineState.barMargin + sparklineState.barWidth;
    const scrolledBars = sparklineState.scrollPosPixel / totalBarWidth;
    const historyIndex =
      sparklineState.selection[sparkline.value.timeStructure].historyIndex;
    const maxNumVisibleBars = sparklineState.maxNumVisibleBars;

    if (maxNumVisibleBars <= historyIndex - scrolledBars) {
      while (
        maxNumVisibleBars <=
        historyIndex - sparklineState.scrollPosPixel / totalBarWidth
      ) {
        sparklineState.scrollPosPixel += totalBarWidth;
      }
    } else if (historyIndex - scrolledBars < 0) {
      while (historyIndex - sparklineState.scrollPosPixel / totalBarWidth < 0) {
        sparklineState.scrollPosPixel -= totalBarWidth;
      }
    }
  }

  function onSparklineSelectionChanged(): void {
    const index = periodSwiperActiveIndex.value;

    if (index === periodSwiperVm.value.activeIndex) {
      return;
    }

    periodSwiperVm.value.swipeTo(index, false);
  }

  function onKpiValuesChanged(): void {
    if (periodSwiperVm.value.numberOfItems === 0) {
      return;
    }

    const index = getSwiperActiveIndex();
    periodSwiperVm.value.swipeTo(index, false);
  }

  function getSwiperActiveIndex(): number {
    if (sparkline.value) {
      return periodSwiperActiveIndex.value;
    }

    if (periodSwiperVm.value.numberOfItems === 0) {
      return 0;
    }

    return periodSwiperVm.value.numberOfItems - 1;
  }

  //
  // Watcher:
  // --------------------
  watch([() => kpiValues.value], onKpiValuesChanged);

  watch(
    [() => sharedSparklineState.showSparklines, () => sharedSparklineState.selection],
    onSparklineSelectionChanged,
    { deep: true }
  );

  watch(
    [
      () => periodSwiperVm.value.isSwiping,
      () => periodSwiperVm.value.isMoving,
      () => periodSwiperVm.value.activeIndex,
    ],
    onPeriodSwiperChanged
  );

  return {};
}
