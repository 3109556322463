import { OlapDatabaseCube, PublishedApplication } from "../backend-wrapper/dto-wrappers";
import { MagicButtonListVm } from "./modelling/magic-button-list-vm";
import { IConnectionFacade } from "../backend-wrapper/connection-facade.interface";
import { IModelFacade } from "../backend-wrapper/model-facade.interface";
import { ITransactionFacade } from "@/common/transactions/transaction-facade.interface";
import { ISemanticModelParams } from "../backend-wrapper/semantic-model-params.interface";
import { NavigationVm } from "./modelling/navigation-vm";
import { TypedEvent } from "@/common/events/typed-event";
import { IDisposable } from "@/common/disposable.interface";

export class ReportImportFinishedEventArgs {
  constructor(public readonly importSucceeded: boolean) {}
}

export class ModellingVm implements IDisposable {
  get selectedCube(): OlapDatabaseCube {
    return this._selectedCube;
  }

  set selectedCube(value: OlapDatabaseCube) {
    this._selectedCube = value;

    this._isCubeSelected =
      this._selectedCube !== null && this._selectedCube !== undefined;
  }

  private readonly _connectionFacade: IConnectionFacade = null;
  private readonly _modelFacade: IModelFacade = null;
  private readonly _transactionFacade: ITransactionFacade = null;
  private readonly _currentConnectionId: string = null;
  private readonly _currentPublishedApplication: PublishedApplication = null;
  private _selectedCube: OlapDatabaseCube = undefined;
  private _OLAPCubes: OlapDatabaseCube[] = [];
  private _olapCubesRetrievalFailed: boolean = false;
  private _isSendingDataToBackend: boolean = false;
  private _isSemanticModellCreated: boolean = false;
  private _semanticModelId: string = null;
  private _reportId: string = null;
  private _isCubeSelected: boolean = false;
  private _isInitialised: boolean = false;
  private _navigationVm: NavigationVm = null;
  private _magicButtonListVm: MagicButtonListVm = null;

  public readonly reportImportFinished = new TypedEvent<ReportImportFinishedEventArgs>();

  get olapCubesRetrievalFailed(): boolean {
    return this._olapCubesRetrievalFailed;
  }

  get isSendingDataToBackend() {
    return this._isSendingDataToBackend;
  }

  get OLAPCubes(): OlapDatabaseCube[] {
    return this._OLAPCubes;
  }

  get isMissingCube(): boolean {
    if (!this._isInitialised) {
      return false;
    }

    return this.OLAPCubes.length === 0;
  }

  get isSemanticModellCreated(): boolean {
    return this._isSemanticModellCreated;
  }

  get showInstruction(): boolean {
    return !this.isSendingDataToBackend && !this.isSemanticModellCreated;
  }

  get isReportModellingVisible(): boolean {
    return !this.isSendingDataToBackend && this.isSemanticModellCreated;
  }

  get isCubeSelected(): boolean {
    return this._isCubeSelected;
  }

  get canCreateSemanticModel(): boolean {
    return this._isCubeSelected === true && this._isSemanticModellCreated === false;
  }

  get navigationVm(): NavigationVm {
    return this._navigationVm;
  }

  get isReportModellingAvailable(): boolean {
    return this._navigationVm !== null;
  }

  get magicButtonListVm(): MagicButtonListVm {
    return this._magicButtonListVm;
  }

  get areAllRequiredDataToFinishModellingProvided(): boolean {
    if (this._navigationVm === null) {
      return false;
    }

    if (this._magicButtonListVm === null) {
      return false;
    }

    if (this.navigationVm.selectedKpis.length === 0) {
      return false;
    }

    if (!this._magicButtonListVm.isValidMagicButtonSelection) {
      return false;
    }

    return true;
  }

  constructor(
    connectionFacade: IConnectionFacade,
    modelFacade: IModelFacade,
    transactionFacade: ITransactionFacade,
    currentConnectionId: string,
    currentPublishedApplication: PublishedApplication
  ) {
    this._connectionFacade = connectionFacade;
    this._modelFacade = modelFacade;
    this._transactionFacade = transactionFacade;
    this._currentConnectionId = currentConnectionId;
    this._currentPublishedApplication = currentPublishedApplication;
  }

  async init(): Promise<void> {
    this._OLAPCubes = await this._getOLAPCubes();

    this._isInitialised = true;
  }

  private async _getOLAPCubes(): Promise<OlapDatabaseCube[]> {
    const result = await this._modelFacade.getOLAPCubesAsync(this._currentConnectionId);

    this._olapCubesRetrievalFailed = !result.succeeded;

    if (result.succeeded) {
      return result.value;
    } else {
      return [];
    }
  }

  async createSemanticModelAndReport(): Promise<boolean> {
    this._isSendingDataToBackend = true;

    await this._transactionFacade.beginOrReuseTransaction();

    const applicationVersionId = this._currentPublishedApplication.applicationVersionId;

    const designatedConnection =
      await this._connectionFacade.getDesignatedConnectionFromConnectionSetId(
        this._currentPublishedApplication.connectionSetId
      );

    const semanticModelParams: ISemanticModelParams = {
      name: this._currentPublishedApplication.name,
      applicationVersionId: applicationVersionId,
      publishedApplicationId: this._currentPublishedApplication.id,
      designatedConnectionId: designatedConnection.id,
      connectionId: designatedConnection.connectionId,
    };

    const result = await this._modelFacade.createSemanticModelAndReportAsync(
      semanticModelParams
    );
    this._isSendingDataToBackend = false;

    if (result.succeeded) {
      this._isSemanticModellCreated = true;
      this._semanticModelId = result.value.semanticModelId;
      this._reportId = result.value.reportId;

      this._magicButtonListVm = new MagicButtonListVm(this._modelFacade, this._reportId);

      this._navigationVm = new NavigationVm(
        this._modelFacade,
        this._currentPublishedApplication.id,
        this._semanticModelId,
        this._reportId
      );
      // tbd: errorhandling
      await this._magicButtonListVm.initAsync();
    }

    return result.succeeded;
  }

  async importFromReport(): Promise<boolean> {
    this._isSendingDataToBackend = true;
    await this.navigationVm.completeReportAsync();
    const result = await this._modelFacade.importFromReportAsync(
      this._currentPublishedApplication.id,
      this._reportId,
      this._currentPublishedApplication.name,
      this._currentPublishedApplication.publishedApplicationGroupId
    );
    if (!result.succeeded) {
      this._transactionFacade.rollbackActiveTransactionIfAny();
    } else {
      await this._transactionFacade.commitActiveTransactionIfAny();
    }

    this.reportImportFinished.emit(new ReportImportFinishedEventArgs(result.succeeded));
    this._isSendingDataToBackend = false;
    return result.succeeded;
  }

  dispose(): void {
    this.reportImportFinished.removeAllListeners();
  }
}
