import {
  CollisionContext,
  CollisionEvent,
  DefaultCollisionHandler,
  ICollisionResolveMethod,
  ILayoutCollisionHandler,
  TileGridService,
} from "@bissantz/tile-grid";
import debounce from "lodash/debounce";

export type ZoomContext = {
  previousFactor: number;
  factor: number;
};

export class GridCollisionHandler implements ILayoutCollisionHandler {
  private _innerDefaultDetector: ILayoutCollisionHandler = new DefaultCollisionHandler();
  private _isZoomMode = false;
  private _zoomContext: ZoomContext = {
    factor: null,
    previousFactor: null,
  };
  private _debouncedDisableZoomHandling: () => void;

  tgService: TileGridService = null;
  gridRef: HTMLElement;

  constructor() {
    this._debouncedDisableZoomHandling = debounce(this._disableZoomHandling, 200);
  }

  valueChanged(context: CollisionContext): ICollisionResolveMethod {
    if (this._isZoomMode) {
      return this._handleZoomCollision(context);
    }

    return this._handleWindowCollision(context);
  }

  enableZoomHandling(zoomContext: ZoomContext): void {
    this._zoomContext.factor = zoomContext.factor;
    this._zoomContext.previousFactor = zoomContext.previousFactor;
    this._isZoomMode = true;
    this._debouncedDisableZoomHandling();
  }

  private _disableZoomHandling(): void {
    this._isZoomMode = false;
  }

  private _handleWindowCollision(context: CollisionContext): ICollisionResolveMethod {
    const isColNumCollsision = context.EventType === CollisionEvent.NumberOfColumns;
    const isWidthIncreased = context.CurrentColumnNumber > context.OldColumnNumber;

    if (isColNumCollsision && isWidthIncreased) {
      const shouldRefresh = this._anyTileCovered();

      return {
        applyRefreshGrid: shouldRefresh,
        shouldRecalculateTileOrder: true,
      };
    }

    return this._innerDefaultDetector.valueChanged(context);
  }

  private _handleZoomCollision(context: CollisionContext): ICollisionResolveMethod {
    const isZoomedIn = this._zoomContext.factor >= this._zoomContext.previousFactor;
    if (isZoomedIn) {
      return this._innerDefaultDetector.valueChanged(context);
    }

    // zoom out: don't change layout
    return {
      applyRefreshGrid: false,
      shouldRecalculateTileOrder: false,
    };
  }

  private _anyTileCovered(): boolean {
    if (!this.gridRef) {
      return false;
    }

    const arrayOfTiles = Array.from(
      this.gridRef.querySelectorAll(".portal-tile-component")
    );

    const scrollFrameRect = this.tgService.getGridScrollFrame().getBoundingClientRect();
    const frameHeight = scrollFrameRect.height + scrollFrameRect.y;

    const bottomMostTile = arrayOfTiles
      // map tiles to boundingRect + tileElement
      .map((tile) => {
        const rect = tile.getBoundingClientRect();
        return { rect: rect, tile };
      })
      // remove all items where the tile is higher than the scrollFrame anyways
      .filter((rectTile) => rectTile.rect.height <= scrollFrameRect.height)
      // map to effHeight + Tile
      .map((rectTile) => {
        return { bottomY: rectTile.rect.y + rectTile.rect.height, tile: rectTile.tile };
      })
      // select the one tile that has highest bottom y
      .reduce((prev, curr) => (prev.bottomY > curr.bottomY ? prev : curr), {
        bottomY: 0,
        tile: null,
      });

    const isBottomTilecovered = bottomMostTile.bottomY > frameHeight;
    return isBottomTilecovered;
  }
}
