import {
  DataSourceAutoModelCreationRequestDto,
  Dm7ApplicationCreationRequestDto,
  ExecuteOrUndoReportCommandRequestDtoOfDashboardReportCommandType,
  IDashboardsServiceClient,
  IDataSourceAutoModelCreationRequestDto,
  IDm7ApplicationCreationRequestDto,
  IDm7ApplicationsServiceClient,
  IOlapCubeAutoModelCreationRequestDto,
  IOlapDatabaseCubeDto,
  IPublishedApplicationsServiceClient,
  IReportCreationRequestDto,
  IReportGroupCreationRequestDto,
  IReportGroupsServiceClient,
  IReportsServiceClient,
  ISemanticModelCreationRequestDto,
  ISemanticModelsServiceClient,
  OlapCubeAutoModelCreationRequestDto,
  DashboardReportCommandInfoDto,
  ReportCreationRequestDto,
  ReportGroupCreationRequestDto,
  SemanticModelCreationRequestDto,
  WorkItemDtoOfImportFromReportResultState,
  ReportCommandInfoDto,
  CreateDashboardReportPeriodSelectionRequestDto,
} from "@/common/service-clients/generated-clients";
import { LongRunningWorkServiceClient } from "@/common/service-clients/long-running-work-service-client";
import { IModelFacade } from "./model-facade.interface";
import { ISemanticModelParams } from "./semantic-model-params.interface";
import {
  OlapDatabaseCube,
  MagicButton,
  HierarchyLevel,
  Dimension,
  PeriodElement,
  Kpi,
} from "./dto-wrappers";
import { Result } from "@/common/results/result";
import { FailedReason } from "@/common/results/failed-reason";
import { ValueResult } from "@/common/results/value-result";
import { ReportCreationResult } from "./report-creation-result";
import { ImportFromReportParams } from "./import-from-report-params";
import { ApplicationNameLink } from "./application-name-link";

export class ModelFacade implements IModelFacade {
  private readonly _publishedApplicationClient: IPublishedApplicationsServiceClient;
  private readonly _semanticModelClient: ISemanticModelsServiceClient;
  private readonly _dm7ApplicationsClient: IDm7ApplicationsServiceClient;
  private readonly _reportGroupsClient: IReportGroupsServiceClient;
  private readonly _reportsClient: IReportsServiceClient;
  private readonly _dashboardsClient: IDashboardsServiceClient;
  private readonly _longRunningWorkClient: LongRunningWorkServiceClient;

  constructor(
    publishedApplicationClient: IPublishedApplicationsServiceClient,
    semanticModelClient: ISemanticModelsServiceClient,
    dm7ApplicationsClient: IDm7ApplicationsServiceClient,
    reportGroupsClient: IReportGroupsServiceClient,
    reportsClient: IReportsServiceClient,
    dashboardsClient: IDashboardsServiceClient,
    longRunningWorkClient: LongRunningWorkServiceClient
  ) {
    this._publishedApplicationClient = publishedApplicationClient;
    this._semanticModelClient = semanticModelClient;
    this._dm7ApplicationsClient = dm7ApplicationsClient;
    this._reportGroupsClient = reportGroupsClient;
    this._reportsClient = reportsClient;
    this._dashboardsClient = dashboardsClient;
    this._longRunningWorkClient = longRunningWorkClient;
  }

  async getOLAPCubesAsync(
    connectionId: string
  ): Promise<ValueResult<OlapDatabaseCube[], FailedReason>> {
    try {
      const allOlapCubes =
        await this._semanticModelClient.getOlapDatabaseCubesFromConnection(connectionId);
      return ValueResult.createFromValue(allOlapCubes);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async createSemanticModelAndReportAsync(
    params: ISemanticModelParams
  ): Promise<ValueResult<ReportCreationResult, FailedReason>> {
    try {
      return await this._createSemanticModelAndReportAsync(params);
    } catch (error) {
      return ValueResult.createFromFailedReason(error);
    }
  }

  async getMagicButtonsAsync(
    reportId: string
  ): Promise<ValueResult<MagicButton[], FailedReason>> {
    try {
      const commandTypes = await this._getAvaibleReportCommandTypesAsync(reportId);
      const magicButtons = commandTypes.map(
        (rci) => new MagicButton(rci.reportCommandType, rci.isExecuted)
      );
      return ValueResult.createFromValue(magicButtons);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  private async _createSemanticModelAndReportAsync(
    params: ISemanticModelParams
  ): Promise<ValueResult<ReportCreationResult, FailedReason>> {
    try {
      const olapDatabaseCube = await this._getOlapDatabaseCubeAsync(params.connectionId);

      if (olapDatabaseCube === null) {
        return ValueResult.createFromFailedReason(
          new FailedReason("no olap database cube found for specified connection")
        );
      }

      const semanticModelId = await this._createSemanticModelAsync(
        params.name,
        params.applicationVersionId
      );

      const dataSourceId = await this._createDataSourceAsync(
        params.designatedConnectionId,
        semanticModelId
      );

      await this._createOlapCubeAsync(
        dataSourceId,
        olapDatabaseCube,
        params.publishedApplicationId
      );

      const dm7ApplicationId = await this._createDm7ApplicationAsync(
        params.applicationVersionId,
        params.name,
        semanticModelId
      );

      const reportGroupId = await this._createReportGroupAsync(
        dm7ApplicationId,
        params.name
      );
      const reportId = await this._createReportAsync(reportGroupId, params.name);

      const reportCreationResult = new ReportCreationResult(semanticModelId, reportId);

      return ValueResult.createFromValue(reportCreationResult);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async applyMagicButtonAsync(
    reportId: string,
    selectedMagicButton: MagicButton
  ): Promise<ValueResult<MagicButton[], FailedReason>> {
    try {
      const singleMB = await this._executeReportCommandAsync(
        reportId,
        selectedMagicButton
      );
      if (singleMB.isFaulted) {
        throw new Error();
      }
      const reportTypes = await this._getAvaibleReportCommandTypesAsync(reportId);
      const allMagicButtons = reportTypes.map(
        (rci) => new MagicButton(rci.reportCommandType, rci.isExecuted)
      );
      return ValueResult.createFromValue(allMagicButtons);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async importFromReportAsync(
    publishedApplicationId: string,
    reportId: string,
    name: string,
    publishedApplicationGroupId: string
  ): Promise<Result<FailedReason>> {
    try {
      await this._importFromReportAsync(
        publishedApplicationId,
        reportId,
        name,
        publishedApplicationGroupId
      );
    } catch (e) {
      return Result.createFromFailedReason(e);
    }
    return new Result();
  }

  private async _getOlapDatabaseCubeAsync(
    connectionId: string
  ): Promise<IOlapDatabaseCubeDto> {
    const olapDatabaseCubes =
      await this._semanticModelClient.getOlapDatabaseCubesFromConnection(connectionId);

    if (olapDatabaseCubes.length >= 1) return olapDatabaseCubes[0];

    return null;
  }

  private async _createSemanticModelAsync(
    name: string,
    applicationVersionId: string
  ): Promise<string> {
    const semanticModelCreationRequest: ISemanticModelCreationRequestDto = {
      name: name,
      description: null,
      applicationVersionId,
    };
    return await this._semanticModelClient.createSemanticModel(
      new SemanticModelCreationRequestDto(semanticModelCreationRequest)
    );
  }

  private async _createDataSourceAsync(
    designatedConnectionId: string,
    semanticModelId: string
  ): Promise<string> {
    const dataSourceCreationRequest: IDataSourceAutoModelCreationRequestDto = {
      designatedConnectionId,
      semanticModelId,
    };
    return await this._semanticModelClient.createDataSourceAutoModel(
      new DataSourceAutoModelCreationRequestDto(dataSourceCreationRequest)
    );
  }

  private async _createOlapCubeAsync(
    dataSourceId: string,
    olapDatabaseCube: IOlapDatabaseCubeDto,
    publishedApplicationId: string
  ): Promise<string> {
    const olapCubeCreationRequest: IOlapCubeAutoModelCreationRequestDto = {
      dataSourceId,
      olapDatabaseCube,
      publishedApplicationId,
    };
    return await this._semanticModelClient.createOlapCubeAutoModel(
      new OlapCubeAutoModelCreationRequestDto(olapCubeCreationRequest)
    );
  }

  private async _createDm7ApplicationAsync(
    applicationVersionId: string,
    name: string,
    semanticModelId: string
  ): Promise<string> {
    const dm7ApplicationCreationRequest: IDm7ApplicationCreationRequestDto = {
      applicationVersionId: applicationVersionId,
      description: "",
      name: name,
      semanticModelId: semanticModelId,
    };

    return await this._dm7ApplicationsClient.createDm7Application(
      new Dm7ApplicationCreationRequestDto(dm7ApplicationCreationRequest)
    );
  }

  private async _createReportGroupAsync(
    dm7ApplicationId: string,
    name: string
  ): Promise<string> {
    const reportGroupCreationRequest: IReportGroupCreationRequestDto = {
      parentGroupId: null,
      dm7ApplicationId,
      name: name,
      description: "",
    };
    return await this._reportGroupsClient.createReportGroup(
      new ReportGroupCreationRequestDto(reportGroupCreationRequest)
    );
  }

  private async _createReportAsync(reportGroupId: string, name: string): Promise<string> {
    const reportCreationRequest: IReportCreationRequestDto = {
      parentGroupId: reportGroupId,
      reportDefinitionType: "DashboardReport",
      name: name,
      description: "",
    };
    return await this._reportsClient.createReport(
      new ReportCreationRequestDto(reportCreationRequest)
    );
  }

  private async _getAvaibleReportCommandTypesAsync(
    reportId: string
  ): Promise<DashboardReportCommandInfoDto[]> {
    const reportCommandInfo = await this._reportsClient.getReportCommands(reportId);

    const commandTypes = reportCommandInfo.filter(
      (value) =>
        value instanceof DashboardReportCommandInfoDto &&
        value.isVisible &&
        value.isAvailable
    );
    return commandTypes as DashboardReportCommandInfoDto[];
  }

  private async _executeReportCommandAsync(
    reportId: string,
    selectedMagicButton: MagicButton
  ): Promise<ReportCommandInfoDto> {
    const singleMB = await this._reportsClient.executeOrUndoReportCommand(
      reportId,
      new ExecuteOrUndoReportCommandRequestDtoOfDashboardReportCommandType({
        commandType: selectedMagicButton.magicButtonType,
        undo: selectedMagicButton.isExecuted,
      })
    );
    return singleMB;
  }

  private async _importFromReportAsync(
    publishedApplicationId: string,
    reportId: string,
    name: string,
    publishedApplicationGroupId: string
  ): Promise<void> {
    const importParams = new ImportFromReportParams(
      publishedApplicationId,
      name,
      publishedApplicationGroupId,
      reportId
    );

    await this._longRunningWorkClient.complete(
      importParams,
      (input) => this._launchReportImportAsync(input),
      (input, workId) => this._getReportImportStatusAsync(workId),
      () => ""
    );
  }

  private async _launchReportImportAsync(
    importParams: ImportFromReportParams
  ): Promise<WorkItemDtoOfImportFromReportResultState> {
    const sourcePublishedApplicationId = importParams.publishedApplicationId;
    const targetPublishedApplicationId = await this._getTargetDashboardApplicationIdAsync(
      importParams.publishedApplicationGroupId,
      importParams.name
    );

    return await this._dashboardsClient.importFromReport(
      sourcePublishedApplicationId,
      importParams.reportId,
      targetPublishedApplicationId
    );
  }

  private async _getReportImportStatusAsync(
    workId: string
  ): Promise<WorkItemDtoOfImportFromReportResultState> {
    return await this._dashboardsClient.getImportFromReportStatus(workId);
  }

  private async _getTargetDashboardApplicationIdAsync(
    publishedApplicationGroupId: string,
    name: string
  ): Promise<string> {
    const publishedApplications = (
      await this._publishedApplicationClient.getPublishedApplications(
        publishedApplicationGroupId
      )
    ).filter(
      (p) =>
        p.applicationType === "Dashboard" &&
        p.name === ApplicationNameLink.getDashboardPublishedApplicationName(name)
    );
    const publishedApplication =
      publishedApplications.length > 0 ? publishedApplications[0] : null;

    return publishedApplication.id;
  }

  async getHierarchiesAsync(
    semanticModelId: string
  ): Promise<ValueResult<Dimension[], FailedReason>> {
    try {
      const allHierarchies = await this._semanticModelClient.getHierarchies(
        semanticModelId
      );

      const allDimension = allHierarchies.map((value) => {
        const hierarchyLevels = value.hierarchyLevels
          .map(
            (hierarchy, index) =>
              new HierarchyLevel(hierarchy.id, value.id, hierarchy.name, index, "")
          )
          .filter((value) => value.levelOrdinal !== 0);
        return new Dimension(value.id, value.name, hierarchyLevels, value.contentType);
      });
      return ValueResult.createFromValue(allDimension);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async loadHierarchieLevelsAsync(
    dimension: Dimension
  ): Promise<ValueResult<Dimension, FailedReason>> {
    try {
      await Promise.all(
        dimension.allHierarchyLevels.map(async (level) => {
          const singleLevel = await this._semanticModelClient.getHierarchyLevel(level.id);
          level.componentId = singleLevel.componentId;
        })
      );

      return ValueResult.createFromValue(dimension);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async loadHierarchyLevelElementsAsync(
    level: HierarchyLevel,
    pubAppId: string
  ): Promise<ValueResult<HierarchyLevel, FailedReason>> {
    try {
      const periodElements =
        await this._semanticModelClient.getComponentElementInfosByHierarchyLevel(
          level.id,
          pubAppId
        );

      const allElements = periodElements.map((element) => {
        return new PeriodElement(element.name, element.caption, level.id);
      });

      level.elements = allElements;

      return ValueResult.createFromValue(level);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async getKpisAsync(semanticModelId: string): Promise<ValueResult<Kpi[], FailedReason>> {
    try {
      const kpis = await this._semanticModelClient.getKpis(semanticModelId);

      return ValueResult.createFromValue(kpis);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async createDashboardReportKpiAsync(
    reportId: string,
    index: number,
    kpiId: string
  ): Promise<Result<FailedReason>> {
    try {
      await this._reportsClient.createDashboardReportKpi(reportId, index, kpiId);
    } catch (error) {
      return Result.createFromFailedReason(error);
    }
    return new Result();
  }

  async createDashboardReportNavigationAsync(
    reportId: string,
    hierarchyLevelId: string
  ): Promise<Result<FailedReason>> {
    try {
      await this._reportsClient.createDashboardReportNavigation(
        reportId,
        hierarchyLevelId
      );
    } catch (error) {
      return Result.createFromFailedReason(error);
    }
    return new Result();
  }

  async createDashboardReportPeriodSelectionAsync(
    publishedApplicationId: string,
    reportId: string,
    hierarchyLevelId: string,
    componentElementName: string
  ): Promise<Result<FailedReason>> {
    try {
      await this._reportsClient.createDashboardReportPeriodSelection(
        reportId,
        new CreateDashboardReportPeriodSelectionRequestDto({
          publishedApplicationId,
          hierarchyLevelId,
          componentElementName,
        })
      );
    } catch (error) {
      return Result.createFromFailedReason(error);
    }
    return new Result();
  }
}
