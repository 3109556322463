<script lang="ts">
import { BcMenuItem as MenuItem } from "@bissantz/controls";
import About from "./about.vue";

import { appResources } from "@/app-resources";
import { IHelpServiceClient } from "@/common/service-clients/generated-clients";
import { smartformsStore } from "@bissantz/smartforms";
import { INotificationService } from "@/features/notifications/notification-service.interface";

import { defineComponent, inject, reactive, ref } from "vue";

export default defineComponent({
  components: {
    About,
    MenuItem,
  },

  setup() {
    const menuTexts = reactive(appResources.menuTexts);
    const about = ref<InstanceType<typeof About> | null>(null);
    const helpClient: IHelpServiceClient = inject("helpClient");
    const notificationService: INotificationService = inject("notificationService");

    function hideLogs(): void {
      if (about.value) about.value.state.showLog = false;
    }

    function openHelpCenter(): void {
      helpClient
        .getToken()
        .then((value) => {
          let url =
            "https://docs.bissantz.de/" +
            smartformsStore.application.language +
            "/Default.htm?cshid=1000700&token=" +
            value;

          window.open(url, "_blank").focus();
        })
        .catch(() =>
          notificationService.error({
            title: appResources.menuTexts.tokenErrorTitle,
            message: appResources.menuTexts.tokenErrorMessage,
          })
        );
    }

    return {
      menuTexts,
      about,
      hideLogs,
      openHelpCenter,
    };
  },
});
</script>

<template>
  <div class="menuFooter">
    <MenuItem class="borderAtTop" v-on:after-leave="hideLogs">
      <template v-slot:title>{{ menuTexts.about }}</template>
      <template v-slot:component><About ref="about" /></template>
    </MenuItem>
    <MenuItem class="borderAtTop" v-on:click.native="openHelpCenter">
      <template v-slot:title>{{ menuTexts.help }}</template>
    </MenuItem>
  </div>
</template>
