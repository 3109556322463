<script lang="ts">
import ValueGroup from "@/features/dashboard/value-group/value-group.vue";
import { KpiTileVm } from "../view-models/kpi-tile-vm";
import { DashboardSettings } from "@/features/dashboard-shared/dashboard-settings";
import { SharedDashboardStateVm } from "../view-models/shared/shared-dashboard-state-vm";
import { ValueGroupVm } from "../view-models/value-group-vm";
import { DashboardCommon } from "../dashboard-common";
import { reactive, watch, defineComponent, PropType, onBeforeMount } from "vue";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";
import KpiMarker from "@/features/dashboard/kpi-tile/kpi-marker.vue";
import { useKpiSelector } from "@/features/dashboard/kpi-tile/kpi-selector-cpsl";

class KpiTileState {
  valueGroupVm = null as ValueGroupVm;
}

export default defineComponent({
  components: {
    KpiMarker,
    ValueGroup,
  },

  emits: [DashboardCommon.common_valueGroupChanged],

  props: {
    dashboardTileId: { type: String, required: true },
    kpiTileVm: { type: Object as PropType<KpiTileVm>, required: true },
    dashboardSelection: {
      type: Object as PropType<DashboardSelection>,
      required: true,
    },
    dashboardSettings: { type: Object as PropType<DashboardSettings>, required: true },
    sharedState: { type: Object as PropType<SharedDashboardStateVm>, required: true },
  },

  setup(props, context) {
    const state = reactive(new KpiTileState()) as KpiTileState;
    const kpiSelectorCpsl = useKpiSelector(
      props.dashboardSelection,
      props.dashboardTileId,
      props.kpiTileVm.kpiInfo.kpiId
    );

    //
    // Life Cycle:
    // --------------------
    onBeforeMount(() => {
      initialize();
    });

    //
    // Computeds:
    // --------------------

    //
    // Functions:
    // --------------------
    function initialize() {
      props.kpiTileVm.updateDrillInfoValueId(props.sharedState);
      state.valueGroupVm = props.kpiTileVm.currentValueGroup;
      context.emit(DashboardCommon.common_valueGroupChanged);
    }

    function onSwiperStopped(): void {
      if (props.dashboardSelection.isBlocked) {
        return;
      }

      context.emit(DashboardCommon.common_valueGroupChanged);
    }

    function onSwiperFinished(): void {
      const emitValueGroupChanged = kpiSelectorCpsl.isKpiSelected.value;
      updateCurrentValueGroupVm(emitValueGroupChanged);
    }

    function updateCurrentValueGroupVm(emitValueGroupChanged: boolean = false): void {
      state.valueGroupVm = props.kpiTileVm.currentValueGroup;
      props.kpiTileVm.updateDrillInfoValueId(props.sharedState);
      if (emitValueGroupChanged) {
        context.emit(DashboardCommon.common_valueGroupChanged);
      }
    }

    function onKpiScaleIndexChanged(): void {
      const scaleIndex = props.sharedState.kpiScaleIndex;
      if (scaleIndex > 0) {
        props.kpiTileVm.deltaValuesSwiperVm.swipeTo(scaleIndex, false);
      }
      const emitValueGroupChanged = kpiSelectorCpsl.isKpiSelected.value;
      updateCurrentValueGroupVm(emitValueGroupChanged);
    }

    //
    // Watcher:
    // --------------------
    watch(
      () => props.sharedState.kpiScaleIndex,
      () => onKpiScaleIndexChanged()
    );

    return {
      state,
      kpiSelectorCpsl,
      onSwiperStopped,
      onSwiperFinished,
    };
  },
});
</script>

<template>
  <div class="kpiTile" v-on:pointerdown="kpiSelectorCpsl.onPointerDown">
    <KpiMarker
      v-if="
        kpiSelectorCpsl.isKpiSelected.value && $props.dashboardSelection.backgroundColor
      "
      v-bind:markerColor="$props.dashboardSelection.backgroundColor"
      v-bind:markerHeight="'var(--titleWithFilterHeight)'"
      v-bind:markerWidth="'6px'"
    />
    <ValueGroup
      v-bind:valueGroupVms="$props.kpiTileVm.valueGroupVms"
      v-bind:valueGroupVm="state.valueGroupVm"
      v-bind:dashboardSelection="$props.dashboardSelection"
      v-bind:dashboardSettings="$props.dashboardSettings"
      v-bind:sharedState="$props.sharedState"
      v-bind:isAnyReadyHot="$props.kpiTileVm.isAnyReadyHot"
      v-bind:isSelected="kpiSelectorCpsl.isKpiSelected.value"
      v-on:swiper-stopped="onSwiperStopped"
      v-on:swiper-finished="onSwiperFinished"
      v-on="$listeners"
    />
  </div>
</template>

<style lang="less" scoped>
.kpiTile {
}
</style>
