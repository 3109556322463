<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    fillColor: { type: String, default: null },
    showPointer: Boolean,
  },

  setup(props) {
    const colorVars = computed(() => {
      return {
        "--fill-color": props.fillColor,
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div
    class="deltaAppLogo"
    v-bind:style="colorVars"
    v-bind:class="{ showPointer: showPointer }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 486.4 115.5"
      style="enable-background: new 0 0 486.4 115.5"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M0,94.6V31h15.9c10.6,0,18.6,2.6,24.1,7.9s8.3,12.9,8.3,23c0,9.7-3,17.6-8.9,23.6c-5.9,6.1-14.1,9.1-24.4,9.1
              L0,94.6L0,94.6z M4.4,35v55.6h10c9.4,0,16.7-2.4,21.8-7.3c5.1-4.9,7.6-12,7.6-21.2C43.8,44,34.3,35,15.3,35H4.4z"
          />
          <path
            d="M61.4,72.1c0,6.2,1.4,11.1,4.3,14.7c2.9,3.5,6.8,5.3,11.9,5.3c5.1,0,10.1-1.9,14.9-5.6v4.4
              c-4.7,3.3-10,5-15.8,5c-5.9,0-10.6-2.1-14.2-6.3s-5.4-10.1-5.4-17.8c0-6.6,1.9-12.2,5.7-16.8c3.8-4.5,8.7-6.8,14.7-6.8
              c5.7,0,10,2,13,5.9c3,4,4.5,9.4,4.5,16.2v1.8L61.4,72.1L61.4,72.1z M90.8,68.4c-0.2-5.2-1.4-9.3-3.8-12.2
              c-2.3-2.9-5.6-4.4-9.7-4.4c-4.3,0-7.8,1.4-10.6,4.3s-4.5,6.9-5.2,12.3H90.8z"
          />
          <path d="M106.3,94.6V27.3h4.1v67.3H106.3z" />
          <path
            d="M142.9,94c-2.1,1-4.1,1.5-6,1.5c-6.7,0-10.1-4-10.1-12.1V52.9h-8.2v-3.7h8.2V37.4c0.7-0.2,1.3-0.5,2-0.7
              c0.7-0.2,1.4-0.4,2-0.7v13.2h12v3.7h-12v30c0,3.1,0.5,5.4,1.5,6.8c1,1.4,2.7,2.1,5,2.1c1.7,0,3.5-0.5,5.5-1.6V94H142.9z"
          />
          <path
            d="M179.1,94.6v-9.1h-0.2c-1.5,3.1-3.6,5.6-6.3,7.4c-2.8,1.8-5.8,2.8-9.2,2.8c-4.3,0-7.7-1.2-10.3-3.6
              c-2.5-2.4-3.8-5.5-3.8-9.2c0-7.9,5.3-12.7,15.8-14.4l14-2.1c0-9.8-3.7-14.7-11-14.7c-5,0-9.9,2-14.6,6v-4.9c1.7-1.3,4-2.5,6.9-3.4
              s5.6-1.4,8.1-1.4c4.7,0,8.3,1.5,10.9,4.4s3.8,7.1,3.8,12.7v29.6h-4.1V94.6z M166.6,72c-4.8,0.7-8.1,1.8-10,3.3s-2.9,4-2.9,7.4
              c0,2.7,0.9,4.9,2.7,6.7c1.8,1.7,4.3,2.6,7.5,2.6c4.4,0,8-1.6,10.9-4.9c2.9-3.2,4.4-7.4,4.4-12.5v-4.4L166.6,72z"
          />
          <path
            d="M249.7,94.6h-11.6l-5.7-16.2h-25l-5.5,16.2h-11.5l23.8-63.7h11.9L249.7,94.6z M229.6,69.8l-8.8-25.4
              c-0.3-0.8-0.5-2.2-0.8-4h-0.2c-0.3,1.7-0.6,3-0.9,4l-8.7,25.4H229.6z"
          />
          <path
            d="M267.8,87.9h-0.2v27.6h-10.3V49.1h10.3v8h0.2c3.5-6,8.7-9.1,15.4-9.1c5.8,0,10.3,2,13.5,6.1s4.8,9.5,4.8,16.3
              c0,7.6-1.8,13.6-5.5,18.2c-3.6,4.6-8.6,6.8-14.9,6.8C275.4,95.6,270.9,93,267.8,87.9z M267.5,70v5.6c0,3.3,1.1,6.1,3.2,8.4
              c2.1,2.3,4.8,3.5,8.1,3.5c3.8,0,6.8-1.5,9-4.5s3.3-7.1,3.3-12.5c0-4.5-1-8-3-10.5s-4.8-3.8-8.2-3.8c-3.7,0-6.6,1.3-8.9,3.9
              C268.7,62.8,267.5,66.1,267.5,70z"
          />
          <path
            d="M322.6,87.9h-0.2v27.6h-10.3V49.1h10.3v8h0.2c3.5-6,8.7-9.1,15.4-9.1c5.8,0,10.3,2,13.5,6.1s4.8,9.5,4.8,16.3
              c0,7.6-1.8,13.6-5.5,18.2c-3.6,4.6-8.6,6.8-14.9,6.8C330.2,95.6,325.7,93,322.6,87.9z M322.3,70v5.6c0,3.3,1.1,6.1,3.2,8.4
              c2.1,2.3,4.8,3.5,8.1,3.5c3.8,0,6.8-1.5,9-4.5s3.3-7.1,3.3-12.5c0-4.5-1-8-3-10.5s-4.8-3.8-8.2-3.8c-3.7,0-6.6,1.3-8.9,3.9
              C323.4,62.8,322.3,66.1,322.3,70z"
          />
        </g>
        <polygon
          points="429.6,7.5 479.9,94.7 402.9,94.7 400.7,98.4 486.4,98.4 429.6,0 372.8,98.4 377.1,98.4"
        />
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.deltaAppLogo {
  // height: 100%;
  width: 100%;
  &.showPointer {
    cursor: pointer;
  }
  svg {
    height: 100%;
    path,
    polygon {
      fill: var(--fill-color);
    }
  }
}
</style>
