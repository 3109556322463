import {
  KpiValueIdFm,
  KpiValueGroupIdFm,
  KpiIdFm,
  DashboardIdFm,
  EntityIdFm,
  ElementIdFm,
  StructureIdFm,
} from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import {
  KpiValueIdDto,
  KpiValueGroupIdDto,
  KpiIdDto,
  DashboardIdDto,
  EntityIdDto,
  ElementIdDto,
  StructureIdDto,
} from "@/common/service-clients/generated-clients";

export class FmDtoMapperDashboardShared {
  static mapElementIdDto(elementIdFm: ElementIdFm): ElementIdDto {
    const elementIdDto = new ElementIdDto();
    elementIdDto.id = elementIdFm.id;
    elementIdDto.structureId = this.mapStructureIdDto(elementIdFm.structureId);
    return elementIdDto;
  }

  static mapKpiValueIdDto(valueIdFm: KpiValueIdFm): KpiValueIdDto {
    if (!valueIdFm) {
      return null;
    }

    const kpiValueIdDto = new KpiValueIdDto();
    kpiValueIdDto.id = valueIdFm.id;
    kpiValueIdDto.kpiValueGroupId = this.mapKpiValueGroupIdDto(valueIdFm.kpiValueGroupId);
    return kpiValueIdDto;
  }

  static mapKpiValueGroupIdDto(kpiValueGroupIdFm: KpiValueGroupIdFm): KpiValueGroupIdDto {
    const kpiValueGroupIdDto = new KpiValueGroupIdDto();
    kpiValueGroupIdDto.id = kpiValueGroupIdFm.id;
    kpiValueGroupIdDto.kpiId = this.mapKpiIdDto(kpiValueGroupIdFm.kpiId);
    return kpiValueGroupIdDto;
  }

  static mapKpiIdDto(kpiIdFm: KpiIdFm): KpiIdDto {
    const kpiIdDto = new KpiIdDto();
    kpiIdDto.id = kpiIdFm.id;
    kpiIdDto.dashboardId = this._mapDashboardIdDto(kpiIdFm.dashboardId);
    return kpiIdDto;
  }

  static mapStructureIdDto(structureIdFm: StructureIdFm): StructureIdDto {
    const structureIdDto = new StructureIdDto();
    structureIdDto.id = structureIdFm.id;
    structureIdDto.kpiId = this.mapKpiIdDto(structureIdFm.kpiId);
    return structureIdDto;
  }

  private static _mapDashboardIdDto(dashboardIdFm: DashboardIdFm): DashboardIdDto {
    const dashboardIdDto = new DashboardIdDto();
    dashboardIdDto.id = dashboardIdFm.id;
    dashboardIdDto.publishedApplicationId = this._mapEntityIdDto(
      dashboardIdFm.publishedApplicationId
    );
    return dashboardIdDto;
  }

  private static _mapEntityIdDto(entityIdFm: EntityIdFm): EntityIdDto {
    const entityIdDto = new EntityIdDto();
    entityIdDto.idType = entityIdFm.idType;
    entityIdDto.internalId = entityIdFm.internalId;
    return entityIdDto;
  }
}
