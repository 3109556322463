<script lang="ts">
import DeltaAppLogo from "./common/components/delta-app-logo.vue";

import { defineComponent } from "vue";

export default defineComponent({
  components: {
    DeltaAppLogo,
  },

  setup() {},
});
</script>

<template>
  <div class="appLabelComponent">
    <div class="appLabel">
      <DeltaAppLogo v-bind:fillColor="'#fff'" />
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "./common/styles/media-queries.less";
@import "./common/styles/fonts.less";

.appLabelComponent {
  .appLabel {
    position: absolute;
    z-index: 1000;
    bottom: 0px;
    right: 1em;
    width: 10em;
    // at vw === 480px -> 8px
    // at vw === 1920px -> 16px
    font-size: calc(0.5rem + ((1vw - 4.8px) * 0.5556));
    height: 30px;
    display: flex;
    align-items: flex-end;

    .media_isAtleastTablet({
      bottom: 0px;
      right: 40px;
      width: 10em;
    });

    max-width: 120px;
    max-height: 40px;
  }
}
</style>
