import { TypedEvent } from "@/common/events/typed-event";
import { IVmReadyChecker } from "../contract/vm-ready-checker.interface";
import { ITypedEvent } from "@/common/events/ityped-event";
import { WatchStopHandle, watch, watchEffect } from "vue";
import { VsRoot } from "../contract/vs-root";
import { IDisposable } from "@/common/disposable.interface";

export class VmReadyTracker implements IDisposable {
  private _vm: VsRoot = null;
  private _vmReadyChecker: IVmReadyChecker = null;
  private _readyChanged = new TypedEvent<{ isVmReady: boolean; hasAnyError: boolean }>();
  private _swHandle: WatchStopHandle = null;
  private _isReady: boolean = false;

  constructor(vmReadyChecker: IVmReadyChecker, vm: VsRoot) {
    this._vmReadyChecker = vmReadyChecker;
    this._vm = vm;
    const result = this._vmReadyChecker.isVmReady(this._vm);
    this._isReady = result.isVmReady && !result.hasAnyError;

    const swHandle = this._registerVmReadyChecker();
    this._swHandle = swHandle;
  }

  get isVmReady(): boolean {
    return this._isReady;
  }

  get readyChangedEvent(): ITypedEvent<{ isVmReady: boolean; hasAnyError: boolean }> {
    return this._readyChanged;
  }

  dispose(): void {
    this._swHandle();
    this._readyChanged?.removeAllListeners();
  }

  private _registerVmReadyChecker(): WatchStopHandle {
    const watchBehaviour = this._vmReadyChecker.getWatchBehaviour(this._vm);
    const watchFn = () => this._onWatcherChange();
    let swHandle: WatchStopHandle = null;

    if (watchBehaviour.watchList && watchBehaviour.watchList?.length) {
      // use list
      watch(watchBehaviour.watchList, watchFn, { immediate: true, deep: true });
    } else if (watchBehaviour.automatic) {
      // use watch effect
      swHandle = watchEffect(watchFn);
    } else {
      throw Error(
        "IVmReadyChecker must specify either a 'watchList' or set 'automatic' watching flag."
      );
    }

    return swHandle;
  }

  private _onWatcherChange(): void {
    const result = this._vmReadyChecker.isVmReady(this._vm);
    const isNowReady = result.isVmReady && !result.hasAnyError;

    if (this._isReady === isNowReady) {
      return;
    }

    this._isReady = isNowReady;
    this._readyChanged.emit(result);
  }
}
