<script lang="ts">
import { computed, defineComponent } from "vue";
import WizardButton from "./wizard-button.vue";

export default defineComponent({
  components: {
    WizardButton,
  },

  props: {
    buttons: { type: Array<string>, required: true },
  },

  setup(props) {
    const paddingTop = computed(() => {
      return props.buttons.length > 0 ? "10px" : "0px";
    });

    return {
      paddingTop,
    };
  },
});
</script>

<template>
  <div class="wizard-button-list" v-bind:style="{ 'padding-top': paddingTop }">
    <WizardButton
      v-for="(buttonName, index) in $props.buttons"
      v-bind:key="index"
      v-bind:buttonText="buttonName"
    />
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";

.wizard-button-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
