import {
  KpiDrillStructureFilterFm,
  KpiDrillQueryFm,
  KpiDrillSortParameterFm,
  ElementQueryFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import {
  KpisQueryDto,
  KpiDrillStructureFilterDto,
  KpiDrillQueryDto,
  KpiDrillSortParameterDto,
  ElementQueryDto,
} from "@/common/service-clients/generated-clients";
import { FmDtoMapperDashboardShared } from "@/features/dashboard-shared/backend-wrapper/fm-dto-mapper-dashboard-shared";

export class FmDtoMapperDashboard {
  static mapKpisQueryDto(
    kpiDrillStructureFilters: KpiDrillStructureFilterFm[]
  ): KpisQueryDto {
    const kpisQueryDto = new KpisQueryDto();
    kpisQueryDto.structureFilters = this._mapKpiDrillStructureFilterDtos(
      kpiDrillStructureFilters
    );
    return kpisQueryDto;
  }

  static mapKpiDrillQueryDto(kpiDrillQueryFm: KpiDrillQueryFm): KpiDrillQueryDto {
    const kpiDrillQueryDto = new KpiDrillQueryDto();
    kpiDrillQueryDto.groupByStructureId = kpiDrillQueryFm.groupByStructureId;
    kpiDrillQueryDto.valueGroupIds = this._getValueGroupIds(
      kpiDrillQueryFm.valueGroupIds
    );
    kpiDrillQueryDto.structureFilters = this._mapKpiDrillStructureFilterDtos(
      kpiDrillQueryFm.structureFilters
    );
    kpiDrillQueryDto.sortParameter = this._mapSortParameterDto(
      kpiDrillQueryFm.sortParameter
    );
    kpiDrillQueryDto.resultLimit = kpiDrillQueryFm.resultLimit;
    kpiDrillQueryDto.selectedPeriodId = kpiDrillQueryFm.selectedPeriodId;
    return kpiDrillQueryDto;
  }

  static mapDashboardElementQueryDtos(
    elementQueryFms: ElementQueryFm[]
  ): ElementQueryDto[] {
    return elementQueryFms.map((elementQueryFm) =>
      this._mapDashboardElementQueryDto(elementQueryFm)
    );
  }

  private static _getValueGroupIds(valueGroupIds: number[]) {
    return valueGroupIds.map((valueGroupId) => valueGroupId);
  }

  private static _mapSortParameterDto(
    sortParameterFm: KpiDrillSortParameterFm
  ): KpiDrillSortParameterDto {
    const kpiDrillSortParameterDto = new KpiDrillSortParameterDto();
    kpiDrillSortParameterDto.sortType = sortParameterFm.sortType;
    kpiDrillSortParameterDto.valueId = FmDtoMapperDashboardShared.mapKpiValueIdDto(
      sortParameterFm.valueId
    );
    return kpiDrillSortParameterDto;
  }

  private static _mapKpiDrillStructureFilterDtos(
    kpiDrillStructureFiltersFms: KpiDrillStructureFilterFm[]
  ): KpiDrillStructureFilterDto[] {
    return kpiDrillStructureFiltersFms.map((kpiDrillStructureFiltersFm) =>
      this._mapKpiDrillStructureFilterDto(kpiDrillStructureFiltersFm)
    );
  }

  private static _mapKpiDrillStructureFilterDto(
    kpiDrillStructureFilterFm: KpiDrillStructureFilterFm
  ): KpiDrillStructureFilterDto {
    const kpiDrillStructureFilterDto = new KpiDrillStructureFilterDto();
    kpiDrillStructureFilterDto.structureNameId =
      kpiDrillStructureFilterFm.structureNameId;
    kpiDrillStructureFilterDto.elementId = kpiDrillStructureFilterFm.elementId;
    return kpiDrillStructureFilterDto;
  }

  private static _mapDashboardElementQueryDto(
    elementQueryFm: ElementQueryFm
  ): ElementQueryDto {
    const elementQueryDto = new ElementQueryDto();
    elementQueryDto.dashboardId = elementQueryFm.dashboardId;
    elementQueryDto.kpiId = elementQueryFm.kpiId;
    elementQueryDto.structureNameId = elementQueryFm.structureNameId;
    elementQueryDto.elementId = elementQueryFm.elementId;
    return elementQueryDto;
  }
}
