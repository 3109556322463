import { IDisposable } from "@/common/disposable.interface";
import { getGUID } from "@/common/helper/guid-helper";
import { VmWatcher } from "./helper/vm-watcher";
import { VsRoot } from "./contract/vs-root";
import { IViewStateDto } from "@/common/service-clients/generated-clients";
import { VsScopeState } from "./vs-scope-state";
import { watch } from "vue";
import { VueHelper } from "@/common/helper/vue-helper";
import { delay } from "@/common/helper/async-helper";
import cloneDeep from "lodash/cloneDeep";
import { IVmWatchList } from "./contract/vm-watch-list.interface";
import { IVsGenerator } from "./contract/vs-generator.interface";
import { IVmReadyChecker } from "./contract/vm-ready-checker.interface";
import { IVsApplicator } from "./contract/vs-applicator.interface";
import { VmReadyTracker } from "./helper/vm-ready-tracker";
import { exists } from "@/common/object-helper/null-helper";
import { ProcessingQueue } from "./processing-queue";

export type VSGetter<TVSDto> = () => TVSDto;
export interface IVsSyncer extends IDisposable {
  readonly id: string;
}

export abstract class VsSyncerBase<TVsDto extends IViewStateDto> implements IVsSyncer {
  protected _vsScopeState: VsScopeState = null;
  private _listeners: IDisposable[] = [];
  private _isFirstStart: boolean = true;

  private _blockVMWatch: boolean = false;

  // helpers
  private _vmWatcher: VmWatcher = null;
  private _vsApplicator: IVsApplicator = null;
  private _vmReadyTracker: VmReadyTracker = null;

  // work data
  /**
   * The view has a change in VS that needs
   * to be persisted to the backend
   */
  private _hasPendingVsChange: boolean = false;
  protected _viewModel: VsRoot = null;
  private _currentVs: IViewStateDto = null;
  readonly id: string = getGUID();
  private _requestQueue = new ProcessingQueue();

  constructor(
    vm: VsRoot,
    vsScopeState: VsScopeState,
    vmWatchList: IVmWatchList,
    vsGenerator: IVsGenerator,
    vmReadyChecker: IVmReadyChecker,
    vsApplicator: IVsApplicator
  ) {
    if (!exists(vm)) {
      throw Error("VM must always be a valid (not null) ref, when creating a syncer");
    }
    this._viewModel = vm;
    this._vsScopeState = vsScopeState;
    this._vmReadyTracker = new VmReadyTracker(vmReadyChecker, vm);
    this._vmWatcher = new VmWatcher(vm, vmWatchList, vsGenerator);
    this._vsApplicator = vsApplicator;

    this._listeners.push(
      this._vmWatcher.vsChangedEvent.on((vs) => this._onVsChangedOnClient(vs))
    );

    const isPersistAllowedSwHandle = watch(
      () => this._vsScopeState?.isPersistVsChangesAllowed.value,
      () => this._onPersistChangesToggled()
    );
    this._listeners.push(VueHelper.stopWatchToDisposable(isPersistAllowedSwHandle));

    if (this._vmReadyTracker.isVmReady) {
      this._onIsVmReadyChanged({ isVmReady: true, hasAnyError: false });
    }

    this._listeners.push(
      this._vmReadyTracker.readyChangedEvent.on((evt) => this._onIsVmReadyChanged(evt))
    );
  }

  get currentVs(): IViewStateDto {
    return this._currentVs;
  }

  set currentVs(value: IViewStateDto) {
    this._currentVs = value;
    this._vmWatcher.updateLastViewState(value);
  }

  dispose(): void {
    this._vmWatcher.stopWatching();
    this.currentVs = null;
    this._viewModel.isVsSyncReady = false;
    this._listeners.map((l) => l.dispose());
    this._vmWatcher.dispose();
    this._vmReadyTracker.dispose();

    this._viewModel = null;
    this._vmWatcher = null;
    this._vmReadyTracker = null;
    this._vsApplicator = null;
    this._vsScopeState = null;
  }

  // TODO: also start/stop backend watching (after 2.4.0)
  private async _startSyncingAsync(): Promise<void> {
    if (this._isFirstStart) {
      this._isFirstStart = false;
      await this._firstStartSyncingAsync();
    } else {
      await this._restStartSyncingAsync();
    }
  }
  private async _firstStartSyncingAsync(): Promise<void> {
    // 1. check if has view state
    const hasViewState = exists(this._viewModel.viewStateId);

    // 1.1: has vs: get via backend-watcher AND apply that vs
    // 1.2: no vs exists: create a local one via vm-watcher
    if (hasViewState) {
      this._blockVMWatch = true;
      this.currentVs = await this._getViewStateAsync();
      this._viewModel.viewStateId = this.currentVs["id"];
      const applyResult = await this._vsApplicator.applyAsync(
        this._viewModel,
        this.currentVs
      );
      this._vsApplicator.setVsRefsOnVm?.(this._viewModel, this.currentVs);
      if (applyResult.isMissingViewState) {
        this._generateInitialVs();
        // hasPendingVsChange = true, but this is already set in _generateInitialVs()
      }
      this._blockVMWatch = false;
    } else {
      this._generateInitialVs();
    }

    // 2. start watching in sub modules
    this._vmWatcher.startWatching();
    this._viewModel.isVsSyncReady = true;
  }

  private _generateInitialVs(): void {
    this.currentVs = this._vmWatcher.generateVs();
    this._hasPendingVsChange = true;

    if (this._vsScopeState.isPersistVsChangesAllowed.value) {
      this._onPersistChangesToggled();
    }
  }

  private async _restStartSyncingAsync(): Promise<void> {
    this._vmWatcher.startWatching();

    if (this._vsScopeState.isPersistVsChangesAllowed.value) {
      // Perhaps other way possible.
      // On restarting syncing, also a vs change
      // must be triggered/persisted
      const newVs = this._vmWatcher.generateVs();
      this._onVsChangedOnClient(newVs);
    }
  }

  // Event Handler Functions
  // -----------------------
  protected _onPersistChangesToggled(): void {
    if (!this._vsScopeState?.isPersistVsChangesAllowed.value) {
      return;
    }

    const hasBackendVs = exists(this._viewModel.viewStateId);
    if (!this._hasPendingVsChange && hasBackendVs) {
      return;
    }

    this._requestQueue.enqueue(() => this._persistVsAsync());
  }

  // Only watch for any changes if vm is ready
  // TODO: handle errors!
  protected _onIsVmReadyChanged(evt: { isVmReady: boolean; hasAnyError: boolean }): void {
    if (this._blockVMWatch) {
      return;
    }

    if (evt.isVmReady) {
      this._startSyncingAsync();
    } else {
      this._vmWatcher.stopWatching();
    }
  }

  private _onVsChangedOnClient(newViewState: IViewStateDto): void {
    if (this._blockVMWatch) {
      return;
    }

    if (!this._vsScopeState.anyVsWasChanged.value) {
      this._vsScopeState.anyVsWasChanged.value = true;
    }

    this.currentVs = newViewState;
    this._hasPendingVsChange = true;

    this._onPersistChangesToggled();
  }

  private async _persistVsAsync(): Promise<void> {
    const releaseIsSyncing = this._vsScopeState.isSyncing.useReleaseCb();
    let resultVs: IViewStateDto = null;
    if (exists(this._viewModel.viewStateId)) {
      await delay(200); // works like a debounce for update requests

      resultVs = await this._updateViewStateAsync(this._copyLatestVs.bind(this));
    } else {
      resultVs = await this._createViewStateAsync(this._copyLatestVs.bind(this));
      this._viewModel.viewStateId = resultVs?.["id"];
    }
    if (resultVs) {
      this.currentVs = resultVs;
      this._vsApplicator.setVsRefsOnVm?.(this._viewModel, resultVs);
    }

    releaseIsSyncing();
    this._hasPendingVsChange = false;
  }

  private _copyLatestVs(): TVsDto {
    this.currentVs = this._vmWatcher.generateVs();
    return cloneDeep(this.currentVs) as TVsDto;
  }

  // Abstract Functions
  // -----------------------
  protected abstract _updateViewStateAsync(
    getLatestVsCopy: VSGetter<TVsDto>
  ): Promise<TVsDto>;

  protected abstract _createViewStateAsync(
    getLatestVsCopy: VSGetter<TVsDto>
  ): Promise<TVsDto>;

  protected abstract _getViewStateAsync(): Promise<IViewStateDto>;
}
