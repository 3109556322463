import { NumberPresentationModeFm } from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { SharedSparklineState } from "@/features/dashboard-shared/sparkline";

export class SharedDashboardStateVm {
  /**
   * "hiddenColumn" Indices fit the overall data col
   * indices (index 0 would be the absolute data)
   *
   * hiddenColumn === 1: hides first delta column
   * hiddenColumn === 2: hides second delta column
   */
  hiddenColumn: number = null;

  /**
   * -1: no scaling for all columns
   *  0: scale information value
   *  1: scale first delta
   *  2: scale second delta
   */
  private _kpiScaleIndex: number = -1;
  private _lastKpiScaleIndex: number = -1;

  set kpiScaleIndex(value: number) {
    this._lastKpiScaleIndex = this._kpiScaleIndex;
    this._kpiScaleIndex = value;
  }

  kpiScaleIndexBeforeSparklines: number = -1;

  get kpiScaleIndex(): number {
    return this._kpiScaleIndex;
  }

  get lastKpiScaleIndex(): number {
    return this._lastKpiScaleIndex;
  }

  kpiTileIndex = 0;

  sparklineState = new SharedSparklineState();

  isSwipingDeltaValues = false;

  animateSetFilter = true;

  defaultNumberPresentationMode: NumberPresentationModeFm = null;

  clone(): SharedDashboardStateVm {
    const result = new SharedDashboardStateVm();

    result.hiddenColumn = this.hiddenColumn;
    result.kpiScaleIndex = this.kpiScaleIndex;
    result.kpiScaleIndexBeforeSparklines = this.kpiScaleIndexBeforeSparklines;
    result.kpiTileIndex = this.kpiTileIndex;
    result._lastKpiScaleIndex = this._lastKpiScaleIndex;
    result.sparklineState = this.sparklineState.clone();
    result.animateSetFilter = this.animateSetFilter;
    result.defaultNumberPresentationMode = this.defaultNumberPresentationMode;

    return result;
  }
}
