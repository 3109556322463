import cloneDeep from "lodash/cloneDeep";

export const CloneHelper = {
  clone<T>(original: any): T {
    if (original?.clone) {
      return original.clone();
    }

    return cloneDeep(original);
  },
};
