import { SharedDrillInfoVm } from "./shared/shared-drill-info-vm";
import { StructureVm } from "./structure-vm";
import { ValueVm } from "./value-vm";
import { StructureElementsVm } from "./structure-elements-vm";
import { SharedKpiInfo } from "./shared/shared-kpi-info";
import { SharedDashboardStateVm } from "./shared/shared-dashboard-state-vm";
import { SwiperVm } from "@/common/components/swiper-vm";
import clamp from "lodash/clamp";
import { SparklineVm } from "@/features/dashboard-shared/sparkline";
import { PeriodVm } from "@/features/dashboard/view-models/period-vm";
import { IDashboardFacade } from "../backend-wrapper/dashboard-facade.interface";
import {
  ElementQueryFm,
  KpiDrillStructureFilterFm,
  ElementQueryResultFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";

export class ValueGroupVm {
  kpiValues: ValueVm[] = [];

  id: number = null;
  valueGroupId: number = null;
  isReadyHot: boolean = false;

  // TODO: try to move shared VMs into vue component. its problematic for cloning
  kpiInfo: SharedKpiInfo = null;

  periodSwiperVm: SwiperVm = null;
  title: string = null;
  sparklinesGlobalMax: number = null;
  prevGroupName: string = null;
  nextGroupName: string = null;
  initialPeriod: PeriodVm = null;
  structures: StructureVm[] = [];
  kpiTileIndex: number = null;
  deltaIndexForDrill: number = 1;

  structureElementsVm: StructureElementsVm = null;

  // shared from this level on:
  drillInfo: SharedDrillInfoVm = new SharedDrillInfoVm();
  valueGroupSwiperVm: SwiperVm;
  deltaValuesSwiperVm: SwiperVm;

  // TODO: should be reduced or split into objects that are more specific for value-group
  sharedState: SharedDashboardStateVm = null;
  private _dashboardFacade: IDashboardFacade;

  // 'available' are all which are not already in a dashboard filter
  get swiperVms(): SwiperVm[] {
    return [this.periodSwiperVm, this.valueGroupSwiperVm, this.deltaValuesSwiperVm];
  }

  get availableStructures(): StructureVm[] {
    const availableStructures = this.structures.filter(
      (structure) =>
        !this.drillInfo.dashboardFilter.filters.some(
          (dbFilter) => dbFilter.structureNameId === structure.nameId
        )
    );

    return availableStructures;
  }

  get hasStructures(): boolean {
    return this.availableStructures && this.availableStructures.length > 0;
  }

  get firstValueHasSparkline(): boolean {
    return this.kpiValues[0].hasSparkline;
  }

  get allValuesHaveSparkline(): boolean {
    return this.kpiValues.filter((x) => !x.hasSparkline).length === 0;
  }

  get period(): PeriodVm {
    const kpiValue = this.kpiValues[0];
    if (kpiValue.hasSparkline && this.periodSwiperVm) {
      const index = this.sparklinesIndex;
      if (index >= 0 && kpiValue.sparkline.sparkBarValues.length - 1) {
        return kpiValue.sparkline.sparkBarValues[index].period;
      }
    }

    return this.initialPeriod;
  }

  get periodCellTexts(): string[] {
    if (!this.kpiValues[0].hasSparkline) {
      return [this.initialPeriod.displayName];
    }

    return this.kpiValues[0].sparkline.sparkBarValues
      .map((sparkBarValue) => sparkBarValue.period.displayName)
      .reverse();
  }

  get sparklinesIndex(): number {
    if (this.kpiValues.length === 0 || !this.kpiValues[0].sparkline) {
      return 0;
    }

    const activeIndex = this.periodSwiperVm.activeIndex;
    return this.kpiValues[0].sparkline.sparkBarValues.length - 1 - activeIndex;
  }

  init(dashboardFacade: IDashboardFacade) {
    this._dashboardFacade = dashboardFacade;
  }

  valueHasSparklines(scaledValueIndex: number): boolean {
    return (
      scaledValueIndex < this.kpiValues.length &&
      this.kpiValues[scaledValueIndex].hasSparkline
    );
  }

  getUseColorOrScaling(kpiScaleIndex: number): boolean {
    if (kpiScaleIndex < 0) {
      return true;
    }

    const idx = this.getScaleIndex(kpiScaleIndex);
    return !this.kpiValues[idx]?.excludedFromScaling && !this.isReadyHot;
  }

  getScaleIndex(kpiScaleIndex: number): number {
    const currentMaxIdx = this.kpiValues.length - 1;
    return clamp(kpiScaleIndex, 0, currentMaxIdx);
  }

  getShowSparklines(sharedState: SharedDashboardStateVm): boolean {
    if (!this.kpiValues[0].hasSparkline || this.isReadyHot) {
      return false;
    }

    return sharedState.sparklineState.showSparklines;
  }

  getShowSecondSectionSparklines(sharedState: SharedDashboardStateVm): boolean {
    if (!this.getShowSparklines(sharedState)) {
      return false;
    }

    if (!sharedState.sparklineState.deltaSparklinesEnabled) {
      return this.kpiValues[0].hasSparkline;
    }

    let kpiScaleIndex = sharedState.kpiScaleIndex;
    if (kpiScaleIndex === -1) {
      if (sharedState.lastKpiScaleIndex !== 0) {
        return false;
      }
      kpiScaleIndex = 0;
    }

    if (kpiScaleIndex >= this.kpiValues.length) {
      return false;
    }

    const hasSparkline = this.kpiValues[kpiScaleIndex].hasSparkline;
    return hasSparkline && kpiScaleIndex === 0;
  }

  getWeatherColor(kpiScaleIndex: number): string {
    const idx = this.getScaleIndex(kpiScaleIndex);
    return this.kpiValues[idx].weatherColor;
  }

  getScaledValue(
    sharedState: SharedDashboardStateVm,
    ignoreExcludeFromScaling: boolean = false
  ): ValueVm {
    const idx =
      sharedState.kpiScaleIndex < 0 && sharedState.sparklineState.showSparklines
        ? sharedState.lastKpiScaleIndex
        : Math.max(sharedState.kpiScaleIndex, 0);
    const hasValues = this.kpiValues && this.kpiValues.length > 0;
    if (
      !hasValues ||
      (hasValues && this.kpiValues[idx]?.excludedFromScaling && !ignoreExcludeFromScaling)
    )
      return null;

    if (idx < this.kpiValues.length) return this.kpiValues[idx];

    return this.kpiValues[0];
  }

  getSparkline(sharedState: SharedDashboardStateVm): SparklineVm {
    const scaledValue = this.getScaledValue(sharedState, true);

    if (!scaledValue) {
      return null;
    }

    if (sharedState.sparklineState.showSparklines && scaledValue.hasSparkline) {
      return scaledValue.sparkline;
    } else if (!sharedState.sparklineState.showSparklines) {
      const totalValuesWithSparklines = this.kpiValues.filter(
        (kpiValue) => kpiValue.hasSparkline
      ).length;
      if (totalValuesWithSparklines === this.kpiValues.length) {
        return scaledValue.sparkline;
      }
    }
  }

  getPeriodSwiperActiveIndex(sharedState: SharedDashboardStateVm): number {
    const sparkline = this.getSparkline(sharedState);

    if (sparkline) {
      const sparklineState = sharedState.sparklineState;
      const selection = sparklineState.selection[sparkline.timeStructure];
      return this.periodCellTexts.length - 1 - selection.historyIndex;
    }

    return this.periodCellTexts.length - 1;
  }

  async getAppliedFilterNames(): Promise<string[]> {
    if (!this._hasAppliedFilters()) {
      return [];
    }

    const kpiCompatibleFilters = this._getKpiCompatibleFilters();
    if (kpiCompatibleFilters.length === 0) {
      return [];
    }

    const elementQueryFms = this._getElementQueries(kpiCompatibleFilters);
    const elementQueryResults = await this._dashboardFacade.getElementQueryResultsAsync(
      this.drillInfo.publishedApplicationId,
      elementQueryFms
    );

    if (elementQueryResults.error) {
      return [];
    }

    return this._processElementQueryResultToFilterName(
      kpiCompatibleFilters,
      elementQueryResults.value
    );
  }

  setInitialPeriodSwiperActiveIndex(): void {
    const sharedState = this.sharedState;
    const index = this.getPeriodSwiperActiveIndex(sharedState);
    this.periodSwiperVm.swipeTo(index, false);
  }

  clickColumn(
    sharedState: SharedDashboardStateVm,
    newScaleIndex: number,
    isExtended: boolean
  ): void {
    if (isExtended && sharedState.sparklineState.showSparklines) {
      this.clickSameColumn();
      return;
    } else if (sharedState.kpiScaleIndex === newScaleIndex) {
      this.clickSameColumn();
    } else {
      this.clickOtherColumn(sharedState, newScaleIndex);
    }

    if (
      this.structureElementsVm.isVisible &&
      (newScaleIndex === 1 || newScaleIndex === 2)
    ) {
      this.deltaIndexForDrill = newScaleIndex;
    }
  }

  clickSameColumn(): void {
    if (!this.hasStructures) {
      return;
    }

    this.structureElementsVm.toggleVisibility(
      this.sharedState.kpiScaleIndex,
      this.availableStructures
    );

    if (!this.structureElementsVm.isVisible) {
      this.structureElementsVm.resetPercentageModes();
    }

    this.setSelectedPeriodInDrill();
  }

  clickOtherColumn(sharedState: SharedDashboardStateVm, newScaleIndex: number): void {
    if (newScaleIndex === 1 || newScaleIndex === 2) {
      sharedState.hiddenColumn = newScaleIndex === 1 ? 2 : 1;
    }

    sharedState.kpiScaleIndex = newScaleIndex;
  }

  setSelectedPeriodInDrill(): void {
    this.structureElementsVm.sharedDrillInfo.selectedPeriodId =
      this.sparklinesIndex === 0 ? null : this.period.id;
  }

  private _hasAppliedFilters(): boolean {
    const currentDbFilters = this.drillInfo.dashboardFilter.filters;
    return currentDbFilters && currentDbFilters.length > 0;
  }

  private _getKpiCompatibleFilters(): KpiDrillStructureFilterFm[] {
    const currentDbFilters = this.drillInfo.dashboardFilter.filters;
    return currentDbFilters.filter((kpiDrillStructureFilterFm) =>
      this.structures.some(
        (structureVm) => structureVm.nameId === kpiDrillStructureFilterFm.structureNameId
      )
    );
  }

  private _getElementQueries(
    kpiDrillStructureFilterFms: KpiDrillStructureFilterFm[]
  ): ElementQueryFm[] {
    return kpiDrillStructureFilterFms.map((filter) => {
      const elementQueryFm = new ElementQueryFm();
      elementQueryFm.elementId = filter.elementId;
      elementQueryFm.structureNameId = filter.structureNameId;
      elementQueryFm.kpiId = this.drillInfo.kpiId;
      elementQueryFm.dashboardId = this.drillInfo.dashboardId;
      return elementQueryFm;
    });
  }

  private _processElementQueryResultToFilterName(
    kpiDrillStructureFilterFms: KpiDrillStructureFilterFm[],
    elementQueryResults: ElementQueryResultFm[]
  ): string[] {
    // keep the sort order, we can not trust the backend.
    return kpiDrillStructureFilterFms.map((filter) => {
      const foundFilter = elementQueryResults.find(
        (r) =>
          r.structureNameId === filter.structureNameId &&
          r.element.id === filter.elementId
      );
      return foundFilter ? foundFilter.element.displayName : "";
    });
  }
}
