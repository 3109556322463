import { StructureIdFm } from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";

export class StructureVm {
  id: number;
  structureId: StructureIdFm;
  nameId: string;
  displayName: string;

  constructor(other?: StructureVm) {
    if (other) {
      this.id = other.id;
      this.structureId = other.structureId;
      this.nameId = other.nameId;
      this.displayName = other.displayName;
    }
  }

  clone(structureId?: StructureIdFm) {
    const clone = new StructureVm();
    clone.id = this.id;
    clone.structureId = structureId ?? this.structureId.clone();
    clone.nameId = this.nameId;
    clone.displayName = this.displayName;
    return clone;
  }
}
