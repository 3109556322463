import { IFullLogLine } from "../log-entry.interface";
import { ILoggerAdapter, TransportType } from "./logger-adapter.interface";
import { ITransport } from "../transports/transport.interface";
import { ConsoleTransport } from "../transports/console-transport";
import { SessionStorageTransport } from "../transports/session-storage-transport";

export class BissantzLoggerAdapter implements ILoggerAdapter {
  private _transportTypes: TransportType[] = [];
  private _transports: ITransport[] = [];

  constructor() {}

  initialize(options: { transportTypes: TransportType[] }): void {
    this._transportTypes = options.transportTypes;

    this._transportTypes.map((transpName) => {
      if (transpName === "console") {
        this._transports.push(new ConsoleTransport());
      } else if (transpName === "sessionStorage") {
        this._transports.push(new SessionStorageTransport());
      } else if (transpName === "logServer") {
        //TODO
      }
    });
  }

  log(fullLogLine: IFullLogLine): void {
    for (const trans of this._transports) trans.writeLog(fullLogLine);
  }
}
