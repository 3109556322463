import { getValueType, isAnyPercentageType } from "@/common/formatting/value-type";
import { ValueVm } from "./value-vm";
import { SharedDrillInfoVm } from "./shared/shared-drill-info-vm";
import { StructureElementsVm } from "./structure-elements-vm";
import { SharedKpiInfo } from "./shared/shared-kpi-info";
import { SharedDashboardStateVm } from "@/features/dashboard/view-models/shared/shared-dashboard-state-vm";
import { SharedRowStateVm } from "./shared/shared-row-state-vm";
import { BusinessColor } from "@/common/formatting/business-color";
import {
  KpiDrillRowFm,
  KpiDrillStructureFilterFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { StructureVm } from "./structure-vm";
import clamp from "lodash/clamp";

export class ElementVm {
  id: string;
  name: string;
  structureNameId: string;
  elementValues: ValueVm[];
  backingFm: KpiDrillRowFm = null;
  drillDepth: number = null;
  nextStructureVms: StructureVm[] = null;
  previousFilters: KpiDrillStructureFilterFm[] = [];

  sharedDrillInfo: SharedDrillInfoVm = null;
  sharedKpiInfo: SharedKpiInfo = null;
  parentRowState: SharedRowStateVm = null;

  nextStructureElements: StructureElementsVm = null;

  get uniqueId(): string {
    let uniqueId = this.name;
    this.backingFm.kpiValues.map((kpiValue) => (uniqueId += "-" + kpiValue.value));
    return uniqueId;
  }

  get filter(): KpiDrillStructureFilterFm {
    const filter: KpiDrillStructureFilterFm = new KpiDrillStructureFilterFm();
    filter.elementId = this.id;
    filter.structureNameId = this.structureNameId;
    return filter;
  }

  get nextPreviousFilters(): KpiDrillStructureFilterFm[] {
    return [...this.previousFilters, this.filter];
  }

  getScaleIndex(sharedState: SharedDashboardStateVm): number {
    const scaleIndex =
      sharedState.sparklineState.showSparklines && sharedState.kpiScaleIndex === -1
        ? sharedState.lastKpiScaleIndex
        : sharedState.kpiScaleIndex;

    return clamp(scaleIndex, 0, 2);
  }

  getScaledElementValue(sharedState: SharedDashboardStateVm): ValueVm {
    const hasValues = this.elementValues && this.elementValues.length > 0;
    if (!hasValues) return null;

    const scaleIndex = Math.max(sharedState.kpiScaleIndex, 0);
    if (scaleIndex < this.elementValues.length) return this.elementValues[scaleIndex];
  }

  getScaleColor(sharedState: SharedDashboardStateVm): string {
    const val = this.getScaledElementValue(sharedState);
    if (!val.value) return BusinessColor.CssStrings.neutral;

    const valueType = getValueType(val.format);
    const isPercent = isAnyPercentageType(valueType);
    let scaleFactor = val.kpiInfo.scaleFactor ?? 1;
    if (isPercent) {
      scaleFactor = Math.sign(scaleFactor);
    }

    let isGoodKpi = scaleFactor * val.value * val.kpiInfo.factor > 0;
    if (val.kpiInfo.invertSign) isGoodKpi = !isGoodKpi;
    return isGoodKpi
      ? BusinessColor.getPositiveColor()
      : BusinessColor.getNegativeColor();
  }

  init(drillDepth: number): void {
    this.drillDepth = drillDepth;
  }

  togglePercentageMode(index: number): void {
    if (this.elementValues[index].isAnyPercentageType) {
      this.parentRowState.isPercentageModeActive[index] = false;
    } else {
      this.parentRowState.isPercentageModeActive[index] =
        !this.parentRowState.isPercentageModeActive[index];
    }
  }

  toggleStructures(kpiScaleIndex: number): void {
    if (this.nextStructureVms.length === 0) {
      return;
    }

    this.nextStructureElements.toggleVisibility(
      kpiScaleIndex,
      this.nextStructureVms,
      this.nextPreviousFilters
    );
  }
}
