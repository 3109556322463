import { MessageType } from "@/services/chat-service/message-type";

export class ChatMessage {
  private _type: MessageType = "Text";

  message: string = null;

  get type(): MessageType {
    return this._type;
  }

  constructor(type: MessageType, message: string) {
    this._type = type;
    this.message = message;
  }
}
