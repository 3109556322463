import {
  ApiSettingsDto,
  BootstrappingServiceClient,
  FeatureTogglesDto,
  TraceSettingsDto,
} from "./common/service-clients/generated-clients";

export class Bootstrapping {
  static apiSettings: ApiSettingsDto = null;
  static featureToggles: FeatureTogglesDto = null;
  static traceSettings: TraceSettingsDto = null;
  static serviceVersion: string = "1.0.0";

  static async initialize() {
    const service = new BootstrappingServiceClient();
    const result = await service.getBootstrapData();
    Bootstrapping.apiSettings = result.apiSettings;
    Bootstrapping.featureToggles = result.featureToggles;
    Bootstrapping.traceSettings = result.traceSettings;
    Bootstrapping.serviceVersion = result.serviceVersion;

    if (Bootstrapping.traceSettings) {
      try {
        const initialize = (
          await import(/* webpackChunkName: "tracing" */ "./common/logging/tracing")
        ).default;
        initialize(Bootstrapping.serviceVersion, Bootstrapping.traceSettings);
      } catch (exception) {
        // when open telemetry does fail to load, still allow the spa to be used
        console.log("initialization of open telemetry failed", exception);
      }
    }
  }
}
