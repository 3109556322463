import { ContentType } from "@/common/service-clients/generated-clients";

export class ApplicationGroup {
  id: string;
  name: string;
}

export class PublishedApplicationGroup {
  id: string;
  name: string;
}

export class PublishedApplication {
  id: string;
  name: string;
  applicationVersionId: string;
  connectionSetId: string;
  publishedApplicationGroupId: string;
}

export class ConnectionGroup {
  id: string;
  name: string;
}

export class Connection {
  id: string;
  name: string;
}

export class DesignatedConnection {
  id: string;
  connectionId: string;
}

export class OlapDatabaseCube {
  catalog: string;
  name: string;
}

// from: https://stackoverflow.com/questions/44480644/string-union-to-string-array
export const availableMagicButtons = [
  "None",
  "Current",
  "Kpi",
  "Actual",
  "DeltaPreviousPeriod",
  "DeltaPreviousYear",
  "DeltaBudget",
  "Ytd",
  "DeltaPreviousYearYtd",
  "DeltaBudgetYtd",
  "Mtd",
  "DeltaPreviousYearMtd",
  "DeltaBudgetMtd",
  "Reference",
  "History",
  "CurrentCurrentYear",
  "CurrentCurrentQuarter",
  "CurrentCurrentMonth",
  "CurrentCurrentWeek",
  "CurrentToday",
  "CurrentPreviousYear",
  "CurrentPreviousQuarter",
  "CurrentPreviousMonth",
  "CurrentPreviousWeek",
  "CurrentYesterday",
  "CurrentLastYear",
  "CurrentLastQuarter",
  "CurrentLastMonth",
  "CurrentLastWeek",
  "CurrentLastDay",
] as const;
export type MagicButtonType = typeof availableMagicButtons[number];

export class MagicButton {
  constructor(
    public magicButtonType: MagicButtonType,
    public isExecuted: boolean = false
  ) {}
}

export class PeriodElement {
  constructor(
    public name: string,
    public caption: string,
    public hierarchyLevelId: string
  ) {}
}

export class HierarchyLevel {
  elements?: PeriodElement[];

  constructor(
    public id: string,
    public hierarchyId: string,
    public name: string,
    public levelOrdinal: number,
    public componentId: string
  ) {}
}

export class Dimension {
  constructor(
    public id: string,
    public name: string,
    public allHierarchyLevels: HierarchyLevel[],
    public contentType: ContentType
  ) {}
}

export class Kpi {
  id: string;
  name: string;
}
