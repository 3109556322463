<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    loadingText: { type: String, default: null },
  },
});
</script>

<template>
  <div class="loadingBox">
    <span class="loading">{{ $props.loadingText }}</span>
    <span class="one">.</span>
    <span class="two">.</span>
    <span class="three">.</span>
  </div>
</template>

<style lang="less" scoped>
@import "../styles/fonts.less";

.loadingBox {
  color: var(--color_neutralText);
  font-size: 1.2em;
  font-family: @fontLight;
  padding: 5px;

  .loading {
    opacity: 0.2;
    animation: text 2s infinite;
  }

  .one {
    opacity: 0;
    animation: dot_one 2s infinite;
  }

  .two {
    opacity: 0;
    animation: dot_two 2s infinite;
  }

  .three {
    opacity: 0;
    animation: dot_three 2s infinite;
  }

  @keyframes dot_one {
    0%,
    20% {
      opacity: 0.2;
    }
    30%,
    70% {
      opacity: 1;
    }
    80% {
      opacity: 0.2;
    }
  }

  @keyframes dot_two {
    0%,
    30% {
      opacity: 0.2;
    }
    40%,
    60% {
      opacity: 1;
    }
    70% {
      opacity: 0.2;
    }
  }

  @keyframes dot_three {
    0%,
    40% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0.2;
    }
  }

  @keyframes text {
    0% {
      opacity: 0.3;
    }
    25%,
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
}
</style>
