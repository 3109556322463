import {
  KpiFm,
  StructureFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { DashboardFiltersListVm } from "../dashboard-filters-list-vm";
import { KpiTileVm } from "../kpi-tile-vm";
import { SwiperVm } from "@/common/components/swiper-vm";
import { ValueGroupBuilder } from "./value-group-builder";
import { SharedKpiInfo } from "../shared/shared-kpi-info";
import { BiColorThresholds } from "@/common/formatting/bicolor-thresholds";
import { StructureVm } from "../structure-vm";
import { CloneHelper } from "@/common/object-helper/clone-helper";
import { DashboardSettings } from "@/features/dashboard-shared/dashboard-settings";
import { SharedDashboardStateVm } from "../shared/shared-dashboard-state-vm";
import { IDashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade.interface";

export class KpiTileBuilder {
  private _valueGroupBuilder: ValueGroupBuilder = new ValueGroupBuilder();

  createKpiTileVm(
    kpiFm: KpiFm,
    publishedApplicationId: string,
    valueGroupSwiperVm: SwiperVm,
    deltaValuesSwiperVm: SwiperVm,
    filters: DashboardFiltersListVm,
    kpiTileIndex: number,
    dbSettings: DashboardSettings,
    sharedState: SharedDashboardStateVm,
    dashboardFacade: IDashboardFacade = null
  ): KpiTileVm {
    const kpiTileVm = new KpiTileVm(dbSettings);
    kpiTileVm.backingFm = kpiFm;
    kpiTileVm.valueGroupSwiperVm = valueGroupSwiperVm;
    kpiTileVm.deltaValuesSwiperVm = deltaValuesSwiperVm;
    kpiTileVm.filters = filters;
    kpiTileVm.kpiTileIndex = kpiTileIndex;
    kpiTileVm.kpiInfo = this._createKpiInfo(kpiFm);
    kpiTileVm.valueGroupVms = this._valueGroupBuilder.createValueGroupVms(
      kpiFm,
      kpiTileVm.kpiInfo,
      publishedApplicationId,
      filters,
      valueGroupSwiperVm,
      deltaValuesSwiperVm,
      sharedState,
      dashboardFacade
    );
    return kpiTileVm;
  }

  clone(
    original: KpiTileVm,
    clonedValueGroupdSwiper: SwiperVm,
    clonedDeltaValuesSwiper: SwiperVm,
    cloneFilters: DashboardFiltersListVm,
    clonedDbSettings: DashboardSettings,
    publishedApplicationId: string = null,
    clonedSharedState: SharedDashboardStateVm
  ): KpiTileVm {
    const clone = new KpiTileVm(clonedDbSettings);
    clone.backingFm = CloneHelper.clone(original.backingFm);
    clone.valueGroupSwiperVm = clonedValueGroupdSwiper;
    clone.deltaValuesSwiperVm = clonedDeltaValuesSwiper;
    clone.filters = cloneFilters;
    clone.kpiTileIndex = original.kpiTileIndex;
    clone.kpiInfo = original.kpiInfo.clone(this._createStructures(clone.backingFm));
    clone.periodSwiperVm = new SwiperVm(
      original.periodSwiperVm.numberOfItemsToDisplay,
      original.periodSwiperVm.activeIndex,
      original.periodSwiperVm.infiniteLoop,
      original.periodSwiperVm.jumpToOtherEndEnabled
    );

    // valueGroupVms needs to be cloned after kpiTileIndex and periodSwiperVm
    clone.valueGroupVms = original.valueGroupVms.map((valGrp) =>
      this._valueGroupBuilder.clone(
        valGrp,
        cloneFilters,
        clone.kpiInfo,
        clone.backingFm.valueGroups.find((grp) => grp.id === valGrp.id),
        publishedApplicationId,
        clonedValueGroupdSwiper,
        clonedDeltaValuesSwiper,
        clonedSharedState
      )
    );

    return clone;
  }

  private _createKpiInfo(kpiFm: KpiFm): SharedKpiInfo {
    return new SharedKpiInfo({
      timeStructure: kpiFm.timeStructureType,
      biColorThresholds: new BiColorThresholds(kpiFm.biColorThresholds),
      factor: kpiFm.factor,
      invertSign: kpiFm.invertSign,
      scaleFactor: kpiFm.scaleFactor,
      kpiId: kpiFm.id,
      kpiStructures: this._createStructures(kpiFm),
    });
  }

  private _createStructures(kpiFm: KpiFm): StructureVm[] {
    return kpiFm.structures.map((structureFm) => this._createStructure(structureFm));
  }

  private _createStructure(structureFm: StructureFm): StructureVm {
    const structure = new StructureVm();
    structure.id = structureFm.id;
    structure.displayName = structureFm.displayName;
    structure.nameId = structureFm.nameId;
    structure.structureId = structureFm.structureId.clone
      ? structureFm.structureId.clone()
      : structureFm.structureId;
    return structure;
  }
}
