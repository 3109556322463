<script lang="ts">
import { BcMenuItem as MenuItem } from "@bissantz/controls";
import MagicButtonList from "./magic-button-list.vue";
import { reactive, defineComponent, watch, computed } from "vue";
import { ModellingVm } from "../../../view-models/modelling-vm";
import { appResources } from "@/app-resources";
import { SelectionResultVm } from "../../../view-models/modelling/selection-result-vm";
import RotatableArrowWithText from "./rotatable-arrow-with-text.vue";
import WizardButtonList from "../../../helper-components/wizard-button-list.vue";

class TitleDefaultProps {
  changeArrowColorOnRotation: boolean = false;
  changeTextColorOnRotation: boolean = false;
  changeTextColorOnHovering: boolean = false;
}

export default defineComponent({
  components: {
    MenuItem,
    MagicButtonList,
    RotatableArrowWithText,
    WizardButtonList,
  },

  props: {
    modellingVm: { type: ModellingVm, required: true },
  },

  setup(props) {
    const state = reactive({
      appWizardTexts: appResources.applicationWizardTexts,
      dimensionSelectionResult: new SelectionResultVm(),
      kpiSelectionResult: new SelectionResultVm(),
      isMagicButtonOpen: false,
    });

    const titleDefaultProps = computed<TitleDefaultProps>(() => {
      return new TitleDefaultProps();
    });

    const levelButtons = computed<string[]>(() => {
      return props.modellingVm.navigationVm.selectedLevels.map((level) => level.name);
    });

    const kpiButtons = computed<string[]>(() => {
      return props.modellingVm.navigationVm.selectedKpis.map((kpi) => kpi.name);
    });

    function toggleDimensionVisibility() {
      state.dimensionSelectionResult.toggle();
    }

    function toggleKpiVisibility() {
      state.kpiSelectionResult.toggle();
    }

    function toggleMagicButtonVisibility() {
      state.isMagicButtonOpen = !state.isMagicButtonOpen;
    }

    function onDimensionSelectionChange(newLength: number): void {
      state.dimensionSelectionResult.numberOfSelectedItems = newLength;
    }

    function onKpiSelectionChange(newLength: number): void {
      state.kpiSelectionResult.numberOfSelectedItems = newLength;
    }

    watch(
      () => props.modellingVm.navigationVm.selectedLevels.length,
      onDimensionSelectionChange
    );

    watch(() => props.modellingVm.navigationVm.selectedKpis.length, onKpiSelectionChange);

    return {
      state,
      toggleDimensionVisibility,
      toggleKpiVisibility,
      toggleMagicButtonVisibility,
      titleDefaultProps,
      levelButtons,
      kpiButtons,
    };
  },
});
</script>

<template>
  <div class="application-wizard_report-modelling-selected-options report-wrapper">
    <!-- PERIOD -->
    <div class="selection-wrapper">
      <div class="period-header">{{ state.appWizardTexts.modelling_periodHeader }}:</div>
      <div class="selected-period">
        {{ $props.modellingVm.navigationVm.selectedPeriodElementsDisplayText }}
      </div>
    </div>

    <!-- Not Clickable Separator -->
    <div class="separator" />

    <!-- DIMENSIONS -->
    <MenuItem
      v-bind:transitionDuration="200"
      v-bind:active="state.dimensionSelectionResult.isOpen"
      v-on:click="toggleDimensionVisibility"
    >
      <template v-slot:title>
        <RotatableArrowWithText
          v-bind:isRotated="state.dimensionSelectionResult.isOpen"
          v-bind:text="state.appWizardTexts.modelling_dimensionHeader"
          v-bind="titleDefaultProps"
        />
      </template>
      <template v-slot:component>
        <WizardButtonList v-bind:buttons="levelButtons" />
      </template>
    </MenuItem>

    <!-- Not Clickable Separator -->
    <div class="separator" />

    <!-- MEASURES -->
    <MenuItem
      v-bind:transitionDuration="200"
      v-bind:active="state.kpiSelectionResult.isOpen"
      v-on:click="toggleKpiVisibility"
    >
      <template v-slot:title>
        <RotatableArrowWithText
          v-bind:isRotated="state.kpiSelectionResult.isOpen"
          v-bind:text="state.appWizardTexts.modelling_measureHeader"
          v-bind="titleDefaultProps"
        />
      </template>
      <template v-slot:component>
        <WizardButtonList v-bind:buttons="kpiButtons" />
      </template>
    </MenuItem>

    <!-- Not Clickable Separator -->
    <div class="separator" />

    <!-- MAGIC BUTTONS -->
    <MenuItem
      v-bind:transitionDuration="200"
      v-bind:active="false"
      v-on:click="toggleMagicButtonVisibility"
    >
      <template v-slot:title>
        <RotatableArrowWithText
          v-bind:isRotated="state.isMagicButtonOpen"
          v-bind:text="state.appWizardTexts.modelling_viewOfPeriodHeader"
          v-bind="titleDefaultProps"
        />
      </template>
      <template v-slot:component>
        <MagicButtonList
          v-bind:magicButtonListVm="$props.modellingVm.magicButtonListVm"
        />
      </template>
    </MenuItem>
  </div>
</template>

<style lang="less" scoped>
@import "../../../../../common/styles/base-styles.less";
@import "./report-modelling.less";
.application-wizard_report-modelling-selected-options {
  display: flex;
  flex: 0 0 69%;
  flex-direction: column;

  .period-header {
    display: flex;
    margin-left: 26px;
  }

  .selected-period {
    color: var(--color_Weather3);
    margin-left: 5px;
  }
}
</style>
