import {
  IHistoricKpiValueDto,
  IPeriodDto,
} from "@/common/service-clients/generated-clients";
import {
  HistoricKpiValueFm,
  PeriodFm,
} from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";

export class FmMapperSparklines {
  static mapHistoricDataFms(historicDataDtos: IHistoricKpiValueDto[]) {
    if (!historicDataDtos) {
      return null;
    }

    return historicDataDtos.map((historicDataDto) =>
      this._mapHistoricDataFm(historicDataDto)
    );
  }

  static mapPeriodFm(periodDto: IPeriodDto): PeriodFm {
    const periodFm = new PeriodFm();
    periodFm.id = periodDto.id;
    periodFm.value = periodDto.value;
    return periodFm;
  }

  private static _mapHistoricDataFm(
    historicDataDto: IHistoricKpiValueDto
  ): HistoricKpiValueFm {
    const historicDataFm = new HistoricKpiValueFm();
    historicDataFm.value = historicDataDto.value;
    historicDataFm.sparklineDeviationValue = historicDataDto.sparklineDeviationValue;
    historicDataFm.period = this.mapPeriodFm(historicDataDto.period);
    historicDataFm.dynamicColorValue = historicDataDto.dynamicColorValue;
    return historicDataFm;
  }
}
