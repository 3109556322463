import { IApplicationFacade } from "../backend-wrapper/application-facade.interface";
import { IConnectionFacade } from "../backend-wrapper/connection-facade.interface";
import { IModelFacade } from "../backend-wrapper/model-facade.interface";
import { ITransactionFacade } from "@/common/transactions/transaction-facade.interface";
import { DatasourceVm } from "./datasource-vm";
import { ApplicationVm } from "./application-vm";
import { ModellingVm, ReportImportFinishedEventArgs } from "./modelling-vm";
import { PortalVm } from "./portal-vm";
import { TypedEvent } from "@/common/events/typed-event";

export class WizardFinishedEventArgs {
  constructor(
    public readonly wizardSucceeded: boolean,
    public readonly portalTileHasBeenCreated: boolean
  ) {}
}

export class AppWizardVm {
  private readonly _connectionFacade: IConnectionFacade = null;
  private readonly _applicationFacade: IApplicationFacade = null;
  private readonly _modelFacade: IModelFacade = null;
  private readonly _transactionFacade: ITransactionFacade = null;
  private readonly _datasourceVm: DatasourceVm = null;
  private readonly _portalVm: PortalVm = null;
  private _applicationVm: ApplicationVm = null;
  private _modellingVm: ModellingVm = null;

  public readonly wizardFinished = new TypedEvent<WizardFinishedEventArgs>();

  get isSendingDataToBackend() {
    if (this._datasourceVm.isSendingDataToBackend) {
      return true;
    }

    if (this._applicationVm?.isSendingDataToBackend === true) {
      return true;
    }

    if (this._modellingVm?.isSendingDataToBackend === true) {
      return true;
    }

    return false;
  }

  get portalTileHasBeenCreated(): boolean {
    return this._applicationVm?.portalTileHasBeenCreated;
  }

  get dataSourceVm(): DatasourceVm {
    return this._datasourceVm;
  }

  constructor(
    connectionFacade: IConnectionFacade,
    applicationFacade: IApplicationFacade,
    modelFacade: IModelFacade,
    transactionFacade: ITransactionFacade,
    portalVm: PortalVm
  ) {
    this._applicationFacade = applicationFacade;
    this._connectionFacade = connectionFacade;
    this._modelFacade = modelFacade;
    this._transactionFacade = transactionFacade;
    this._portalVm = portalVm;
    this._datasourceVm = new DatasourceVm(connectionFacade, transactionFacade);
  }

  async getViewModelForStep(stepNumber: number): Promise<unknown> {
    if (stepNumber === 0) {
      return this._datasourceVm;
    }

    if (stepNumber === 1) {
      this._applicationVm = await this._buildApplicationVm();
      return this._applicationVm;
    }

    if (stepNumber === 2) {
      this._modellingVm?.dispose();
      this._modellingVm = await this._buildModellingVm();
      this._modellingVm.reportImportFinished.on((e) => this._onReportImportFinished(e));
      return this._modellingVm;
    }

    return null;
  }

  async cancel() {
    await this._transactionFacade.rollbackActiveTransactionIfAny();
  }

  private async _buildApplicationVm(): Promise<ApplicationVm> {
    const connectionSetId = await this._datasourceVm.getCurrentConnectionSetId();

    if (connectionSetId === null) {
      return null;
    }

    return new ApplicationVm(
      this._applicationFacade,
      this._transactionFacade,
      this._portalVm,
      connectionSetId
    );
  }

  private async _buildModellingVm(): Promise<ModellingVm> {
    const currentPublishedApplication =
      await this._applicationVm.getCurrentPublishedApplication();

    if (currentPublishedApplication === null) {
      return null;
    }

    return new ModellingVm(
      this._connectionFacade,
      this._modelFacade,
      this._transactionFacade,
      this._datasourceVm.currentConnectionId,
      currentPublishedApplication
    );
  }

  private _onReportImportFinished(e: ReportImportFinishedEventArgs): void {
    this.wizardFinished.emit(
      new WizardFinishedEventArgs(e.importSucceeded, this.portalTileHasBeenCreated)
    );
  }

  dispose(): void {
    this._modellingVm?.dispose();
    this.wizardFinished.removeAllListeners();
  }
}
