<script lang="ts">
import { SparklineCommon } from "./sparkline-common";
import { SparklineVm } from "./sparkline-vm";
import { SharedSparklineState } from "./shared-sparkline-state";
import { BarAnimation } from "./animations/bar-animation";
import { CanvasVm } from "./animations/canvas-vm";

import {
  PropType,
  defineComponent,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
  watch,
  ref,
  nextTick,
} from "vue";

import { isLeftDownInEvent } from "@/common/events/dom-event-helper";
import { ClickHelper } from "@/common/events/click-helper";
import { TouchHelper } from "@/common/events/touch-helper";
import { roundToDecimal } from "@/common/formatting/rounding";
import { DefaultStyles } from "@/common/styles/default-styles";
import { BarAnimationState } from "./animations/bar-animation-state";
import { SwipeDetectionHelper } from "@/common/swipe-detection-helper";
import { IColorInfo } from "@/common/formatting/color-info-interface";
import { useAnimationCounter } from "./animations/useAnimationCounter";
import { useHelpText } from "@/services/help-text-service/help-text-cpsl";
import { DM7Timeout } from "@/common/helper/dm7-timeout";

class SparklineBoxState {
  initialized: boolean = null;
  kpiScaleIndexJustChanged = false;
  clickHelper: ClickHelper = null;
  touchHelper: TouchHelper = null;
  swipeDetector: SwipeDetectionHelper = null;
  scrollLevel = 1;
  autoScrollTimeout: DM7Timeout = null;
  canvasVm: CanvasVm = null;
  startTime: number = null;
  barAnimations: BarAnimation[] = null;
  eventsAdded = false;
  autoScrollEnabled = false;
}

export default defineComponent({
  emits: [SparklineCommon.events.sparklineBox_histIdxChanged],
  props: {
    sparklineVm: { type: Object as PropType<SparklineVm>, default: null },
    sparklineDisplayState: {
      type: Object as PropType<SharedSparklineState>,
      required: true,
    },
    colorInfo: { type: Object as PropType<IColorInfo>, required: true },
    kpiScaleIndex: { type: Number, default: 0 },
    excludeFromScaling: { type: Boolean, default: false },
    sparklinesGlobalMax: { type: Number, default: null },
    drillDepth: { type: Number, default: 0 },
    zoomFactor: { type: Number, default: 1 },
    sparklineHeight: { type: Number, default: 23 },
  },

  setup(props, context) {
    const ref_scrollContainer = ref<HTMLDivElement | null>(null);
    const ref_sparkline = ref<HTMLDivElement | null>(null);

    const state = reactive(new SparklineBoxState()) as SparklineBoxState;
    const animationCounter = useAnimationCounter(props.sparklineDisplayState);
    const helpTextCpsl = useHelpText();

    //
    // Life Cycle:
    // --------------------
    onMounted(() => {
      state.clickHelper = new ClickHelper();
      state.touchHelper = new TouchHelper();
      state.clickHelper.touchHelper = state.touchHelper;
      state.clickHelper.setOnClickAction(onClick);
      state.clickHelper.setOnLongClickAction(onLongClick);
      state.clickHelper.setOnLongClickFinishedAction(onLongClickFinished);
      state.touchHelper.setTapAction(onTap);
      state.touchHelper.setLongTouchAction(onLongTouch);
      state.touchHelper.setLongTouchFinishedAction(onLongTouchFinished);

      initialize();
    });

    onBeforeUnmount(() => {
      animationCounter.isAnimating.value = false;
    });

    //
    // Computeds:
    // --------------------
    const sparklineBoxStyle = computed(() => {
      const boxWidth = props.sparklineDisplayState.widthPixel + "px";
      return { "min-width": boxWidth, "max-width": boxWidth };
    });

    const currentZoomFactor = computed(() => {
      let factor = 1;

      if (factor > 0) {
        factor = props.zoomFactor;
      }

      return factor;
    });

    const canSwipe = computed(() => {
      return (
        props.sparklineVm.sparkBarValues.length >
        props.sparklineDisplayState.maxNumVisibleBars
      );
    });

    const isValueSelectionEnabled = computed({
      get(): boolean {
        const ts = props.sparklineVm.timeStructure;
        return props.sparklineDisplayState.selection[ts].isMouseHoverEnabled;
      },

      set(isEnabled: boolean) {
        const ts = props.sparklineVm.timeStructure;
        props.sparklineDisplayState.selection[ts].isMouseHoverEnabled = isEnabled;
      },
    });

    const isSelectionFixed = computed({
      get(): boolean {
        const ts = props.sparklineVm.timeStructure;
        return props.sparklineDisplayState.selection[ts].isSelectionFixed;
      },

      set(value: boolean) {
        const ts = props.sparklineVm.timeStructure;
        props.sparklineDisplayState.selection[ts].isSelectionFixed = value;
      },
    });

    const localHistoryIdx = computed(() => {
      const maxPossibleIdx = props.sparklineVm.sparkBarValues.length - 1;

      return Math.min(
        props.sparklineDisplayState.selection[props.sparklineVm.timeStructure]
          .historyIndex,
        maxPossibleIdx
      );
    });

    const scrollPosPixel = computed(() => {
      let innerScrollPosPixel = props.sparklineDisplayState.scrollPosPixel;
      if (!innerScrollPosPixel) {
        innerScrollPosPixel = 0;
      }

      innerScrollPosPixel = Math.max(0, innerScrollPosPixel);
      innerScrollPosPixel = Math.min(maxAllowedScrolledPx.value, innerScrollPosPixel);
      return innerScrollPosPixel;
    });

    const scrollStyle = computed(() => {
      if (!state.initialized) {
        return;
      }

      if (isSelectionFixed.value) {
        snapBars();
        drawVisible();
      }

      return { transform: "translateX(" + scrollPosPixel.value + "px)" };
    });

    const globalSparklinesEnabled = computed(() => {
      return props.sparklineDisplayState.globalSparklinesEnabled;
    });

    const maxAllowedScrolledPx = computed(() => {
      const sparklineState = props.sparklineDisplayState;
      const totalBarWidth = sparklineState.barMargin + sparklineState.barWidth;
      const maxNumVisibleBars = sparklineState.maxNumVisibleBars;
      if (props.sparklineVm.sparkBarValues.length < maxNumVisibleBars) {
        return 0;
      }
      return (
        totalBarWidth * (props.sparklineVm.sparkBarValues.length - maxNumVisibleBars)
      );
    });

    const animationStartIndex = computed(() => {
      const displayState = props.sparklineDisplayState;
      const effBarWidth = displayState.barMargin + displayState.barWidth;
      return Math.floor(scrollPosPixel.value / effBarWidth);
    });

    const animationEndIndex = computed(() => {
      const displayState = props.sparklineDisplayState;
      let endIndex = animationStartIndex.value + displayState.maxNumVisibleBars;
      return Math.min(endIndex, state.barAnimations.length);
    });

    //
    // Functions:
    // --------------------
    function initialize(): void {
      if (!props.sparklineVm || !props.sparklineDisplayState.showSparklines) {
        return;
      }

      state.barAnimations = [];
      state.canvasVm = createCanvasInfo();
      const sparkline = ref_sparkline.value;
      sparkline.insertBefore(state.canvasVm.canvas, sparkline.childNodes[0]);
      props.sparklineVm.animations = [];
      state.swipeDetector = new SwipeDetectionHelper();
      initializeAnimations();
    }

    function initializeAnimations(): void {
      state.initialized = false;
      const barAnimationState = getBarAnimationState();
      if (props.sparklineDisplayState.preMode === "disabled") {
        props.sparklineVm.animations = [];
        barAnimationState.defaultTransition = false;
      }
      state.barAnimations = props.sparklineVm.getAnimations(barAnimationState);
      state.initialized = true;
      startAnimation();
    }

    function getBarAnimationState(): BarAnimationState {
      const barAnimationState = new BarAnimationState();

      barAnimationState.availableHeight = state.canvasVm.height;
      barAnimationState.sparklinesGlobalMax = props.sparklinesGlobalMax;
      barAnimationState.sparklinesDisplayState = props.sparklineDisplayState;
      barAnimationState.excludeFromScaling = props.excludeFromScaling;
      barAnimationState.globalSparklinesEnabled = globalSparklinesEnabled.value;

      barAnimationState.factor = props.colorInfo.factor;
      barAnimationState.scaleFactor = props.colorInfo.scaleFactor;
      barAnimationState.biColorThresholds = props.colorInfo.biColorThresholds;
      barAnimationState.invertSign = props.colorInfo.invertSign;

      return barAnimationState;
    }

    function getStartAndEndWithOffset(): { start: number; end: number } {
      const useOffset = state.autoScrollEnabled || !isSelectionFixed.value;
      const offset = useOffset ? 3 : 0;

      let start = Math.max(animationStartIndex.value - offset, 0);
      const end = Math.min(animationEndIndex.value + offset, state.barAnimations.length);

      const numberOfBars = end - start;
      const maxNumVisibleBars = props.sparklineDisplayState.maxNumVisibleBars;

      if (!useOffset && numberOfBars !== maxNumVisibleBars) {
        start = Math.max(end - maxNumVisibleBars, 0);
      }

      return { start, end };
    }

    function createCanvasInfo(): CanvasVm {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const barWidth = props.sparklineDisplayState.barWidth;
      const barMargin = props.sparklineDisplayState.barMargin;
      const maxNumBars = props.sparklineDisplayState.maxNumBars;

      const width = maxNumBars * (barMargin + barWidth) + barMargin;
      const height = props.sparklineHeight;

      canvas.style.height = height + "px";
      canvas.style.width = width + "px";

      // TODO: update pixel ratio on window resize
      const scale = window.devicePixelRatio;
      canvas.width = Math.floor(width * scale);
      canvas.height = Math.floor(height * scale);
      context.scale(scale, scale);

      return new CanvasVm(canvas, context, width, height);
    }

    function startAnimation(): void {
      animationCounter.isAnimating.value = true;
      if (
        props.excludeFromScaling ||
        !props.sparklineDisplayState.animationsCurrentlyEnabled
      ) {
        drawVisible();
        nextTick(() => (animationCounter.isAnimating.value = false));
      } else {
        state.startTime = Date.now();
        window.requestAnimationFrame(updateBars);
      }
    }

    function updateBars(): void {
      const elapsed = getElapsedTime_ms();
      clearVisibleCanvas();

      let changedValues = 0;
      const start = animationStartIndex.value;
      const end = animationEndIndex.value;

      for (let i = start; i < end; i++) {
        const animation = state.barAnimations[i];
        animation.update(elapsed);
        draw(animation, i);

        if (!animation.isAnimationDone) {
          changedValues++;
        }
      }

      if (changedValues > 0) {
        window.requestAnimationFrame(updateBars);
      } else {
        animationCounter.isAnimating.value = false;
        state.startTime = null;
      }
    }

    function draw(barAnimation: BarAnimation, barIndex: number): void {
      if (isValueSelectionEnabled.value && localHistoryIdx.value !== barIndex) {
        state.canvasVm.context.globalAlpha = 0.4;
      } else {
        state.canvasVm.context.globalAlpha = 1;
      }

      state.canvasVm.context.fillStyle = props.excludeFromScaling
        ? DefaultStyles.colorConstants.neutralTextHEX
        : barAnimation.color;
      state.canvasVm.context.fillRect(
        barAnimation.x,
        barAnimation.y,
        barAnimation.barWidth,
        barAnimation.barHeight
      );
    }

    function getElapsedTime_ms(): number {
      return Date.now() - state.startTime;
    }

    function clearAllCanvas(): void {
      state.canvasVm?.context.clearRect(
        0,
        0,
        state.canvasVm.width,
        state.canvasVm.height
      );
    }

    function clearVisibleCanvas(): void {
      const sparklineDisplayState = props.sparklineDisplayState;
      const barWidth = sparklineDisplayState.barWidth;
      const barMargin = sparklineDisplayState.barMargin;
      const visibleStartIndex =
        sparklineDisplayState.maxNumBars - animationEndIndex.value;
      const numVisibleItems = animationEndIndex.value - animationStartIndex.value;

      const startPosition = visibleStartIndex * (barWidth + barMargin);
      const width = numVisibleItems * (barWidth + barMargin) + barMargin;

      state.canvasVm.context.clearRect(startPosition, 0, width, state.canvasVm.height);
    }

    function selectNearestBar(xcoord: number): void {
      const sparklineElement = ref_sparkline.value;
      const sparklineRect = sparklineElement.getBoundingClientRect();
      let start = sparklineRect.x + sparklineRect.width;

      if (!start) {
        start =
          props.sparklineDisplayState.maxNumVisibleBars *
          (props.sparklineDisplayState.barMargin + props.sparklineDisplayState.barWidth) *
          currentZoomFactor.value;
        xcoord = start - xcoord;
      }

      let index = Math.floor(
        roundToDecimal(start - xcoord) /
          ((props.sparklineDisplayState.barMargin +
            props.sparklineDisplayState.barWidth) *
            currentZoomFactor.value)
      );
      index = Math.min(index, props.sparklineVm.sparkBarValues.length - 1);
      index = Math.max(index, 0);
      setHistIdx(index);
    }

    function setHistIdx(idx: number) {
      const selection =
        props.sparklineDisplayState.selection[props.sparklineVm.timeStructure];
      if (idx != selection.historyIndex) {
        selection.historyIndex = idx;
        context.emit(
          SparklineCommon.events.sparklineBox_histIdxChanged,
          props.drillDepth
        );
      }
    }

    function addEndEvents(): void {
      if (!state.eventsAdded) {
        state.eventsAdded = true;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
        document.addEventListener("touchmove", onTouchMove);
        document.addEventListener("touchend", onTouchEnd);
      }
    }

    function removeEndEvents(): void {
      state.eventsAdded = false;
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
    }

    function fixSelection(): void {
      isSelectionFixed.value = true;
      state.autoScrollEnabled = false;
      removeEndEvents();
    }

    function enableValueSelectionMode(position_x: number): void {
      isValueSelectionEnabled.value = true;
      isSelectionFixed.value = false;
      selectNearestBar(position_x);
    }

    function disableValueSelectionMode(): void {
      isValueSelectionEnabled.value = false;
      isSelectionFixed.value = true;
      setHistIdx(0);
    }

    function initAutoScroll(position_x: number): void {
      if (isOutRight(position_x)) {
        if (!state.autoScrollEnabled) {
          state.autoScrollEnabled = true;
          autoScroll(1);
        } else {
          setScrollSpeed(position_x);
        }
      } else if (isOutLeft(position_x)) {
        if (!state.autoScrollEnabled) {
          state.autoScrollEnabled = true;
          autoScroll(-1);
        } else {
          setScrollSpeed(position_x);
        }
      } else {
        state.autoScrollEnabled = false;
      }
    }

    function setScrollSpeed(position_x: number): void {
      const pxsOut = getTotalPxsOut(position_x);
      if (pxsOut > 100) {
        state.scrollLevel = 50;
      } else if (pxsOut > 80) {
        state.scrollLevel = 7;
      } else if (pxsOut > 60) {
        state.scrollLevel = 5;
      } else if (pxsOut > 40) {
        state.scrollLevel = 3;
      } else if (pxsOut > 20) {
        state.scrollLevel = 2;
      } else {
        state.scrollLevel = 1;
      }
    }

    function autoScroll(direction: number): void {
      clearTimeout(state.autoScrollTimeout);
      state.autoScrollTimeout = setTimeout(() => {
        if (direction === 1) {
          scrollRight();
        } else if (direction === -1) {
          scrollLeft();
        }
        if (state.autoScrollEnabled) {
          autoScroll(direction);
        }
      }, 30);
    }

    function scrollRight(): void {
      if (props.sparklineDisplayState.scrollPosPixel > 0) {
        let localScrollPosPixel =
          props.sparklineDisplayState.scrollPosPixel - state.scrollLevel;
        localScrollPosPixel = Math.max(localScrollPosPixel, 0);
        props.sparklineDisplayState.scrollPosPixel = localScrollPosPixel;
      } else {
        state.autoScrollEnabled = false;
        drawVisible();
      }
      const { x, width } = getScrollContainerRect();
      const barOffset = props.sparklineDisplayState.barWidth * 0.75;
      selectNearestBar(x + width - barOffset);
    }

    function scrollLeft(): void {
      const maxScrollDiff = maxAllowedScrolledPx.value;
      if (props.sparklineDisplayState.scrollPosPixel < maxScrollDiff) {
        let localScrollPosPixel =
          props.sparklineDisplayState.scrollPosPixel + state.scrollLevel;
        localScrollPosPixel = Math.min(localScrollPosPixel, maxScrollDiff);
        props.sparklineDisplayState.scrollPosPixel = localScrollPosPixel;
      } else {
        state.autoScrollEnabled = false;
        drawVisible();
      }
      const { x } = getScrollContainerRect();
      const barOffset = props.sparklineDisplayState.barWidth * 0.75;
      selectNearestBar(x + barOffset);
    }

    function getScrollContainerRect(): DOMRect {
      const container = ref_scrollContainer.value;
      if (container) {
        return container.getBoundingClientRect();
      }
      return null;
    }

    function isOutRight(position_x: number): boolean {
      const { x, width } = getScrollContainerRect();
      return x + width < position_x + 10;
    }

    function isOutLeft(position_x: number): boolean {
      const { x } = getScrollContainerRect();
      return x > position_x - 10;
    }

    function getTotalPxsOut(position_x: number): number {
      const { x, width } = getScrollContainerRect();
      if (x + width < position_x + 10) {
        return position_x + 10 - x - width;
      } else if (x > position_x - 10) {
        return x - position_x + 10;
      }
    }

    function snapBars(): void {
      const totalBarWidth =
        props.sparklineDisplayState.barMargin + props.sparklineDisplayState.barWidth;
      const localScrollPosPixel = props.sparklineDisplayState.scrollPosPixel;
      const scrolledBars = roundToDecimal(localScrollPosPixel / totalBarWidth, 0);
      props.sparklineDisplayState.scrollPosPixel = totalBarWidth * scrolledBars;
    }

    function move(position_x: number): void {
      if (isValueSelectionEnabled.value && !isSelectionFixed.value) {
        addEndEvents();
        selectNearestBar(position_x);
        initAutoScroll(position_x);
      }
    }

    function onClick(ev: MouseEvent): void {
      if (isValueSelectionEnabled.value) {
        disableValueSelectionMode();
      } else {
        enableValueSelectionMode(ev.clientX);
      }
    }

    function onLongClick(ev: MouseEvent): void {
      enableValueSelectionMode(ev.clientX);
    }

    function onLongClickFinished(): void {
      if (!isValueSelectionEnabled.value) return;
      fixSelection();
    }

    function onTap() {
      disableValueSelectionMode();
    }

    function onLongTouch(ev: TouchEvent) {
      if (state.swipeDetector.isVerticalSwipe) {
        return;
      }

      enableValueSelectionMode(ev.changedTouches[0].clientX);
    }

    function onLongTouchFinished(): void {
      fixSelection();
    }

    function onMouseDown(ev: MouseEvent): void {
      if (isLeftDownInEvent(ev)) {
        state.clickHelper.mouseDown(ev);
        ev.stopPropagation();
      }
    }

    function onMouseMove(ev: MouseEvent) {
      if (
        !state.touchHelper.isTouch &&
        state.clickHelper.isMouseDown &&
        isLeftDownInEvent(ev)
      ) {
        move(ev.clientX);
      }
    }

    function onMouseUp(ev: MouseEvent) {
      state.clickHelper.mouseUp(ev);
      ev.stopPropagation();
    }

    function onTouchStart(ev: TouchEvent): void {
      const x = ev.changedTouches[0].clientX;
      const y = ev.changedTouches[0].clientY;
      state.swipeDetector.setStartPosition(x, y);
      state.touchHelper.touchStart(ev);
      ev.stopPropagation();
    }

    function onTouchMove(ev: TouchEvent): void {
      const x = ev.changedTouches[0].clientX;
      const y = ev.changedTouches[0].clientY;
      state.swipeDetector.setEndPosition(x, y);

      if (state.swipeDetector.isHorizontalSwipe) {
        move(ev.changedTouches[0].clientX);
      }
    }

    function onTouchEnd(ev: TouchEvent): void {
      state.touchHelper.touchEnd(ev);
      ev.stopPropagation();
    }

    function onTouchMoveBox(ev: TouchEvent): void {
      if (isValueSelectionEnabled.value && !isSelectionFixed.value) {
        ev.preventDefault();
      }
    }

    function sparklinesModeChanged(): void {
      if (!props.sparklineVm) {
        return;
      }

      if (!state.initialized) {
        initialize();
        return;
      }

      initializeAnimations();
    }

    function onKpiScaleIndexChanged(): void {
      state.kpiScaleIndexJustChanged = true;
      setTimeout(() => (state.kpiScaleIndexJustChanged = false), 100);
    }

    function maxNumVisibleBarsUpdated(): void {
      const totalBarWidth =
        props.sparklineDisplayState.barMargin + props.sparklineDisplayState.barWidth;
      const maxNumVisibleBars = props.sparklineDisplayState.maxNumVisibleBars;
      const index =
        props.sparklineDisplayState.selection[props.sparklineVm.timeStructure]
          .historyIndex;
      const numOfBlocks = Math.floor(index / maxNumVisibleBars);
      props.sparklineDisplayState.scrollPosPixel =
        numOfBlocks * maxNumVisibleBars * totalBarWidth;
      drawVisible();
    }

    function maxUpdated(newGlobalMax: number, oldGlobalMax: number) {
      if (
        !props.sparklineDisplayState.showSparklines ||
        !oldGlobalMax ||
        !newGlobalMax ||
        animationCounter.isAnimating.value
      ) {
        return;
      }
      state.initialized = false;
      const barAnimationState = getBarAnimationState();
      barAnimationState.defaultTransition = false;
      state.barAnimations = props.sparklineVm.getAnimations(barAnimationState);
      state.initialized = true;
      if (state.kpiScaleIndexJustChanged) {
        drawVisible();
      } else {
        startAnimation();
      }
    }

    function drawVisible(): void {
      if (state.startTime || !props.sparklineVm || !state.initialized) {
        return;
      }

      const { start, end } = getStartAndEndWithOffset();
      clearAllCanvas();
      for (let i = start; i < end; i++) {
        const animation = state.barAnimations[i];
        animation.disableAnimation();
        draw(animation, i);
      }
    }

    //
    // Watcher:
    // --------------------
    watch(() => props.kpiScaleIndex, onKpiScaleIndexChanged);

    watch(() => props.sparklineDisplayState.mode, sparklinesModeChanged);

    watch(() => props.sparklineDisplayState.maxNumVisibleBars, maxNumVisibleBarsUpdated);

    watch(() => props.sparklinesGlobalMax, maxUpdated);

    watch(() => props.sparklineDisplayState.selection, drawVisible, { deep: true });

    return {
      ref_scrollContainer,
      ref_sparkline,
      helpTextCpsl,

      canSwipe,
      scrollStyle,
      sparklineBoxStyle,
      isValueSelectionEnabled,

      onTouchMoveBox,
      onMouseDown,
      onMouseMove,
      onMouseUp,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
    };
  },
});
</script>

<template>
  <div class="sparklines-box">
    <div
      class="sparklines-box-wrapper"
      v-if="sparklineVm"
      v-bind:data-helpText="helpTextCpsl.sparklineText(isValueSelectionEnabled)"
      v-on:touchmove="onTouchMoveBox"
      v-bind:style="sparklineBoxStyle"
    >
      <!-- SPARK BARS -->
      <div
        class="scrollContainer"
        v-bind:class="{ canSwipe: canSwipe }"
        ref="ref_scrollContainer"
      >
        <div
          class="sparkline"
          ref="ref_sparkline"
          v-bind:style="scrollStyle"
          v-bind:class="[sparklineVm.barOrientation]"
          v-on:mousedown="onMouseDown"
          v-on:mousemove="onMouseMove"
          v-on:mouseup="onMouseUp"
          v-on:touchstart="onTouchStart"
          v-on:touchmove="onTouchMove"
          v-on:touchend="onTouchEnd"
        />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/media-queries.less";

.sparklines-box {
  box-sizing: border-box;

  .sparklines-box-wrapper {
    display: flex;
    justify-content: flex-end;
    justify-items: center;
    height: 100%;
    position: relative;
    background-color: var(--color_bg_white);

    .scrollContainer {
      flex: 1 1;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      cursor: pointer;
    }

    .sparkline {
      background-color: var(--color_bg_white);
      position: relative;

      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      box-sizing: border-box;
    }

    .sparkline.positive {
      align-items: flex-end;
    }
  }
}

.elementSparklinesSection
  > .sparklines-box
  > .sparklines-box-wrapper
  > .scrollContainer
  > .sparkline {
  align-items: center;
}
</style>
