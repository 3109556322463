<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import Swiper from "@/common/components/swiper.vue";
import DashboardValue from "@/features/dashboard/helper-components/dashboard-value.vue";
import { SwiperVm } from "@/common/components/swiper-vm";
import { ValueVm } from "@/features/dashboard/view-models/value-vm";
import { SharedDashboardStateVm } from "@/features/dashboard/view-models/shared/shared-dashboard-state-vm";

export class SwipeableDashboardValueCommon {
  static swipeableDashboardValue_clicked = "swipeableDashboardValue_clicked";
}

export default defineComponent({
  components: { DashboardValue, Swiper },

  emits: [SwipeableDashboardValueCommon.swipeableDashboardValue_clicked],

  props: {
    swiperVm: { type: Object as PropType<SwiperVm>, required: true },
    swipeItems: { type: Array as PropType<ValueVm[]>, required: true },
    zoomFactor: { type: Number, default: 1 },
    isClickEnabled: { type: Boolean, default: false },
    isSwipeEnabled: { type: Boolean, default: true },
    isScaled: { type: Boolean, default: false },
    sharedState: { type: Object as PropType<SharedDashboardStateVm>, required: true },
    useColorOrScaling: { type: Boolean, default: false },
    alignCenter: { type: Boolean, default: false },
    colorizeBackground: { type: Boolean, default: true },
    isElement: { type: Boolean, default: false },
    percentageModes: {
      type: Array as PropType<boolean[]>,
      default: null,
    },
  },

  setup(props, context) {
    //
    // Computeds:
    // --------------------
    const showItemSeparator = computed<boolean>(() => {
      if (props.swiperVm.isSwiping) {
        return true;
      }

      const scrolledPixel = Math.abs(props.swiperVm.scrolledPixel);
      const isScrolled = 0 < scrolledPixel && scrolledPixel < props.swiperVm.itemWidth;
      return isScrolled;
    });

    //
    // Functions:
    // --------------------
    function isPercentageMode(valueVm: ValueVm): boolean {
      if (!props.percentageModes) {
        return false;
      }

      return props.percentageModes[valueVm.columnIdx] && !valueVm.isAnyPercentageType;
    }

    function onValueClicked(index: number): void {
      if (props.swiperVm.isMoving || props.swiperVm.isSwiping) {
        return;
      }

      context.emit(
        SwipeableDashboardValueCommon.swipeableDashboardValue_clicked,
        props.swipeItems[index]
      );
    }

    return { showItemSeparator, isPercentageMode, onValueClicked };
  },
});
</script>

<template>
  <div class="swipeable-dashboard-value">
    <Swiper
      v-if="$props.swipeItems.length > 1"
      v-bind:swiperVm="$props.swiperVm"
      v-bind:itemsVms="$props.swipeItems"
      v-bind:zoomFactor="$props.zoomFactor"
      v-bind:blockSwipe="!$props.isSwipeEnabled"
      v-on="$listeners"
    >
      <template v-slot:swipeItems="sProps">
        <DashboardValue
          v-bind:class="{
            'item-separator': showItemSeparator,
          }"
          v-bind:valueVm="sProps.swipeItem"
          v-bind:sharedState="$props.sharedState"
          v-bind:alignCenter="$props.alignCenter"
          v-bind:disableBrackets="!$props.isScaled"
          v-bind:defaultTextColor="'var(--valueTextColor)'"
          v-bind:enableColorAndScaling="useColorOrScaling"
          v-bind:colorizeBackground="$props.colorizeBackground"
          v-bind:isClickEnabled="$props.isClickEnabled"
          v-bind:isElement="$props.isElement"
          v-bind:isPercentageMode="isPercentageMode(sProps.swipeItem)"
          v-on:kpiValue_valueLeftClicked="onValueClicked(sProps.index - 1)"
          v-on="$listeners"
        />
      </template>
    </Swiper>
    <DashboardValue
      v-else
      v-bind:valueVm="$props.swipeItems[0]"
      v-bind:sharedState="$props.sharedState"
      v-bind:alignCenter="$props.alignCenter"
      v-bind:disableBrackets="!$props.isScaled"
      v-bind:defaultTextColor="'var(--valueTextColor)'"
      v-bind:enableColorAndScaling="useColorOrScaling"
      v-bind:colorizeBackground="$props.colorizeBackground"
      v-bind:isClickEnabled="$props.isClickEnabled"
      v-bind:isElement="$props.isElement"
      v-bind:isPercentageMode="isPercentageMode($props.swipeItems[0])"
      v-on:kpiValue_valueLeftClicked="onValueClicked(0)"
      v-on="$listeners"
    />
  </div>
</template>

<style scoped lang="less">
.swipeable-dashboard-value {
  .common-scalable-value {
    height: 100%;
    width: 100%;
    align-items: center;

    &.item-separator {
      border-right: 1px solid transparent;
    }
  }
}
</style>
