<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {},
});
</script>

<template>
  <!-- Waiting for the database-icon (ROS) -->
  <!-- Dont forget to delete the icon afterwards -->
  <img src="./database-icon.png" alt="Database" />
</template>

<style lang="less" scoped>
.database-icon {
}
</style>
