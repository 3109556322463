import { Bubble } from "./bubble";
import isNull from "lodash/isNull";
import { IChatService } from "@/services/chat-service/chat-service.interface";
import { ChatMessage } from "@/services/chat-service/chat-message";

export class ChatService implements IChatService {
  private _showInputMessageBox: boolean = false;
  private _isHidingInputMsgBox: boolean = false;

  readonly messageQueue: Bubble[] = [];
  readonly processedMessages: Bubble[] = [];

  get isInputMsgBoxVisible(): boolean {
    return this._showInputMessageBox;
  }

  get isHidingInputMsgBox(): boolean {
    return this._isHidingInputMsgBox;
  }

  get hasMessages(): boolean {
    return this.processedMessages.length > 0 || this.messageQueue.length > 0;
  }

  showInputMessageBox(): void {
    this._isHidingInputMsgBox = false;
    this._showInputMessageBox = true;
  }

  hideInputMessageBox(): void {
    this._showInputMessageBox = false;
  }

  hideInputMessageBoxAnimated(): void {
    this._isHidingInputMsgBox = true;
  }

  toggleInputMessageBoxAnimated(): void {
    if (this.isInputMsgBoxVisible) {
      this.hideInputMessageBoxAnimated();
    } else {
      this.showInputMessageBox();
    }
  }

  queueResponse(chatMessages: ChatMessage[] = []): void {
    this.messageQueue.push(new Bubble(chatMessages, "left"));
  }

  queueQuestion(chatMessages: ChatMessage[] = []): void {
    if (!isNull(chatMessages) && chatMessages.length > 0) {
      this.messageQueue.push(new Bubble(chatMessages, "right"));

      this._generateResponse(chatMessages[0].message);
    }
  }

  setFirstMessageAsProcessed(): void {
    if (this.messageQueue.length > 0) {
      const firstBubble = this.messageQueue.shift();
      this.processedMessages.push(firstBubble);
    }
  }

  private async _generateResponse(question: string): Promise<void> {
    if (this._isJustSayingHi(question)) {
      const chatMessages = this._generateHiResponse();
      this.queueResponse(chatMessages);
      return;
    }

    const chatMessages = this._generateInvalidQuestionResponse();
    this.queueResponse(chatMessages);
  }

  private _isJustSayingHi(question: string): boolean {
    const hellos = ["hi", "hello", "hallo", "servus", "hola", "hey"];
    const input = question.toLowerCase();
    return hellos.some((hello) => input.includes(hello));
  }

  private _generateHiResponse(): ChatMessage[] {
    const chatMessages: ChatMessage[] = [];
    chatMessages.push(new ChatMessage("Text", "Hallo, wie kann ich Ihnen helfen?"));
    return chatMessages;
  }

  private _generateInvalidQuestionResponse(): ChatMessage[] {
    const chatMessages: ChatMessage[] = [];
    chatMessages.push(
      new ChatMessage(
        "Text",
        "Es tut mir leid, ich verstehe Ihre Frage nicht. Bitte versuchen Sie es erneut!"
      )
    );
    return chatMessages;
  }
}
