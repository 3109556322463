import { DashboardFiltersListVm } from "../dashboard-filters-list-vm";
import {
  KpiDrillQueryFm,
  KpiDrillSortParameterFm,
  SortTypeFm,
  KpiDrillStructureFilterFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { KpiValueIdFm } from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";

export class SharedDrillInfoVm {
  publishedApplicationId: string = null;
  dashboardId: number = null;
  kpiId: number = null;
  valueId: number = null;
  valueGroupId: number = null;
  selectedPeriodId: string = null;
  sortType: SortTypeFm = "BySortId";

  dashboardFilter: DashboardFiltersListVm = null;

  clone(cloneFilters: DashboardFiltersListVm = null) {
    const clone = new SharedDrillInfoVm();

    clone.dashboardId = this.dashboardId;
    clone.publishedApplicationId = this.publishedApplicationId;
    clone.kpiId = this.kpiId;
    clone.valueId = this.valueId;
    clone.valueGroupId = this.valueGroupId;
    clone.selectedPeriodId = this.selectedPeriodId;
    clone.sortType = this.sortType;

    if (cloneFilters) clone.dashboardFilter = cloneFilters;
    else clone.dashboardFilter = this.dashboardFilter.clone();

    return clone;
  }

  createDrillQuery(
    structureId: number,
    sortOrder: SortTypeFm,
    additionalFilters: KpiDrillStructureFilterFm[] = [],
    extendRows: boolean,
    valId: number,
    drillResultLimit: number = 50
  ): KpiDrillQueryFm {
    const query = new KpiDrillQueryFm();
    query.groupByStructureId = structureId;

    // merges filters from whole dashboard (for all KPI) and the current drill
    query.structureFilters = [
      ...this.dashboardFilter.filters,
      ...additionalFilters,
    ] as KpiDrillStructureFilterFm[];

    query.valueGroupIds = [this.valueGroupId];

    valId = valId ?? this.valueId;
    valId = Math.max(Math.min(valId, 3), 1);

    const valueId = new KpiValueIdFm();
    valueId.id = valId;
    valueId.kpiValueGroupId.id = this.valueGroupId;
    valueId.kpiValueGroupId.kpiId.id = this.kpiId;
    valueId.kpiValueGroupId.kpiId.dashboardId.id = this.dashboardId;
    valueId.kpiValueGroupId.kpiId.dashboardId.publishedApplicationId.idType =
      "PublishedApplication";
    valueId.kpiValueGroupId.kpiId.dashboardId.publishedApplicationId.internalId =
      this.publishedApplicationId;

    query.sortParameter = new KpiDrillSortParameterFm();
    query.sortParameter.sortType = sortOrder;
    query.sortParameter.valueId =
      sortOrder === "BySortId" ||
      sortOrder === "ByNameAscending" ||
      sortOrder === "ByNameDescending"
        ? null
        : valueId;

    if (this.selectedPeriodId) {
      query.selectedPeriodId = this.selectedPeriodId;
    }

    query.resultLimit = drillResultLimit;

    return query;
  }
}
