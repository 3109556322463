export function backgroundClicked(event: UIEvent): boolean {
  const ignoredTargetClicked = event?.composedPath().some((eventTarget) => {
    return (
      isHtmlElement(eventTarget) &&
      (isInputMessageBox(eventTarget) ||
        isChatWindow(eventTarget) ||
        isPortalControls(eventTarget) ||
        isKpiValue(eventTarget))
    );
  });

  return !ignoredTargetClicked;
}

// TODO: remove hard coded css selectors
export function isHtmlElement(eventTarget: EventTarget): boolean {
  return eventTarget instanceof HTMLElement;
}

// TODO: remove hard coded css selectors
export function isChatWindow(eventTarget: EventTarget): boolean {
  const targetElement = eventTarget as HTMLElement;
  return targetElement.className?.includes("bubbleMsg");
}

// TODO: remove hard coded css selectors
export function isPortalControls(eventTarget: EventTarget): boolean {
  const targetElement = eventTarget as HTMLElement;
  return targetElement.className?.includes("portalControlsComponent");
}

// TODO: remove hard coded css selectors
export function isInputMessageBox(eventTarget: EventTarget): boolean {
  const targetElement = eventTarget as HTMLElement;
  return (
    targetElement.className?.includes("inputTextBox") ||
    targetElement.className?.includes("inputButton")
  );
}

// TODO: remove hard coded css selectors
export function isKpiValue(eventTarget: EventTarget): boolean {
  const targetElement = eventTarget as HTMLElement;
  return (
    targetElement.className?.includes("title-row") ||
    targetElement.className?.includes("dashboard-host")
  );
}
