<script lang="ts">
import {
  defineComponent,
  PropType,
  computed,
  onMounted,
  ref,
  watch,
  nextTick,
} from "vue";
import type { CSSProperties } from "vue/types/jsx";
import { Cell } from "@/common/components/simple-grid/contracts/cell";

export const separatorTxt = "separator";
export const gridId = "gridId";

export default defineComponent({
  components: {},
  emits: [],
  props: {
    cells: { type: Array as PropType<Cell[]>, required: true },
    widths: { type: Array as PropType<string[]>, required: true },
    heights: { type: Array as PropType<string[]>, required: true },
  },
  setup(props, context) {
    const ref_simpleGrid = ref(null);

    //
    // Life Cycle:
    // --------------------
    onMounted(() => {
      initGrid();
    });

    //
    // Computeds:
    // --------------------
    const simpleGridStyle = computed<CSSProperties>(() => {
      const colWidths = props.widths.join(" ");
      const rowHeights = props.heights.join(" ");

      return {
        gridTemplateColumns: colWidths,
        gridTemplateRows: rowHeights,
      };
    });

    //
    // Functions:
    // --------------------
    function initGrid(): void {
      const simpleGrid = ref_simpleGrid.value as HTMLElement;

      if (!simpleGrid) {
        return;
      }

      setStylesForSlotComponents();
      addSeparators();
    }

    function setStylesForSlotComponents(): void {
      const simpleGrid = ref_simpleGrid.value as HTMLElement;
      const childComponents = Array.from(simpleGrid.children) as HTMLElement[];
      for (const child of childComponents) {
        const componentName = child.dataset[gridId];
        const cells = props.cells.find((cells) => cells.id === componentName);
        if (cells) {
          Object.assign(child.style, getStyle(cells));
        } else {
          child.style.display = "none";
        }
      }
    }

    function addSeparators(): void {
      const simpleGrid = ref_simpleGrid.value as HTMLElement;
      const separators = props.cells.filter((cells) => cells.id === separatorTxt);
      for (let i = 0; i < separators.length; i++) {
        const separatorDiv = document.createElement("div");
        Object.assign(separatorDiv.style, getStyle(separators[i]));
        simpleGrid.appendChild(separatorDiv);
      }
    }

    function getStyle(cells: Cell) {
      const colSpan = cells.colSpan === -1 ? props.widths.length : cells.colSpan;
      const rowSpan = cells.rowSpan === -1 ? props.heights.length : cells.rowSpan;
      return {
        gridColumn: `${cells.col + 1} / span ${colSpan || 1}`,
        gridRow: `${cells.row + 1} / span ${rowSpan || 1}`,
        backgroundColor: cells.backgroundColor,
        padding: cells.padding ?? "0",
        display: "flex",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
      };
    }

    watch(
      [() => props.cells],
      async () => {
        await nextTick();
        initGrid();
      },
      { deep: true }
    );

    return {
      ref_simpleGrid,
      simpleGridStyle,
      context,
    };
  },
});
</script>

<template>
  <div ref="ref_simpleGrid" class="simple-grid" v-bind:style="simpleGridStyle">
    <slot></slot>
  </div>
</template>

<style scoped lang="less">
.simple-grid {
  width: 100%;
  height: 100%;
  display: grid;
}
</style>
