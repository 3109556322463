import { exists, existsAndNotEmpty } from "@/common/object-helper/null-helper";
import { IPortalFacade } from "../backend-wrapper/portal-facade.interface";

export class PortalVm {
  constructor(
    private readonly _portalFacade: IPortalFacade,
    public portalId: string,
    public portalPageId: string
  ) {}

  async createPortalTileForApplicationAsync(
    dashboardApplicationId: string,
    dashboardApplicationName: string
  ): Promise<boolean> {
    if (!existsAndNotEmpty(this.portalId)) {
      return false;
    }

    if (!existsAndNotEmpty(this.portalPageId)) {
      return false;
    }

    if (!existsAndNotEmpty(dashboardApplicationId)) {
      return false;
    }

    if (!existsAndNotEmpty(dashboardApplicationName)) {
      return false;
    }

    // in version 1 app.wizard can create max 1 dashboard pro created application
    const dashboardId = 1;

    const portalTileNamesResult = await this._portalFacade.getAllPortalTileNamesAsync(
      this.portalPageId
    );

    let portalTileName = dashboardApplicationName;

    if (portalTileNamesResult.succeeded) {
      portalTileName = this._createUniqueName(
        portalTileName,
        portalTileNamesResult.value
      );
    }

    const result = await this._portalFacade.createPortalTileAsync(
      this.portalPageId,
      dashboardApplicationId,
      portalTileName,
      dashboardId
    );

    return result.succeeded;
  }

  private _createUniqueName(startName: string, existentNames: string[]): string {
    if (!exists(existentNames)) {
      return startName;
    }

    let uniqueName = startName;
    let contor: number = 1;

    while (existentNames.includes(uniqueName)) {
      uniqueName = startName + " " + contor++;
    }

    return uniqueName;
  }
}
