import { PortalTileVm } from "@/features/portal/view-models/portal-tile-vm";
import { PlacementType, defaultTileConfiguration } from "@bissantz/tile-grid";
import { DashboardBuilder } from "@/features/dashboard/view-models/builder/dashboard-builder";
import { DashboardSettings } from "@/features/dashboard-shared/dashboard-settings";
import { TileDescriptorFm } from "@/features/portal/backend-wrapper/tile-descriptor-fm";
import { DashboardVm } from "@/features/dashboard/view-models/dashboard-vm";
import { CloneHelper } from "@/common/object-helper/clone-helper";
import { reactive } from "vue";

const dashboardBuilder = new DashboardBuilder();

export class PortalTileBuilder {
  createPortalTileVm(tileDescriptor: TileDescriptorFm, sortOder?: number): PortalTileVm {
    const result = reactive(new PortalTileVm()) as PortalTileVm;
    result.tileFm = tileDescriptor.portalTileFm;
    result.publishedApplicationId =
      tileDescriptor.portalTilePageFm?.publishedApplicationId;
    result.tilePageFm = tileDescriptor.portalTilePageFm;

    result.tileConfig = {
      ...defaultTileConfiguration,
      x: 0,
      y: 0,
      w: 2,
      h: 1,
      i: "-1",
      isResizable: false,
      placement: "normal" as PlacementType,
      order: null,
    };
    if (sortOder) {
      result.tileConfig.order = sortOder;
    }
    result.tileConfig.i = result.id;

    result.dbSettings = new DashboardSettings();
    DashboardSettings.applyDefaultPortal(result.dbSettings);

    result.dashboardVm = this._createEmptyDashboard(tileDescriptor, result.dbSettings);
    result.error = tileDescriptor.error;

    return result;
  }

  private _createEmptyDashboard(
    tileDescriptor: TileDescriptorFm,
    settings: DashboardSettings
  ): DashboardVm {
    if (!tileDescriptor.portalTilePageFm) {
      return null;
    }

    const dashboardId = tileDescriptor?.getDashboardId();
    const dbResult = dashboardBuilder.createDashboard(dashboardId);
    dbResult.dbSettings = settings;
    dbResult.tilePageId = tileDescriptor.portalTilePageFm.id;

    return dbResult;
  }

  clone(original: PortalTileVm): PortalTileVm {
    const clone = new PortalTileVm();
    clone.showTile = original.showTile;
    clone.disableTransitions = original.disableTransitions;
    clone.publishedApplicationId = original.publishedApplicationId;
    clone.tilePageFm = CloneHelper.clone(original.tilePageFm);
    clone.tilePageFm.id = null;

    // should normally be null
    clone.error = original.error;

    clone.dbSettings = original.dbSettings.clone();
    clone.dashboardVm = dashboardBuilder.clone(original.dashboardVm, clone.dbSettings);

    clone.tileFm = CloneHelper.clone(original.tileFm);
    clone.tileFm.id = null;
    clone.tileFm.name = clone.tilePageFm.name;

    clone.tileConfig = { ...original.tileConfig };
    clone.tileConfig.i = clone.tilePageFm.id;

    return clone;
  }
}
