import { IDashboardsServiceClient } from "@/common/service-clients/generated-clients";
import { IDashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade.interface";
import { SimpleResult } from "@/common/results/simple-result";
import {
  KpiFm,
  KpiDashboardFm,
  KpiDrillResultFm,
  KpiDrillStructureFilterFm,
  KpiDrillQueryFm,
  ElementQueryFm,
  ElementQueryResultFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { FmMapperDashboard } from "@/features/dashboard/backend-wrapper/fm-mapper-dashboard";
import { FmDtoMapperDashboard } from "@/features/dashboard/backend-wrapper/fm-dto-mapper-dashboard";
import { DashboardError } from "@/features/dashboard/backend-wrapper/dashboard-error";
import { tryGetRequestError } from "@/common/request-error";
import { DashboardRequestHelper } from "./request-helper";

export class DashboardFacade implements IDashboardFacade {
  private readonly _dashboardClient: IDashboardsServiceClient;

  constructor(dashboardClient: IDashboardsServiceClient) {
    this._dashboardClient = dashboardClient;
  }

  async getDashboardsAsync(
    publishedApplicationId: string
  ): Promise<SimpleResult<KpiDashboardFm[], DashboardError>> {
    const requestFunc = () => this._innerGetDashboardsAsync(publishedApplicationId);
    return DashboardRequestHelper.pollRequest(requestFunc);
  }

  private async _innerGetDashboardsAsync(
    publishedApplicationId: string
  ): Promise<SimpleResult<KpiDashboardFm[], DashboardError>> {
    let result: KpiDashboardFm[] = null;
    let error: DashboardError = null;

    try {
      const kpiDashboardDtos = await this._dashboardClient.getDashboards(
        publishedApplicationId
      );
      result = FmMapperDashboard.mapKpiDashboardFms(kpiDashboardDtos);
    } catch (e) {
      error = tryGetRequestError(e);
    }
    return {
      error: error,
      value: result,
    };
  }

  async getDashboardKpisAsync(
    publishedApplicationId: string,
    dashboardId: number,
    kpiDrillStructureFilters: KpiDrillStructureFilterFm[]
  ): Promise<SimpleResult<KpiFm[], DashboardError>> {
    const requestFunc = () =>
      this._innerGetDashboardKpisAsync(
        publishedApplicationId,
        dashboardId,
        kpiDrillStructureFilters
      );
    return DashboardRequestHelper.pollRequest(requestFunc);
  }

  private async _innerGetDashboardKpisAsync(
    publishedApplicationId: string,
    dashboardId: number,
    kpiDrillStructureFilters: KpiDrillStructureFilterFm[]
  ): Promise<SimpleResult<KpiFm[], DashboardError>> {
    let result: KpiFm[] = null;
    let error: DashboardError = null;

    try {
      const kpisQueryDto = FmDtoMapperDashboard.mapKpisQueryDto(kpiDrillStructureFilters);
      const kpiDtos = await this._dashboardClient.getDashboardKpis(
        publishedApplicationId,
        dashboardId,
        kpisQueryDto
      );
      result = FmMapperDashboard.mapKpiFms(kpiDtos);
    } catch (e) {
      error = tryGetRequestError(e);
    }
    return { error: error, value: result };
  }

  async getElementQueryResultsAsync(
    publishedApplicationId: string,
    elementQueryFms: ElementQueryFm[]
  ): Promise<SimpleResult<ElementQueryResultFm[], DashboardError>> {
    const requestFunc = () =>
      this._innerGetElementQueryResultsAsync(publishedApplicationId, elementQueryFms);
    return DashboardRequestHelper.pollRequest(requestFunc);
  }

  private async _innerGetElementQueryResultsAsync(
    publishedApplicationId: string,
    elementQueryFms: ElementQueryFm[]
  ): Promise<SimpleResult<ElementQueryResultFm[], DashboardError>> {
    let result: ElementQueryResultFm[] = null;
    let error: DashboardError = null;

    try {
      const elementsQueryDto =
        FmDtoMapperDashboard.mapDashboardElementQueryDtos(elementQueryFms);
      const elementQueryResultDtos = await this._dashboardClient.getElements(
        publishedApplicationId,
        elementsQueryDto
      );
      result = FmMapperDashboard.mapElementQueryResultFms(elementQueryResultDtos);
    } catch (e) {
      error = tryGetRequestError(e);
    }

    return { error: error, value: result };
  }

  // TODO:
  // Retry polling and allowing user to retry request not yet integrated for drills!
  async drillDashboardKpiAsync(
    publishedApplicationId: string,
    dashboardId: number,
    kpiId: number,
    kpiDrillQuery: KpiDrillQueryFm
  ): Promise<SimpleResult<KpiDrillResultFm, DashboardError>> {
    let result: KpiDrillResultFm = null;
    let error: DashboardError = null;

    try {
      const kpiDrillQueryDto = FmDtoMapperDashboard.mapKpiDrillQueryDto(kpiDrillQuery);
      const kpiDrillResultDto = await this._dashboardClient.drillDashboardKpi(
        publishedApplicationId,
        dashboardId,
        kpiId,
        kpiDrillQueryDto
      );
      result = FmMapperDashboard.mapKpiDrillResultFm(kpiDrillResultDto);
    } catch (e) {
      error = tryGetRequestError(e);
    }

    return {
      error: error,
      value: result,
    };
  }
}
