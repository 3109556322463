import { BissantzLoggerAdapter } from "./adapter/bissantz-logger-adapter";
import { Logger } from "./logger";
import { ILogger } from "./logger.interface";
import { AxiosResponse, InternalAxiosRequestConfig } from "axios";

let loggerInstance: ILogger = null;

// singleton like wrapper/accesspr for the logger instance
export async function initLogger() {
  loggerInstance ??= new Logger(new BissantzLoggerAdapter());
}

export function getLogger(): ILogger {
  return loggerInstance;
}

export function logGlobalUnhandledErrors(ev: ErrorEvent): void {
  const msg = `${ev.message}`;
  loggerInstance.log("error", msg, null, ev.error);

  ev.preventDefault();
}

export function logUnhandledRejection(ev: PromiseRejectionEvent) {
  let msg = "Promise rejected: ";

  if (typeof ev.reason === "string" || typeof ev.reason === "number") {
    msg += ` ${ev.reason}`;
    loggerInstance.log("error", msg);
  } else if (typeof ev.reason === "object") {
    const err = ev.reason instanceof Error ? ev.reason : null;

    if (err) {
      msg = err.message;
      loggerInstance.log("error", msg, null, err);
    } else if (ev.reason.message) {
      msg = ev.reason.message;
      loggerInstance.log("error", msg, null, ev.reason.stack ?? null);
    } else {
      msg += ` ${JSON.stringify(ev.reason)}`;
      loggerInstance.log("error", msg);
    }
  } else {
    loggerInstance.log("error", msg);
  }

  ev.preventDefault();
}

export function logVueErrors(err: Error, vm: Vue, info: string): void {
  const msg = `Vue.Error in ${info}: ${err.message}`;
  loggerInstance.log("error", msg, null, err);
}

export function logHttpRequest(
  req: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  loggerInstance.log("info", `${req.method.toUpperCase()} Request to: ${req.url}`, {
    httpMethod: req.method,
    httpHeader: req.headers,
    httpParams: req.params,
    httpData: req.data,
  });

  return req;
}

export function logHttpResponse(res: AxiosResponse<any>): AxiosResponse<any> {
  if (res.status >= 200 || res.status < 300) {
    loggerInstance.log(
      "info",
      `Received response: ${res.config.method.toUpperCase()} to ${res.config.url}`,
      {
        httpMethod: res.config.method,
        httpResponseHeader: res.headers,
        httpResponseData: res.data,
      }
    );
  } else {
    loggerInstance.log(
      "error",
      `Request failed: ${res.config.method.toUpperCase()} to ${
        res.config.url
      }\nError code: ${res.status}, Message: ${res.statusText}`,
      {
        httpStatus: res.status,
        httpError: res.statusText,
        httpMethod: res.config.method,
        httpResponseHeader: res.headers,
        httpResponseData: res.data,
      }
    );
  }

  return res;
}
