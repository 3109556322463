import { IModelFacade } from "../../backend-wrapper/model-facade.interface";
import { MagicButton, MagicButtonType } from "../../backend-wrapper/dto-wrappers";
import { FailedReason } from "@/common/results/failed-reason";
import { ValueResult } from "@/common/results/value-result";
import { appResources } from "@/app-resources";

export class MagicButtonListVm {
  private readonly _modelFacade: IModelFacade = null;
  private readonly _reportId: string;
  private _newMagicButtons: MagicButton[];
  private _notAllowedCombinations: MagicButtonType[][] = [
    ["Ytd", "DeltaPreviousYearYtd"],
    ["Ytd", "DeltaBudgetYtd"],
    ["Ytd", "DeltaPreviousYearYtd", "DeltaBudgetYtd"],
    ["Mtd", "DeltaPreviousYearMtd"],
    ["Mtd", "DeltaBudgetMtd"],
    ["Mtd", "DeltaPreviousYearMtd", "DeltaBudgetMtd"],
  ];
  private _referenceButtonNames: Partial<Record<MagicButtonType, string>> = {
    DeltaPreviousPeriod: appResources.magicButtonTexts.ReferencePP,
    DeltaPreviousYear: appResources.magicButtonTexts.ReferencePY,
    DeltaPreviousYearYtd: appResources.magicButtonTexts.ReferencePY,
    DeltaBudget: appResources.magicButtonTexts.ReferencePl,
    DeltaBudgetYtd: appResources.magicButtonTexts.ReferencePl,
    DeltaPreviousYearMtd: appResources.magicButtonTexts.ReferencePY,
    DeltaBudgetMtd: appResources.magicButtonTexts.ReferencePl,
  };
  private _currentMagicButtons: MagicButton[] = [];
  areMagicButtonsOpaque: boolean = false;

  get isValidMagicButtonSelection(): boolean {
    return this.currentMagicButtons.some((mb) => mb.isExecuted);
  }

  get isConfirmDisabled(): boolean {
    return !this.isValidMagicButtonSelection || this.areMagicButtonsOpaque;
  }

  get currentMagicButtons(): MagicButton[] {
    return this._currentMagicButtons;
  }

  set newMagicButtons(magicButtons: MagicButton[]) {
    this._newMagicButtons = magicButtons;
  }

  private get _currentExecutedMagicButtonTypes(): MagicButtonType[] {
    return this.currentMagicButtons
      .filter((button) => button.isExecuted)
      .map((button) => button.magicButtonType);
  }

  constructor(modelFacade: IModelFacade, reportId: string) {
    this._modelFacade = modelFacade;
    this._reportId = reportId;
  }

  async initAsync(): Promise<void> {
    await this._getMagicButtonsAsync();
  }

  toggleOpacity(): void {
    this.areMagicButtonsOpaque = !this.areMagicButtonsOpaque;
  }

  isValidMagicButtonCombination(selectedMagicButton: MagicButton): boolean {
    const currentExecutedButtons = this._currentExecutedMagicButtonTypes;

    let result = true;

    currentExecutedButtons.push(selectedMagicButton.magicButtonType);

    for (const combination of this._notAllowedCombinations) {
      if (combination.every((comb) => currentExecutedButtons.includes(comb))) {
        result = false;
        break;
      }
    }

    return result;
  }

  private _synchronizeMagicButtons(): void {
    this._addNewMagicButtons();
    this._removeObsoleteMagicButtons();
    this._updateMagicButtonsIsExecutedFlag();
  }

  private async _getMagicButtonsAsync(): Promise<void> {
    const result = await this._modelFacade.getMagicButtonsAsync(this._reportId);

    if (result.succeeded) {
      this._currentMagicButtons = MagicButtonListVm._removeNotSupportedMagicButtons(
        result.value
      );
    }
  }

  private _addNewMagicButtons(): void {
    this._newMagicButtons.forEach((newButton) => {
      const index = this.currentMagicButtons.findIndex(
        (oldButton) => oldButton.magicButtonType === newButton.magicButtonType
      );

      if (index === -1) {
        this.currentMagicButtons.push(newButton);
      }
    });
  }

  private _removeObsoleteMagicButtons(): void {
    this._currentMagicButtons = this.currentMagicButtons.filter((mB) => {
      return this._newMagicButtons.some(
        (element) => element.magicButtonType === mB.magicButtonType
      );
    });
  }

  private _updateMagicButtonsIsExecutedFlag(): void {
    this._newMagicButtons.forEach((newButton) => {
      const index = this.currentMagicButtons.findIndex(
        (oldButton) => oldButton.magicButtonType === newButton.magicButtonType
      );

      if (index !== -1) {
        this.currentMagicButtons[index].isExecuted = newButton.isExecuted;
      }
    });
  }

  private static _removeNotSupportedMagicButtons(
    magicButtons: MagicButton[]
  ): MagicButton[] {
    return magicButtons.filter(
      (button) => MagicButtonListVm._isNotSupported(button.magicButtonType) === false
    );
  }

  generateReferenceButtonName(): string {
    const referenceButtons: MagicButtonType[] = [
      "DeltaPreviousPeriod",
      "DeltaPreviousYear",
      "DeltaBudget",
      "DeltaPreviousYearYtd",
      "DeltaBudgetYtd",
      "DeltaPreviousYearMtd",
      "DeltaBudgetMtd",
    ];

    const referenceButtonNameArray = Array.from(
      new Set(
        this._currentExecutedMagicButtonTypes
          .filter((button) => referenceButtons.includes(button))
          .map((button) => this._referenceButtonNames[button])
      )
    );

    return referenceButtonNameArray.join(" ");
  }

  async updateMagicButtonsAfterCommandExecutionAsync(
    selectedMagicButton: MagicButton
  ): Promise<ValueResult<MagicButton[], FailedReason>> {
    this.toggleOpacity();

    const result = await this._modelFacade.applyMagicButtonAsync(
      this._reportId,
      selectedMagicButton
    );

    if (result.succeeded) {
      this.newMagicButtons = result.value;
      this._newMagicButtons = MagicButtonListVm._removeNotSupportedMagicButtons(
        this._newMagicButtons
      );
      this._synchronizeMagicButtons();
    }
    this.toggleOpacity();

    return result;
  }

  private static _isNotSupported(magicButtonType: MagicButtonType): boolean {
    if (magicButtonType === "Kpi") {
      return true; // currently not supported in the first version. if all kpis are required they must be selected individually.
    }

    if (magicButtonType === "Current") {
      return true; // currently not supported in the first version. (backend-error)
    }

    return false;
  }
}
