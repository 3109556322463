import { Timeouts } from "./dom-event-helper";

// This Helper class allows you to execute any method
// after a specific touch action occurred.
//
// Available Actions:
// - Tap
// - Double Tap
// - Long Touch
//      User touched long enough the screen for a long
//      touch and the User is still touching the creen.
// - Long Touch Finished
//      User long touched the screen and is not
//      touching the screen anymore.
//
// How to use this Helper class:
//
// Create a new instance:
// this.touchHelper = new TouchHelper();
//
// Set the actions you need. Example:
// Tap:
// touchHelper.setTapAction(() => mymethod(event));
// touchHelper.setDoubleTapAction(myMethod);
//
// Link the event ontouchstart with the touchHelper:
// onTouchStart(event: TouchEvent): void {
//  this.touchHelper.onTouchStart(event);
// }
//
// Link the event ontouchend with the touchHelper:
// onTouchEnd(event: TouchEvent): void {
//  this.touchHelper.onTouchEnd(event);
// }
//
// (Extra/Optional): You can cancel any touch action
// by calling the cancelTouch().
// For example on the ontouchmove event.

export type TouchAction = (ev?: TouchEvent) => void;
export class TouchHelper {
  private _isTap: boolean = false;
  private _isTouch: boolean = false;
  private _isLongTouch: boolean = false;

  private _tapTimeoutId: NodeJS.Timeout = null;
  private _touchTimeoutId: NodeJS.Timeout = null;
  private _longTouchTimeoutId: NodeJS.Timeout = null;
  private _tapCounter: number = 0;

  private _initialPosition: number = null;
  swipeOffset: number = 0;

  private _tapAction: TouchAction = null;
  private _doubleTapAction: TouchAction = null;
  private _longTouchAction: TouchAction = null;
  private _longTouchFinishedAction: TouchAction = null;

  get isTouch(): boolean {
    return this._isTouch;
  }

  setTapAction(action: TouchAction): void {
    this._tapAction = action;
  }

  setDoubleTapAction(action: TouchAction): void {
    this._doubleTapAction = action;
  }

  setLongTouchAction(action: TouchAction): void {
    this._longTouchAction = action;
  }

  setLongTouchFinishedAction(action: TouchAction): void {
    this._longTouchFinishedAction = action;
  }

  touchStart(ev: TouchEvent = null): void {
    if (ev && this.swipeOffset > 0) {
      this._initialPosition = ev.changedTouches[0].clientX;
    }
    this._initTouch();
    this._initTap();
    this._initLongTouch(ev);
  }

  touchEnd(ev: TouchEvent = null): void {
    clearTimeout(this._longTouchTimeoutId);
    this._initTouch();
    if (this._isLongTouch && this._longTouchFinishedAction) {
      this._longTouchFinishedAction(ev);
    }
    if (this._isTap) {
      if (this._doubleTapAction) {
        this._tapCounter++;
        if (this._tapCounter === 1) {
          this._onSimpleTap(ev);
        } else if (this._tapCounter === 2) {
          this._onDoubleTap(ev);
        }
      } else if (this._tapAction) {
        this._tapAction(ev);
      }
    }
  }

  cancelTouch(ev: TouchEvent = null): void {
    if (
      this.swipeOffset > 0 &&
      ev &&
      Math.abs(ev.changedTouches[0].clientX - this._initialPosition) < this.swipeOffset
    ) {
      return;
    }

    this._initialPosition = -1;
    this._isTouch = false;
    this._isTap = false;
    this._isLongTouch = false;
    clearTimeout(this._longTouchTimeoutId);
  }

  private _initTouch(): void {
    this._isTouch = true;
    clearTimeout(this._touchTimeoutId);
    this._touchTimeoutId = setTimeout(() => (this._isTouch = false), 900);
  }

  private _initTap(): void {
    this._isTap = true;
    clearTimeout(this._tapTimeoutId);
    this._tapTimeoutId = setTimeout(() => (this._isTap = false), 250);
  }

  private _initLongTouch(ev: TouchEvent): void {
    this._isLongTouch = false;
    clearTimeout(this._longTouchTimeoutId);
    if (this._longTouchAction) {
      this._longTouchTimeoutId = setTimeout(
        () => this._onLongTouch(ev),
        Timeouts.longTouchTime_ms
      );
    }
  }

  private _onLongTouch(ev: TouchEvent): void {
    this._isLongTouch = true;
    this._initTouch();
    this._longTouchAction(ev);
  }

  private _onSimpleTap(ev: TouchEvent): void {
    clearTimeout(this._tapTimeoutId);
    this._tapTimeoutId = setTimeout(() => {
      if (this._tapCounter === 1 && this._tapAction) {
        this._tapAction(ev);
      }
      this._tapCounter = 0;
    }, Timeouts.doubleClickTime_ms);
  }

  private _onDoubleTap(ev: TouchEvent): void {
    this._tapCounter = 0;
    if (this._doubleTapAction) {
      this._doubleTapAction(ev);
    }
  }
}
