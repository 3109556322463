import { ITransactionFacade } from "@/common/transactions/transaction-facade.interface";
import { IApplicationFacade } from "../backend-wrapper/application-facade.interface";
import {
  ApplicationGroup,
  PublishedApplication,
  PublishedApplicationGroup,
} from "../backend-wrapper/dto-wrappers";
import { ValueResult } from "@/common/results/value-result";
import { FailedReason } from "@/common/results/failed-reason";
import { PortalVm } from "./portal-vm";
import { ApplicationCreationResult } from "../backend-wrapper/application-creation-result";
import { ApplicationNameUniqueValidator } from "../validators/application-name-unique-validator";

type ApplicationMode = "Overview" | "AddNew" | "UseExistent";

export class ApplicationVm {
  get selectedPublishedApplication(): PublishedApplication {
    return this._selectedPublishedApplication;
  }

  set selectedPublishedApplication(value: PublishedApplication) {
    this._selectedPublishedApplication = value;

    this._mode =
      this._selectedPublishedApplication?.id === this._addNewApplicationId
        ? "AddNew"
        : "UseExistent";
  }

  get selectedApplicationGroup(): ApplicationGroup {
    return this._selectedApplicationGroup;
  }

  set selectedApplicationGroup(value: ApplicationGroup) {
    this._selectedApplicationGroup = value;

    this._nameValidator = new ApplicationNameUniqueValidator(
      this._applicationFacade,
      this.selectedApplicationGroup?.id
    );
  }

  selectedPublishedApplicationGroup: PublishedApplicationGroup = undefined;
  enteredApplicationName: string = null;

  private readonly _applicationFacade: IApplicationFacade = null;
  private readonly _transactionFacade: ITransactionFacade = null;
  private readonly _portalVm: PortalVm = null;
  private readonly _connectionSetId: string = null;
  private readonly _addNewApplicationId: string = "new_published_application_id";
  private _addNewApplicationText: string = "+";
  private _mode: ApplicationMode = "Overview";
  private _selectedApplicationGroup: ApplicationGroup = undefined;
  private _selectedPublishedApplication: PublishedApplication = null;
  private _createdPublishedApplicationId: string = null;
  private _publishedApplications: PublishedApplication[] = [];
  private _applicationGroups: ApplicationGroup[] = [];
  private _publishedApplicationsRetrievalFailed: boolean = false;
  private _applicationGroupsRetrievalFailed: boolean = false;
  private _publishedApplicationGroups: PublishedApplicationGroup[] = [];
  private _publishedApplicationGroupsRetrievalFailed: boolean = false;
  private _isSendingDataToBackend: boolean = false;
  private _portalTileHasBeenCreated: boolean = false;
  private _nameValidator = new ApplicationNameUniqueValidator(
    this._applicationFacade,
    this.selectedApplicationGroup?.id
  );

  get isSendingDataToBackend() {
    return this._isSendingDataToBackend;
  }

  get isInAddNewApplicationMode(): boolean {
    return this._mode === "AddNew";
  }

  get isInUseExistingApplicationMode(): boolean {
    return this._mode === "UseExistent";
  }

  get publishedApplications(): PublishedApplication[] {
    return this._publishedApplications;
  }

  get publishedApplicationsRetrievalFailed(): boolean {
    return this._publishedApplicationsRetrievalFailed;
  }

  get applicationGroups(): ApplicationGroup[] {
    return this._applicationGroups;
  }

  get applicationGroupsRetrievalFailed(): boolean {
    return this._applicationGroupsRetrievalFailed;
  }

  get publishedApplicationGroups(): PublishedApplicationGroup[] {
    return this._publishedApplicationGroups;
  }

  get publishedApplicationGroupsRetrievalFailed(): boolean {
    return this._publishedApplicationGroupsRetrievalFailed;
  }

  get isPubAppDisabled(): boolean {
    return this.publishedApplications.length <= 1 && !!this.selectedPublishedApplication;
  }

  get isMissingAppGroup(): boolean {
    return this.applicationGroups.length === 0 && this.isInAddNewApplicationMode;
  }

  get isMissingPubAppGroup(): boolean {
    return this.publishedApplicationGroups.length === 0 && this.isInAddNewApplicationMode;
  }

  get canContinueToNextStep(): boolean {
    return this.canCreateNewApplication || this.isInUseExistingApplicationMode;
  }

  get portalTileHasBeenCreated(): boolean {
    return this._portalTileHasBeenCreated;
  }

  get applicationNameValidator(): ApplicationNameUniqueValidator {
    return this._nameValidator;
  }

  get canCreateNewApplication(): boolean {
    if (!this.selectedApplicationGroup) {
      return false;
    }

    if (!this.selectedPublishedApplicationGroup) {
      return false;
    }

    if (!this.enteredApplicationName) {
      return false;
    }

    if (
      this._nameValidator !== null &&
      this._nameValidator.isLastSpecifiedValueUnique === false
    ) {
      return false;
    }

    return true;
  }

  constructor(
    applicationFacade: IApplicationFacade,
    transactionFacade: ITransactionFacade,
    portalVm: PortalVm,
    connectionSetId: string
  ) {
    this._applicationFacade = applicationFacade;
    this._transactionFacade = transactionFacade;
    this._portalVm = portalVm;
    this._connectionSetId = connectionSetId;
  }

  async init(addNewApplicationText: string): Promise<void> {
    this._addNewApplicationText = addNewApplicationText;

    this._publishedApplications = await this._getPublishedApplications();
    this._publishedApplications.push(this._buildAddNewPublishedApplicationOption());

    if (this.publishedApplicationGroupsRetrievalFailed) {
      return;
    }

    this._applicationGroups = await this._getApplicationGroups();

    if (this.applicationGroupsRetrievalFailed) {
      return;
    }

    this._publishedApplicationGroups = await this._getPublishedApplicationGroups();
  }

  async getCurrentPublishedApplication(): Promise<PublishedApplication> {
    let currentPublishedApplication = this.selectedPublishedApplication;

    if (this._createdPublishedApplicationId != null) {
      currentPublishedApplication = await this._applicationFacade.getPublishedApplication(
        this._createdPublishedApplicationId
      );
    }

    return currentPublishedApplication;
  }

  private async _getPublishedApplications(): Promise<PublishedApplication[]> {
    const result = await this._applicationFacade.getPublishedDeltaMasterApplications(
      this._connectionSetId
    );

    this._publishedApplicationsRetrievalFailed = !result.succeeded;

    if (result.succeeded) {
      return result.value;
    } else {
      return [];
    }
  }

  private async _getApplicationGroups(): Promise<ApplicationGroup[]> {
    const result = await this._applicationFacade.getApplicationGroups();

    this._applicationGroupsRetrievalFailed = !result.succeeded;

    if (result.succeeded) {
      return result.value;
    } else {
      return [];
    }
  }

  private async _getPublishedApplicationGroups(): Promise<ApplicationGroup[]> {
    const result = await this._applicationFacade.getPublishedApplicationGroups();

    this._publishedApplicationGroupsRetrievalFailed = !result.succeeded;

    if (result.succeeded) {
      return result.value;
    } else {
      return [];
    }
  }

  async useSelectedApplicationAsync(): Promise<void> {
    try {
      this._isSendingDataToBackend = true;
      await this._transactionFacade.beginOrReuseTransaction();

      this._createdPublishedApplicationId = this.selectedPublishedApplication.id;

      const dashboardApplicationId =
        await this._applicationFacade.createNewApplicationFrom(
          this.selectedPublishedApplication
        );

      this._portalTileHasBeenCreated =
        await this._portalVm.createPortalTileForApplicationAsync(
          dashboardApplicationId,
          this.selectedPublishedApplication.name
        );

      this._isSendingDataToBackend = false;
    } catch {
      this._isSendingDataToBackend = true;
    }
  }

  async createNewApplicationAsync(): Promise<
    ValueResult<ApplicationCreationResult, FailedReason>
  > {
    this._isSendingDataToBackend = true;
    await this._transactionFacade.beginOrReuseTransaction();

    const result = await this._applicationFacade.createNewApplication(
      this.enteredApplicationName,
      this.selectedApplicationGroup.id,
      this._connectionSetId,
      this.selectedPublishedApplicationGroup.id
    );

    if (result.succeeded) {
      this._createdPublishedApplicationId = result.value.deltaMasterApplicationId;

      this._portalTileHasBeenCreated =
        await this._portalVm.createPortalTileForApplicationAsync(
          result.value.dashboardApplicationId,
          this.enteredApplicationName
        );
    }

    this._isSendingDataToBackend = false;

    return result;
  }

  private _buildAddNewPublishedApplicationOption(): PublishedApplication {
    return {
      publishedApplicationGroupId: null,
      id: this._addNewApplicationId,
      name: this._addNewApplicationText,
      applicationVersionId: null,
      connectionSetId: null,
    };
  }
}
