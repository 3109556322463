import { PortalTileVm } from "./portal-tile-vm";
import { PortalTileBuilder } from "@/features/portal/view-models/builder/portal-tile-builder";
import { PortalFacade } from "../backend-wrapper/portal-facade";
import { TileConfiguration } from "@bissantz/tile-grid";
import { gridConstants } from "../portal-grid/grid-common";

export class PortalPageVm {
  private _portalFacade: PortalFacade = null;
  private _tileBuilder = new PortalTileBuilder();

  tiles: PortalTileVm[] = [];
  isLoading: boolean = true;
  title: string = "";
  id: string = "";
  hasNoTiles = false;

  constructor(portalFacade: PortalFacade) {
    this._portalFacade = portalFacade;
  }

  get shownTiles(): PortalTileVm[] {
    return this.tiles;
  }

  // artificial prop to virtualize the individual order properties of each tile
  // and only have a logical order of ids
  // (only needed for VS syncing)
  get tileOrder(): string {
    const orderedTileIds = this.tiles
      .filter((t) => !t.isTemporary)
      .map((t) => {
        return { id: t.id, order: t.tileConfig.order };
      })
      .sort((t1, t2) => t1.order - t2.order)
      .map((t) => t.id);

    const result = orderedTileIds.join(" > ");
    return result;
  }

  async getPortalTileVmsAsync(): Promise<void> {
    this.isLoading = true;

    const tileDescriptors = await this._portalFacade.getPortalTiles(this.id);
    if (!tileDescriptors?.length) {
      this.hasNoTiles = true;
      this.isLoading = false;
      return;
    }

    const tileVms = tileDescriptors.map((tileDescriptor, index) =>
      this._tileBuilder.createPortalTileVm(tileDescriptor, index)
    );

    this._updateInitialTileConfigs(tileVms);

    this.tiles.splice(0, this.tiles.length, ...tileVms);

    this.isLoading = false;
  }

  private _updateInitialTileConfigs(tileVms: PortalTileVm[]): void {
    for (const tileVm of tileVms) {
      tileVm.tileConfig.h = gridConstants.minimalTileRows;
    }
  }

  get tileLayout(): TileConfiguration[] {
    return this.shownTiles.map((tile) => tile.tileConfig);
  }
}
