<script lang="ts">
import Dashboard from "@/features/dashboard/dashboard.vue";
import ArrowIcon from "@/common/components/icons/arrow-icon.vue";
import XIcon from "@/common/components/icons/x-icon.vue";
import CloneIcon from "@/common/components/icons/clone-icon.vue";
import SparklineIcon from "@/common/components/icons/sparkline-icon.vue";
import ShareButton from "@/features/sharing/share-button.vue";
import WidthToggle from "./helper-components/width-toggle.vue";
import HideAndShow from "@/common/components/hide-and-show.vue";
import { useHelpText } from "@/services/help-text-service/help-text-cpsl";

import { SharedSparklineState } from "@/features/dashboard-shared/sparkline";
import { PortalTileVm } from "./view-models/portal-tile-vm";
import { DefaultStyles } from "@/common/styles/default-styles";
import { appResources } from "@/app-resources";
import { SharedPageState } from "./view-models/shared/shared-page-state";
import { PortalCommon } from "./portal-common";
import { gridConstants } from "./portal-grid/grid-common";
import { dragHandle, TileGridService } from "@bissantz/tile-grid";
import { LiveFeatureTogglesVm } from "@/features/live-feature-toggles/live-feature-toggles-vm";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";
import { formatResourceString } from "@/common/formatting/textFormatting";
import { SessionVm } from "@/session-vm";
import { getBrowserType, isMobile } from "@/common/browser-detection";
import { delay } from "@/common/helper/async-helper";
import { resizeHelper, Unobserver } from "@/common/resize-helper";
import { SparklineCommon } from "@/features/dashboard-shared/sparkline";
import { TileFacade } from "./backend-wrapper/tile-facade";

import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";
import { Mutex } from "async-mutex";

class PortalTileState {
  textResources = appResources.portalTexts;
  defaultStyles = DefaultStyles;
  presentationColor: string = null;
  shownAppName: string = "";
  blink = false;
  isInitialized = false;
  sparklineAnimationRunning: Promise<void> = null;
  deleteTriggered = new Mutex();
}

export default defineComponent({
  components: {
    Dashboard,
    SparklineIcon,
    XIcon,
    CloneIcon,
    ArrowIcon,
    WidthToggle,
    HideAndShow,
    ShareButton,
  },

  emits: [
    PortalCommon.portal_scaledValueInitialized.name,
    PortalCommon.portalTile_deleteTile.name,
  ],

  props: {
    tileVm: { type: Object as PropType<PortalTileVm>, required: true },
    isEditing: { type: Boolean, default: false },
    sharedPageState: { type: Object as PropType<SharedPageState>, required: true },
    dashboardSelection: {
      type: Object as PropType<DashboardSelection>,
      required: true,
    },
    needUpdatePortalScalingContext: { type: Boolean, required: true },
  },

  setup: function (props, context) {
    //
    // Injections:
    // ------------------
    const tileFacade: TileFacade = inject<TileFacade>("tileFacade");
    const tileGridService: TileGridService = inject("tileGridService");
    const liveToggles = inject("liveToggles") as LiveFeatureTogglesVm;
    const session = inject("session") as SessionVm;
    //
    // DOM/Comp. refs:
    // ------------------
    const ref_componentElem = ref<HTMLDivElement>();
    let unobserveCallback: Unobserver = null;

    // State:
    const state = reactive(new PortalTileState());

    const helpTextCpsl = useHelpText();

    // Life cycle:
    // ------------------
    onMounted(() => {
      applyLiveToggles();
      if (!props.tileVm.dashboardVm?.isInitialized) {
        return;
      }

      onDashboardInitialized();
    });

    onBeforeUnmount(() => {
      unobserveCallback?.();
    });

    //
    // Computeds:
    // ------------------
    const canExtend = computed<boolean>(() => {
      if (isMobile()) {
        return false;
      }

      const hasAtLeast4GridCols = props.sharedPageState.numberOfGridColumns >= 4;
      return hasAtLeast4GridCols;
    });

    const title = computed<string>(() => {
      return props.tileVm.shownTitle;
    });

    const showError = computed(() => {
      const showErrorResult =
        !props.tileVm.error &&
        (props.tileVm.dashboardVm.error === "TimedOut" ||
          props.tileVm.dashboardVm.error === "BackendIsLoading");

      return showErrorResult;
    });

    const errorMessage = computed<string>(() => {
      let errorMsg = null;
      if (props.tileVm?.error) {
        errorMsg = state.textResources.errorUnkownTileError;
        if (props.tileVm.error === "NoTilePage") {
          errorMsg = state.textResources.errorNoTilePage;
        }
      } else if (props.tileVm.dashboardVm?.error) {
        errorMsg = getDBError();
      }

      return errorMsg;
    });

    const shareElement = computed<HTMLElement>(() => {
      return ref_componentElem.value?.parentElement;
    });

    const canShowDeleteButton = computed<boolean>(() => {
      return props.tileVm.isTemporary;
    });

    const canShowToggleKpisButton = computed<boolean>(() => {
      return isShowAllKpiActive.value && props.tileVm.hasEnoughKpis;
    });

    const isShowAllKpiActive = computed<boolean>(() => {
      return !props.tileVm.dbSettings.applyKpiIdFilter;
    });

    const canShowButtons = computed<boolean>(() => {
      return props.tileVm.dashboardVm?.isInitialized && !errorMessage.value;
    });

    const canShowShareButton = computed<boolean>(() => {
      if (!canShowButtons.value) {
        return false;
      }

      if (session.isEmbeddedMode) {
        return false;
      }

      const isDesktop = !isMobile();
      const isSafari = getBrowserType() === "safari";
      return isDesktop || isSafari;
    });

    const canShowSparklinesButton = computed<boolean>(() => {
      return (
        canShowButtons.value &&
        props.tileVm.dashboardVm?.sharedState.sparklineState.canShowSparklines
      );
    });

    const globalSparklinesEnabled = computed<boolean>(() => {
      return liveToggles?.isEnabled && liveToggles.toggleValues.sparkline_globalScaling;
    });

    const areSparklinesDisabled = computed<boolean>(() => {
      if (!props.tileVm.dashboardVm) {
        return true;
      }

      return props.tileVm.dashboardVm.sharedState.sparklineState.isDisabled;
    });

    const showLargeSparklineIcon = computed<boolean>(() => {
      return props.tileVm.dashboardVm?.sharedState.sparklineState.mode !== "global";
    });

    //
    // Methods:
    // ------------------

    // TODO VS: is this still working? Need to mock backend for testing this
    function onRetryLoadDashboardClick(): void {
      props.tileVm.dashboardVm.error = null;
      props.tileVm.dashboardVm.isInitialized = false;
      props.tileVm.dashboardVm.retryInitializeAsync();
    }

    function getDBError(): string {
      if (props.tileVm.dashboardVm.error === "InvalidDashboardId") {
        return state.textResources.errorInvalidDashboardId;
      } else if (props.tileVm.dashboardVm.error === "NotFound") {
        updateAppName();
        const appText = state.shownAppName ? ` '${state.shownAppName}'` : "";
        return formatResourceString(state.textResources.errorApplicationHasNoDbFile, {
          appName: appText,
        });
      } else if (props.tileVm.dashboardVm.error === "NoPermission") {
        const appText = state.shownAppName ? ` '${state.shownAppName}'` : "";
        return formatResourceString(state.textResources.errorTileViewPermission, {
          appName: appText,
        });
      } else if (props.tileVm.dashboardVm.error === "TimedOut") {
        return state.textResources.errorTimeout;
      } else if (props.tileVm.dashboardVm.error === "BackendIsLoading") {
        return state.textResources.errorBackendIsLoading;
      }
      return state.textResources.errorUnkownTileError;
    }

    async function updateAppName() {
      if (state.shownAppName) {
        return;
      }

      const pubAppId = props.tileVm.tilePageFm.publishedApplicationId;
      state.shownAppName = await tileFacade.getPubAppName(pubAppId);
    }

    function startBlink() {
      state.blink = true;
      setTimeout(() => {
        state.blink = false;
      }, 1000);
    }

    function applyLiveToggles(): void {
      if (!liveToggles?.isEnabled) {
        return;
      }

      applySparklineToggles(props.tileVm.dashboardVm?.sharedState?.sparklineState);
    }

    function applySparklineToggles(sparklinesState: SharedSparklineState): void {
      if (!sparklinesState) {
        return;
      }

      const toggleValues = liveToggles.toggleValues;

      sparklinesState.globalSparklinesEnabled = toggleValues.sparkline_globalScaling;
      sparklinesState.animationsPossible = toggleValues.sparkline_animations;
      sparklinesState.deltaSparklinesEnabled = toggleValues.sparkline_deltaValues;
    }

    function onCloneTile(): void {
      context.emit(PortalCommon.portalTile_clone.name, props.tileVm);
    }

    async function onDeleteThisTile(): Promise<void> {
      await state.deleteTriggered.acquire();
      const isClonedTile = props.tileVm.isTemporary;
      context.emit(
        PortalCommon.portalTile_deleteTile.name,
        props.tileVm.id,
        isClonedTile
      );
    }

    function getNewHeight(): number {
      const newHeight = ref_componentElem.value?.clientHeight;
      if (!newHeight) {
        return null;
      }
      const heightInRows = Math.ceil(newHeight / props.sharedPageState.rowHeight);
      return Math.max(heightInRows, gridConstants.minimalTileRows);
    }

    function onScreenshotRendered(): void {
      startBlink();
    }

    function onToggleShownKpiClick(): void {
      if (props.dashboardSelection.isBlocked || !props.tileVm.hasEnoughKpis) {
        return;
      }

      props.tileVm.dashboardVm.toggleKpiFilter();
    }

    function onSparklinesButtonClick(): void {
      if (!canShowButtons.value) {
        return;
      }

      props.tileVm.dashboardVm?.sharedState.sparklineState.toggleSparklinesMode(
        globalSparklinesEnabled.value
      );
    }

    function processInitializeScaledValue(): void {
      if (!props.tileVm.dashboardVm?.isInitialized) return;
      if (!props.needUpdatePortalScalingContext) return;

      props.tileVm.initPortalScaledValue();
      context.emit(PortalCommon.portal_scaledValueInitialized.name, props.tileVm);
    }

    const onTileResize = (() => {
      // variable scoped to all calls of below function
      let previousResizeCall: Promise<void> = null;
      return async function (): Promise<void> {
        if (!state.isInitialized) {
          return;
        }

        const height = getNewHeight();
        const tileIndex = props.tileVm.tileConfig.i;
        const width = props.tileVm.tileConfig.w;

        if (previousResizeCall) {
          previousResizeCall.then(() => {
            previousResizeCall = tileGridService.setTileSize(tileIndex, width, height);
            toggleWidthIfChanged(width);
          });
        } else {
          previousResizeCall = tileGridService.setTileSize(tileIndex, width, height);
          toggleWidthIfChanged(width);
        }
      };
    })();

    function toggleWidthIfChanged(width: number): void {
      if (width === props.tileVm.tileConfig.w) {
        return;
      }

      props.tileVm.tileConfig.w = width;
    }

    function onGridColsChanged(): void {
      if (!(props.tileVm.tileConfig.w > 2)) {
        return;
      }

      if (canExtend.value) {
        return;
      }

      props.tileVm.tileConfig.w = 2;
    }

    function onWidthChanged(): void {
      if (props.tileVm.tileConfig.w > 2) {
        props.tileVm.dashboardVm.dbSettings.isExtended = true;
      } else {
        props.tileVm.dashboardVm.dbSettings.isExtended = false;
      }
    }

    function onDashboardInitializedChanged(): void {
      if (!props.tileVm.dashboardVm.isInitialized) {
        return;
      }

      onDashboardInitialized();
    }

    function onDashboardInitialized(): void {
      processInitializeScaledValue();
      unobserveCallback = resizeHelper.observe(ref_componentElem.value, onTileResize);
      state.isInitialized = true;
    }

    function applyScalingContext(): void {
      props.tileVm.tryApplyScalingContext(props.sharedPageState.portaScalingContext);
    }

    function onSparklineModeChanged(): void {
      state.sparklineAnimationRunning = delay(
        SparklineCommon.generalAnimationMaxDuration
      );
    }

    function onMaxNumKpiValuesChanged(): void {
      if (!props.tileVm.dashboardVm.isVsSyncReady) {
        return;
      }

      if (props.tileVm.dashboardVm?.maxNumKpiValues > 1) {
        return;
      }

      props.tileVm.tileConfig.w = 2;
    }

    // Watchers:
    // -----------------
    watch(() => props.sharedPageState.numberOfGridColumns, onGridColsChanged);
    watch(() => props.tileVm.tileConfig.w, onWidthChanged);
    watch(() => props.tileVm.dashboardVm?.isInitialized, onDashboardInitializedChanged);
    watch(
      [
        () => props.tileVm.dbSettings.applyKpiIdFilter,
        () => props.tileVm.dashboardVm?.showsAnyStructureElements,
      ],
      applyScalingContext
    );
    watch(
      () => props.tileVm.dashboardVm?.sharedState.sparklineState.mode,
      onSparklineModeChanged
    );
    watch(() => props.tileVm.dashboardVm?.maxNumKpiValues, onMaxNumKpiValuesChanged);

    return {
      // data
      helpTextCpsl,

      // Injections, State & Refs:
      state,
      dragHandle,
      ref_componentElem,
      liveToggles,

      // Computeds:
      areSparklinesDisabled,
      canExtend,
      title,
      showError,
      errorMessage,
      shareElement,
      canShowDeleteButton,
      canShowToggleKpisButton,
      isShowAllKpiActive,
      canShowButtons,
      canShowShareButton,
      canShowSparklinesButton,
      globalSparklinesEnabled,
      showLargeSparklineIcon,

      // Methods:

      // Event Handler:
      onDeleteThisTile,
      onCloneTile,
      onSparklinesButtonClick,
      onToggleShownKpiClick,
      onScreenshotRendered,
      onRetryLoadDashboardClick,
    };
  },
});
</script>

<template>
  <div
    ref="ref_componentElem"
    class="portal-tile-component"
    v-bind:class="{
      blink: state.blink,
      'is-extended-width': $props.tileVm.dbSettings.isExtended,
    }"
    v-if="$props.tileVm"
  >
    <div class="header-tile">
      <!-- DASHBOARD TITLE -->
      <div
        class="title-row dashboard-title"
        v-bind:class="[canShowDeleteButton ? 'clone' : '', dragHandle]"
        v-bind:style="{ color: state.presentationColor }"
      >
        <div v-if="canShowDeleteButton" class="title-side" />
        <HideAndShow>{{ title }}</HideAndShow>
        <XIcon
          v-if="canShowDeleteButton"
          v-bind:initialColor="state.defaultStyles.colorConstants.headerTextHEX"
          v-on:click.native="onDeleteThisTile"
          v-bind:data-helpText="helpTextCpsl.deletePortalTileText()"
        />
      </div>

      <!-- MENU ENTRIES -->
      <div class="title-row tile-menu">
        <!-- Show KPIs -->
        <div
          class="show-kpis-button side-action action"
          v-bind:data-helpText="helpTextCpsl.kpiExpansionText(isShowAllKpiActive)"
          v-on:click="onToggleShownKpiClick"
          v-bind:class="{
            isActive: canShowToggleKpisButton,
            'disabled-icon': !$props.tileVm.hasEnoughKpis,
            'hidden-icon': !$props.tileVm.hasEnoughKpis,
          }"
        >
          <ArrowIcon
            v-bind:initialColor="
              isShowAllKpiActive
                ? state.defaultStyles.colorConstants.darkTextHEX
                : state.defaultStyles.colorConstants.headerTextHEX
            "
            v-bind:rotationDegree="isShowAllKpiActive ? -90 : 90"
          />
        </div>

        <div class="action-group">
          <!-- Share Tile Button  -->
          <div class="shareButtonWrapper middle-action action" v-if="canShowShareButton">
            <ShareButton
              v-bind:toBeShared="shareElement"
              v-bind:isEnabled="canShowButtons"
              v-bind:screenshotName="title"
              v-bind:waitScreenshotFor="state.sparklineAnimationRunning"
              v-on:screenshot_rendered="onScreenshotRendered"
            />
          </div>

          <!-- Show Sparklines  -->
          <div
            class="show-sparkline-button middle-action action"
            v-on:click="onSparklinesButtonClick"
            v-bind:class="{ 'disabled-icon': !canShowSparklinesButton }"
            v-bind:data-helpText="
              helpTextCpsl.sparklineButtonText(
                canShowSparklinesButton,
                areSparklinesDisabled
              )
            "
          >
            <SparklineIcon
              v-bind:largeIcon="showLargeSparklineIcon"
              v-bind:initialColor="
                areSparklinesDisabled
                  ? state.defaultStyles.colorConstants.headerTextHEX
                  : state.defaultStyles.colorConstants.darkTextHEX
              "
            />
          </div>

          <!-- Clone Tile  -->
          <div
            v-on:click="onCloneTile"
            class="clone-button middle-action action"
            v-bind:class="{ 'disabled-icon': !canShowButtons }"
            v-bind:data-helpText="helpTextCpsl.clonePortalTileText()"
          >
            <CloneIcon
              v-bind:initialColor="state.defaultStyles.colorConstants.headerTextHEX"
            />
          </div>
        </div>

        <!-- Width Toggle -->
        <div
          class="width-toggle-button side-action action"
          v-bind:class="{ 'disabled-icon': !canShowButtons }"
        >
          <WidthToggle
            v-bind:enabled="canShowButtons"
            v-bind:canExtend="
              $props.tileVm.tileConfig.w < 4 && $props.tileVm.canExtendWidth && canExtend
            "
            v-bind:canShrink="$props.tileVm.tileConfig.w > 2"
            v-model="$props.tileVm.tileConfig.w"
          />
        </div>
      </div>
    </div>

    <div
      class="dashboard-host"
      v-bind:class="{ 'is-extended-width': $props.tileVm.dbSettings.isExtended }"
    >
      <div v-if="errorMessage" class="tile-error">
        <div class="error-text">
          {{ errorMessage }}
        </div>
        <div
          class="retry-button"
          v-if="showError"
          v-on:click="onRetryLoadDashboardClick"
        />
      </div>
      <Dashboard
        v-if="$props.sharedPageState.wasShown && $props.tileVm.dashboardVm"
        v-bind:dashboardSelection="$props.dashboardSelection"
        v-bind:dashboardVm="$props.tileVm.dashboardVm"
        v-bind:dashboardTileId="$props.tileVm.id"
        v-bind:isTemporary="$props.tileVm.isTemporary"
        v-bind:keyId="$props.tileVm.key"
        v-bind:globalSparklinesEnabled="globalSparklinesEnabled"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<style lang="less">
@import "../../common/styles/base-styles.less";
@import "../../common/styles/media-queries.less";

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.portal-tile-component {
  padding-top: 28px;
  white-space: nowrap;
  opacity: 1;

  --hostedFontSize: 12px;
  --tile_width: 100%;
  --kpi-bar_height: 5em;
  --error-tile-height: 80px;

  width: var(--tile_width);

  display: flex;
  flex-direction: column;

  &.blink {
    animation-name: blink;
    animation-duration: 300ms;
  }

  .tile-error {
    white-space: normal;
    height: var(--error-tile-height);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 0 2em;

    .error-text {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color_neutralText);
    }

    .retry-button {
      cursor: pointer;
      margin-top: 1em;
      height: 2em;
      width: 2em;

      background-image: var(--icon_update);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .header-tile {
    font-size: var(--hostedFontSize);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--color_neutralText);
    border-bottom: 1px solid var(--color_bg-gray);
    background-color: var(--color_bg_white);
    // TODO: rounded border implementation is just prototypical
    //      if approved, at least name the literals
    border-radius: 6px 6px 0 0;
    width: 100%;

    .title-row {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;

      &.clone {
        justify-content: space-between;
      }

      .title-side {
        visibility: hidden;
        min-width: 0;
        flex: 0 0 auto;
        flex-basis: 30px;
      }

      .xIcon {
        height: 40%;
        min-width: 0;
        flex: 0 0 auto;
        flex-basis: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .dashboard-title {
      // TODO: borders into css variable
      border-radius: 6px 6px 0 0;
      font-size: 18px;
      padding: 4px 6px;
      font-family: @fontSemi;
      white-space: nowrap;
      cursor: grab;

      min-width: 0;

      & span {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
      }
    }

    .tile-menu {
      justify-content: space-between;

      // menu entries:
      .action-group {
        height: 100%;
        align-self: center;
        display: flex;
      }
      .action {
        &.side-action {
          width: 25px;

          &:first-child {
            align-self: flex-start;
            margin-left: 7px;
          }
          &:last-child {
            align-self: flex-end;
            margin-right: 7px;
          }
        }
        &.middle-action {
          width: 22px;
          margin: 0 18px;
        }

        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: var(--color_headerText);
        cursor: pointer;

        &.disabled-icon {
          &.hidden-icon {
            opacity: 0;
          }

          opacity: 0.2;
          cursor: auto;

          & > div {
            cursor: auto;
          }
        }
      }

      .width-toggle-button {
        cursor: auto;
      }

      .arrowIcon {
        height: 80%;
      }

      .sparklineIcon {
        height: 60%;
      }

      .cloneIcon {
        height: 70%;
      }
    }
  }

  .dashboard-host {
    background-color: var(--color_bg_white);
    padding: 6px;
    padding-top: 0;
    font-size: var(--hostedFontSize);
    position: relative;
    // TODO: clean up and bundle code for borders
    border-radius: 0 0 6px 6px;

    &.is-extended-width {
      padding-left: 0;
      padding-right: 0;
    }

    // TODO: clean up and bundle code for borders
    & > .dashboard > :last-child .valueGroup:not(.showsDrill) {
      .kpiFrame.backgroundImage {
        border-radius: 0 0 4px 4px;
      }
    }
  }

  &.is-extended-width {
    .tile-menu {
      padding-left: 20px;
      padding-right: 16px;
    }
  }
}
</style>
