import {
  IStatusServiceClient,
  IHelpServiceClient,
  StatusServiceClient,
  HelpServiceClient,
  ITransactionsServiceClient,
  TransactionsServiceClient,
  IInternalUsersServiceClient,
  InternalUsersServiceClient,
} from "@/common/service-clients/generated-clients";
import { AxiosInstance } from "axios";

export function getUsedServiceClients(axios: AxiosInstance) {
  return {
    transactionsClient: new TransactionsServiceClient(
      null,
      axios
    ) as ITransactionsServiceClient,

    statusClient: new StatusServiceClient(null, axios) as IStatusServiceClient,
    helpClient: new HelpServiceClient(null, axios) as IHelpServiceClient,
    userServiceClient: new InternalUsersServiceClient(
      null,
      axios
    ) as IInternalUsersServiceClient,
  };
}
