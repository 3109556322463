import { exists, existsAndNotEmpty } from "@/common/object-helper/null-helper";
import { IBcInput } from "./bc-input.interface";
import { IUniqueValidator } from "./unique-validator.interface";

export class BcInputUniqueValidator {
  public static NotUniqueErrorCode = "notUnique";
  public static UniqueValidationFailedErrorCode = "uniqueValidationFailed";

  private _errorKeys: string[] = [];

  constructor(private _input: IBcInput, private _uniqueValidator: IUniqueValidator) {}

  async validateAsync(): Promise<void> {
    if (!exists(this._input.computedValue)) {
      return;
    }

    if (typeof this._input.computedValue !== "string") {
      return;
    }

    const inputValue = this._input.computedValue as string;

    if (!existsAndNotEmpty(inputValue)) {
      return;
    }

    const result = await this._uniqueValidator.isValueUniqueAsync(inputValue);

    if (result.succeeded) {
      if (result.value === true) {
        this._finishValidation([]);
      } else {
        this._finishValidation([BcInputUniqueValidator.NotUniqueErrorCode]);
      }
    } else {
      // failed to get the necessary datas: custom error
      this._finishValidation([BcInputUniqueValidator.UniqueValidationFailedErrorCode]);
    }
  }

  serialize(): void {
    if (this._errorKeys.length === 0) {
      // there might be error codes added by other validators like valueIsMissing
      // if this validator doesn't provide custom codes the existent one should not be changed
      return;
    }

    this._finishValidation(this._errorKeys);
  }

  private _finishValidation(errorKeys: string[] = null): void {
    if (errorKeys === null || errorKeys === undefined) {
      this._errorKeys = [];
    }

    this._errorKeys = errorKeys;
    this._input.validity = this._errorKeys.length === 0;
    this._input.errorKeys = this._errorKeys;
  }
}
