<script lang="ts">
import DialogWarningIcon from "@/common/components/icons/dialog-warning-icon.vue";
import { DefaultStyles } from "@/common/styles/default-styles";
import { defineComponent, reactive } from "vue";
import { appResources } from "@/app-resources";

//constants
const warningColor = DefaultStyles.colorConstants.weatherPlus3;

export default defineComponent({
  components: {
    DialogWarningIcon,
  },

  setup() {
    const state = reactive({
      appWizardTexts: appResources.applicationWizardTexts,
    });

    return {
      state,
      warningColor,
    };
  },
});
</script>

<template>
  <div class="application-wizard_screen-popup">
    <div class="header">
      <div class="titleRow">
        <div class="icon">
          <DialogWarningIcon v-bind:mainColor="warningColor" />
        </div>
        <div class="title">{{ state.appWizardTexts.general_screenPopupHeader }}</div>
      </div>
    </div>
    <div class="separator" />
    <div class="message">
      {{ state.appWizardTexts.general_screenPopupMessage }}
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";
.application-wizard_screen-popup {
  width: clamp(220px, 80%, 100%);
  background-color: var(--color_bg_white);
  border-radius: 10px;
  padding: 20px;
  color: var(--color_neutralText);
  position: relative;

  .header {
    .close {
      position: absolute;
      right: 14px;
      top: 13px;
      height: 16px;
      width: 16px;
      cursor: pointer;
    }

    .titleRow {
      display: flex;
      align-items: center;
      padding-right: 15px;

      .icon {
        width: 48px;
        height: 48px;
        flex: 0 0 48px;
      }
      .title {
        font-size: 25px;
        padding-left: 19px;
      }
    }
  }

  .separator {
    margin-top: 13px;
    background-color: var(--color_bg-gray);
    height: 2px;
    width: 100%;
  }

  .message {
    margin-top: 1em;
    font-size: 18px;
    line-height: 1.5;
  }
}
</style>
