import { VsRoot } from "@/services/view-state-service/contract/vs-root";
import {
  IVmReadyChecker,
  WatchBehaviour,
} from "@/services/view-state-service/contract/vm-ready-checker.interface";
import { DashboardVm } from "../../view-models/dashboard-vm";
import { ValueGroupVm } from "../../view-models/value-group-vm";
import { StructureElementsVm } from "../../view-models/structure-elements-vm";
import { ElementVm } from "../../view-models/element-vm";

export class DashboardVmReadyChecker implements IVmReadyChecker {
  getWatchBehaviour(): WatchBehaviour {
    return { watchList: null, automatic: true };
  }

  isVmReady(vm: VsRoot): { isVmReady: boolean; hasAnyError: boolean } {
    const dashboardVm = vm as DashboardVm;

    const hasAnyError = !!dashboardVm.error;
    const mainVmInitialized = dashboardVm.isInitialized;
    const areKpiReady = this._areAllKpiReady(dashboardVm);

    const isVmReady = !hasAnyError && mainVmInitialized && areKpiReady;

    return {
      isVmReady,
      hasAnyError,
    };
  }

  private _areAllKpiReady(vm: DashboardVm): boolean {
    const areReady = vm.shownKpiTileVms.every((kpiTileVm) =>
      this._isValueGroupReady(kpiTileVm.currentValueGroup)
    );
    return areReady;
  }

  private _isValueGroupReady(valGrp: ValueGroupVm): boolean {
    if (!valGrp.structureElementsVm.isVisible) {
      return true;
    }

    const isStructureElementsReady = this._isStructureElementsReady(
      valGrp.structureElementsVm
    );
    return isStructureElementsReady;
  }

  private _isStructureElementsReady(strElem: StructureElementsVm): boolean {
    if (!strElem) {
      return false;
    }

    if (strElem.isUpdating || !strElem.isInitialized) {
      return false;
    }

    return strElem.currentElementVms.every((elemVm) => this._isElementReady(elemVm));
  }

  private _isElementReady(elem: ElementVm): boolean {
    if (!elem.nextStructureElements.isVisible) {
      return true;
    }

    return this._isStructureElementsReady(elem.nextStructureElements);
  }
}
