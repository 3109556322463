<script lang="ts">
import ModalDialog from "./helper-components/modal-dialog.vue";
import ApplicationWizard from "./application-wizard.vue";
import AppWizardScreenPopup from "./helper-components/app-wizard-screen-popup.vue";
import { DefaultStyles } from "@/common/styles/default-styles";
import { AppWizardSwitch } from "./app-wizard-switch";
import { defineComponent, inject } from "vue";

// constants
const background = DefaultStyles.colorConstants.bgWhite;

export default defineComponent({
  components: {
    ModalDialog,
    ApplicationWizard,
    AppWizardScreenPopup,
  },

  setup() {
    const appWizardSwitch = inject("appWizardSwitch") as AppWizardSwitch;

    return {
      background,
      appWizardSwitch,
    };
  },
});
</script>

<template>
  <div class="modal-application-wizard">
    <ModalDialog>
      <Transition>
        <ApplicationWizard v-if="appWizardSwitch.enabled" class="show-app-wizard" />
      </Transition>
      <div class="hide-app-wizard">
        <AppWizardScreenPopup />
      </div>
    </ModalDialog>
  </div>
</template>

<style lang="less" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

.modal-application-wizard {
  .show-app-wizard {
    @media (max-width: 750px) {
      display: none;
    }
  }

  .hide-app-wizard {
    display: none;

    @media (max-width: 750px) {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
