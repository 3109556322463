<script lang="ts">
import KpiTile from "./kpi-tile/kpi-tile.vue";
import KpiPlaceHolder from "./kpi-tile/kpi-tile-placeholder.vue";

import { DashboardVm } from "./view-models/dashboard-vm";
import { DisplayDeviceHint } from "@/common/styles/media-queries";
import { IDisposable } from "@/common/disposable.interface";
import { IMediaQueries } from "@/common/styles/media-queries.interface";
import { DashboardCommon } from "./dashboard-common";
import { ValueGroupVm } from "./view-models/value-group-vm";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";
import { DashboardSettings } from "@/features/dashboard-shared/dashboard-settings";

import {
  ref,
  reactive,
  inject,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  PropType,
  defineComponent,
} from "vue";
import { useRegisterVsSyncer } from "./view-states/register-vs-syncer.cpsl";
import { useBackendDependencies } from "./backend-wrapper/backend-dependencies.cpsl";

class DashboardState {
  mediaQueryListener = null as IDisposable;
  innerDisplayDevice = null as string;
}

export default defineComponent({
  components: {
    KpiTile,
    KpiPlaceHolder,
  },

  emits: [DashboardCommon.common_focusElementChanged],

  props: {
    dashboardSelection: {
      type: Object as PropType<DashboardSelection>,
      required: true,
    },
    dashboardVm: { type: Object as PropType<DashboardVm>, required: true },
    dashboardTileId: { type: String, required: true },
    isTemporary: { type: Boolean, required: true },
    globalSparklinesEnabled: { type: Boolean, required: true },
    keyId: { type: String, required: true },
  },

  setup: function (props, context) {
    const facades = useBackendDependencies();
    const mediaQueries: IMediaQueries = inject("mediaQueries");
    const ref_dashboard = ref<HTMLDivElement>();
    const state = reactive(new DashboardState()) as DashboardState;
    if (!props.isTemporary) {
      useRegisterVsSyncer(props.dashboardVm, facades.dashboardViewStateFacade);
    } else {
      props.dashboardVm.isVsSyncReady = true;
    }

    onMounted(async () => {
      await props.dashboardVm.initializeAsync(
        facades.dashboardFacade,
        props.dashboardSelection,
        props.dashboardTileId
      );

      props.dashboardVm.sharedState.animateSetFilter = false;
      initializeListeners();
      onMediaQueryChanged();
    });

    onBeforeUnmount(() => disposeListeners());

    const dashboardSettings = computed<DashboardSettings>(() => {
      return props.dashboardVm.dbSettings;
    });

    const rootStyle = computed(() => {
      const fontSize = 12;
      const headerHeight = props.dashboardVm.dbSettings.isExtended ? 28 : 22;

      return {
        "font-size": `${fontSize}px`,
        "--headerHeight": `${headerHeight}`,
        "--titleWithFilterHeight": `${headerHeight}px`,
      };
    });

    const displayDevice = computed(() => {
      if (dashboardSettings.value && dashboardSettings.value.forcedDisplayDevice)
        return dashboardSettings.value.forcedDisplayDevice;

      return state.innerDisplayDevice;
    });

    function initializeListeners(): void {
      state.mediaQueryListener = mediaQueries.mediaChanged.on(onMediaQueryChanged);
    }

    function disposeListeners(): void {
      state.mediaQueryListener?.dispose();
    }

    function onValueGroupChanged(): void {
      props.dashboardVm.onValueGroupChanged();
    }

    function onValueGroupReadyStateIsOn(valueGroupVm: ValueGroupVm): void {
      props.dashboardVm.setIsReadyHot(valueGroupVm);
    }

    function onValueGroupReadyStateIsOff(): void {
      props.dashboardVm.clearIsReadyHot();
    }

    function onExcludeFromScalingChanged(): void {
      props.dashboardVm.onExcludeFromScalingChanged();
    }

    function onSparklineBoxHistIndexChanged(): void {
      props.dashboardVm.refreshColorAndScaling();
    }

    function hideSparklines(lastKpiIndex: number): void {
      props.dashboardVm.disableSparklines(lastKpiIndex);
    }

    function onDrillClosed(kpiId: number): void {
      props.dashboardVm.closeTilesSameScaleGroup(kpiId);
    }

    function onMediaQueryChanged(): void {
      const atLeastTablet: DisplayDeviceHint = "mediaHint_isAtLeastTablet";

      if (mediaQueries.isExactlyPhone) {
        state.innerDisplayDevice = "mediaHint_isMobile";
      } else if (mediaQueries.isExactlyTablet) {
        state.innerDisplayDevice = "mediaHint_isTablet " + atLeastTablet;
      } else if (mediaQueries.isExactlyLaptop) {
        state.innerDisplayDevice = "mediaHint_isLaptop " + atLeastTablet;
      }
    }

    function onKpiFilterChanged(): void {
      props.dashboardVm.onKpiFiltersChanged();
      context.emit(
        DashboardCommon.common_focusElementChanged,
        ref_dashboard.value.parentElement.parentElement,
        // TODO: remove hard coded css selector
        [".valueGroup"]
      );
    }

    async function onFiltersChanged(): Promise<void> {
      const closeDrills = props.dashboardVm.isVsSyncReady;
      await props.dashboardVm.onFiltersChangedAsync(closeDrills);
    }

    watch(
      () => props.dashboardVm.filters.filters.length,
      async () => onFiltersChanged()
    );

    watch(() => dashboardSettings.value.applyKpiIdFilter, onKpiFilterChanged);

    watch(
      () => props.dashboardVm.sharedState.sparklineState.mode,
      () => props.dashboardVm.onSparklinesModeChanged()
    );

    watch(
      () => props.dashboardVm.sharedState.sparklineState.showSparklines,
      () => props.dashboardVm.onShowSparklinesChanged()
    );

    return {
      state,
      ref_dashboard,
      dashboardSettings,
      displayDevice,
      rootStyle,
      onValueGroupChanged,
      onValueGroupReadyStateIsOn,
      onValueGroupReadyStateIsOff,
      onExcludeFromScalingChanged,
      onSparklineBoxHistIndexChanged,
      onDrillClosed,
      hideSparklines,
    };
  },
});
</script>

<template>
  <div
    class="dashboard"
    ref="ref_dashboard"
    v-if="dashboardVm.isVsSyncReady && !dashboardVm.error"
    v-bind:class="[
      {
        'disable-scroll': !dashboardSettings.scrollTiles,
        'full-width': dashboardSettings.useFullWidth,
      },
      displayDevice,
    ]"
    v-bind:style="rootStyle"
  >
    <KpiTile
      v-for="kpiTileVm in dashboardVm.shownKpiTileVms"
      v-bind:key="kpiTileVm.kpiInfo.kpiId"
      v-bind:kpiTileVm="kpiTileVm"
      v-bind:dashboardTileId="dashboardTileId"
      v-bind:dashboardSettings="dashboardSettings"
      v-bind:sharedState="dashboardVm.sharedState"
      v-bind:dashboardSelection="dashboardSelection"
      v-on:common_valueGroupChanged="onValueGroupChanged"
      v-on:valueGroup_readyStateIsOn="onValueGroupReadyStateIsOn"
      v-on:valueGroup_readyStateIsOff="onValueGroupReadyStateIsOff"
      v-on:common_excludeFromScalingChanged="onExcludeFromScalingChanged"
      v-on:sparklineBox_histIdxChanged="onSparklineBoxHistIndexChanged"
      v-on:valueGroup_drillClosed="onDrillClosed"
      v-on:common_hideSparklines="hideSparklines"
      v-on="$listeners"
    />
  </div>
  <div
    v-else-if="!dashboardVm.isVsSyncReady && !dashboardVm.error"
    class="dashboard"
    ref="ref_dashboard"
    v-bind:class="[
      {
        'disable-scroll': !dashboardSettings.scrollTiles,
        'full-width': dashboardSettings.useFullWidth,
      },
      displayDevice,
    ]"
    v-bind:style="rootStyle"
  >
    <KpiPlaceHolder />
  </div>
</template>

<style lang="less" scoped>
.dashboard {
  --dashboardFrameMargin: 6px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.disable-scroll {
    min-height: unset;
    height: unset;
  }

  &.full-width {
    display: block;
  }
}
</style>
