<script lang="ts">
import BissantzSpinner from "@/common/components/bissantz-spinner.vue";
import ShareIcon from "@/common/components/icons/share-icon.vue";

import { appResources } from "@/app-resources";
import { DefaultStyles } from "@/common/styles/default-styles";
import {
  ComputedRef,
  computed,
  ref,
  inject,
  defineComponent,
  PropType,
  reactive,
} from "vue";
import { getBrowserType, isMobile } from "@/common/browser-detection";
import { takeScreenshot, ScreenshotError } from "./screenshot-helper";
import { copyImageToClipboard } from "./share-helper";
import { INotificationService } from "@/features/notifications/notification-service.interface";
import { ClipboardCopyResult, downloadImage } from "./share-helper";
import { SharingCommon } from "./sharing-common";
import { useHelpText } from "@/services/help-text-service/help-text-cpsl";
import { delay } from "@/common/helper/async-helper";

export default defineComponent({
  components: {
    BissantzSpinner,
    ShareIcon,
  },

  emits: [
    "update:modelValue",
    "input",
    SharingCommon.screenshot_copied.name,
    SharingCommon.screenshot_rendered.name,
  ],

  props: {
    isEnabled: { type: Boolean, default: true },
    toBeShared: { type: HTMLElement as PropType<HTMLElement | null>, default: null },
    screenshotName: { type: String, required: true },
    waitScreenshotFor: { type: Promise as PropType<Promise<void> | null>, default: null },
  },

  // TODO: add separate composable for notification handling
  setup(props, context) {
    const notificationService = inject("notificationService") as INotificationService;
    const ref_shareButtonComponent = ref(null);

    const helpTextCpsl = useHelpText();

    const state = reactive({
      isWaitingToShare: false,
      isRenderingScreenshot: false,
      defaultStyles: DefaultStyles,
    });

    function onClick() {
      if (isDisabled.value) return;
      takeTileScreenshot();
    }

    async function takeTileScreenshot() {
      state.isRenderingScreenshot = true;

      // Start wait-spinner animation before.
      // Seems like the calculation can in some cases consume so much computation power
      // that the spinner can't be started in parallel anymore if not delaying.
      await delay(100);

      if (props.waitScreenshotFor) {
        await props.waitScreenshotFor;
      }
      const result = await takeScreenshot(props.toBeShared);

      if (result.error) {
        handleScreenshotError(result.error);
        return;
      }

      state.isWaitingToShare = true;
      state.isRenderingScreenshot = false;
      context.emit(SharingCommon.screenshot_rendered.name, result.image);

      await share(result.image);
    }

    async function share(img: Blob) {
      const canShare = isMobile() || getBrowserType() === "safari";
      if (canShare) {
        await shareViaWebShareAPI(img);
      } else {
        await shareViaClipboard(img);
      }
      state.isWaitingToShare = false;
    }

    function handleScreenshotError(error: ScreenshotError): void {
      state.isRenderingScreenshot = false;

      if (error === "RenderingFailed") {
        notificationService.error({
          title: appResources.sharingTexts.errorSharingNotPossibleTitle,
          message: appResources.sharingTexts.errorScreenshotRendering,
        });
      } else if (error === "TooManyElements") {
        notificationService.error({
          title: appResources.sharingTexts.errorSharingNotPossibleTitle,
          message: appResources.sharingTexts.errorScreenshotSizeLimit,
        });
      }
    }

    async function shareViaWebShareAPI(img: Blob) {
      // TODO: currently webShareAPI does not always work on iOS so we use this temporarily.
      //       The issue seems to be that larger files are not allowed to be shared.
      try {
        downloadImage(img, props.screenshotName);
        return;
      } catch (err) {
        const wasAborted = err?.name === "AbortError";
        if (wasAborted) {
          return;
        }

        notificationService.error({
          title: appResources.sharingTexts.errorSharingNotPossibleTitle,
          message: appResources.sharingTexts.errorShareUnknownReason,
        });
      }
    }

    async function shareViaClipboard(img: Blob) {
      // check and handle focus
      if (document.hasFocus()) {
        await copyImageAndNotify(img);
      } else {
        // No info (that the user needs to refocus the browser) wanted here
        const focusHandler = async () => {
          await copyImageAndNotify(img);
          window.removeEventListener("focus", focusHandler);
        };
        window.addEventListener("focus", focusHandler);
      }
    }

    async function copyImageAndNotify(img: Blob) {
      const copyResult = await copyImageToClipboard(img);
      state.isWaitingToShare = false;

      const messageData = getNotificationFromCopyResult(copyResult);
      if (messageData.message) {
        notificationService.error(messageData);
      } else {
        // just feels a bit nicer regarding the notification:
        const actionNotifyDelay = 300;
        setTimeout(() => {
          context.emit(SharingCommon.screenshot_copied.name, img);
          notificationService.check(messageData);
        }, actionNotifyDelay);
      }
    }

    function getNotificationFromCopyResult(copyResult: ClipboardCopyResult) {
      switch (copyResult) {
        case "success":
          return {
            title: appResources.sharingTexts.successfullyCopiedToClipboardTitle,
            message: null,
          };
        case "error":
          return {
            title: appResources.sharingTexts.errorSharingNotPossibleTitle,
            message: appResources.sharingTexts.errorCopyingToClipboardUnknownReason,
          };
        case "not-supported":
          return {
            title: appResources.sharingTexts.errorSharingNotPossibleTitle,
            message:
              appResources.sharingTexts.errorCopyingToClipboardIncompatiblePlatform,
          };
      }
    }

    const isDisabled: ComputedRef<boolean> = computed(() => {
      return (
        !props.isEnabled ||
        state.isRenderingScreenshot ||
        state.isWaitingToShare ||
        getBrowserType() === "firefox"
      );
    });

    return {
      state,
      takeTileScreenshot,
      isDisabled,
      ref_shareButtonComponent,
      onClick,
      helpTextCpsl,
    };
  },
});
</script>

<template>
  <div
    class="shareButtonComponent"
    v-bind:class="{ disabled: isDisabled }"
    v-bind:data-helpText="helpTextCpsl.shareButtonText(isDisabled)"
    v-on:click="onClick"
    ref="ref_shareButtonComponent"
  >
    <div v-if="state.isRenderingScreenshot" class="spinnerWrapper">
      <BissantzSpinner />
    </div>
    <ShareIcon
      v-else
      v-bind:mainColor="state.defaultStyles.colorConstants.headerTextHEX"
    />
  </div>
</template>

<style lang="less" scoped>
.shareButtonComponent {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .shareIcon {
    height: 70%;
  }

  .spinnerWrapper {
    height: 15px;
    width: 15px;
    // sets spinner line width:
    font-size: 3px;
  }

  &.disabled {
    opacity: 0.2;
    cursor: auto;
  }
}
</style>
