<script lang="ts">
import { computed, defineComponent } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    mainColor: {
      type: String,
      default: "#000",
    },
  },
  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      const usedColor = props.mainColor;
      return {
        "--main-color": usedColor,
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="dialogQuestionIcon" v-bind:style="colorVars">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 212 212"
      style="enable-background: new 0 0 212 212"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M105.7,6.3c55.1,0,100,44.9,100,100s-44.9,100-100,100c-55.1,0-100-44.9-100-100S50.5,6.3,105.7,6.3 M105.7,0.3
			c-58.5,0-106,47.5-106,106s47.5,106,106,106s106-47.5,106-106S164.2,0.3,105.7,0.3L105.7,0.3z"
          />
        </g>
        <g>
          <path
            class="styled"
            d="M80.5,58c9.3-10.7,28.1-14.3,40.6-7.6c16.9,9,17.6,31.5,8.1,45.9c-9.1,13.9-24.8,14.6-24.8,42.5"
          />
          <line class="styled" x1="104.2" y1="151.7" x2="104.2" y2="164.3" />
        </g>
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.dialogQuestionIcon {
  width: 100%;
  height: 100%;

  svg {
    stroke: var(--main-color);
    // stroke-width: 14;
    width: 100%;
    height: 100%;
    fill: none;
    // stroke-miterlimit: 10;
    // stroke-linejoin: round;

    styled {
      stroke-width: 12;
      stroke-miterlimit: 10;
    }
  }
}
</style>
