import { inject, provide } from "vue";
import { AxiosInstance } from "axios";

import {
  IPortalsServiceClient,
  PortalsServiceClient,
} from "@/common/service-clients/generated-clients";
import { PortalMenuFacade } from "./portal-menu-facade";

export function useBackendDependencies() {
  const axiosInstance = inject("axiosInstance", null) as AxiosInstance;

  // list of used service clients:
  let portalClient: IPortalsServiceClient = null;

  instanciateUsedServiceClients(axiosInstance);

  // TODO:for now we just provide the ServiceClients
  // Create Facades:
  // ---------------
  let portalMenuFacade = inject<PortalMenuFacade>("portalMenuFacade", null);
  if (!portalMenuFacade) {
    portalMenuFacade = new PortalMenuFacade(portalClient);
    provide("portalMenuFacade", portalMenuFacade);
  }

  // Functions:
  // ---------------
  function instanciateUsedServiceClients(axiosInstance: AxiosInstance): void {
    portalClient = new PortalsServiceClient(null, axiosInstance);
  }

  return {
    portalMenuFacade,
  };
}
