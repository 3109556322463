<script lang="ts">
import { BcCheckbox } from "@bissantz/controls";
import type { DesignVersion } from "@bissantz/controls";
import { LiveToggleItem } from "./live-feature-toggles-vm";
import { LiveToggleEvents } from "./events";
import { reactive, defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    BcCheckbox,
  },

  emits: [LiveToggleEvents.liveToggle_valueChanged],

  props: {
    design: { type: String as PropType<DesignVersion>, default: null },
    toggleItem: { type: Object as PropType<LiveToggleItem | null>, default: null },
  },

  setup(props, context) {
    const state = reactive({
      tempValue: null as boolean,
    });

    function init() {
      state.tempValue = props.toggleItem.getValue();
    }

    init();

    function onValueChanged() {
      props.toggleItem.setValue(state.tempValue);
      context.emit(LiveToggleEvents.liveToggle_valueChanged);
    }

    return {
      state,
      onValueChanged,
    };
  },
});
</script>

<template>
  <div class="liveToggleItem">
    <div class="itemTitle">{{ $props.toggleItem.title }}:</div>
    <div class="inputBox">
      <BcCheckbox
        v-bind:design="$props.design"
        v-model="state.tempValue"
        v-on:change="onValueChanged"
      />
    </div>
  </div>
</template>

<style lang="less">
.liveToggleItem {
  min-width: 200px;
  max-width: 200px;
  height: 3em;
  display: flex;
  justify-content: space-between;

  .itemTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & {
    margin-left: 5em;
  }
}
</style>
