<script lang="ts">
import ArrowIcon from "@/common/components/icons/arrow-icon.vue";
import HideAndShow from "@/common/components/hide-and-show.vue";

import { PortalCommon } from "../portal-common";
import { DefaultStyles } from "@/common/styles/default-styles";

import {
  onMounted,
  computed,
  ref,
  ComputedRef,
  defineComponent,
  PropType,
  reactive,
} from "vue";

export default defineComponent({
  components: { ArrowIcon, HideAndShow },

  emits: [PortalCommon.portal_pageIndexSelected.name],

  props: {
    openedByTouch: { type: Boolean },
    texts: { type: Array as PropType<string[]>, default: new Array<string>() },
    selectedIndex: { type: Number, default: 0 },
  },

  setup(props, context) {
    const state = reactive({
      defaultStyles: DefaultStyles,
      currentPageIdx: 0,
      pages: [] as { first: number; last: number }[],
    });

    // DOM refs:
    const ref_listWrapper = ref(null as HTMLDivElement);
    const ref_exposeeList = ref(null as HTMLDivElement);

    // Lifecycle Methods
    // ------------------------------
    onMounted(() => {
      init();
      getInitialPageIdx(props.selectedIndex);
    });

    // Computeds
    // ------------------------------
    const selectedPage: ComputedRef<{ first: number; last: number }> = computed(() => {
      return state.pages[state.currentPageIdx];
    });

    const numPages: ComputedRef<number> = computed(() => {
      return state.pages.length;
    });

    const hasHiddenItemsLeft: ComputedRef<boolean> = computed(() => {
      if (numPages.value === 1) return false;

      return state.currentPageIdx > 0;
    });

    const hasHiddenItemsRight: ComputedRef<boolean> = computed(() => {
      if (numPages.value === 1) return false;

      return state.currentPageIdx < numPages.value - 1;
    });

    // Methods
    // ------------------------------
    function init(): void {
      const availableWidth = Math.floor(ref_listWrapper.value.clientWidth);

      const titleWidths = _getTitleWidths();

      const separatorWidth = _getSeparatorsWidth();
      const fullSeparatorsLength = separatorWidth * (titleWidths.length - 1);
      const fullWidth =
        fullSeparatorsLength + titleWidths.reduce((prev, curr) => prev + curr, 0);

      // All title can be shown in page:
      if (availableWidth > fullWidth) {
        _setSinglePageStyle();
        return;
      }

      // If we have multiple pages with titles: get the pages:
      state.pages = _calculatePaging(availableWidth, titleWidths, separatorWidth);
    }

    function _getSeparatorsWidth() {
      const actualSeparatorWidth = ref_exposeeList.value
        .querySelector(".separator")
        .getBoundingClientRect().width;

      return actualSeparatorWidth;
    }

    function getInitialPageIdx(selectedItemIdx: number): void {
      if (state.pages.length <= 1) return;

      state.currentPageIdx = state.pages.findIndex(
        (def) => selectedItemIdx >= def.first && selectedItemIdx <= def.last
      );
    }

    function isInCurrentPage(index: number): boolean {
      const noPageDefs = !state.pages.length;
      if (noPageDefs) {
        return true;
      }

      const isInCurrentPage =
        selectedPage.value.first <= index && index <= selectedPage.value.last;

      return isInCurrentPage;
    }

    function shouldShowSeparator(index: number): boolean {
      const nextExposeItemIsInCurrentPage = isInCurrentPage(index + 1);
      const previousExposeItemIsInCurrentPage =
        isInCurrentPage(Math.max(index - 1, 0)) || index === selectedPage.value.first;
      const isNotLastItem = index < props.texts.length - 1;

      return (
        nextExposeItemIsInCurrentPage &&
        previousExposeItemIsInCurrentPage &&
        isNotLastItem
      );
    }

    function _getTitleWidths(): number[] {
      const titleElems = Array.from(
        ref_exposeeList.value.querySelectorAll(".exposeeItem")
      ) as HTMLElement[];

      const strippedPaddings = titleElems
        .map((elem) => elem.getBoundingClientRect())
        .map((rect) => {
          return Math.ceil(rect.width);
        });
      return strippedPaddings;
    }

    function _setSinglePageStyle() {
      // Change into display flex mode:
      ref_exposeeList.value.style.display = "flex";

      // adjust flex-grow/shrink for tiles:
      const titleElems = Array.from(
        ref_exposeeList.value.querySelectorAll(".exposeeItem")
      ) as HTMLElement[];
      for (const titleElem of titleElems) {
        const elemMax = titleElem
          .querySelector(".hideAndShowBody")
          .getBoundingClientRect().width;
        titleElem.style.maxWidth = `${elemMax}px`;
        titleElem.style.flex = `1 0`;
      }
    }

    function _calculatePaging(
      availableWidth: number,
      titleWidths: number[],
      separatorWidth: number
    ): { first: number; last: number }[] {
      let currentPageWidth = 0;
      let currentStart = 0;
      let tempPageDefs: { first: number; last: number }[] = [];
      for (let idx = 0; idx < titleWidths.length; idx++) {
        const isStartingElem = idx === 0;
        const usedSeparatorWidth = isStartingElem ? 0 : separatorWidth;
        const width = usedSeparatorWidth + titleWidths[idx];

        if (currentPageWidth + width > availableWidth) {
          tempPageDefs.push({ first: currentStart, last: idx - 1 });
          currentPageWidth = 0 - separatorWidth;
          currentStart = idx;
        }

        currentPageWidth += width;
      }

      let lastPageWidth = 0;
      let lastPageRects: number[] = [];
      const maxIdx = titleWidths.length - 1;
      for (let revIdx = maxIdx; revIdx >= 0; revIdx--) {
        const usedSeparatorWidth = revIdx === maxIdx ? 0 : separatorWidth;
        const width = usedSeparatorWidth + titleWidths[revIdx];

        if (lastPageWidth + width > availableWidth) {
          break;
        }
        lastPageWidth += width;
        lastPageRects.push(width);
      }

      tempPageDefs.push({
        first: titleWidths.length - lastPageRects.length,
        last: titleWidths.length - 1,
      });

      return tempPageDefs;
    }

    // Event Handler
    // ------------------------------
    function onExposeeItemClick(index: number): void {
      context.emit(PortalCommon.portal_pageIndexSelected.name, index);
    }

    function onLeftArrowClick(event: Event): void {
      event.stopImmediatePropagation();
      state.currentPageIdx = Math.max(0, state.currentPageIdx - 1);
    }

    function onRightArrowClick(event: Event): void {
      event.stopImmediatePropagation();
      state.currentPageIdx = Math.min(numPages.value - 1, state.currentPageIdx + 1);
    }

    return {
      state,

      // dom refs:
      ref_listWrapper,
      ref_exposeeList,

      // computeds:
      hasHiddenItemsLeft,
      hasHiddenItemsRight,

      // functions:
      isInCurrentPage,
      shouldShowSeparator,
      onExposeeItemClick,
      onLeftArrowClick,
      onRightArrowClick,
    };
  },
});
</script>

<template>
  <div class="exposeeComponent" v-on:click.stop>
    <div class="arrowFrame" v-on:click.stop>
      <ArrowIcon
        v-show="hasHiddenItemsLeft"
        v-bind:rotationDegree="180"
        v-on:click.native="onLeftArrowClick"
      />
    </div>

    <!-- Limits/Sets the visible width -->
    <div class="listWrapper" ref="ref_listWrapper" v-on:click.stop>
      <!--  -->
      <!-- Centers visible expose items, must not be flexbox: js-code need to measure actual item widths -->
      <div class="exposeeList" ref="ref_exposeeList">
        <template v-for="(text, index) in $props.texts">
          <div
            class="exposeeItem"
            v-bind:class="{
              hoverStyle: !$props.openedByTouch,
              selectedItem: index === $props.selectedIndex,
            }"
            v-bind:key="text + index"
            v-if="isInCurrentPage(index)"
            v-on:click="onExposeeItemClick(index)"
          >
            <HideAndShow>
              <strong>{{ text }}</strong>
            </HideAndShow>
          </div>
          <div
            class="separator"
            v-if="shouldShowSeparator(index)"
            v-bind:key="text + index + '-separator'"
          >
            <div class="inner" />
          </div>
        </template>
      </div>
    </div>

    <div class="arrowFrame" v-on:click.stop>
      <ArrowIcon
        v-show="hasHiddenItemsRight"
        v-bind:rotationDegree="0"
        v-on:click.native="onRightArrowClick"
      />
    </div>
  </div>
</template>

<style lang="less">
@import "../../../common/styles/media-queries.less";

.exposeeComponent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .arrowFrame {
    width: 40px;
    height: 3em;
    padding-bottom: 1em;

    .arrowIcon {
      width: 40px;
      z-index: 1;
      background-color: var(--color_bg_white);
      --initial-color: var(--color_neutralText);

      &:hover {
        --initial-color: var(--color_Weather3);
      }
    }
  }

  .separator {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    .inner {
      height: 100%;
      width: 1px;
      background-color: var(--color_headerText);
    }
  }

  .listWrapper {
    width: calc(100% - 80px);
    height: 3em;
    overflow: hidden;
    position: relative;

    .exposeeList {
      z-index: 0;
      display: block;
      justify-items: center;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      position: relative;

      height: 100%;
      white-space: nowrap;

      .exposeeItem {
        display: inline-flex;
        justify-content: center;
        align-items: flex-end;

        height: 100%;
        max-width: 10em;
        font-size: 1.75em;
        padding: 0 1em;

        color: var(--color_neutralText);
        &.selectedItem {
          color: var(--color_Weather3);
        }

        &.hoverStyle:hover {
          @media (hover: hover) {
            color: var(--color_Weather3);
          }
        }

        &:last-child {
          .separator {
            display: none;
          }
        }
      }
    }
  }
}
</style>
