<script lang="ts">
import { computed, defineComponent } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    mainColor: {
      type: String,
      default: "#000",
    },
  },
  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      const usedColor = props.mainColor;
      return {
        "--main-color": usedColor,
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="dialogErrorIcon" v-bind:style="colorVars">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-10 -5 228 225"
      style="enable-background: new -10 -5 228 225"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M106,6c55.1,0,100,44.9,100,100s-44.9,100-100,100S6,161.1,6,106S50.9,6,106,6 M106,0C47.5,0,0,47.5,0,106
			s47.5,106,106,106c58.5,0,106-47.5,106-106S164.5,0,106,0L106,0z"
          />
        </g>
        <g>
          <line x1="53.2" y1="158.8" x2="158.7" y2="53.3" />
          <line x1="53.2" y1="53.3" x2="158.7" y2="158.8" />
        </g>
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.dialogErrorIcon {
  width: 100%;
  height: 100%;

  svg {
    stroke: var(--main-color);
    stroke-width: 12;
    width: 100%;
    height: 100%;
    line {
      fill: none;
      stroke-miterlimit: 10;
    }
  }
}
</style>
