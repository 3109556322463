import { Application, IRoutesFactory, RoutesFactory } from "@bissantz/smartforms";
import { RouteConfig } from "vue-router";
import { Bootstrapping } from "./bootstrapping";
import LiveFeatureToggles from "./features/live-feature-toggles/live-feature-toggles.vue";

export class Routes implements IRoutesFactory {
  private _defaultRoutesFactory = new RoutesFactory();

  getRoutes(application: Application): RouteConfig[] {
    const defaultRoutes = this._defaultRoutesFactory.getRoutes(application, true);

    const appRoutes: Array<RouteConfig> = [];

    if (Bootstrapping.featureToggles.showLiveToggles)
      appRoutes.push({
        name: "live-toggles",
        path: "/toggles",
        component: LiveFeatureToggles,
      });

    return appRoutes.concat(defaultRoutes);
  }
}
