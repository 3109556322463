<script lang="ts">
import { defineComponent, onBeforeMount, reactive } from "vue";
import { ClickHelper } from "@/common/events/click-helper";
import { TouchHelper } from "@/common/events/touch-helper";

export class DefaultEvents {
  static emptyCellLeftClicked = "emptyCellLeftClicked";
}

class DefaultState {
  clickHelper = new ClickHelper();
  touchHelper = new TouchHelper();
}

export default defineComponent({
  components: {},
  emits: [],
  props: {
    isClickable: { type: Boolean, default: true },
  },
  setup(props, context) {
    const state = reactive(new DefaultState());

    //
    // Life Cycle:
    // --------------------
    onBeforeMount(() => {
      state.clickHelper.touchHelper = state.touchHelper;

      state.clickHelper.setOnLeftClickAction(() =>
        context.emit(DefaultEvents.emptyCellLeftClicked)
      );

      state.touchHelper.setTapAction(() =>
        context.emit(DefaultEvents.emptyCellLeftClicked)
      );
    });

    //
    // Computeds:
    // --------------------

    //
    // Functions:
    // --------------------

    //
    // Watcher:
    // --------------------

    return {
      state,
    };
  },
});
</script>

<template>
  <div
    class="empty-cell"
    v-bind:class="{ 'is-clickable': $props.isClickable }"
    v-on:mousedown="state.clickHelper.mouseDown($event)"
    v-on:mouseup="state.clickHelper.mouseUp($event)"
    v-on:touchstart="state.touchHelper.touchStart($event)"
    v-on:touchmove="state.touchHelper.cancelTouch($event)"
    v-on:touchend="state.touchHelper.touchEnd($event)"
  ></div>
</template>

<style scoped lang="less">
.empty-cell {
  width: 100%;
  height: 100%;

  &.is-clickable {
    cursor: pointer;
  }
}
</style>
