import { TileError } from "../errors";
import { DashboardId } from "@/features/dashboard-shared/dashboard-id";
import { IPortalTileFm, IDashboardTilePageFm } from "./facade-models";

export interface ITileDescriptorFm {
  portalTileFm: IPortalTileFm;
  portalTilePageFm: IDashboardTilePageFm;
  error: TileError;
}
export class TileDescriptorFm implements ITileDescriptorFm {
  portalTileFm: IPortalTileFm = null;
  portalTilePageFm: IDashboardTilePageFm = null;
  error: TileError = null;

  get key(): string {
    return (
      this.portalTileFm.portalPageId +
      this.portalTileFm.id +
      this.portalTilePageFm.dashboardId
    );
  }

  static fromJS(td: ITileDescriptorFm): TileDescriptorFm {
    const result = new TileDescriptorFm();
    result.error = td.error;
    result.portalTileFm = td.portalTileFm;
    result.portalTilePageFm = td.portalTilePageFm;

    return result;
  }

  getDashboardId(): DashboardId {
    return new DashboardId(
      this.portalTilePageFm?.publishedApplicationId ?? null,
      this.portalTilePageFm?.dashboardId ?? null
    );
  }
}
