<script lang="ts">
import ZoomSlider from "./zoom-slider.vue";
import PageHintList from "./page-hints/page-hint-list.vue";

import { StatusBarService } from "@/services/status-bar-service";
import { IDisposable } from "@/common/disposable.interface";
import { DefaultStyles } from "@/common/styles/default-styles";
import { isMobile } from "@/common/browser-detection";
import { resizeHelper, Unobserver } from "@/common/resize-helper";

import {
  defineComponent,
  computed,
  ComputedRef,
  inject,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  reactive,
  ref,
} from "vue";

class PortalFooterState {
  showText = false;
  statusText = "";
  showsBgColor = false;
  disposeListeners = [] as IDisposable[];
  defaultStyles = DefaultStyles;
  canShowPageHints = false;
  maxAvailablePageHintWidthInPixels: number = 0;
}

export default defineComponent({
  components: {
    ZoomSlider,
    PageHintList,
  },

  setup() {
    const statusBarService: StatusBarService = inject("statusBarService");

    const state = reactive(new PortalFooterState()) as PortalFooterState;

    let unobserveCallback: Unobserver = null;
    const portalFooterDiv = ref<HTMLDivElement>();
    const statusDisplayAreaDiv = ref<HTMLDivElement>();

    const showZoom: ComputedRef<boolean> = computed(() => {
      if (isMobile()) {
        return false;
      }
      return !state.showText;
    });

    onBeforeMount(() => {
      // change listeners for page hints:
      state.disposeListeners.push(
        statusBarService.backgroundColorChanged.on(onBackgroundColorChanged)
      );

      state.disposeListeners.push(
        statusBarService.pageTitlesChanged.on(updateShowPageHints)
      );

      // change listeners for status text
      state.disposeListeners.push(statusBarService.showTextTriggered.on(onShowText));
      state.disposeListeners.push(statusBarService.hideTextTriggered.on(onHideText));
    });

    onMounted(() => {
      state.maxAvailablePageHintWidthInPixels = getMaxAvailableWidthForPageHints();

      unobserveCallback = resizeHelper.observe(
        portalFooterDiv.value,
        onStatusDisplayAreaResize
      );
    });

    onBeforeUnmount(() => {
      state.disposeListeners.map((dl) => dl?.dispose());

      unobserveCallback?.();
    });

    const showPageHints = computed(() => {
      return state.canShowPageHints && !state.showText;
    });

    const displayAreaJustify = computed(() => {
      return state.showText ? "flex-start" : "center";
    });

    function updateShowPageHints() {
      const hasMultiplePages = statusBarService.currentPageHints.pageTitles.length > 1;
      state.canShowPageHints = hasMultiplePages;
    }

    function onBackgroundColorChanged(color: string): void {
      state.showsBgColor = color ? true : false;
    }

    function onShowText(text: string) {
      state.statusText = text;
      state.showText = true;
    }

    function onHideText() {
      state.showText = false;
    }

    function onStatusDisplayAreaResize() {
      state.maxAvailablePageHintWidthInPixels = getMaxAvailableWidthForPageHints();
    }

    function getMaxAvailableWidthForPageHints(): number {
      return statusDisplayAreaDiv.value.clientWidth;
    }

    return {
      // state &...:
      state,
      statusBarService,
      portalFooterDiv,
      statusDisplayAreaDiv,

      // computed
      showZoom,
      showPageHints,
      displayAreaJustify,
    };
  },
});
</script>

<template>
  <div
    ref="portalFooterDiv"
    class="portal-footer"
    v-bind:class="{ 'shows-bg-color': state.showsBgColor }"
  >
    <div v-if="!state.showText" class="spacer">
      <ZoomSlider v-if="showZoom" />
    </div>
    <div ref="statusDisplayAreaDiv" class="status-display-area">
      <PageHintList
        class="page-hint-list-visible"
        v-bind:showsBgColor="state.showsBgColor"
        v-bind:maxAvailableWidthInPixels="state.maxAvailablePageHintWidthInPixels"
        v-if="showPageHints"
      />
      <div class="portal-status-text" v-else-if="state.showText">
        <span class="portal-status-text-span">{{ state.statusText }}</span>
      </div>
    </div>

    <div class="spacer invisible" v-bind:class="{ 'spacer-with-icon': true }" />
  </div>
</template>

<style lang="less" scoped>
@import "../../common/styles/media-queries.less";
@import "../../common/styles/fonts.less";

.portal-footer {
  height: 40px;
  font-size: calc(0.5rem + ((1vw - 4.8px) * 0.5556));
  width: calc(100% - 80px);
  padding: 0 40px;
  display: flex;
  justify-content: space-between;

  .spacer {
    flex: 0 0 7em;
    .media_isAtleastTablet({
        flex-basis: 15rem;
    });
  }

  .spacer-with-icon {
    display: flex;
    justify-content: flex-start;
    margin-top: 7px;
  }

  .status-display-area {
    height: 100%;
    display: flex;
    flex: 1 1;
    overflow: hidden;
    justify-content: v-bind(displayAreaJustify);

    .page-hint-list-visible {
      @media (max-width: 669px) {
        display: none;
      }
    }

    .portal-status-text {
      height: 100%;
      font-size: 14px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: var(--color_neutralText);

      .shows-bg-color& {
        color: var(--color_bg_white);
      }

      .portal-status-text-span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>
