import {
  KpiDrillRowFm,
  KpiDrillStructureFilterFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { ElementVm } from "../element-vm";
import { SharedDrillInfoVm } from "../shared/shared-drill-info-vm";
import { SharedKpiInfo } from "../shared/shared-kpi-info";
import { SharedRowStateVm } from "../shared/shared-row-state-vm";
import { StructureElementsBuilder } from "./structure-elements-builder";
import { CloneHelper } from "@/common/object-helper/clone-helper";
import { ValueBuilder } from "./value-builder";
import { SharedValuesVm } from "../shared/shared-values-vm";
import { StructureVm } from "../structure-vm";
import { StructureElementsVm } from "../structure-elements-vm";
import { ValueVm } from "../value-vm";
import { IDashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade.interface";

export class ElementBuilder {
  private _valueBuilder: ValueBuilder = new ValueBuilder();
  private _structureElementsBuilder = new StructureElementsBuilder();

  createElements(
    drillRowFms: KpiDrillRowFm[],
    sharedKpiInfo: SharedKpiInfo,
    sharedDrillInfo: SharedDrillInfoVm,
    parentRowState: SharedRowStateVm,
    structureNameId: string,
    nextStructureVm: StructureVm[],
    previousFilters: KpiDrillStructureFilterFm[],
    dashboardFacade: IDashboardFacade = null
  ): ElementVm[] {
    return drillRowFms.map((rowFm) =>
      this.createElement(
        rowFm,
        sharedKpiInfo,
        sharedDrillInfo,
        parentRowState,
        structureNameId,
        nextStructureVm,
        previousFilters,
        dashboardFacade
      )
    );
  }

  createElement(
    elemFm: KpiDrillRowFm,
    sharedKpiInfo: SharedKpiInfo,
    sharedDrillInfo: SharedDrillInfoVm,
    parentRowState: SharedRowStateVm,
    structureNameId: string,
    nextStructureVm: StructureVm[],
    previousFilters: KpiDrillStructureFilterFm[],
    dashboardFacade: IDashboardFacade = null
  ): ElementVm {
    const elementVm = new ElementVm();
    elementVm.backingFm = elemFm;
    elementVm.id = elemFm.structureElement.id;
    elementVm.name = elemFm.structureElement.displayName;
    elementVm.structureNameId = structureNameId;
    elementVm.sharedDrillInfo = sharedDrillInfo;
    elementVm.sharedKpiInfo = sharedKpiInfo;
    elementVm.parentRowState = parentRowState;
    elementVm.elementValues = this._createValueVms(sharedKpiInfo, parentRowState, elemFm);

    elementVm.previousFilters = previousFilters;
    elementVm.nextStructureVms = nextStructureVm;
    elementVm.nextStructureElements = this._createNextStructureElements(
      elementVm,
      sharedKpiInfo,
      sharedDrillInfo,
      dashboardFacade
    );

    return elementVm;
  }

  private _createValueVms(
    sharedKpiInfo: SharedKpiInfo,
    parentRowState: SharedRowStateVm,
    elemFm: KpiDrillRowFm
  ): ValueVm[] {
    const valueVms = this._valueBuilder.createValueVms(
      elemFm.kpiValues,
      sharedKpiInfo,
      parentRowState.period,
      elemFm.unit
    );
    valueVms.map((val) => (val.parentRowState = parentRowState));
    return valueVms;
  }

  private _createNextStructureElements(
    elementVm: ElementVm,
    sharedKpiInfo: SharedKpiInfo,
    sharedDrillInfo: SharedDrillInfoVm,
    dashboardFacade: IDashboardFacade
  ): StructureElementsVm {
    const nextParentRowState = this._createSharedRowStateVm(elementVm);

    return this._structureElementsBuilder.createStructureElements(
      sharedDrillInfo,
      sharedKpiInfo,
      nextParentRowState,
      dashboardFacade
    );
  }

  private _createSharedRowStateVm(elementVm: ElementVm): SharedRowStateVm {
    const parentValues: SharedValuesVm[] = [];
    const parentValue = new SharedValuesVm(
      elementVm.elementValues.map((val) => val.value)
    );
    parentValues.push(parentValue);

    if (elementVm.elementValues[0].hasSparkline) {
      for (
        let j = 1;
        j < elementVm.elementValues[0].sparkline.sparkBarValues.length;
        j++
      ) {
        const values: number[] = [];
        for (let i = 0; i < elementVm.elementValues.length; i++) {
          if (elementVm.elementValues[i].hasSparkline) {
            values.push(elementVm.elementValues[i].sparkline.sparkBarValues[j].value);
          }
        }
        const parentRowState = new SharedValuesVm(values);
        parentValues.push(parentRowState);
      }
    }

    const parentRowState = new SharedRowStateVm(parentValues);
    parentRowState.name = elementVm.name;
    return parentRowState;
  }

  clone(
    original: ElementVm,
    cloneDrillInfo: SharedDrillInfoVm,
    cloneKpiInfo: SharedKpiInfo,
    cloneParentRowState: SharedRowStateVm
  ): ElementVm {
    const clone = new ElementVm();

    clone.backingFm = CloneHelper.clone(original.backingFm);

    clone.id = original.id;
    clone.name = original.name;
    clone.structureNameId = original.structureNameId;

    clone.sharedDrillInfo = cloneDrillInfo;
    clone.sharedKpiInfo = cloneKpiInfo;
    clone.parentRowState = cloneParentRowState;

    clone.elementValues = original.elementValues.map((val) =>
      this._valueBuilder.clone(val, cloneKpiInfo, cloneParentRowState)
    );

    const nextParentRowState = original.nextStructureElements.parentRowState.clone();
    clone.nextStructureElements = this._structureElementsBuilder.clone(
      original.nextStructureElements,
      cloneDrillInfo,
      cloneKpiInfo,
      nextParentRowState
    );
    clone.nextStructureVms = original.nextStructureVms.map(
      (structureVm) => new StructureVm(structureVm)
    );

    return clone;
  }
}
