﻿<script lang="ts">
import { reactive, defineComponent, provide, onBeforeUnmount, watch } from "vue";
import { useRoute } from "vue2-helpers/vue-router";

import { MediaQueries } from "./common/styles/media-queries";

import AppLabel from "@/app-label.vue";
import Portal from "@/features/portal/portal.vue";
import PortalFooter from "@/features/portal-footer/portal-footer.vue";
import PortalMenu from "@/features/main-menu/portal-menu.vue";
import MenuFooter from "@/features/main-menu/menu-footer.vue";
import NotificationHost from "@/features/notifications/notification-host.vue";
import ModalApplicationWizard from "@/features/application-wizard/modal-application-wizard.vue";

import { SessionVm } from "./session-vm";
import { portalSelection } from "@/features/portal/view-models/portal-selection-vm";
import { Bootstrapping } from "./bootstrapping";

import {
  UserLanguageCode,
  IFeatureTogglesDto,
} from "@/common/service-clients/generated-clients";
import {
  combinePaths,
  FormsAuthentication,
  getBaseUrl,
  smartformsStore,
} from "@bissantz/smartforms";

import { getCommonAxiosInstance } from "@/common/common-axios-instance";
import { getUserFromToken } from "./common/internal-auth-provider";
import { IDisposable } from "./common/disposable.interface";

import { LiveFeatureTogglesVm } from "./features/live-feature-toggles/live-feature-toggles-vm";
import { IMediaQueries } from "./common/styles/media-queries.interface";
import { ILogger } from "./common/logging/logger.interface";
import { getLogger } from "./common/logging/logging-instance";
import { appResources, switchAppResourceLanguage } from "./app-resources";
import { IBackgroundChangeService } from "./services/background-change-service.interface";
import { BackgroundChangeService } from "./services/background-change-service";
import { IInputEventsService } from "./services/input-events-service.interface";
import { InputEventsService } from "./services/input-events-service";
import { Dm7App } from "./app";
import { StatusBarService } from "./services/status-bar-service";
import { IStatusBarService } from "./services/status-bar-service.interface";
import { IZoomService } from "./services/zoom-service.interface";
import { ZoomService } from "./services/zoom-service";
import { IChatService } from "@/services/chat-service/chat-service.interface";
import { ChatService } from "@/services/chat-service/chat-service";
import { INotificationService } from "./features/notifications/notification-service.interface";
import { NotificationService } from "./features/notifications/notification-service";
import { getBrowserType, BrowserType } from "./common/browser-detection";
import { AppWizardSwitch } from "./features/application-wizard/app-wizard-switch";
import { HelpTextService } from "./services/help-text-service/help-text-service";
import { getUsedServiceClients } from "./used-service-clients";
import { useVsSyncerScope } from "./services/view-state-service/vs-syncer-scope-cpsl";

export default defineComponent({
  components: {
    AppLabel,
    PortalFooter,
    Portal,
    PortalMenu,
    MenuFooter,
    NotificationHost,
    ModalApplicationWizard,
  },

  setup() {
    // Static Axios-instance must be provided, so that (e.g.) authorization tokens can be refreshed
    // for all clients. Otherwise, they automatically create their own local axios instance (copy).
    const axiosInstance = getCommonAxiosInstance();
    provide("axiosInstance", axiosInstance);

    // Service Clients (services)
    const clients = getUsedServiceClients(axiosInstance);

    provide("transactionsClient", clients.transactionsClient);
    provide("statusClient", clients.statusClient);
    provide("helpClient", clients.helpClient);

    // Frontend Services
    const featureToggles: IFeatureTogglesDto = Bootstrapping.featureToggles;
    provide("featureToggles", featureToggles);
    const statusBarService: IStatusBarService = new StatusBarService();
    provide("statusBarService", statusBarService);
    const zoomService: IZoomService = new ZoomService(
      getBrowserType() === ("safari" as BrowserType)
    );
    provide("zoomService", zoomService);
    const chatService: IChatService = reactive(new ChatService()) as IChatService;
    provide("chatService", chatService);
    const notificationService: INotificationService = new NotificationService();
    provide("notificationService", notificationService);
    const helpTextService: HelpTextService = new HelpTextService(statusBarService);
    provide("helpTextService", helpTextService);
    const mediaQueries: IMediaQueries = new MediaQueries();
    provide("mediaQueries", mediaQueries);
    const logger: ILogger = getLogger();
    provide("logger", logger);
    const appWizardSwitch = reactive(new AppWizardSwitch());
    provide("appWizardSwitch", appWizardSwitch);
    const inputEventsService: IInputEventsService = new InputEventsService();
    provide("inputEventsService", inputEventsService);
    const liveToggles: LiveFeatureTogglesVm = reactive(
      new LiveFeatureTogglesVm(Bootstrapping.featureToggles.showLiveToggles)
    ) as LiveFeatureTogglesVm;
    provide("liveToggles", liveToggles);

    const backgroundChangeService: IBackgroundChangeService =
      new BackgroundChangeService();
    provide("backgroundChangeService", backgroundChangeService);
    provide("portalSelection", portalSelection);

    useVsSyncerScope();

    let session: SessionVm = null;
    let application: Dm7App = null;
    const route = useRoute();

    const _mediaListener: IDisposable = null;

    // Vue Life Cycle
    function init() {
      if (!(smartformsStore.application instanceof Dm7App)) throw "DM7App type expected";

      application = reactive(smartformsStore.application) as object as Dm7App;
      provide("application", application);
      session = reactive(application.session);
      session.isEmbeddedMode = !application.userInfoOptions.show;
      provide("session", session);

      application.toc.onHeaderClicked = _tocHeaderClicked;

      processRouteParams();

      document.addEventListener("contextmenu", (ev) => ev.preventDefault());
    }
    init();

    onBeforeUnmount(() => {
      if (_mediaListener) _mediaListener.dispose();
    });

    function _tocHeaderClicked() {
      application.content.find((c) => c.contentId == "portal").navigate();
    }

    watch(() => application.language, onApplicationLanguageChanged, {
      deep: true,
      immediate: true,
    });
    async function onApplicationLanguageChanged() {
      const language = application.language;
      switchAppResourceLanguage(language);
      helpTextService.setOverlappingTexts();
      application.userInfoOptions.formsAuthDisplayName =
        appResources.generalTexts.formsAuthDisplayName;

      if (Bootstrapping.apiSettings.authenticationMode === "Internal") {
        if (application.user && language !== application.user?.language) {
          const token = await clients.userServiceClient.updateUserLanguage(
            language as UserLanguageCode
          );
          // forms auth in this case means internal auth provider
          if (token && application.authentication instanceof FormsAuthentication) {
            try {
              const user = getUserFromToken(token);
              if (user) {
                user.rememberLogin = application.user.rememberLogin;
                application.user = user;
                application.storage.setUser(user, user.rememberLogin);
              }
            } catch (exception) {
              console.error(exception);
            }
          }
        }
      }
    }

    watch(() => application.dataScope?.path, onDataScopePathChanged, { deep: true });
    function onDataScopePathChanged() {
      const baseUrl = getBaseUrl();
      if (Bootstrapping.apiSettings.authenticationMode == "Internal")
        application.userAdminLink = combinePaths(
          "/",
          combinePaths(baseUrl, "/admin/internal-users")
        );
      else
        application.userAdminLink = combinePaths(
          "/",
          combinePaths(baseUrl, combinePaths("/admin/", application.dataScope.path))
        );
    }

    watch(() => route, processRouteParams, { deep: true });
    async function processRouteParams() {
      // only additional route params are handled here
      // starting 2.1.5. SmartForms supports auto-updating routeParams (route-to-variable)
      // see https://bc-tfs.activedirectory.bissantz.de/tfs/DefaultCollection/ExternalTeam/_workitems/edit/22005

      if (route.query.menu && route.query.menu === "none") {
        application.noMainMenu = true;
      }
    }

    return {
      application,
      liveToggles,
      appWizardSwitch,
      route,
    };
  },
});
</script>

<template>
  <div class="app-root">
    <router-view ref="routerView" v-bind:application="application">
      <template v-slot:app-label>
        <AppLabel />
      </template>
      <template v-slot:footer> </template>
      <template v-slot:portal-footer>
        <PortalFooter />
      </template>
      <template v-slot:menu-footer>
        <MenuFooter />
      </template>
      <template v-slot:portal>
        <Portal v-bind:key="route.path" />
      </template>
      <template v-slot:portal-menu>
        <PortalMenu />
      </template>
    </router-view>
    <NotificationHost
      v-bind:zIndex="1000"
      v-bind:addDemos="liveToggles.toggleValues.notificationDemos"
    />
    <ModalApplicationWizard v-show="appWizardSwitch.enabled" />
  </div>
</template>

<style lang="less">
// explicitly not scoped: base-styles.less should be applied everywhere
@import "./common/styles/base-styles.less";

#app #startup {
  color: darkgrey;
  font-size: 10.25pt;
  font-family: @fontLight;
  padding: 10px;
}
</style>
