<script lang="ts">
import { defineComponent, PropType, computed, inject } from "vue";
import SimpleGrid from "@/common/components/simple-grid/simple-grid.vue";
import Swiper from "@/common/components/swiper.vue";
import SwipeableSortCell from "@/common/components/cell-basics/swipeable-sort-cell.vue";
import SortCell, { SortCellType } from "@/common/components/cell-basics/sort-cell.vue";
import { SwiperVm } from "@/common/components/swiper-vm";
import { IMediaQueries } from "@/common/styles/media-queries.interface";
import { Cell } from "@/common/components/simple-grid/contracts/cell";
import { GridIds } from "@/features/dashboard/status-configs/grid-ids";
import type { CSSProperties } from "vue/types/jsx";
import { useHelpText } from "@/services/help-text-service/help-text-cpsl";

export default defineComponent({
  components: { SwipeableSortCell, SortCell, Swiper, SimpleGrid },
  emits: [],
  props: {
    swiperVm: { type: Object as PropType<SwiperVm | null>, required: true },
    itemsVms: { type: Array as PropType<string[]>, required: true },
    zoomFactor: { type: Number, required: false, default: 1 },
    cells: { type: Array as PropType<Array<Cell>>, required: true },
    widths: { type: Array as PropType<string[]>, required: true },
    defaultHeight: { type: String, required: true },
    iconColor: { type: String, required: true },
    drillSpacerWidth: { type: Number, default: 0 },
    sortedColumn: { type: Number, required: true },
    cellSortTypes: {
      type: Array as PropType<SortCellType[]>,
      required: true,
    },
    deltaSortSwiperVm: { type: SwiperVm, default: null },
    deltaSortSwipeItems: { type: Array as PropType<number[]>, default: null },
  },
  setup(props) {
    const mediaQueries: IMediaQueries = inject("mediaQueries");
    const helpTextCpsl = useHelpText();
    const gridIds = GridIds;

    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const sortBarHeight = computed<string[]>(() =>
      mediaQueries.isMouse ? ["10px"] : [props.defaultHeight]
    );

    const sortBarIconColor = computed<string>(() =>
      mediaQueries.isMouse ? props.iconColor : "transparent"
    );

    const sortTitleStyle = computed<CSSProperties>(() => {
      return { marginLeft: props.drillSpacerWidth + "px", paddingLeft: "11px" };
    });

    const sortValueStyle = computed<CSSProperties>(() => {
      return { justifyContent: "flex-end", paddingRight: "20%" };
    });

    //
    // Functions:
    // --------------------

    //
    // Watcher:
    // --------------------
    return {
      helpTextCpsl,
      gridIds,
      sortBarHeight,
      sortBarIconColor,
      sortTitleStyle,
      sortValueStyle,
    };
  },
});
</script>

<template>
  <Swiper
    class="sort-bar"
    v-bind:swiperVm="swiperVm"
    v-bind:itemsVms="$props.itemsVms"
    v-bind:zoomFactor="$props.zoomFactor"
    v-bind:data-helpText="helpTextCpsl.sortElementsText()"
  >
    <template v-slot:swipeItems="sProps">
      <SimpleGrid
        v-bind:key="sProps.index"
        v-bind:cells="$props.cells"
        v-bind:widths="$props.widths"
        v-bind:heights="sortBarHeight"
        v-on="$listeners"
      >
        <SortCell
          v-bind:data-grid-id="gridIds.sortTitle"
          v-bind:color="sortBarIconColor"
          v-bind:clickAreaStyle="sortTitleStyle"
          v-bind:binarySort="false"
          v-bind:index="0"
          v-bind:sortedIndex="$props.sortedColumn"
          v-bind:sortType="$props.cellSortTypes[0]"
          v-on="$listeners"
        />
        <SortCell
          v-bind:data-grid-id="gridIds.sortInformation"
          v-bind:color="sortBarIconColor"
          v-bind:clickAreaStyle="sortValueStyle"
          v-bind:binarySort="true"
          v-bind:index="1"
          v-bind:sortedIndex="$props.sortedColumn"
          v-bind:sortType="$props.cellSortTypes[1]"
          v-on="$listeners"
        />
        <SwipeableSortCell
          v-if="$props.cellSortTypes.length > 2 && $props.deltaSortSwipeItems.length > 0"
          v-bind:data-grid-id="gridIds.sortDeltas"
          v-bind:swiperVm="$props.deltaSortSwiperVm"
          v-bind:swipeItems="$props.deltaSortSwipeItems"
          v-bind:zoomFactor="$props.zoomFactor"
          v-bind:color="sortBarIconColor"
          v-bind:clickAreaStyle="sortValueStyle"
          v-bind:binarySort="true"
          v-bind:sortedIndex="$props.sortedColumn"
          v-bind:sortTypes="$props.cellSortTypes.slice(2, $props.cellSortTypes.length)"
          v-on="$listeners"
        />
        <SortCell
          v-if="$props.cellSortTypes.length > 2"
          v-bind:data-grid-id="gridIds.sortDelta1"
          v-bind:color="sortBarIconColor"
          v-bind:clickAreaStyle="sortValueStyle"
          v-bind:binarySort="true"
          v-bind:index="3"
          v-bind:sortedIndex="$props.sortedColumn"
          v-bind:sortType="$props.cellSortTypes[3]"
          v-on="$listeners"
        />
        <SortCell
          v-if="$props.cellSortTypes.length > 2"
          v-bind:data-grid-id="gridIds.sortDelta2"
          v-bind:color="sortBarIconColor"
          v-bind:clickAreaStyle="sortValueStyle"
          v-bind:binarySort="true"
          v-bind:index="2"
          v-bind:sortedIndex="$props.sortedColumn"
          v-bind:sortType="$props.cellSortTypes[2]"
          v-on="$listeners"
        />
      </SimpleGrid>
    </template>
  </Swiper>
</template>

<style scoped lang="less">
.sort-bar {
}
</style>
