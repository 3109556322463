<script lang="ts">
import { defineComponent } from "vue";
import { BcMenuItem as MenuItem } from "@bissantz/controls";
import HideAndShow from "@/common/components/hide-and-show.vue";
import RotatableArrowWithText from "./rotatable-arrow-with-text.vue";
import { TreeNodeVm } from "./trees/tree-node-vm";
import AppWizardSpinner from "../../../helper-components/app-wizard-spinner.vue";

export default defineComponent({
  name: "TreeNode",

  components: {
    MenuItem,
    HideAndShow,
    AppWizardSpinner: AppWizardSpinner,
    RotatableArrowWithText,
  },

  props: {
    model: { type: TreeNodeVm, required: true },
  },

  setup(props) {
    async function toggle() {
      await props.model.toggleAsync();
    }

    return {
      toggle: toggle,
    };
  },
});
</script>
<template>
  <MenuItem v-bind:transitionDuration="200" v-on:click="toggle">
    <template v-slot:title>
      <RotatableArrowWithText
        v-show="!$props.model.isSelectable"
        v-bind:isRotated="$props.model.isOpen"
        v-bind:text="$props.model.name"
        v-bind:addAdditionalSpaceToTheTop="!$props.model.isRoot"
        v-bind:changeTextColorOnRotation="!$props.model.dataLoadingFailed"
        v-bind:class="{
          'data-loading-failed': $props.model.dataLoadingFailed,
        }"
      />
      <HideAndShow
        v-show="$props.model.isSelectable"
        class="application-wizard_tree-node distance-top distance-left clickable-item"
        v-bind:class="{
          'is-selected': $props.model.isSelected,
        }"
        >{{ $props.model.name }}</HideAndShow
      >
      <AppWizardSpinner v-show="$props.model.isLoadingData" />
    </template>
    <template v-slot:component>
      <TreeNode
        v-for="child in $props.model.children"
        class="application-wizard_tree-node distance-left clickable-item"
        v-bind:model="child"
        v-bind:key="child.id"
      />
    </template>
  </MenuItem>
</template>

<style lang="less" scoped>
@import "../../../../../common/styles/base-styles.less";
@import "./report-modelling.less";

.application-wizard_tree-node {
  .distance-top.distance-left {
    padding-left: 15px;
  }

  .data-loading-failed {
    color: red;
  }
}
</style>
