import { TimeStructureTypeFm } from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";
import { SparklineMode } from "@/features/dashboard-shared/sparkline";

export class SharedSparklineState {
  private _mode: SparklineMode = "disabled";
  private _prevMode: SparklineMode = "disabled";
  private _canShowSparklines: boolean = false;
  private _disableAnimationOnlyOnce: boolean = false;
  private _isDisabling: boolean = false;
  private _numAnimatingSparklines: number = 0;
  private _animationsPossible: boolean = true;
  private _maxNumBars: number = 13; // The total number of bars, all the bars

  globalSparklinesEnabled: boolean = false;
  deltaSparklinesEnabled: boolean = false;

  animationsCurrentlyEnabled: boolean = true;
  scrollPosPixel = 0;

  // The maximum number of bars the user is going to see at a time.
  // Ex: maxNumBars 5. maxNumVisibleBars: 3. E D [ C B A ], or after scroll
  // one bar: E [ D C B ] A. The user can see 3 bars, the other two are hidden.
  maxNumVisibleBars: number = 13;

  barMargin: number = 2;
  barWidth: number = 8;

  get maxNumBars(): number {
    return this._maxNumBars;
  }

  set maxNumBars(value: number) {
    this._maxNumBars = Math.max(this.maxNumVisibleBars, value);
  }

  get widthPixel(): number {
    const numberOfBars = Math.min(this.maxNumBars, 13);
    const effBarWidth = this.barMargin + this.barWidth;

    return effBarWidth * numberOfBars + this.barMargin;
  }

  set mode(value: SparklineMode) {
    this._prevMode = this._mode;
    this._mode = value;
  }

  set animationsPossible(value: boolean) {
    this._animationsPossible = value;
    if (!value) {
      this.animationsCurrentlyEnabled = false;
    }
  }

  get mode(): SparklineMode {
    return this._mode;
  }

  get preMode(): SparklineMode {
    return this._prevMode;
  }

  get isDisabled(): boolean {
    return this.mode === "disabled";
  }

  selection: {
    [key in TimeStructureTypeFm]: {
      isMouseHoverEnabled: boolean;
      isSelectionFixed: boolean;
      historyIndex: number;
    };
  };

  get isAnyAnimating(): boolean {
    return this._numAnimatingSparklines > 0;
  }

  set numAnimatingSparklines(value: number) {
    this._numAnimatingSparklines = value;

    const changedToZero = this._numAnimatingSparklines === 0;
    if (changedToZero) {
      this._onAnimationFinished();
    }
  }

  get numAnimatingSparklines(): number {
    return this._numAnimatingSparklines;
  }

  get showSparklines(): boolean {
    if (this.isAnyAnimating || this._isDisabling) {
      return true;
    }

    return this.mode !== "disabled";
  }

  get canShowSparklines(): boolean {
    return this._canShowSparklines;
  }

  set canShowSparklines(canShow: boolean) {
    this._canShowSparklines = canShow;

    const isDisabled = this.mode === "disabled";
    if (!canShow && !isDisabled) {
      this.disableSparklines();
    }
  }

  constructor() {
    const selectionDefault = {
      historyIndex: 0,
      isMouseHoverEnabled: false,
      isSelectionFixed: true,
    };
    this.selection = {
      Day: Object.assign({}, selectionDefault),
      Week: Object.assign({}, selectionDefault),
      Month: Object.assign({}, selectionDefault),
      Quarter: Object.assign({}, selectionDefault),
      Year: Object.assign({}, selectionDefault),
      Other: Object.assign({}, selectionDefault),
    };
  }

  toggleSparklinesMode(globalSparklinesEnabled: boolean): void {
    if (!this.canShowSparklines) {
      this.mode = "disabled";
      return;
    }

    if (this.isDisabled) {
      this.mode = globalSparklinesEnabled ? "global" : "local";
      return;
    }

    if (this.mode === "global") {
      this.mode = "local";
      return;
    }

    if (this.mode === "local") {
      this.disableSparklines();
      return;
    }
  }

  disableSparklines(): void {
    if (this.mode === "disabled") {
      return;
    }

    if (this.animationsCurrentlyEnabled) {
      this._isDisabling = true;
    }

    this.mode = "disabled";
  }

  setSelection(historyIndex: number): void {
    for (const key in this.selection) {
      const val = this.selection[key as TimeStructureTypeFm];
      val.historyIndex = historyIndex;
    }
  }

  setSparklinesSelectionMode(enabled: boolean): void {
    for (const key in this.selection) {
      const val = this.selection[key as TimeStructureTypeFm];
      val.isMouseHoverEnabled = enabled;
    }
  }

  enableSparklinesSelectionMode(): void {
    const enabled = true;
    this.setSparklinesSelectionMode(enabled);
  }

  resetSelection() {
    const historyIndex = 0;
    this.setSelection(historyIndex);

    const enabled = false;
    this.setSparklinesSelectionMode(enabled);
  }

  clone(): SharedSparklineState {
    const result = new SharedSparklineState();

    result._mode = this._mode;
    result._prevMode = this._prevMode;
    result._canShowSparklines = this._canShowSparklines;

    result.globalSparklinesEnabled = this.globalSparklinesEnabled;
    result.deltaSparklinesEnabled = this.deltaSparklinesEnabled;
    result._animationsPossible = this._animationsPossible;
    result.animationsCurrentlyEnabled = this.animationsCurrentlyEnabled;
    result._disableAnimationOnlyOnce = this._disableAnimationOnlyOnce;

    result.scrollPosPixel = this.scrollPosPixel;
    result.maxNumBars = this.maxNumBars;
    result.maxNumVisibleBars = this.maxNumVisibleBars;
    result.barMargin = this.barMargin;
    result.barWidth = this.barWidth;

    for (const key in this.selection) {
      const select = result.selection[key as TimeStructureTypeFm];
      const orig = this.selection[key as TimeStructureTypeFm];

      select.historyIndex = orig.historyIndex;
      select.isMouseHoverEnabled = orig.isMouseHoverEnabled;
      select.isSelectionFixed = orig.isSelectionFixed;
    }

    return result;
  }

  disableAnimationOnce(): void {
    this._disableAnimationOnlyOnce = true;
    this.animationsCurrentlyEnabled = false;
  }

  private _onAnimationFinished(): void {
    if (this._disableAnimationOnlyOnce) {
      this._disableAnimationOnlyOnce = false;
      this.animationsCurrentlyEnabled = this._animationsPossible;
      return;
    }

    if (!this._isDisabling) {
      return;
    }

    this._isDisabling = false;
  }
}
