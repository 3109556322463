<script lang="ts">
import HideAndShow from "@/common/components/hide-and-show.vue";
import HeightSetter from "@/features/dashboard-shared/height-setter.vue";
import { useHelpText } from "@/services/help-text-service/help-text-cpsl";

import { ValueGroupVm } from "../view-models/value-group-vm";
import { SharedDashboardStateVm } from "../view-models/shared/shared-dashboard-state-vm";
import { TouchHelper } from "@/common/events/touch-helper";
import { ClickHelper } from "@/common/events/click-helper";

import {
  ref,
  reactive,
  computed,
  onMounted,
  PropType,
  defineComponent,
  watch,
} from "vue";

class TitleWithFilterState {
  animateLastFilter: boolean = null;
  appliedFilters: string[] = [];
  clickHelper = new ClickHelper();
  touchHelper = new TouchHelper();
}

export default defineComponent({
  components: { HeightSetter, HideAndShow },

  props: {
    valueGroupVm: { type: Object as PropType<ValueGroupVm>, required: true },
    titleTextColor: { type: String, default: "" },
    sharedState: { type: Object as PropType<SharedDashboardStateVm>, required: true },
  },

  setup(props) {
    const ref_filtersTextAnimation = ref<HTMLDivElement | null>(null);
    const helpTextCpsl = useHelpText();
    const state = reactive(new TitleWithFilterState());

    //
    // Life Cycle:
    // --------------------
    onMounted(() => {
      state.clickHelper.touchHelper = state.touchHelper;
      state.touchHelper.setTapAction(removeLastFilter);
      state.clickHelper.setOnLeftClickAction(removeLastFilter);
      addAnimationEndEvent();
      setFilters();
    });

    //
    // Computeds:
    // --------------------
    const filtersText = computed<string>(() => {
      if (state.appliedFilters.length === 0) {
        return null;
      }

      const useFullLength = !state.animateLastFilter;
      const lastIdx = useFullLength ? state.appliedFilters.length : -1;
      const filters = state.appliedFilters.slice(0, lastIdx);

      if (filters.length > 0) {
        return ", " + filters.join(", ");
      }

      return null;
    });

    const lastFilterText = computed(() => {
      const hasFilters = state.appliedFilters.length !== 0;
      const isAnimating = state.animateLastFilter;

      if (!hasFilters || !isAnimating) {
        return null;
      }

      return ", " + state.appliedFilters.at(-1);
    });

    const animationStyle = computed(() => {
      if (!state.animateLastFilter) {
        return {};
      }

      return {
        "animation-name": "blinkKeyFrame",
      };
    });

    //
    // Functions:
    // --------------------
    async function setFilters(
      numFiltersNew: number = 0,
      numFiltersOld: number = 0
    ): Promise<void> {
      state.appliedFilters = await props.valueGroupVm.getAppliedFilterNames();
      state.animateLastFilter = isAnimationPossible(numFiltersNew, numFiltersOld);
    }
    function removeLastFilter(): void {
      props.valueGroupVm.drillInfo.dashboardFilter.removeLastFilter();
      state.animateLastFilter = false;
    }

    function addAnimationEndEvent(): void {
      const filtersTextAnimation = ref_filtersTextAnimation.value;
      filtersTextAnimation.onanimationend = onFilterTextAnimationEnd;
    }

    function onFilterTextAnimationEnd(ev: AnimationEvent): void {
      if (ev.animationName !== "blinkKeyFrame") {
        return;
      }

      state.animateLastFilter = false;
    }

    function isAnimationPossible(numFiltersNew: number, numFiltersOld: number): boolean {
      const animationIsEnabled = props.sharedState.animateSetFilter;
      return animationIsEnabled && numFiltersNew > numFiltersOld;
    }

    //
    // Watcher:
    // --------------------
    watch(() => props.valueGroupVm.drillInfo.dashboardFilter.filters.length, setFilters);

    return {
      state,
      helpTextCpsl,
      ref_filtersTextAnimation,
      filtersText,
      lastFilterText,
      animationStyle,
    };
  },
});
</script>

<template>
  <div
    class="dashboard_title-with-filter"
    v-bind:style="{ color: $props.titleTextColor }"
    v-bind:data-helpText="helpTextCpsl.removeFilterText(state.appliedFilters.length)"
    v-on:mousedown="state.clickHelper.mouseDown($event)"
    v-on:mouseup="state.clickHelper.mouseUp($event)"
    v-on:touchstart="state.touchHelper.touchStart($event)"
    v-on:touchend="state.touchHelper.touchEnd($event)"
  >
    <HideAndShow>
      {{ $props.valueGroupVm.title }}<span class="filters">{{ filtersText }}</span>
    </HideAndShow>
    <span>
      <HeightSetter />
      <span
        ref="ref_filtersTextAnimation"
        class="filters animation"
        v-bind:style="animationStyle"
        >{{ lastFilterText }}</span
      >
    </span>
  </div>
</template>

<style lang="less">
@import "../../../common/styles/fonts.less";

.dashboard_title-with-filter {
  display: flex;
  align-items: center;
  color: var(--color_neutralText);
  background-color: var(--color_bg_white);
  font-size: 1.45em;
  font-family: @fontSemi;
  height: var(--titleWithFilterHeight);
  border-bottom: solid 1px var(--color_bg_white);
  padding-left: 5px;

  & > span {
    font-family: @fontSemi;
  }

  .filters {
    font-size: 12px;
  }

  .animation {
    animation-duration: 1s;
  }

  @keyframes blinkKeyFrame {
    0%,
    49% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0;
    }
  }
}
</style>
