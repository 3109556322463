import { IFullLogLine as IFullLogLine } from "../log-entry.interface";

export const logStorageKey = "bissantzLogStorage";
// max allowed log size per session:
const maxLogKBSize = 300; //kb

export class SessionLogStorage {
  private static _logStorage: IFullLogLine[];
  private static _logSizeKB: number = 0;
  private static _isInit: boolean = false;

  private constructor() {}
  private static _init() {
    const existingLogStorage = (window as any)[logStorageKey];
    if (existingLogStorage) {
      SessionLogStorage._logStorage = existingLogStorage;
      SessionLogStorage._logSizeKB = SessionLogStorage._recalcLogSize();
    } else {
      SessionLogStorage._logStorage = [];
      (window as any)[logStorageKey] = SessionLogStorage._logStorage;
    }
    SessionLogStorage._isInit = true;
  }

  static writeToStorage(fullLogLine: IFullLogLine): void {
    if (!SessionLogStorage._isInit) SessionLogStorage._init();

    const entrySizeInKB = JSON.stringify(fullLogLine).length / 1024;
    SessionLogStorage._logStorage.push(fullLogLine);

    SessionLogStorage._logSizeKB += entrySizeInKB;

    while (SessionLogStorage._logSizeKB > maxLogKBSize) {
      const removed = SessionLogStorage._logStorage.shift();
      SessionLogStorage._logSizeKB -= JSON.stringify(removed).length / 1024;
    }
  }

  static clear(): void {
    SessionLogStorage._logStorage = [];
  }

  static getStorage(): IFullLogLine[] {
    if (!SessionLogStorage._isInit) return [];

    return this._logStorage;
  }

  // TODO: is this possible with better performance?
  private static _recalcLogSize(): number {
    const sizeInByte = JSON.stringify(SessionLogStorage._logStorage).length;
    return sizeInByte / 1024;
  }
}
