import { IModelFacade } from "../../backend-wrapper/model-facade.interface";
import { HierarchyLevel, Kpi, PeriodElement } from "../../backend-wrapper/dto-wrappers";
import { FailedReason } from "@/common/results/failed-reason";
import { TreeNodeVm } from "../../tabs/tab-components/modelling/trees/tree-node-vm";
import { TreeNodeEventNotifier } from "../../tabs/tab-components/modelling/trees/tree-node-event-notifier";
import { appResources } from "@/app-resources";
import { MeasureNodeLoader } from "../../tabs/tab-components/modelling/tree-loaders/measure-node-loader";
import { Result } from "@/common/results/result";
import { DimensionNodeLoader } from "../../tabs/tab-components/modelling/tree-loaders/dimension-node-loader";
import { PeriodHierarchyNodeLoader } from "../../tabs/tab-components/modelling/tree-loaders/period-node-loader";
import { DimensionRetriever } from "../../tabs/tab-components/modelling/tree-loaders/dimension-retriever";
import { exists } from "@/common/object-helper/null-helper";

export class NavigationVm {
  private readonly _modelFacade: IModelFacade;
  private readonly _currentPublishedApplication: string;
  private readonly _reportId: string;

  private _selectedLevels: HierarchyLevel[] = [];
  private _selectedPeriodElement: TreeNodeVm = null;
  private _selectedKpis: Kpi[] = [];
  private _periods: TreeNodeVm;
  private _dimensions: TreeNodeVm;
  private _kpis: TreeNodeVm;

  get selectedLevels(): HierarchyLevel[] {
    return this._selectedLevels;
  }

  get selectedKpis(): Kpi[] {
    return this._selectedKpis;
  }

  get selectedPeriodElementsDisplayText(): string {
    if (this._selectedPeriodElement) {
      const periodCaption = (this._selectedPeriodElement.value as PeriodElement).caption;
      return periodCaption;
    }
    return "";
  }

  get periods(): TreeNodeVm {
    return this._periods;
  }

  get dimensions(): TreeNodeVm {
    return this._dimensions;
  }

  get kpis(): TreeNodeVm {
    return this._kpis;
  }

  constructor(
    modelFacade: IModelFacade,
    currentPublishedApplication: string,
    semanticModelId: string,
    reportId: string
  ) {
    this._modelFacade = modelFacade;
    this._currentPublishedApplication = currentPublishedApplication;
    this._reportId = reportId;

    const dimensionRetriever = new DimensionRetriever(this._modelFacade, semanticModelId);

    this._periods = new TreeNodeVm(
      "allPeriods",
      appResources.applicationWizardTexts.modelling_periodHeader,
      new PeriodHierarchyNodeLoader(dimensionRetriever, currentPublishedApplication)
    );
    this._periods.eventNotifier = new TreeNodeEventNotifier();

    this._dimensions = new TreeNodeVm(
      "allDimensions",
      appResources.applicationWizardTexts.modelling_dimensionHeader,
      new DimensionNodeLoader(dimensionRetriever)
    );
    this._dimensions.eventNotifier = new TreeNodeEventNotifier();

    this._kpis = new TreeNodeVm(
      "allMeasures",
      appResources.applicationWizardTexts.modelling_measureHeader,
      new MeasureNodeLoader(this._modelFacade, semanticModelId)
    );
    this._kpis.eventNotifier = new TreeNodeEventNotifier();
  }

  togglePeriodElementSelection(elementNode: TreeNodeVm): void {
    NavigationVm._toggleSelection(
      elementNode,
      this.addPeriodElement.bind(this, elementNode),
      this.removePeriodElement.bind(this)
    );
  }

  addPeriodElement(elementNode: TreeNodeVm): void {
    if (!this._selectedPeriodElement) {
      this._selectedPeriodElement = elementNode;
      return;
    }

    this._selectedPeriodElement.isSelected = false;
    this._selectedPeriodElement = elementNode;
  }

  removePeriodElement(): void {
    this._selectedPeriodElement = null;
  }

  toggleLevelSelection(levelNode: TreeNodeVm): void {
    NavigationVm._toggleSelection(
      levelNode,
      this.addLevel.bind(this, levelNode.value),
      this.removeLevel.bind(this, levelNode.value)
    );
  }

  addLevel(level: HierarchyLevel): void {
    const newSelectedLevels = [];

    let newLevelHasBeenAdded = false;

    for (const existentLevel of this._selectedLevels) {
      if (
        level.hierarchyId === existentLevel.hierarchyId &&
        newLevelHasBeenAdded === false
      ) {
        if (level.levelOrdinal < existentLevel.levelOrdinal) {
          newSelectedLevels.push(level);
          newLevelHasBeenAdded = true;
        }
      }

      newSelectedLevels.push(existentLevel);
    }

    if (newLevelHasBeenAdded === false) {
      newSelectedLevels.push(level);
    }

    this._selectedLevels = newSelectedLevels;
  }

  removeLevel(level: HierarchyLevel): void {
    NavigationVm._removeItem(this._selectedLevels, level, (item) => item.id);
  }

  toggleKpiSelection(kpiNode: TreeNodeVm): void {
    NavigationVm._toggleSelection(
      kpiNode,
      this.addKpi.bind(this, kpiNode.value),
      this.removeKpi.bind(this, kpiNode.value)
    );
  }

  addKpi(kpi: Kpi): void {
    this._selectedKpis.push(kpi);
  }

  removeKpi(kpi: Kpi): void {
    NavigationVm._removeItem(this._selectedKpis, kpi, (item) => item.id);
  }

  async completeReportAsync(): Promise<Result<FailedReason>> {
    for (let kpiIndex = 0; kpiIndex < this._selectedKpis.length; kpiIndex++) {
      const result = await this._modelFacade.createDashboardReportKpiAsync(
        this._reportId,
        kpiIndex,
        this._selectedKpis[kpiIndex].id
      );

      if (!result.succeeded) {
        return result;
      }
    }

    for (const level of this._selectedLevels) {
      const result = await this._modelFacade.createDashboardReportNavigationAsync(
        this._reportId,
        level.id
      );

      if (!result.succeeded) {
        return result;
      }
    }

    if (this._selectedPeriodElement !== null) {
      const periodElement = this._selectedPeriodElement.value as PeriodElement;

      const result = await this._createDashboardReportPeriodSelectionAsync(
        periodElement.hierarchyLevelId,
        periodElement.name
      );

      if (!result.succeeded) {
        return result;
      }
    }

    return new Result();
  }

  private async _createDashboardReportPeriodSelectionAsync(
    hierarchyLevelId: string,
    periodElementName: string
  ): Promise<Result<FailedReason>> {
    return await this._modelFacade.createDashboardReportPeriodSelectionAsync(
      this._currentPublishedApplication,
      this._reportId,
      hierarchyLevelId,
      periodElementName
    );
  }

  private static _toggleSelection<T>(
    treeNode: TreeNodeVm,
    addItem: () => void,
    removeItem: () => void
  ): void {
    const item = treeNode.value as T;

    if (!exists(item)) {
      return;
    }

    if (treeNode.isSelected) {
      addItem();
    } else {
      removeItem();
    }
  }

  private static _removeItem<T>(
    selectedItems: Array<T>,
    itemToRemove: T,
    idRetriever: (_: T) => string
  ): void {
    const itemIndex = selectedItems.findIndex(
      (item) => idRetriever(item) === idRetriever(itemToRemove)
    );

    if (itemIndex >= 0) {
      selectedItems.splice(itemIndex, 1);
    }
  }
}
