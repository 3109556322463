import { DashboardError } from "./dashboard-error";
import { delay } from "@/common/helper/async-helper";
import { SimpleResult } from "@/common/results/simple-result";

export class DashboardRequestHelper {
  static readonly maxRetries = 3;

  private static readonly _pollingIntervalMs = 3000;
  private static readonly _pollingDurationMs = 60000;

  // quick polling is used to speed up the initial loading of dashboards
  private static readonly _numberQuickTries = 3;
  private static readonly _quickPollingIntervalMs = 500;

  private static readonly _maxPollingRetries =
    DashboardRequestHelper._pollingDurationMs /
      DashboardRequestHelper._pollingIntervalMs +
    DashboardRequestHelper._numberQuickTries;

  static async pollRequest<T>(
    requestFunc: () => Promise<SimpleResult<T, DashboardError>>
  ): Promise<SimpleResult<T, DashboardError>> {
    let retries = 0;
    let currentResult: SimpleResult<T, DashboardError> = null;

    while (retries < DashboardRequestHelper._maxPollingRetries) {
      currentResult = await requestFunc();
      if (!currentResult.error || currentResult.error !== "BackendIsLoading") {
        break;
      }

      const pollingDelay = DashboardRequestHelper._getPollingIntervalMs(retries);
      await delay(pollingDelay);
      retries++;
    }

    return currentResult;
  }

  // derive the interval from number of tries
  // first 3 tries use _quickPollingInterval
  // then use _pollingInterval
  private static _getPollingIntervalMs(tries: number) {
    if (tries < DashboardRequestHelper._numberQuickTries) {
      return DashboardRequestHelper._quickPollingIntervalMs;
    }

    return DashboardRequestHelper._pollingIntervalMs;
  }
}
