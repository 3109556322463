// Keep in sync with AdminUI. TODO: refactor to shared code

import { Credential, LoginResult, User } from "@bissantz/smartforms";
import {
  InternalUsersServiceClient,
  RequestAuthenticationDto,
} from "@/common/service-clients/generated-clients";
import decode from "jwt-decode";
import { appResources } from "@/app-resources";

export async function login(login: string, password: string): Promise<LoginResult> {
  const client = new InternalUsersServiceClient(null);
  try {
    const request = new RequestAuthenticationDto({ login: login, password: password });
    const result = await client.authenticate(request);
    return { success: true, user: getUserFromToken(result.token) };
  } catch (exception) {
    console.error(exception);
    // wrong user name or password results in 400 bad request, this is nonsense from REST-convention-perspective
    // unfortunately, we cannot show any specific error message (no difference between request failed or login failed)

    return {
      success: false,
      errorMessage: appResources.generalTexts.formsAuthLoginFailed,
      user: null,
    };
  }
}

export function getUserFromToken(token: string): User {
  // because the token from the endpoint uses 'given_name' instead of 'name'
  const username = decode<Record<string, any>>(token)["given_name"];
  const user = new User(username, null, new Credential("bearer", token));
  return User.fromLoginResult(user);
}
