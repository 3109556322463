<script lang="ts">
import { HideAndShowHelper } from "./hide-and-show-helper";
import { defineComponent, reactive, computed, ref } from "vue";

export default defineComponent({
  props: {
    disableAnimation: Boolean,
  },

  setup() {
    // dom refs:
    const ref_hideAndShowInner = ref<HTMLDivElement | null>(null);
    const ref_hideAndShowComponent = ref<HTMLDivElement | null>(null);

    // state:
    const state = reactive({
      isScrolling: false,
      _scrollTimeoutId: null,
      _delayTimeoutId: null,
    });

    const scrollStyle = computed(() => {
      if (!state.isScrolling) return null;

      let textWidth = _getTextWidth();
      let animationDurations = _getAnimationDurations();
      let animationDelays = _getAnimationDelays(animationDurations);
      let timeoutDuration = _getTimeoutDuration(animationDurations, animationDelays);

      state._scrollTimeoutId = setTimeout(() => {
        clearTimeout(state._scrollTimeoutId);
        state.isScrolling = false;
      }, timeoutDuration);

      return {
        "--textWidth": `${textWidth}`,
        "--animationDelays": `${animationDelays.moveRight}s, ${animationDelays.stayRight}s, ${animationDelays.moveLeft}s`,
        "--animationDurations": `${animationDurations.moveRight}s, ${animationDurations.stayRight}s, ${animationDurations.moveLeft}s`,
      };
    });

    function onMouseEnter(): void {
      if (
        ref_hideAndShowComponent.value &&
        HideAndShowHelper.isScrollable(ref_hideAndShowComponent.value) &&
        !state.isScrolling
      ) {
        startDelayedAutoScroll();
      }
    }

    function onTouchStart(): void {
      if (
        ref_hideAndShowComponent.value &&
        HideAndShowHelper.isScrollable(ref_hideAndShowComponent.value) &&
        !state.isScrolling
      ) {
        startDelayedAutoScroll();
      }
    }

    function startDelayedAutoScroll(): void {
      const delayTime_ms = 1000;
      state._delayTimeoutId = setTimeout(() => {
        clearTimeout(state._delayTimeoutId);
        state.isScrolling = true;
      }, delayTime_ms);
    }

    function stopDelayedAutoScroll(): void {
      clearTimeout(state._delayTimeoutId);
    }

    function _isEllipsisScrollable(): boolean {
      const hideAndShow = ref_hideAndShowInner.value;
      if (!hideAndShow) return false;
      return hideAndShow.scrollWidth === hideAndShow.clientWidth;
    }

    function _getTextWidth(): string {
      if (_isEllipsisScrollable()) return `0%`;

      const hideAndShow = ref_hideAndShowInner.value;
      return `calc(${hideAndShow.clientWidth}px - 100%)`;
    }

    function _getAnimationDurations() {
      if (_isEllipsisScrollable()) {
        return {
          moveRight: 0,
          stayRight: 1,
          moveLeft: 0,
        };
      }

      const textSpeed = 0.05; // 50 Pixels per second = 0.05 px/millisecond
      const hideAndShow = ref_hideAndShowInner.value;
      const width_px = Math.abs(hideAndShow.offsetWidth - hideAndShow.scrollWidth);
      return {
        moveRight: width_px / textSpeed / 2000, // Making it faster -> /2
        stayRight: 1, // fixed 1000ms
        moveLeft: 0.25, // fixed 250ms
      };
    }

    function _getAnimationDelays(animationDurations: any) {
      return {
        moveRight: 0,
        stayRight: animationDurations.moveRight,
        moveLeft: animationDurations.moveRight + animationDurations.stayRight,
      };
    }

    function _getTimeoutDuration(animationDurations: any, animationDelays: any) {
      const duration_ms = animationDurations.moveLeft + animationDelays.moveLeft;
      const duration_s = duration_ms * 1000;
      const offset_s = 0.2;
      return duration_s + offset_s;
    }

    return {
      // state & refs:
      state,
      ref_hideAndShowInner,
      ref_hideAndShowComponent,

      // computeds:
      scrollStyle,

      // functions:
      onMouseEnter,
      stopDelayedAutoScroll,
      onTouchStart,
    };
  },
});
</script>

<template>
  <div
    ref="ref_hideAndShowComponent"
    class="hideAndShow"
    v-if="$props.disableAnimation === true"
  >
    <div ref="ref_hideAndShowInner" class="hideAndShowWrapper">
      <div class="hideAndShowBody">
        <slot />
      </div>
    </div>
  </div>
  <div
    v-else
    ref="ref_hideAndShowComponent"
    class="hideAndShow"
    v-on:mouseenter="onMouseEnter"
    v-on:mouseleave="stopDelayedAutoScroll"
    v-on:touchstart="onTouchStart"
  >
    <div
      ref="ref_hideAndShowInner"
      class="hideAndShowWrapper"
      v-bind:style="scrollStyle"
      v-bind:class="{ autoScroll: state.isScrolling }"
    >
      <div class="hideAndShowBody">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.hideAndShow {
  display: inline-grid;
  align-items: center;
  min-width: 0;
  height: 100%;
}

.hideAndShowWrapper {
  --textWidth: -50%;
  --animationDurations: 1s, 1s, 1s;
  --animationDelays: 0s, 1s, 2s;
  width: auto;
  position: relative;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.hideAndShowBody {
  display: inline;
}

.autoScroll {
  .hideAndShowBody {
    text-overflow: unset;
    display: inline-block;
    animation-fill-mode: forwards;
    animation-name: scrollRight, stayRight, scrollLeft;
    animation-delay: var(--animationDelays);
    animation-duration: var(--animationDurations);
    animation-timing-function: linear;
  }
}

@keyframes scrollRight {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(var(--textWidth));
  }
}

@keyframes stayRight {
  from {
    transform: translateX(var(--textWidth));
  }
  to {
    transform: translateX(var(--textWidth));
  }
}

@keyframes scrollLeft {
  from {
    transform: translateX(var(--textWidth));
  }
  to {
    transform: translateX(0%);
  }
}
</style>
