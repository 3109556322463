import cloneDeep from "lodash/cloneDeep";
import { SharedValuesVm } from "./shared-values-vm";
import { PeriodVm } from "@/features/dashboard/view-models/period-vm";

export class SharedRowStateVm {
  isPercentageModeActive: boolean[] = null;
  period: PeriodVm = null;
  name: string = null;

  /// Values for each Sparkline index (information and delta values)
  sharedValues: SharedValuesVm[] = [];
  selectedIndex: number = 0;

  get values(): number[] {
    if (this.selectedIndex < this.sharedValues.length) {
      return this.sharedValues[this.selectedIndex].values;
    }
  }

  get isValuePositive(): boolean[] {
    return this.values.map((value) => Math.sign(value) >= 0);
  }

  get deviationValuesExist(): boolean {
    return this.values.length > 1;
  }

  constructor(sharedValues: SharedValuesVm[]) {
    this.sharedValues = sharedValues;
    this.isPercentageModeActive = sharedValues[0].values.map(() => false);
  }

  clone(): SharedRowStateVm {
    const clone = new SharedRowStateVm(cloneDeep(this.sharedValues));
    clone.period = this.period?.clone();
    clone.selectedIndex = this.selectedIndex;
    clone.isPercentageModeActive = cloneDeep(this.isPercentageModeActive);
    return clone;
  }
}
