export class PeriodVm {
  readonly id: string;
  readonly displayName: string;

  constructor(id: string, displayName: string) {
    this.id = id;
    this.displayName = displayName;
  }

  clone(): PeriodVm {
    return new PeriodVm(this.id, this.displayName);
  }
}
