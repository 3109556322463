import { computed, Ref } from "vue";
import { useHelpText } from "@/services/help-text-service/help-text-cpsl";
import { SwiperVm } from "@/common/components/swiper-vm";

export function useHelpTextHelper(
  isExcludedFromScaling: Ref<boolean>,
  isReadyHot: Ref<boolean>,
  isShowSparklines: Ref<boolean>,
  canSwipePeriod: Ref<boolean>,
  kpiScaleIndex: Ref<number>,
  hasStructures: Ref<boolean>,
  isShowStructureElements: Ref<boolean>,
  canClickValueGroupTexts: Ref<boolean>,
  canSwipe: Ref<boolean>,
  deltaValuesSwiper: Ref<SwiperVm>
) {
  const helpTextCpsl = useHelpText();

  const readyHot = computed<string>(() =>
    helpTextCpsl.scalingText(
      isExcludedFromScaling.value,
      isReadyHot.value,
      isShowSparklines.value
    )
  );

  const periodTexts = computed<string>(() =>
    helpTextCpsl.kpiHeaderTexts(
      isExcludedFromScaling.value,
      isReadyHot.value,
      isShowSparklines.value,
      canSwipePeriod.value,
      kpiScaleIndex.value,
      -1
    )
  );

  const periodEmptyValue = computed<string>(() =>
    helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      isReadyHot.value,
      kpiScaleIndex.value,
      -1,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value
    )
  );

  const periodDeviationValues = computed<string>(() =>
    helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      isReadyHot.value,
      kpiScaleIndex.value,
      -1,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value
    )
  );

  const valueGroupTexts = computed<string>(() =>
    helpTextCpsl.kpiHeaderTexts(
      isExcludedFromScaling.value,
      isReadyHot.value,
      isShowSparklines.value,
      canClickValueGroupTexts.value,
      kpiScaleIndex.value,
      0
    )
  );

  const valueGroupValues = computed<string>(() =>
    helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      isReadyHot.value,
      kpiScaleIndex.value,
      0,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value
    )
  );

  const deltaColumnIndex = computed<number>(() => {
    const activeIndex = deltaValuesSwiper.value.activeIndex;
    return activeIndex === 1 || activeIndex === 3 ? 1 : 2;
  });

  const deltaTexts = computed<string>(() =>
    helpTextCpsl.kpiHeaderTexts(
      isExcludedFromScaling.value,
      isReadyHot.value,
      isShowSparklines.value,
      canSwipe.value,
      kpiScaleIndex.value,
      deltaColumnIndex.value
    )
  );

  const deltaValues = computed<string>(() => {
    return helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      isReadyHot.value,
      kpiScaleIndex.value,
      deltaColumnIndex.value,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value
    );
  });

  const deltaText_1 = computed<string>(() =>
    helpTextCpsl.scalingText(
      isExcludedFromScaling.value,
      isReadyHot.value,
      isShowSparklines.value
    )
  );

  const deltaText_2 = computed<string>(() =>
    helpTextCpsl.scalingText(
      isExcludedFromScaling.value,
      isReadyHot.value,
      isShowSparklines.value
    )
  );

  const deltaValue_1 = computed<string>(() =>
    helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      isReadyHot.value,
      kpiScaleIndex.value,
      2,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value
    )
  );

  const deltaValue_2 = computed<string>(() =>
    helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      isReadyHot.value,
      kpiScaleIndex.value,
      1,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value
    )
  );

  return {
    readyHot,
    periodTexts,
    periodEmptyValue,
    periodDeviationValues,
    valueGroupTexts,
    valueGroupValues,
    deltaTexts,
    deltaValues,
    deltaText_1,
    deltaText_2,
    deltaValue_1,
    deltaValue_2,
  };
}
