import { DashboardStatusType } from "@/features/dashboard/status-configs/dashboard-status";
import { Cell } from "@/common/components/simple-grid/contracts/cell";
import { GridIds as gridIds } from "@/features/dashboard/status-configs/grid-ids";
import { sharedDashboardWidths } from "@/features/dashboard/status-configs/shared-widths";

// Use any CSS grid unit (px, %, fr, auto) for widths and heights.
// Specify an integer for colSpan/rowSpan to indicate the number of columns/rows to span.
// Set colSpan/rowSpan to -1 to use all available columns/rows.

export class SortBarStatusConfig {
  private static readonly _cells: Record<DashboardStatusType, Cell[]> = {
    OneValueNoSparklines: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 2 },
    ],
    OneValueNoSparklinesReadyHot: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 2 },
    ],
    ThreeValuesNoSparklinesScaleIndexGreaterZero: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 2 },
      { id: gridIds.sortDeltas, row: 0, col: 4 },
    ],
    ThreeValuesNoSparklinesScaleIndexGreaterZeroReadyHot: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 2 },
      { id: gridIds.sortDeltas, row: 0, col: 4 },
    ],
    ThreeValuesNoSparklinesScaleIndexZero: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 2 },
      { id: gridIds.sortDeltas, row: 0, col: 4 },
    ],
    ThreeValuesNoSparklinesScaleIndexZeroReadyHot: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 2 },
      { id: gridIds.sortDeltas, row: 0, col: 4 },
    ],
    NotExtendedAndSparklines: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 2 },
    ],
    ExtendedOneValueNoSparklines: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 1 },
    ],
    ExtendedOneValueNoSparklinesReadyHot: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 1 },
    ],
    ExtendedNoSparklines: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 1 },
      { id: gridIds.sortDelta1, row: 0, col: 3 },
      { id: gridIds.sortDelta2, row: 0, col: 5 },
    ],
    ExtendedNoSparklinesReadyHot: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 1 },
      { id: gridIds.sortDelta1, row: 0, col: 3 },
      { id: gridIds.sortDelta2, row: 0, col: 5 },
    ],
    ExtendedAndSparklines: [
      { id: gridIds.sortTitle, row: 0, col: 0 },
      { id: gridIds.sortInformation, row: 0, col: 2 },
      { id: gridIds.sortDelta1, row: 0, col: 3 },
      { id: gridIds.sortDelta2, row: 0, col: 4 },
    ],
  };

  static getCells(dashboardStatusType: DashboardStatusType): Cell[] {
    return this._cells[dashboardStatusType];
  }

  static getWidths(dashboardStatusType: DashboardStatusType): string[] {
    return sharedDashboardWidths[dashboardStatusType];
  }
}
