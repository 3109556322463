import { ValueBuilder } from "./value-builder";
import { DashboardFiltersListVm } from "../dashboard-filters-list-vm";
import { ValueGroupVm } from "../value-group-vm";
import {
  KpiFm,
  KpiValueGroupFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { KpiValueFm } from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import { SharedDrillInfoVm } from "../shared/shared-drill-info-vm";
import { StructureElementsBuilder } from "./structure-elements-builder";
import { SharedKpiInfo } from "../shared/shared-kpi-info";
import { SharedRowStateVm } from "../shared/shared-row-state-vm";
import { SharedValuesVm } from "../shared/shared-values-vm";
import { ValueVm } from "../value-vm";
import { SwiperVm } from "@/common/components/swiper-vm";
import { SharedDashboardStateVm } from "../shared/shared-dashboard-state-vm";
import { IDashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade.interface";
import { PeriodFm } from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";
import { PeriodVm } from "@/features/dashboard/view-models/period-vm";

export class ValueGroupBuilder {
  private _valueBuilder = new ValueBuilder();
  private _structureElementsBuilder = new StructureElementsBuilder();

  createValueGroupVms(
    kpiFm: KpiFm,
    info: SharedKpiInfo,
    publishedApplicationId: string,
    dashboardFilters: DashboardFiltersListVm,
    valueGroupSwiperVm: SwiperVm,
    deltaValuesSwiperVm: SwiperVm,
    sharedState: SharedDashboardStateVm,
    dashboardFacade: IDashboardFacade = null
  ): ValueGroupVm[] {
    const valueGroupVms: ValueGroupVm[] = [];

    for (
      let valueGroupIdx = 0;
      valueGroupIdx < kpiFm.valueGroups.length;
      valueGroupIdx++
    ) {
      const valueGroupVm = this._createValueGroupVm(
        kpiFm,
        info,
        valueGroupIdx,
        publishedApplicationId,
        dashboardFilters,
        valueGroupSwiperVm,
        deltaValuesSwiperVm,
        sharedState,
        dashboardFacade
      );

      valueGroupVms.push(valueGroupVm);
    }

    return valueGroupVms;
  }

  private _createValueGroupVm(
    kpiFm: KpiFm,
    info: SharedKpiInfo,
    valueGroupIdx: number,
    publishedApplicationId: string,
    dashboardFilters: DashboardFiltersListVm,
    valueGroupSwiperVm: SwiperVm,
    deltaValuesSwiperVm: SwiperVm,
    sharedState: SharedDashboardStateVm,
    dashboardFacade: IDashboardFacade
  ): ValueGroupVm {
    const kpiValueGroupFm = kpiFm.valueGroups[valueGroupIdx];
    const valueFms = kpiFm.valueGroups[valueGroupIdx].values;
    const periodVm = new PeriodVm(
      kpiValueGroupFm.period.id,
      kpiValueGroupFm.period.value
    );
    const parentRowState = this._createSharedRowStateVm(valueFms, periodVm);

    const valueGroupVm = this.createValueGroupVmWithoutValues(
      info,
      publishedApplicationId,
      valueGroupIdx,
      kpiFm,
      kpiValueGroupFm.period,
      dashboardFilters
    );
    valueGroupVm.sharedState = sharedState;

    valueGroupVm.valueGroupSwiperVm = valueGroupSwiperVm;
    valueGroupVm.deltaValuesSwiperVm = deltaValuesSwiperVm;

    valueGroupVm.kpiValues = this._createValueVms(
      parentRowState,
      valueFms,
      valueGroupVm.kpiInfo,
      periodVm
    );

    valueGroupVm.structures = info.kpiStructures;

    valueGroupVm.structureElementsVm =
      this._structureElementsBuilder.createStructureElements(
        valueGroupVm.drillInfo,
        valueGroupVm.kpiInfo,
        parentRowState,
        dashboardFacade
      );

    valueGroupVm.prevGroupName = this._getPrevGroupTitle(
      valueGroupIdx,
      kpiFm.valueGroups
    );

    valueGroupVm.nextGroupName = this._getNextGroupTitle(
      valueGroupIdx,
      kpiFm.valueGroups
    );

    return valueGroupVm;
  }

  private _createValueVms(
    parentRowState: SharedRowStateVm,
    valueFms: KpiValueFm[],
    kpiInfo: SharedKpiInfo,
    period: PeriodVm
  ): ValueVm[] {
    let valueVms: ValueVm[] = [];
    if (!!valueFms && valueFms.length > 0) {
      valueVms = this._valueBuilder.createValueVms(valueFms, kpiInfo, period);
      valueVms.map((val) => (val.parentRowState = parentRowState));
    }
    return valueVms;
  }

  private _createSharedRowStateVm(
    valueFms: KpiValueFm[],
    period: PeriodVm
  ): SharedRowStateVm {
    const parentValues: SharedValuesVm[] = [];

    if (valueFms) {
      const parentValue = new SharedValuesVm(valueFms.map((valueFm) => valueFm.value));
      parentValues.push(parentValue);

      const firstValueFm = valueFms[0];
      if (this._valueFmHasSparkline(firstValueFm)) {
        for (let i = 1; i < firstValueFm.historicData.length; i++) {
          const values: number[] = [];
          for (let j = 0; j < valueFms.length; j++) {
            if (this._valueFmHasSparkline(valueFms[j])) {
              values.push(valueFms[j].historicData[i].value);
            }
          }
          const parentValue = new SharedValuesVm(values);
          parentValues.push(parentValue);
        }
      }
    }

    const sharedRowState = new SharedRowStateVm(parentValues);
    sharedRowState.period = period;
    return sharedRowState;
  }

  private _valueFmHasSparkline(kpiValueFm: KpiValueFm): boolean {
    return (
      kpiValueFm &&
      kpiValueFm.hasHistoryData &&
      !!kpiValueFm.historicData &&
      kpiValueFm.historicData.length > 0
    );
  }

  private _getPrevIndex(currentIndex: number, totalNumItems: number): number {
    return currentIndex > 0 ? currentIndex - 1 : totalNumItems - 1;
  }

  private _getNextIndex(currentIndex: number, totalNumItems: number): number {
    return currentIndex < totalNumItems - 1 ? currentIndex + 1 : 0;
  }

  private _getPrevGroupTitle(groupIdx: number, valueGroups: KpiValueGroupFm[]): string {
    if (valueGroups.length < 2) return null;

    const prevIndex = this._getPrevIndex(groupIdx, valueGroups.length);
    return valueGroups[prevIndex].values[0].name;
  }

  private _getNextGroupTitle(groupIdx: number, valueGroups: KpiValueGroupFm[]): string {
    if (valueGroups.length < 2) return null;

    const nextIdx = this._getNextIndex(groupIdx, valueGroups.length);
    return valueGroups[nextIdx].values[0].name;
  }

  clone(
    original: ValueGroupVm,
    cloneFilters: DashboardFiltersListVm,
    cloneInfo: SharedKpiInfo,
    kpiValueGroupFm: KpiValueGroupFm,
    publishedApplicationId: string = null,
    clonedValueGroupSwiper: SwiperVm = null,
    clonedDeltaValuesSwiper: SwiperVm = null,
    clonedSharedState: SharedDashboardStateVm = null
  ): ValueGroupVm {
    const clone = new ValueGroupVm();

    clone.id = original.id;
    clone.valueGroupId = original.valueGroupId;
    clone.drillInfo = original.drillInfo.clone(cloneFilters);
    if (publishedApplicationId)
      clone.drillInfo.publishedApplicationId = publishedApplicationId;
    clone.title = original.title;
    clone.valueGroupSwiperVm =
      clonedValueGroupSwiper ??
      new SwiperVm(
        original.valueGroupSwiperVm.numberOfItemsToDisplay,
        original.valueGroupSwiperVm.activeIndex
      );
    clone.deltaValuesSwiperVm =
      clonedDeltaValuesSwiper ??
      new SwiperVm(
        original.deltaValuesSwiperVm.numberOfItemsToDisplay,
        original.deltaValuesSwiperVm.activeIndex
      );

    clone.sharedState = clonedSharedState ?? new SharedDashboardStateVm();
    clone.sparklinesGlobalMax = original.sparklinesGlobalMax;
    clone.prevGroupName = original.prevGroupName;
    clone.nextGroupName = original.nextGroupName;
    clone.initialPeriod = original.initialPeriod.clone();
    clone.kpiTileIndex = original.kpiTileIndex;
    clone.deltaIndexForDrill = original.deltaIndexForDrill;
    clone.kpiInfo = cloneInfo;
    clone.structures = original.structures.map((struct) =>
      clone.kpiInfo.kpiStructures.find(
        (kpiStructure) => kpiStructure.structureId.id === struct.structureId.id
      )
    );

    const parentRowState = original.kpiValues[0].parentRowState.clone();

    clone.kpiValues = original.kpiValues.map((val) =>
      this._valueBuilder.clone(
        val,
        clone.kpiInfo,
        parentRowState,
        kpiValueGroupFm.values.find((fmVal) => fmVal.kpiValueId.id === val.id)
      )
    );
    clone.structureElementsVm = this._structureElementsBuilder.clone(
      original.structureElementsVm,
      clone.drillInfo,
      clone.kpiInfo,
      parentRowState
    );

    return clone;
  }

  createValueGroupVmWithoutValues(
    info: SharedKpiInfo,
    publishedApplicationId: string,
    valueGroupIdx: number,
    kpiTileFm: KpiFm,
    period: PeriodFm,
    dashboardFilters: DashboardFiltersListVm
  ): ValueGroupVm {
    const result = new ValueGroupVm();
    result.drillInfo = new SharedDrillInfoVm();
    result.drillInfo.kpiId = kpiTileFm.id;
    result.drillInfo.dashboardId = kpiTileFm.dashboardId;
    result.drillInfo.publishedApplicationId = publishedApplicationId;
    result.drillInfo.dashboardFilter = dashboardFilters;
    result.drillInfo.valueGroupId = kpiTileFm.valueGroups[valueGroupIdx].id;

    result.id = kpiTileFm.valueGroups[valueGroupIdx].id;
    result.valueGroupId = kpiTileFm.valueGroups[valueGroupIdx].id;
    result.title = kpiTileFm.name;
    result.initialPeriod = new PeriodVm(period.id, period.value);
    result.structures = info.kpiStructures;
    result.kpiInfo = info;

    return result;
  }
}
