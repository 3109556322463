import {
  IKpiValueIdDto,
  KpiValueGroupIdDto,
  IKpiIdDto,
  IDashboardIdDto,
  IEntityIdDto,
  EntityType,
  IKpiValueDto,
  AggregationType,
  StructureIdDto,
  IElementIdDto,
  DashboardIdDto,
} from "@/common/service-clients/generated-clients";
import {
  KpiValueFm,
  KpiValueIdFm,
  KpiValueGroupIdFm,
  KpiIdFm,
  DashboardIdFm,
  EntityIdFm,
  EntityTypeFm,
  AggregationTypeFm,
  StructureIdFm,
  ElementIdFm,
} from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import { FmMapperSparklines } from "@/features/dashboard-shared/sparkline/backend-wrapper/fm-mapper-sparklines";

export class FmMapperDashboardShared {
  static mapKpiValueFms(values: IKpiValueDto[]): KpiValueFm[] {
    return values.map((value) => this.mapKpiValueFm(value));
  }

  static mapKpiValueFm(kpiValueDto: IKpiValueDto): KpiValueFm {
    const kpiValueFm = new KpiValueFm();
    kpiValueFm.kpiValueId = this.mapKpiValueIdFm(kpiValueDto.kpiValueId);
    kpiValueFm.name = kpiValueDto.name;
    kpiValueFm.value = kpiValueDto.value;
    kpiValueFm.format = kpiValueDto.format;
    kpiValueFm.hasHistoryData = kpiValueDto.hasHistoryData;
    kpiValueFm.historicData = FmMapperSparklines.mapHistoricDataFms(
      kpiValueDto.historicData
    );
    kpiValueFm.isStatus = kpiValueDto.isStatus;
    kpiValueFm.statusTextsByValue = kpiValueDto.statusTextsByValue;
    kpiValueFm.dynamicColorValue = kpiValueDto.dynamicColorValue;
    kpiValueFm.aggregationType = this._mapAggregationType(kpiValueDto.aggregationType);
    kpiValueFm.isPercentageAdjustmentRequired =
      kpiValueDto.isPercentageAdjustmentRequired;
    kpiValueFm.isKpiSwitchActive = kpiValueDto.isPercentageAdjustmentRequired;
    return kpiValueFm;
  }

  static mapKpiDashboardIdFm(dashboardIdDto: DashboardIdDto) {
    const dashboardIdFm = new DashboardIdFm();
    dashboardIdFm.id = dashboardIdDto.id;
    dashboardIdFm.publishedApplicationId = this._mapEntityIdFm(
      dashboardIdDto.publishedApplicationId
    );
    return dashboardIdFm;
  }

  static mapKpiValueIdFm(kpiValueIdDto: IKpiValueIdDto): KpiValueIdFm {
    const kpiValueIdFm = new KpiValueIdFm();
    kpiValueIdFm.id = kpiValueIdDto.id;
    kpiValueIdFm.kpiValueGroupId = this.mapKpiValueGroupIdFm(
      kpiValueIdDto.kpiValueGroupId
    );
    return kpiValueIdFm;
  }

  static mapKpiValueGroupIdFm(kpiValueGroupIdDto: KpiValueGroupIdDto): KpiValueGroupIdFm {
    const kpiValueGroupIdFm = new KpiValueGroupIdFm();
    kpiValueGroupIdFm.id = kpiValueGroupIdDto.id;
    kpiValueGroupIdFm.kpiId = this.mapKpiIdFm(kpiValueGroupIdDto.kpiId);
    return kpiValueGroupIdFm;
  }

  static mapKpiIdFm(kpiIdDto: IKpiIdDto): KpiIdFm {
    const kpiIdFm = new KpiIdFm();
    kpiIdFm.id = kpiIdDto.id;
    kpiIdFm.dashboardId = this._mapDashboardIdFm(kpiIdDto.dashboardId);
    return kpiIdFm;
  }

  static mapStructureIdFm(structureIdDto: StructureIdDto): StructureIdFm {
    const structureIdFm = new StructureIdFm();
    structureIdFm.id = structureIdDto.id;
    structureIdFm.kpiId = this.mapKpiIdFm(structureIdDto.kpiId);
    return structureIdFm;
  }

  static mapElementIdFm(elementIdDto: IElementIdDto) {
    const elementIdFm = new ElementIdFm();
    elementIdFm.id = elementIdDto.id;
    elementIdFm.structureId = this.mapStructureIdFm(elementIdDto.structureId);
    return elementIdFm;
  }

  private static _mapDashboardIdFm(dashboardIdDto: IDashboardIdDto): DashboardIdFm {
    const dashboardIdFm = new DashboardIdFm();
    dashboardIdFm.id = dashboardIdDto.id;
    dashboardIdFm.publishedApplicationId = this._mapEntityIdFm(
      dashboardIdDto.publishedApplicationId
    );
    return dashboardIdFm;
  }

  private static _mapEntityIdFm(entityIdDto: IEntityIdDto): EntityIdFm {
    const entityIdFm = new EntityIdFm();
    entityIdFm.idType = this._mapIdTypeFm(entityIdDto.idType);
    entityIdFm.internalId = entityIdDto.internalId;
    return entityIdFm;
  }

  private static _mapIdTypeFm(idType: EntityType): EntityTypeFm {
    return idType;
  }

  private static _mapAggregationType(
    aggregationType: AggregationType
  ): AggregationTypeFm {
    return aggregationType;
  }
}
