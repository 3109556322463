import { exists } from "./object-helper/null-helper";

export type RequestError =
  | "NoPermission"
  | "TimedOut"
  | "AnyBackend"
  | "NotFound"
  | "UnknownError"
  | "BackendIsLoading";

export function getRequestError(statusCode: number): RequestError {
  if (!statusCode) return "UnknownError";

  if (statusCode === 202) {
    return "BackendIsLoading";
  } else if (statusCode === 403) {
    return "NoPermission";
  } else if (statusCode === 404) {
    return "NotFound";
  } else if (statusCode === 408 || statusCode === 503 || statusCode === 504) {
    return "TimedOut";
  } else if (statusCode >= 500) {
    return "AnyBackend";
  } else {
    return "UnknownError";
  }
}

export function tryGetRequestError(e: Error): RequestError {
  let statusCode: number = -1;

  if (exists(e["status"])) {
    statusCode = e["status"];
  } else {
    console.error(e);
  }

  return getRequestError(statusCode);
}
