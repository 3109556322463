import { StructureElementsListVm } from "./structure-elements-list-vm";
import { SwiperVm } from "@/common/components/swiper-vm";
import { StructureVm } from "./structure-vm";
import { StructureElementsListBuilder } from "./builder/structure-elements-list-builder";
import { SharedDrillInfoVm } from "./shared/shared-drill-info-vm";
import { SharedKpiInfo } from "./shared/shared-kpi-info";
import { SharedRowStateVm } from "./shared/shared-row-state-vm";
import { ElementVm } from "./element-vm";
import {
  KpiDrillStructureFilterFm,
  SortTypeFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { SortCellType } from "@/common/components/cell-basics/sort-cell.vue";
import { IDashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade.interface";

export class StructureElementsVm {
  private _isVisible = false;

  structureElementsSwiperVm: SwiperVm = null;
  structureElementsListVms: StructureElementsListVm[] = null;
  sharedDrillInfo: SharedDrillInfoVm = null;
  sharedKpiInfo: SharedKpiInfo = null;
  parentRowState: SharedRowStateVm = null;
  sortedColumn: number = 2;
  sortType: SortTypeFm = undefined;

  isInitialized = false;

  private _structureElementsListBuilder = new StructureElementsListBuilder();
  dashboardFacade: IDashboardFacade;

  get isUpdating(): boolean {
    if (this.structureElementsListVms) {
      const updatingStructure = this.structureElementsListVms.find(
        (structureElementsListVm) => structureElementsListVm.updating
      );
      return !!updatingStructure;
    }

    return true;
  }

  get currentStructureElementsListVm(): StructureElementsListVm {
    if (this.structureElementsListVms && this.structureElementsListVms.length > 0) {
      const index = this.structureElementsSwiperVm.getRealIndex();
      return this.structureElementsListVms[index];
    }
  }

  get currentElementVms(): ElementVm[] {
    if (this.structureElementsListVms && this.structureElementsListVms.length > 0) {
      const index = this.structureElementsSwiperVm.getRealIndex();
      return this.structureElementsListVms[index].elementVms;
    }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  private _initStructureElementsListVms(
    structureVms: StructureVm[],
    previousFilters: KpiDrillStructureFilterFm[] = []
  ): void {
    const structureElementsListVms: StructureElementsListVm[] = [];

    this.initSwiper(structureVms);

    for (let i = 0; i < structureVms.length; i++) {
      const nextStructureVms = structureVms
        .filter((structureVm) => structureVm.id !== structureVms[i].id)
        .map((structureVm) => new StructureVm(structureVm));

      const structureElementsListVm =
        this._structureElementsListBuilder.createStructureElementsList(
          structureVms[i],
          structureVms,
          nextStructureVms,
          previousFilters,
          this.sharedDrillInfo,
          this.sharedKpiInfo,
          this.parentRowState,
          this.dashboardFacade
        );
      structureElementsListVms.push(structureElementsListVm);
    }

    this.structureElementsListVms = structureElementsListVms;
    this.isInitialized = true;
  }

  initSwiper(structureVms: StructureVm[]): void {
    if (this.structureElementsSwiperVm.activeIndex <= structureVms.length) {
      return;
    }

    this.structureElementsSwiperVm.swipeTo(1, false);
  }

  resetPercentageModes(): void {
    this.currentStructureElementsListVm.resetPercentageModes();
  }

  closeDrills(): void {
    this.currentStructureElementsListVm.elementVms.map(
      (element) => (element.nextStructureElements._isVisible = false)
    );
  }

  toggleVisibility(
    kpiScaleIndex: number,
    structures: StructureVm[],
    previousFilters: KpiDrillStructureFilterFm[] = []
  ) {
    if (!this.isVisible) {
      this.show(kpiScaleIndex, structures, previousFilters);
    } else {
      this.hide();
    }
  }

  hide() {
    this._isVisible = false;
  }

  show(
    kpiScaleIndex: number,
    structures: StructureVm[],
    previousFilters: KpiDrillStructureFilterFm[] = []
  ): void {
    this.sortedColumn = kpiScaleIndex + 1;
    this.setInitialSortType(kpiScaleIndex);

    this._initStructureElementsListVms(structures, previousFilters);

    this._isVisible = true;
  }

  setInitialSortType(scaleIndex: number): void {
    if (scaleIndex === -1) {
      this.sortType = "BySortId";
    } else {
      this.sortType = this.parentRowState.isValuePositive[scaleIndex]
        ? "ByValueDescending"
        : "ByValueAscending";
    }
  }

  getInitialCellSortTypes(): SortCellType[] {
    const cellSortTypes: SortCellType[] =
      this.sortedColumn === 0
        ? this.sortType === "ByNameAscending"
          ? ["Ascending"]
          : ["Descending"]
        : ["None"];
    const numberOfValues = this.parentRowState.isValuePositive.length;

    for (let i = 0; i < numberOfValues; i++) {
      let sortCellType: SortCellType = "Ascending";
      if (this.sortedColumn === 0 || this.sortType === undefined) {
        sortCellType = this._getValueCellSortType(i);
      } else {
        sortCellType = this.sortType === "ByValueAscending" ? "Ascending" : "Descending";
      }
      cellSortTypes.push(sortCellType);
    }

    return cellSortTypes;
  }

  private _getValueCellSortType(valueIndex: number): SortCellType {
    return this.parentRowState.isValuePositive[valueIndex] ? "Descending" : "Ascending";
  }
}
