﻿import { WorkItemState } from "@/common/service-clients/generated-clients";
import { delay } from "@/common/helper/async-helper";

type Progress = (value: number) => void;

// keep this as close as possible to the dto definition
interface IWorkItemDto<T> {
  id: string;
  state: WorkItemState;
  progressInPercentage: number | null;
  result: T | null;
}

type WorkLaunchFunc<T, V> = (input: V) => Promise<IWorkItemDto<T>>;
type WorkStatusFunc<T, V> = (input: V, workId: string) => Promise<IWorkItemDto<T>>;

export interface LongRunningWorkOptions {
  timeout: number;
  delay: number;
}

export class LongRunningWorkServiceClient<T = any> {
  private readonly _options: LongRunningWorkOptions;

  constructor(options?: Partial<LongRunningWorkOptions>) {
    this._options = { delay: 1000, timeout: -1, ...options };
  }

  async complete<V>(
    fileId: V,
    launch: WorkLaunchFunc<T, V>,
    status: WorkStatusFunc<T, V>,
    progress: Progress
  ): Promise<T> {
    const deadline =
      this._options.timeout > 0 ? new Date().getTime() + this._options.timeout : -1;

    let work = await launch(fileId);
    while (work.state === "Todo" || work.state === "InProgress") {
      progress(work.progressInPercentage || 0);

      await delay(this._options.delay);

      work = await status(fileId, work.id);

      if (deadline > 0 && deadline > new Date().getTime()) {
        throw new AsyncWorkError("work item did not complete in time", work.result, true);
      }
    }

    progress(work.progressInPercentage || 0);

    if (work.state === "Faulted") {
      throw new AsyncWorkError("work item did not complete successfully", work.result);
    }

    return work.result;
  }
}

export class AsyncWorkError extends Error {
  timeout: boolean;
  result: any;

  constructor(message: string, result: any, timeout?: boolean) {
    super(message);
    this.name = "AsyncWorkError";
    this.timeout = timeout ?? false;
    this.result = result;
  }
}
