import { FontConfig } from "@/features/dashboard-shared/scalable-value";

export class DashboardCommon {
  static common_focusElementChanged = "common_focusElementChanged";
  static common_excludeFromScalingChanged = "common_excludeFromScalingChanged";
  static common_valueGroupChanged = "common_valueGroupChanged";
  static common_hideSparklines = "common_hideSparklines";

  static valueGroup_readyStateIsOff = "valueGroup_readyStateIsOff";
  static valueGroup_readyStateIsOn = "valueGroup_readyStateIsOn";
  static valueGroup_drillClosed = "valueGroup_drillClosed";

  static element_onMouseOverElement = "element_onMouseOverElement";
  static element_onMouseLeaveElement = "element_onMouseLeaveElement";

  static elementTitleSection_toggleDrill = "elementTitleSection_toggleDrill";
  static elementTitleSection_applyFilter = "elementTitleSection_applyFilter";

  static elementConfig = new FontConfig({
    minScaledEm: 1.0,
    maxScaledEm: 2.3,
    scalingStepEm: 0.1,
    defaultFontSize: 1.2,
  });

  static kpiConfig = new FontConfig({
    minScaledEm: 1.0,
    maxScaledEm: 2.3,
    scalingStepEm: 0.1,
    defaultFontSize: 1.2,
  });

  private static _drillSpacerWidth = 6;
  private static _drillSpacerWidthExtended = 15;

  static getDrillSpacerWidth(drillDepth: number): number {
    return this._drillSpacerWidth * (drillDepth - 1);
  }

  static getDrillSpacerWidthExtended(drillDepth: number): number {
    return this._drillSpacerWidthExtended * drillDepth;
  }

  static incrementDurationInSeconds = 0.25;
}
