<script lang="ts">
import { IStatusServiceClient } from "@/common/service-clients/generated-clients";
import { SessionLogExporter } from "@/common/logging/storage/session-log-exporter";
import { appResources } from "@/app-resources";
import DeltaAppLogo from "@/common/components/delta-app-logo.vue";
import { BcCollapseTransition } from "@bissantz/controls";
import { SessionVm } from "@/session-vm";
import {
  reactive,
  computed,
  inject,
  onBeforeMount,
  defineComponent,
  nextTick,
} from "vue";

export default defineComponent({
  components: {
    DeltaAppLogo,
    BcCollapseTransition,
  },

  setup() {
    const statusClient: IStatusServiceClient = inject("statusClient");
    const session = inject("session") as SessionVm;

    const state = reactive({
      aboutTexts: appResources.aboutTexts,
      statusText: "",
      showLog: false,
      logContent: "",
      hideLogControls: false,
    });

    //---vue life cycle methods
    function init() {
      state.hideLogControls = session.isEmbeddedMode;
    }
    init();

    onBeforeMount(async () => {
      const status = await statusClient.status();
      state.statusText = status.infoText;
    });
    //---end life cycle methods

    const toggleLogText = computed(() => {
      return state.showLog ? state.aboutTexts.hideLog : state.aboutTexts.showLog;
    });

    async function toggleLogs(): Promise<void> {
      if (!state.showLog) {
        state.logContent = SessionLogExporter.getHumanReadableLog();
      }
      // wait for DOM to update
      await nextTick();
      state.showLog = !state.showLog;
    }

    function exportLogs(): void {
      SessionLogExporter.getStructuredLogFile();
    }

    return {
      state,

      // Computed
      toggleLogText,

      // Functions
      exportLogs,
      toggleLogs,
    };
  },
});
</script>

<template>
  <div class="about">
    <div class="card">
      <div class="aboutTitle">
        <DeltaAppLogo v-bind:fillColor="'#231F20'" />
      </div>
      <div class="aboutStatus">
        {{ state.statusText }}
      </div>
      <div class="logControls" v-bind:class="{ hidden: state.hideLogControls }">
        <span v-on:click="toggleLogs">{{ toggleLogText }}</span>
        <span v-on:click="exportLogs">{{ state.aboutTexts.exportLog }}</span>
      </div>
    </div>
    <BcCollapseTransition v-bind:duration="1000">
      <div v-show="state.showLog" class="logContent">
        <textarea readonly v-model="state.logContent" />
      </div>
    </BcCollapseTransition>
  </div>
</template>

<style lang="less" scoped>
@import "../../common/styles/media-queries.less";

.about {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.5em;
  height: 100%;

  .card {
    flex: 0 0 auto;
    padding: 1em;

    .aboutTitle {
      height: 2em;
      width: 10em;
      padding-bottom: 1em;
    }

    .aboutVersionNumber {
      font-size: 0.8em;
    }

    .aboutStatus {
      font-size: 0.8em;
      max-width: 400px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .logControls {
      visibility: visible;
      font-size: 0.8em;
      &.hidden {
        visibility: hidden;
      }
      > span {
        margin-right: 1.2em;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .logContent {
    width: 100%;
    height: calc(50vh - 8em);
    margin-bottom: 16px;
    display: flex;
    justify-content: center;

    > textarea {
      width: 90%;
      height: 100%;
      white-space: pre;
      overflow-wrap: normal;
      overflow-x: scroll;
      resize: none;
    }
  }
}
</style>
