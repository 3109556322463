<script lang="ts">
import { computed, defineComponent } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    mainColor: {
      type: String,
      default: "#000",
    },
  },

  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      const usedColor = props.mainColor;
      return {
        "--main-color": usedColor,
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="chatIcon" v-bind:style="colorVars">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169.583 161.644">
      <polygon
        points="38.439,44.358 38.439,130.757 108.289,130.757 142.855,154.644 142.855,130.757 162.583,130.757 
      162.583,44.358 "
      />
      <line x1="59.716" y1="69.115" x2="139.075" y2="69.115" />
      <line x1="59.716" y1="87.794" x2="139.075" y2="87.794" />
      <line x1="59.716" y1="106.472" x2="105.263" y2="106.472" />
      <polyline points="38.439,90.873 7,90.873 7,7 108.414,7 108.414,44.237 " />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.chatIcon {
  width: 100%;
  height: 100%;
  cursor: inherit;

  svg {
    stroke: var(--main-color);
    stroke-width: 14;
    width: 100%;
    height: 100%;
    fill: none;
    stroke-miterlimit: 10;
    stroke-linejoin: round;

    line {
      stroke-linejoin: miter;
      stroke-width: 7;
    }
  }
}
</style>
