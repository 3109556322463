import {
  ApplicationDto,
  ApplicationVersionDto,
  IApplicationGroupsServiceClient,
  IApplicationVersionsServiceClient,
  IApplicationsServiceClient,
  IPublishedApplicationsServiceClient,
  PublishedApplicationDto,
  IPublishedApplicationGroupsServiceClient,
  ApplicationType,
  PublishedApplicationFilterDto,
} from "@/common/service-clients/generated-clients";
import {
  ApplicationGroup,
  PublishedApplication,
  PublishedApplicationGroup,
} from "./dto-wrappers";
import { IApplicationFacade } from "./application-facade.interface";
import { getGUID } from "@/common/helper/guid-helper";
import { ValueResult } from "@/common/results/value-result";
import { FailedReason } from "@/common/results/failed-reason";
import { ApplicationCreationResult } from "./application-creation-result";
import { ApplicationNameLink } from "./application-name-link";

export class ApplicationFacade implements IApplicationFacade {
  private readonly _applicationsClient: IApplicationsServiceClient;
  private readonly _applicationVersionsClient: IApplicationVersionsServiceClient;
  private readonly _publishedApplicationClient: IPublishedApplicationsServiceClient;
  private readonly _applicationGroupsClient: IApplicationGroupsServiceClient;
  private readonly _publishedApplicationGroupsClient: IPublishedApplicationGroupsServiceClient;

  constructor(
    applicationsClient: IApplicationsServiceClient,
    applicationVersionsClient: IApplicationVersionsServiceClient,
    publishedApplicationClient: IPublishedApplicationsServiceClient,
    applicationGroupsClient: IApplicationGroupsServiceClient,
    publishedApplicationGroupsClient: IPublishedApplicationGroupsServiceClient
  ) {
    this._applicationsClient = applicationsClient;
    this._applicationVersionsClient = applicationVersionsClient;
    this._publishedApplicationClient = publishedApplicationClient;
    this._applicationGroupsClient = applicationGroupsClient;
    this._publishedApplicationGroupsClient = publishedApplicationGroupsClient;
  }

  async getPublishedApplication(
    publishedApplicationId: string
  ): Promise<PublishedApplication> {
    return await this._publishedApplicationClient.getPublishedApplication(
      publishedApplicationId
    );
  }

  async getApplicationGroups(): Promise<ValueResult<ApplicationGroup[], FailedReason>> {
    try {
      const applicationGroups = await this._applicationGroupsClient.getApplicationGroups(
        null,
        "Admin"
      );
      return ValueResult.createFromValue(applicationGroups);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async getPublishedApplicationGroups(): Promise<
    ValueResult<PublishedApplicationGroup[], FailedReason>
  > {
    try {
      const publishedAppGroups =
        await this._publishedApplicationGroupsClient.getPublishedApplicationGroups(
          null,
          "Admin"
        );
      return ValueResult.createFromValue(publishedAppGroups);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async getPublishedDeltaMasterApplications(
    connectionSetId: string
  ): Promise<ValueResult<PublishedApplication[], FailedReason>> {
    try {
      if (connectionSetId === null || connectionSetId === undefined) {
        return ValueResult.createFromValue([]);
      }

      const publishedApplications = await this._publishedApplicationClient.find(
        new PublishedApplicationFilterDto({
          minimumRole: "Admin",
          connectionSetId: connectionSetId,
          applicationTypes: ["DeltaMaster"],
          isExported: false,
        })
      );

      return ValueResult.createFromValue(publishedApplications);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async getAllApplicationNamesFor(
    applicationGroupId: string
  ): Promise<ValueResult<string[], FailedReason>> {
    try {
      const applications = await this._applicationsClient.getApplications(
        applicationGroupId
      );
      const applicationNames = applications.map((application) => application.name);
      return ValueResult.createFromValue(applicationNames);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async createNewApplicationFrom(
    publishedApplication: PublishedApplication
  ): Promise<string> {
    const application = await this._getApplicationFor(publishedApplication);

    return await this._createNewApplication(
      "Dashboard",
      ApplicationNameLink.getDashboardPublishedApplicationName(publishedApplication.name),
      application.applicationGroupId,
      publishedApplication.connectionSetId,
      publishedApplication.publishedApplicationGroupId
    );
  }

  async createNewApplication(
    applicationName: string,
    applicationGroupId: string,
    connectionSetId: string,
    publishedApplicationGroupId: string
  ): Promise<ValueResult<ApplicationCreationResult, FailedReason>> {
    try {
      const deltaMasterApplicationId = await this._createNewApplication(
        "DeltaMaster",
        applicationName,
        applicationGroupId,
        connectionSetId,
        publishedApplicationGroupId
      );
      const dashboardApplicationId = await this._createNewApplication(
        "Dashboard",
        ApplicationNameLink.getDashboardPublishedApplicationName(applicationName),
        applicationGroupId,
        connectionSetId,
        publishedApplicationGroupId
      );

      const result = new ApplicationCreationResult(
        deltaMasterApplicationId,
        dashboardApplicationId
      );

      return ValueResult.createFromValue(result);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  private async _createNewApplication(
    applicationType: ApplicationType,
    applicationName: string,
    applicationGroupId: string,
    connectionSetId: string,
    publishedApplicationGroupId: string
  ): Promise<string> {
    const applicationId = await this._createApplication(
      applicationType,
      applicationName,
      applicationGroupId
    );

    const applicationVersionId = await this._createApplicationVersion(
      applicationType,
      applicationId,
      applicationName
    );

    const createdPublishedApplicationId = await this._createPublishedApplication(
      applicationName,
      publishedApplicationGroupId,
      applicationVersionId,
      applicationType,
      connectionSetId
    );

    return createdPublishedApplicationId;
  }

  private async _createApplication(
    applicationType: ApplicationType,
    applicationName: string,
    applicationGroupId: string
  ): Promise<string> {
    const application = new ApplicationDto({
      id: null,
      name: applicationName,
      description: null,
      applicationGroup: null,
      applicationGroupId: applicationGroupId,
      applicationType: applicationType,
    });

    return await this._applicationsClient.createApplication(application);
  }

  private async _createApplicationVersion(
    applicationType: ApplicationType,
    applicationId: string,
    applicationName: string
  ): Promise<string> {
    const applicationTypeCode = applicationType === "DeltaMaster" ? "-dm" : "-db";

    const applicationVersion = new ApplicationVersionDto({
      application: null,
      applicationId: applicationId,
      id: null,
      description: null,
      identificationCode: getGUID(),
      name: applicationName + applicationTypeCode,
    });

    return await this._applicationVersionsClient.createApplicationVersion(
      applicationVersion
    );
  }

  private async _createPublishedApplication(
    applicationName: string,
    publishedApplicationGroupId: string,
    applicationVersionId: string,
    applicationType: ApplicationType,
    connectionSetId: string
  ): Promise<string> {
    const publishedApplication = new PublishedApplicationDto({
      id: null,
      publishedApplicationGroup: null,
      publishedApplicationGroupId: publishedApplicationGroupId,
      description: null,
      name: applicationName,
      displayOrder: 0,
      cacheGroupType: "AllUsers",
      applicationVersion: null,
      applicationVersionId: applicationVersionId,
      applicationType: applicationType,
      connectionSet: null,
      connectionSetId: connectionSetId,
    });

    const createdPublishedApplicationId =
      await this._publishedApplicationClient.createPublishedApplication(
        publishedApplication
      );

    return createdPublishedApplicationId;
  }

  private async _getApplicationFor(
    publishedApplication: PublishedApplication
  ): Promise<ApplicationDto> {
    const applicationVersion =
      await this._applicationVersionsClient.getApplicationVersion(
        publishedApplication.applicationVersionId
      );

    return await this._applicationsClient.getApplication(
      applicationVersion.applicationId
    );
  }
}
