import { ISemaphoreCounter, SemaphoreCounter } from "@/common/helper/semaphore-counter";
import { Mutex } from "async-mutex";
import { Ref, ref } from "vue";

export class VsScopeState {
  isPersistVsChangesAllowed: Ref<boolean> = ref<boolean>(false);
  anyVsWasChanged: Ref<boolean> = ref<boolean>(false);
  isSyncing: ISemaphoreCounter = SemaphoreCounter.create();
  requestMutex: Mutex = new Mutex();
  isInError: Ref<boolean> = ref<boolean>(false);
}
