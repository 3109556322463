<script lang="ts">
import { defineComponent, PropType, reactive, onBeforeMount, computed } from "vue";
import SortIcon from "@/common/components/icons/sort-icon.vue";
import type { CSSProperties } from "vue/types/jsx";
import { ClickHelper } from "@/common/events/click-helper";
import { TouchHelper } from "@/common/events/touch-helper";

export type SortCellType = "Ascending" | "Descending" | "None";

class SortCellState {
  clickHelper = new ClickHelper();
  touchHelper = new TouchHelper();
  isMouseOver: boolean = false;
}

const multipleCellSortTypeMap: Record<SortCellType, SortCellType> = {
  Descending: "Ascending",
  Ascending: "None",
  None: "Descending",
};

const binaryCellSortTypeMap: Record<SortCellType, SortCellType> = {
  Descending: "Ascending",
  Ascending: "Descending",
  None: "Ascending",
};

export class SortCellCommon {
  static sortingChanged = "sortingChanged";
}

export default defineComponent({
  components: { SortIcon },
  emits: [SortCellCommon.sortingChanged],
  props: {
    index: { type: Number, required: true },
    sortedIndex: { type: Number, required: true },
    binarySort: { type: Boolean, default: true },
    color: { type: String, required: true },
    clickAreaStyle: { type: Object as PropType<CSSProperties>, required: true },
    sortType: { type: String as () => SortCellType, required: true },
  },
  setup(props, context) {
    const state = reactive(new SortCellState());

    //
    // Life Cycle:
    // --------------------
    onBeforeMount(() => {
      state.clickHelper.touchHelper = state.touchHelper;
      state.clickHelper.setOnLeftClickAction(toggleSorting);
      state.touchHelper.setTapAction(toggleSorting);
    });

    //
    // Computeds:
    // --------------------
    const enabled = computed<boolean>(() => props.index === props.sortedIndex);

    //
    // Functions:
    // --------------------
    function toggleSorting(): void {
      let sortType: SortCellType;
      if (!enabled.value) {
        sortType = props.sortType;
      } else if (props.binarySort) {
        sortType = binaryCellSortTypeMap[props.sortType];
      } else {
        sortType = multipleCellSortTypeMap[props.sortType];
      }
      context.emit(SortCellCommon.sortingChanged, props.index, sortType);
    }

    function onMouseOver(): void {
      state.isMouseOver = true;
    }

    function onMouseLeave(): void {
      state.isMouseOver = false;
    }

    //
    // Watcher:
    // --------------------

    return { state, enabled, onMouseOver, onMouseLeave };
  },
});
</script>

<template>
  <div class="sort-cell">
    <div
      class="click-tap-area"
      v-bind:style="$props.clickAreaStyle"
      v-on:mouseover="onMouseOver"
      v-on:mouseleave="onMouseLeave"
      v-on:mousedown="state.clickHelper.mouseDown($event)"
      v-on:mouseup="state.clickHelper.mouseUp($event)"
      v-on:touchstart="state.touchHelper.touchStart($event)"
      v-on:touchmove="state.touchHelper.cancelTouch($event)"
      v-on:touchend="state.touchHelper.touchEnd($event)"
    >
      <SortIcon
        v-if="state.isMouseOver"
        v-bind:color="$props.color"
        v-bind:sortAscending="$props.sortType === 'Ascending'"
        v-bind:sortEnabled="$props.sortType !== 'None' && enabled"
      />
    </div>
  </div>
</template>

<style scoped lang="less">
.sort-cell {
  width: 100%;
  height: 100%;

  .click-tap-area {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .sortIcon {
      width: 23px;
    }
  }
}
</style>
