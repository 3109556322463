import { appResources } from "@/app-resources";

export function useHelpText() {
  function kpiHeaderTexts(
    isExcludedFromScaling: boolean,
    isReadyHot: boolean,
    showSparkline: boolean,
    canClick: boolean,
    kpiScaleIndex: number,
    columnIndex: number
  ): string {
    const texts = [kpiHeaderNavigationText(columnIndex, canClick)];

    if (!showSparkline && kpiScaleIndex >= 0) {
      texts.push(scalingText(isExcludedFromScaling, isReadyHot, showSparkline));
    }

    return joinTexts(texts);
  }

  function kpiHeaderNavigationText(columnIndex: number, isClickable: boolean): string {
    if (!isClickable) {
      return "";
    }
    if (columnIndex === -1) {
      return appResources.helpTexts.changePeriod;
    }
    if (columnIndex === 0) {
      return appResources.helpTexts.changeValueGroup;
    }
    return appResources.helpTexts.changeDeviation;
  }

  function kpiValueTexts(
    isExcludedFromScaling: boolean,
    isReadyHot: boolean,
    kpiScaleIndex: number,
    columnIndex: number,
    hasStructures: boolean,
    showSparkline: boolean,
    showStructureElements: boolean
  ): string {
    if (isReadyHot) {
      return scalingText(isExcludedFromScaling, isReadyHot, showSparkline);
    }

    if (kpiScaleIndex !== columnIndex) {
      return otherKpiValueText(
        isExcludedFromScaling,
        isReadyHot,
        kpiScaleIndex,
        columnIndex,
        hasStructures,
        showSparkline,
        showStructureElements
      );
    }

    return sameKpiValueText(
      isExcludedFromScaling,
      isReadyHot,
      kpiScaleIndex,
      hasStructures,
      showSparkline,
      showStructureElements
    );
  }

  function otherKpiValueText(
    isExcludedFromScaling: boolean,
    isReadyHot: boolean,
    kpiScaleIndex: number,
    columnIndex: number,
    hasStructures: boolean,
    showSparkline: boolean,
    showStructureElements: boolean
  ): string {
    if (kpiScaleIndex === -1) {
      return appResources.helpTexts.changeTypographicalScaling;
    }

    if (columnIndex === -1) {
      return joinTexts([
        appResources.helpTexts.showAccurateNumbers,
        scalingText(isExcludedFromScaling, isReadyHot, showSparkline),
        changePeriodText(showSparkline),
      ]);
    }

    if (showSparkline) {
      return hasStructures ? drillText(showStructureElements) : "";
    }

    return joinTexts([
      scalingText(isExcludedFromScaling, isReadyHot, showSparkline),
      appResources.helpTexts.changeTypographicalScaling,
    ]);
  }

  function sameKpiValueText(
    isExcludedFromScaling: boolean,
    isReadyHot: boolean,
    kpiScaleIndex: number,
    hasStructures: boolean,
    showSparkline: boolean,
    showStructureElements: boolean
  ): string {
    if (!hasStructures) {
      return periodScalingText(
        isExcludedFromScaling,
        isReadyHot,
        showSparkline,
        kpiScaleIndex
      );
    }

    return joinTexts([
      drillText(showStructureElements),
      periodScalingText(isExcludedFromScaling, isReadyHot, showSparkline, kpiScaleIndex),
    ]);
  }

  function periodScalingText(
    isExcludedFromScaling: boolean,
    isReadyHot: boolean,
    showSparkline: boolean,
    kpiScaleIndex: number
  ): string {
    return kpiScaleIndex === -1
      ? changePeriodText(showSparkline)
      : scalingText(isExcludedFromScaling, isReadyHot, showSparkline);
  }

  function scalingText(
    isExcludedFromScaling: boolean,
    isReadyHot: boolean,
    isSparklineVisible: boolean
  ): string {
    if (isSparklineVisible) {
      return "";
    }
    if (isExcludedFromScaling) {
      return isReadyHot
        ? appResources.helpTexts.includeToScalingReadyHot
        : appResources.helpTexts.includeToScaling;
    }

    return isReadyHot
      ? appResources.helpTexts.excludeFromScalingReadyHot
      : appResources.helpTexts.excludeFromScaling;
  }

  function drillText(showStructureElemenst: boolean): string {
    return showStructureElemenst
      ? appResources.helpTexts.closeDrill
      : appResources.helpTexts.drill;
  }

  function changePeriodText(showSparkline: boolean): string {
    return showSparkline ? appResources.helpTexts.changePeriod : "";
  }

  function elementDrillText(
    structureElementsVisible: boolean,
    hasStructures: boolean
  ): string {
    if (!hasStructures) {
      return "";
    }
    return structureElementsVisible
      ? appResources.helpTexts.closeElementDrill
      : appResources.helpTexts.drillElement;
  }

  function removeFilterText(appliedFiltersLength: number): string {
    return appliedFiltersLength > 0 ? appResources.helpTexts.removeFilter : "";
  }

  function sparklineText(isValueSelectionEnabled: boolean): string {
    return isValueSelectionEnabled
      ? appResources.helpTexts.changeSparkline
      : appResources.helpTexts.selectSparkline;
  }

  function showAvailablePortalPagesText(hasMultiplePages: boolean): string {
    return hasMultiplePages ? appResources.helpTexts.showAvailablePortalPages : "";
  }

  function switchPortalPagesText(hasMultiplePages: boolean): string {
    return hasMultiplePages ? appResources.helpTexts.switchPortalPages : "";
  }

  function sparklineButtonText(
    canShowSparklinesButton: boolean,
    isSparklineDisabled: boolean
  ): string {
    if (!canShowSparklinesButton) {
      return "";
    }
    return isSparklineDisabled
      ? appResources.helpTexts.showSparklines
      : appResources.helpTexts.hideSparklines;
  }

  function kpiExpansionText(isShowAllKpiActive: boolean): string {
    return isShowAllKpiActive
      ? appResources.helpTexts.collapseKpis
      : appResources.helpTexts.expandKpis;
  }

  function shareButtonText(isDisabled: boolean): string {
    return isDisabled ? "" : appResources.helpTexts.takeScreenshot;
  }

  function deletePortalTileText(): string {
    return appResources.helpTexts.deletePortalTile;
  }

  function toggleElementInformationValueText(canToggle: boolean): string {
    return canToggle ? appResources.helpTexts.toggleElementInformationValue : "";
  }

  function elementDeltasText(canTogglePercentageMode: boolean): string {
    const texts = [appResources.helpTexts.changeElementDeviation];

    if (canTogglePercentageMode) {
      texts.push(appResources.helpTexts.toggleElementInformationValue);
    }

    return joinTexts(texts);
  }

  function changeStructureText(hasStructurtes: boolean): string {
    return hasStructurtes ? appResources.helpTexts.changeStructure : "";
  }

  function sortElementsText(): string {
    return appResources.helpTexts.sortElements;
  }

  function addFilterText(): string {
    return appResources.helpTexts.addFilter;
  }

  function clonePortalTileText(): string {
    return appResources.helpTexts.clonePortalTile;
  }

  function shrinkDashboardText(): string {
    return appResources.helpTexts.shrinkDashboard;
  }

  function expandDashboardText(): string {
    return appResources.helpTexts.expandDashboard;
  }

  function joinTexts(texts: string[]): string {
    let text = "";
    for (let i = 0; i < texts.length; i++) {
      if (!texts[i] || texts[i].length === 0) {
        continue;
      }

      if (text.length === 0) {
        text = texts[i];
      } else {
        text = [text, texts[i]].join(" | ");
      }
    }

    return text;
  }

  return {
    kpiHeaderTexts,
    kpiValueTexts,
    scalingText,
    elementDrillText,
    elementDeltasText,
    removeFilterText,
    sparklineText,
    showAvailablePortalPagesText,
    switchPortalPagesText,
    sparklineButtonText,
    kpiExpansionText,
    shareButtonText,
    deletePortalTileText,
    toggleElementInformationValueText,
    changeStructureText,
    sortElementsText,
    addFilterText,
    clonePortalTileText,
    shrinkDashboardText,
    expandDashboardText,
  };
}
