import { isNull } from "lodash";

export class HideAndShowHelper {
  static isScrollable(el: Element, index: number = null): boolean {
    const hideAndShowWrapper = ".hideAndShowWrapper";
    let hideAndShow: HTMLDivElement = null;

    if (isNull(index)) {
      hideAndShow = el.querySelector(hideAndShowWrapper) as HTMLDivElement;
    } else {
      const hideAndShows = el.querySelectorAll(hideAndShowWrapper);
      if (index < hideAndShows.length) {
        hideAndShow = hideAndShows[index] as HTMLDivElement;
      }
    }

    return hideAndShow && hideAndShow.scrollWidth > hideAndShow.clientWidth;
  }
}
