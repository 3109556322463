import { ValueVm } from "../value-vm";
import { HistoricKpiValueFm } from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";
import { KpiValueFm } from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import { SharedKpiInfo } from "../shared/shared-kpi-info";
import {
  SparklineVm,
  SparkBarValueVm,
  SparkOrientation,
} from "@/features/dashboard-shared/sparkline";
import { PeriodVm } from "@/features/dashboard/view-models/period-vm";

export class SparklineBuilder {
  buildSparkline(elementValue: ValueVm, sharedKpiInfo: SharedKpiInfo): SparklineVm {
    if (
      !elementValue.backingFm.hasHistoryData ||
      !elementValue.backingFm.historicData ||
      elementValue.backingFm.historicData.length === 0
    )
      throw new Error("ERROR: Tried to create sparklines, but no history data exists");

    const valueFm = elementValue.backingFm;
    const result = new SparklineVm();
    result.backingFm = valueFm;
    result.rowUnit = elementValue.rowUnit;
    result.timeStructure = sharedKpiInfo.timeStructure;

    const validValues = valueFm.historicData.filter((h) => h.value);
    const validOrientations = this._getValueOrientations(validValues, sharedKpiInfo);
    const totalPositive = validOrientations.filter((o) => o === "positive").length;
    const totalNegative = validOrientations.filter((o) => o === "negative").length;
    let makeInvalidNegative = totalPositive === 0;
    if (sharedKpiInfo.invertSign) makeInvalidNegative = !makeInvalidNegative;

    if (totalPositive > 0 && totalNegative === 0) result.barOrientation = "positive";
    else if (totalNegative > 0 && totalPositive === 0) result.barOrientation = "negative";
    else result.barOrientation = "mixed";

    const maxIdx = valueFm.historicData.length - 1;
    for (let idx = 0; idx <= maxIdx; idx++) {
      const histData = valueFm.historicData[idx];
      const prevSparkBarValue =
        idx + 1 <= maxIdx ? valueFm.historicData[idx + 1].value : null;
      const sparkBarValueVm = new SparkBarValueVm();
      this._fillSparkBarValueVm(
        sparkBarValueVm,
        prevSparkBarValue,
        histData,
        makeInvalidNegative,
        sharedKpiInfo,
        elementValue.isAnyPercentageType
      );
      result.sparkBarValues.push(sparkBarValueVm);
    }
    result.initCurrentValue();

    return result;
  }

  clone(original: SparklineVm, cloneBackingFm: KpiValueFm): SparklineVm {
    const clone = new SparklineVm();
    clone.backingFm = cloneBackingFm;
    clone.rowUnit = original.rowUnit;
    clone.barOrientation = original.barOrientation;
    clone.timeStructure = original.timeStructure;
    clone.sparkBarValues = original.sparkBarValues.map((val) => this._cloneBarValue(val));
    clone.initCurrentValue();

    return clone;
  }

  private _cloneBarValue(original: SparkBarValueVm): SparkBarValueVm {
    const clone = new SparkBarValueVm();

    clone.isPositive = original.isPositive;
    clone.dynamicColorValue = original.dynamicColorValue;
    clone.period = original.period.clone();
    clone.value = original.value;
    clone.prevValue = original.prevValue;
    clone.isAnyPercentageType = original.isAnyPercentageType;
    clone.sparklineDeviationValue = original.sparklineDeviationValue;

    return clone;
  }

  private _fillSparkBarValueVm(
    sparkBarValueVm: SparkBarValueVm,
    prevSparkBarValue: number,
    histData: HistoricKpiValueFm,
    makeInvalidNegative: boolean,
    kpiInfo: SharedKpiInfo,
    isAnyPercentageType: boolean
  ): void {
    sparkBarValueVm.period = new PeriodVm(histData.period.id, histData.period.value);
    sparkBarValueVm.value = histData.value;
    sparkBarValueVm.prevValue = prevSparkBarValue;
    sparkBarValueVm.isAnyPercentageType = isAnyPercentageType;
    sparkBarValueVm.sparklineDeviationValue = histData.sparklineDeviationValue;
    sparkBarValueVm.dynamicColorValue = histData.dynamicColorValue;
    sparkBarValueVm.isPositive = this._getIsPositive(
      histData.value,
      makeInvalidNegative,
      kpiInfo
    );
  }

  private _getValueOrientations(
    validValues: HistoricKpiValueFm[],
    sharedKpiInfo: SharedKpiInfo
  ): SparkOrientation[] {
    return validValues.map((v) => {
      let orientation = this._valueToOrientation(
        v.value * Math.sign(sharedKpiInfo.scaleFactor)
      );
      if (sharedKpiInfo.invertSign) {
        if (orientation === "positive") orientation = "negative";
        else if (orientation === "negative") orientation = "positive";
      }

      return orientation;
    });
  }

  private _getIsPositive(
    value: number,
    makeInvalidNegative: boolean,
    kpiInfo: SharedKpiInfo
  ): boolean {
    let isPositive = this._valueToOrientation(value, makeInvalidNegative) === "positive";
    isPositive = kpiInfo.scaleFactor < 0 ? !isPositive : isPositive;
    return kpiInfo.invertSign ? !isPositive : isPositive;
  }

  private _valueToOrientation(
    value: number,
    makeInvalidNegative: boolean = false
  ): SparkOrientation {
    if (this._isNumber(value?.toString())) {
      if (value < 0) return "negative";
      if (value >= 0) return "positive";
    }

    return makeInvalidNegative ? "negative" : "positive";
  }

  private _isNumber(str: string): boolean {
    if (typeof str !== "string") {
      return false;
    }

    if (str.trim() === "") {
      return false;
    }

    return !Number.isNaN(Number(str));
  }
}
