import { DashboardStatusType } from "@/features/dashboard/status-configs/dashboard-status";
import { Cell } from "@/common/components/simple-grid/contracts/cell";
import { sharedDashboardWidths } from "@/features/dashboard/status-configs/shared-widths";
import { GridIds as gridIds } from "@/features/dashboard/status-configs/grid-ids";

// Use any CSS grid unit (px, %, fr, auto) for widths and heights.
// Specify an integer for colSpan/rowSpan to indicate the number of columns/rows to span.
// Set colSpan/rowSpan to -1 to use all available columns/rows.

export class ElementStatusConfig {
  private static readonly _cells: Record<DashboardStatusType, Cell[]> = {
    OneValueNoSparklines: [{ id: gridIds.titleValue, row: 0, col: 0, colSpan: 3 }],
    OneValueNoSparklinesReadyHot: [
      { id: gridIds.titleValue, row: 0, col: 0, colSpan: 3 },
    ],
    ThreeValuesNoSparklinesScaleIndexGreaterZero: [
      { id: gridIds.titleValue, row: 0, col: 0, colSpan: 3 },
      { id: gridIds.deltaValues, row: 0, col: 4 },
    ],
    ThreeValuesNoSparklinesScaleIndexGreaterZeroReadyHot: [
      { id: gridIds.titleValue, row: 0, col: 0, colSpan: 3 },
      { id: gridIds.deltaValues, row: 0, col: 4 },
    ],
    ThreeValuesNoSparklinesScaleIndexZero: [
      { id: gridIds.titleValue, row: 0, col: 0, colSpan: 3 },
      { id: gridIds.deltaValues, row: 0, col: 4 },
    ],
    ThreeValuesNoSparklinesScaleIndexZeroReadyHot: [
      { id: gridIds.titleValue, row: 0, col: 0, colSpan: 3 },
      { id: gridIds.deltaValues, row: 0, col: 4 },
    ],
    NotExtendedAndSparklines: [
      { id: gridIds.titleValue, row: 0, col: 0 },
      { id: gridIds.sparkline, row: 0, col: 1, padding: "6px 0" },
      { id: gridIds.valueGroupValue, row: 0, col: 2 },
    ],
    ExtendedOneValueNoSparklines: [
      { id: gridIds.titleValue, row: 0, col: 0, colSpan: 2 },
    ],
    ExtendedOneValueNoSparklinesReadyHot: [
      { id: gridIds.titleValue, row: 0, col: 0, colSpan: 2 },
    ],
    ExtendedNoSparklines: [
      { id: gridIds.titleValue, row: 0, col: 0, colSpan: 2 },
      { id: gridIds.deltaValue_1, row: 0, col: 3, padding: "0 10px 0 5px" },
      { id: gridIds.deltaValue_2, row: 0, col: 5, padding: "0 24px 0 5px" },
    ],
    ExtendedNoSparklinesReadyHot: [
      { id: gridIds.titleValue, row: 0, col: 0, colSpan: 2 },
      { id: gridIds.deltaValue_1, row: 0, col: 3, padding: "0 10px 0 5px" },
      { id: gridIds.deltaValue_2, row: 0, col: 5, padding: "0 24px 0 5px" },
    ],
    ExtendedAndSparklines: [
      { id: gridIds.titleValue, row: 0, col: 0 },
      { id: gridIds.sparkline, row: 0, col: 1, padding: "6px 0" },
      { id: gridIds.valueGroupValue, row: 0, col: 2 },
      { id: gridIds.deltaValue_1, row: 0, col: 3, padding: "0 10px 0 5px" },
      { id: gridIds.deltaValue_2, row: 0, col: 4, padding: "0 24px 0 5px" },
    ],
  };

  static getCells(dashboardStatusType: DashboardStatusType): Cell[] {
    return this._cells[dashboardStatusType];
  }

  static getWidths(dashboardStatusType: DashboardStatusType): string[] {
    return sharedDashboardWidths[dashboardStatusType];
  }
}
