import { DashboardVm } from "@/features/dashboard/view-models/dashboard-vm";
import {
  IDashboardPortalTilePageViewStateDto,
  IViewStateDto,
  KpiDrillViewStateDto,
  KpiDrillStructureFilterViewStateDto,
  KpiViewStateDto,
  KpiValueGroupViewStateDto,
} from "@/common/service-clients/generated-clients";
import { sparklineModeFromFm } from "@/features/dashboard-shared/sparkline";
import { StructureElementsVm } from "@/features/dashboard/view-models/structure-elements-vm";
import { StructureVm } from "@/features/dashboard/view-models/structure-vm";
import { VsRoot } from "@/services/view-state-service/contract/vs-root";
import {
  IVsApplicator,
  VsApplicatorResult,
} from "@/services/view-state-service/contract/vs-applicator.interface";
import { KpiDrillStructureFilterFm } from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { FmMapperDashboard } from "@/features/dashboard/backend-wrapper/fm-mapper-dashboard";
import { KpiTileVm } from "@/features/dashboard/view-models/kpi-tile-vm";
import { SharedRowStateVm } from "@/features/dashboard/view-models/shared/shared-row-state-vm";
import { ElementVm } from "@/features/dashboard/view-models/element-vm";
import { delay } from "@/common/helper/async-helper";

const MAX_NUM_ELEMENT_VMS_ON_RELOAD = 400;

export class DashboardVsApplicator implements IVsApplicator {
  private _dashboardVm: DashboardVm;
  private _viewState: IDashboardPortalTilePageViewStateDto;
  private _vsResult: VsApplicatorResult = null;

  async applyAsync(
    vm: VsRoot,
    vsToBeApplied: IViewStateDto
  ): Promise<VsApplicatorResult> {
    this._dashboardVm = vm as DashboardVm;
    this._viewState = vsToBeApplied as IDashboardPortalTilePageViewStateDto;
    this._vsResult = VsApplicatorResult.success;

    const viewState = this._viewState;

    if (!viewState) {
      return this._vsResult;
    }

    this._dashboardVm.sharedState.kpiScaleIndex = viewState.scaledColumn;
    this._setFilters();
    this._setKpiFilter();
    this._setHiddenColumn();
    this._setShownKpis();
    this._setCurrentValueGroup();
    this._setDeltaSwiper();
    this._setIsExcludedFromScaling();
    this._setSparklines();
    await this._setDrills(viewState.scaledColumn);

    return this._vsResult;
  }

  private _setHiddenColumn(): void {
    if (this._viewState.shownSecondValue === 1) {
      this._dashboardVm.sharedState.hiddenColumn = 2;
    } else {
      this._dashboardVm.sharedState.hiddenColumn = 1;
    }
  }

  private _setFilters(): void {
    const filters = this._viewState.kpiDrillStructureFilters;

    filters.forEach((filterVs: KpiDrillStructureFilterViewStateDto) => {
      const filter = new KpiDrillStructureFilterFm();
      filter.elementId = filterVs.structureElementName;
      filter.structureNameId = filterVs.structureName;
      this._dashboardVm.filters.addStructureFilter(filter);
    });

    this._dashboardVm.sharedState.animateSetFilter = false;
  }

  private _setKpiFilter(): void {
    const shownKpis = this._viewState.kpis.filter((kpi) => kpi.isShown);
    if (shownKpis.length <= 1) {
      return;
    }

    this._dashboardVm.dbSettings.applyKpiIdFilter = false;
  }

  private _setCurrentValueGroup(): void {
    for (const kpiVm of this._dashboardVm.kpiTileVms) {
      const kpiVs = this._viewState.kpis.find(
        (vs) => vs.kpiId.id === kpiVm.backingFm.kpiId.id
      );

      if (!kpiVs) {
        continue;
      }

      const valGrpVs = kpiVs.kpiValueGroups.find(
        (kpiValueGroup) => kpiValueGroup.isSelected
      );

      if (!valGrpVs) {
        continue;
      }

      const valGrpVm = kpiVm.valueGroupVms.find(
        (vm) => vm.id === valGrpVs.kpiValueGroupId.id
      );
      if (!valGrpVm) {
        continue;
      }

      const index = kpiVm.valueGroupVms.indexOf(valGrpVm) + 1;
      kpiVm.valueGroupSwiperVm.swipeTo(index, false);

      if (!valGrpVm.kpiValues[0].sparkline) {
        continue;
      }
      // TODO: Set selection mode and scrolledBars per timeStructure.
      // (VS definitions need to be changed).
      const periodIndex = valGrpVm.kpiValues[0].sparkline.sparkBarValues.length - 1;
      kpiVm.periodSwiperVm.swipeTo(periodIndex, false);
    }
  }

  private _setDeltaSwiper(): void {
    if (this._dashboardVm.deltaValuesSwiperVm) {
      const deltaValuesActiveIndex =
        this._dashboardVm.sharedState.hiddenColumn === 1 ? 2 : 1;
      this._dashboardVm.deltaValuesSwiperVm.swipeTo(deltaValuesActiveIndex);
    } else {
      this._dashboardVm.initDeltaValuesSwiper();
    }
  }

  private _setShownKpis(): void {
    this._dashboardVm.dbSettings.shownKpiIds = this._viewState.kpis
      .filter((kpi) => kpi.isShown)
      .map((kpi) => kpi.kpiId.id);

    this._handleError_NoShownKPIinVS();
  }

  private _handleError_NoShownKPIinVS() {
    // silent error handling: if a vs does not provide any (valid) visible
    // KPI, use at least the current first KPI.
    if (
      this._dashboardVm.dbSettings.shownKpiIds.length === 0 &&
      this._dashboardVm.kpiTileVms.length > 0
    ) {
      const firstKpiId = this._dashboardVm.kpiTileVms[0].backingFm.id;
      this._dashboardVm.dbSettings.shownKpiIds.push(firstKpiId);
      this._vsResult.updateFrom(VsApplicatorResult.failMissingViewState);
    }
  }

  private _setIsExcludedFromScaling(): void {
    const isAnyExcludeFromScaling = this._getIsAnyExcludedFromScaling();

    if (isAnyExcludeFromScaling) {
      this._scaleAllValues();
    }

    for (let kpiIndex = 0; kpiIndex < this._viewState.kpis.length; kpiIndex++) {
      const kpiVs = this._viewState.kpis[kpiIndex];
      const kpiTileVm = this._dashboardVm.kpiTileVms.find(
        (ktvm) => ktvm.kpiInfo.kpiId === kpiVs.kpiId.id
      );

      for (
        let valueGroupIndex = 0;
        valueGroupIndex < kpiVs.kpiValueGroups.length;
        valueGroupIndex++
      ) {
        const valueGroupVs = kpiVs.kpiValueGroups[valueGroupIndex];
        const valueGroupVm = kpiTileVm.valueGroupVms.find(
          (vg) => vg.valueGroupId === valueGroupVs.kpiValueGroupId.id
        );

        for (let valueIndex = 0; valueIndex < valueGroupVs.values.length; valueIndex++) {
          const valueVs = valueGroupVs.values[valueIndex];
          const valueVm = valueGroupVm.kpiValues[valueIndex];

          valueVm.excludedFromScaling = valueVs.isExcludedFromScaling;
        }
      }
    }
  }

  private _setSparklines(): void {
    const dashboardVm = this._dashboardVm;
    const sharedState = dashboardVm.sharedState;
    const sparklineState = sharedState.sparklineState;

    if (this._viewState.sparkline.sparklineMode === "None") {
      return;
    }

    this._setSparklinesBarSelection();
    const sparklineMode = sparklineModeFromFm(this._viewState.sparkline.sparklineMode);
    sharedState.kpiScaleIndexBeforeSparklines = sharedState.kpiScaleIndex;
    dashboardVm.updateMaxNumberSparklines();
    sparklineState.mode = sparklineMode;
    sparklineState.disableAnimationOnce();
  }

  private _setSparklinesBarSelection(): void {
    // TODO: Set selection mode and scrolledBars per timeStructure.
    //  (VS definitions need to be changed).
  }

  private async _setDrills(kpiScaleIndex: number): Promise<void> {
    for (const kpiVm of this._dashboardVm.shownKpiTileVms) {
      await this._setDrill(kpiVm, kpiScaleIndex);
    }
  }

  private async _setDrill(kpiTileVm: KpiTileVm, kpiScaleIndex: number): Promise<void> {
    const selectedValueGroupVs = this._getSelectedValueGroupVS(kpiTileVm);

    if (!selectedValueGroupVs) {
      return;
    }

    const firstDrillVs = this._getFirstDrillVS(selectedValueGroupVs.drills);

    if (!firstDrillVs) {
      return;
    }

    await this._processDrill(
      kpiScaleIndex,
      kpiTileVm.currentValueGroup.kpiValues[0].parentRowState,
      kpiTileVm.currentValueGroup.structureElementsVm,
      kpiTileVm.currentValueGroup.availableStructures,
      firstDrillVs,
      selectedValueGroupVs.drills
    );
  }

  private async _processDrill(
    kpiScaleIndex: number,
    parentRowState: SharedRowStateVm,
    structureElementsVm: StructureElementsVm,
    availableStructures: StructureVm[],
    drillVS: KpiDrillViewStateDto,
    drillVSs: KpiDrillViewStateDto[]
  ): Promise<void> {
    parentRowState.isPercentageModeActive = drillVS.isPercentageMode;

    await this._setStructureElements(
      structureElementsVm,
      availableStructures,
      drillVS,
      kpiScaleIndex
    );

    await this._processElements(structureElementsVm, drillVSs, drillVS, kpiScaleIndex);
  }

  private async _setStructureElements(
    structureElementsVm: StructureElementsVm,
    availableStructuresVm: StructureVm[],
    drillVs: KpiDrillViewStateDto,
    kpiScaleIndex: number,
    filtersFm: KpiDrillStructureFilterFm[] = []
  ): Promise<void> {
    const structureElementsIndex =
      availableStructuresVm.findIndex((s) => s.id === drillVs.kpiDrillQuery.groupBy.id) +
      1;
    structureElementsVm.structureElementsSwiperVm.swipeTo(structureElementsIndex);
    structureElementsVm.show(kpiScaleIndex, availableStructuresVm, filtersFm);
    structureElementsVm.currentStructureElementsListVm.drillResultLimit =
      drillVs.kpiDrillQuery.resultLimit;
    const extendRows = false;
    const deltaValuesActiveIndex = drillVs.shownSecondValue;
    structureElementsVm.sortedColumn = drillVs.kpiDrillQuery.kpiSortParameter.valueId.id;
    structureElementsVm.currentStructureElementsListVm.previousFilters =
      drillVs.kpiDrillQuery.kpiDrillStructureFilters.map((f) => {
        const filter = new KpiDrillStructureFilterFm();
        filter.elementId = f.structureElementName;
        filter.structureNameId = f.structureName;
        return filter;
      });
    structureElementsVm.sortType = FmMapperDashboard.mapSortTypeFm(
      drillVs.kpiDrillQuery.kpiSortParameter.sortType
    );
    await structureElementsVm.currentStructureElementsListVm.updateElementsAsync(
      extendRows,
      this._dashboardVm.sharedState.kpiScaleIndex,
      deltaValuesActiveIndex,
      structureElementsVm.sortType,
      structureElementsVm.sortedColumn
    );
    structureElementsVm.currentStructureElementsListVm.scaleValuesForDrill(
      this._dashboardVm.sharedState
    );
  }

  private async _processElements(
    structureElementsVm: StructureElementsVm,
    drillVSs: KpiDrillViewStateDto[],
    parentDrillVs: KpiDrillViewStateDto,
    kpiScaleIndex: number
  ): Promise<void> {
    const currentDrillVSs = this._getChildrenDrillVSs(parentDrillVs, drillVSs);
    const elementVmsWithDrills = await this._reloadElementsIfNecessary(
      currentDrillVSs,
      parentDrillVs,
      kpiScaleIndex,
      structureElementsVm
    );
    await this._processElementVmsFirst(elementVmsWithDrills, drillVSs, kpiScaleIndex);
  }

  private async _processElementVmsFirst(
    elementsVsResults: ElementVsResult[],
    drillVSs: KpiDrillViewStateDto[],
    kpiScaleIndex: number
  ): Promise<void> {
    for (let i = 0; i < elementsVsResults.length; i++) {
      const elementVm = elementsVsResults[i].elementVm;
      const drillVS = elementsVsResults[i].drillVS;
      await this._processDrill(
        kpiScaleIndex,
        elementVm.nextStructureElements.parentRowState,
        elementVm.nextStructureElements,
        elementVm.nextStructureVms,
        drillVS,
        drillVSs
      );
    }
  }

  private async _reloadElementsIfNecessary(
    drillVSs: KpiDrillViewStateDto[],
    parentDrillVS: KpiDrillViewStateDto,
    kpiScaleIndex: number,
    structureElementsVm: StructureElementsVm
  ): Promise<ElementVsResult[]> {
    // When VS is sorted alphabetically and the language changes, it can happen that an
    // Element that has a drillVS is not loaded. In this case, the drill needs to be reloaded
    // so the drill can be opened.
    const elementVsResults: ElementVsResult[] = [];
    const elementIndexes: number[] = [];
    let dataWasReloaded = false;

    for (let i = 0; i < drillVSs.length; i++) {
      const drillVs = drillVSs[i];
      let result = this._getElementVsResult(
        drillVs,
        structureElementsVm.currentElementVms
      );

      while (!result) {
        if (
          structureElementsVm.currentStructureElementsListVm.drillResultLimit >
          MAX_NUM_ELEMENT_VMS_ON_RELOAD
        ) {
          break;
        }

        dataWasReloaded = true;
        structureElementsVm.currentStructureElementsListVm.drillResultLimit *= 2;
        structureElementsVm.currentStructureElementsListVm.elementVms.splice(0);
        const extendRows = false;
        await delay(50);
        await structureElementsVm.currentStructureElementsListVm.updateElementsAsync(
          extendRows,
          kpiScaleIndex,
          parentDrillVS.shownSecondValue,
          structureElementsVm.sortType,
          structureElementsVm.sortedColumn
        );
        result = this._getElementVsResult(drillVs, structureElementsVm.currentElementVms);
      }

      if (!result) {
        continue;
      }

      elementVsResults.push(result);
      elementIndexes.push(result.elementIndex);
    }

    if (dataWasReloaded) {
      const maxIndex = Math.max(...elementIndexes) + 1;
      structureElementsVm.currentStructureElementsListVm.elementVms.splice(maxIndex);
      structureElementsVm.currentStructureElementsListVm.scaleValuesForDrill(
        this._dashboardVm.sharedState
      );

      return elementVsResults.map((elementVsResult) =>
        this._getElementVsResult(
          elementVsResult.drillVS,
          structureElementsVm.currentElementVms
        )
      );
    }

    return elementVsResults;
  }

  private _scaleAllValues(): void {
    const tmpScalingContext = this._dashboardVm.fontScaler.presetScalingContext;
    this._dashboardVm.fontScaler.presetScalingContext = null;
    const ignoreExcludeFromScaling = true;
    this._dashboardVm.refreshColorAndScaling(ignoreExcludeFromScaling);
    this._dashboardVm.fontScaler.presetScalingContext = tmpScalingContext;
  }

  private _getIsAnyExcludedFromScaling(): boolean {
    return !!this._viewState.kpis.find(
      (kpiVs) =>
        !!kpiVs.kpiValueGroups.find(
          (valueGroupVs) =>
            !!valueGroupVs.values.find((kpiValue) => kpiValue.isExcludedFromScaling)
        )
    );
  }

  private _getSelectedValueGroupVS(kpiTileVm: KpiTileVm): KpiValueGroupViewStateDto {
    const kpiVS = this._getKpiVS(kpiTileVm);
    return kpiVS?.kpiValueGroups?.find((valGrp) => valGrp.isSelected);
  }

  private _getKpiVS(kpiTileVm: KpiTileVm): KpiViewStateDto {
    return this._viewState.kpis.find(
      (vs) => vs.kpiId.id === kpiTileVm.backingFm.kpiId.id
    );
  }

  private _getFirstDrillVS(drillVSs: KpiDrillViewStateDto[]): KpiDrillViewStateDto {
    // The first drill has no parentReferenceKey, and there is only one.
    return drillVSs.find((drillVs) => !drillVs.parentReferenceKey);
  }

  private _getChildrenDrillVSs(
    parentVS: KpiDrillViewStateDto,
    drillVSs: KpiDrillViewStateDto[]
  ): KpiDrillViewStateDto[] {
    return drillVSs.filter(
      (drillVS) => drillVS.parentReferenceKey === parentVS.referenceKey
    );
  }

  private _getElementVsResult(
    drillVS: KpiDrillViewStateDto,
    elementVms: ElementVm[]
  ): ElementVsResult {
    for (let i = 0; i < elementVms.length; i++) {
      const elementVm = elementVms[i];
      if (
        elementVm.backingFm.elementId.id === drillVS.elementId.id &&
        elementVm.backingFm.elementId.structureId.id === drillVS.elementId.structureId.id
      ) {
        return new ElementVsResult(drillVS, elementVm, i);
      }
    }
    return null;
  }
}

class ElementVsResult {
  constructor(
    public drillVS: KpiDrillViewStateDto,
    public elementVm: ElementVm,
    public elementIndex: number
  ) {}
}
