export function isMouseLeftHover(
  mousePosition: number,
  element: HTMLDivElement
): boolean {
  const rect = element.getBoundingClientRect();
  const position = mousePosition - rect.left;
  const halfItem = rect.width / 2;
  return position >= 0 && position <= halfItem;
}

export function isMouseRightHover(
  mousePosition: number,
  element: HTMLDivElement
): boolean {
  const rect = element.getBoundingClientRect();
  const position = mousePosition - rect.left;
  const halfItem = rect.width / 2;
  return position > halfItem && position < rect.width;
}

export function getNumberPxsOutOfCenter(
  mousePosition: number,
  element: HTMLDivElement
): number {
  const rect = element.getBoundingClientRect();
  const position = mousePosition - rect.left;
  const halfItem = rect.width / 2;
  return Math.abs(position - halfItem);
}

export function isInRect(x: number, y: number, rect: DOMRect): boolean {
  return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
}

export function isLeftDownInEvent(ev: MouseEvent): boolean {
  return ev.buttons % 2 !== 0;
}

export class Timeouts {
  static readonly removeTouchMouseDown = 300;
  static readonly doubleClickTime_ms = 250;
  static readonly longTouchTime_ms = 500;
  static readonly longClickTime_ms = 500;
}
