export class DefaultStyles {
  static readonly colorConstants = {
    baseRedHEX: "#bd0119",
    baseBlueHEX: "#012d71",
    neutralTextHEX: "#848484",
    neutralTextRGB: "rgb(132, 132, 132)",
    darkTextHEX: "#646464",
    headerTextHEX: "#c9c9c9",
    bgWhite: "#ffffff",
    bgGray: "#e6e6e6",
    weatherMinus3: "#bd0119",
    weatherMinus2: "#d04d5e",
    weatherMinus1: "#e499a3",
    weatherMinus0: "#ababab",
    weatherPlus1: "#99abc6",
    weatherPlus2: "#4d6c9b",
    weatherPlus3: "#012d71",
    editYellow: "#ff9900",
  };

  static readonly fontsize = {
    kpiTileTitle: "12pt",
  };

  /**
   * @param colorHEX hex color string without an aplha hex part
   * @param alpha value between 0 and 1, with 0 == invisible
   */
  static getHEXAlphaColor(colorHEX: string, alpha: number): string {
    if (!(colorHEX.length === 7 || colorHEX.length === 4))
      throw Error(
        "Must be valid non-alpha hexColor (3 or 6 digits). Given color was: " + colorHEX
      );

    if (alpha < 0 || alpha > 1)
      throw Error("Alpha values need to be floats between 0 and 1. Got value: " + alpha);

    const alphaHEX = Math.round(alpha * 255);
    let alphaHEXText = alphaHEX.toString(16);

    if (alphaHEXText.length === 1) alphaHEXText = "0" + alphaHEXText;

    return colorHEX + alphaHEXText;
  }
}
