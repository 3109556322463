<script lang="ts">
import { computed, defineComponent } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    mainColor: { type: String, default: "#000" },
  },
  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      const usedColor = props.mainColor;
      return {
        "--main-color": usedColor,
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="dialogCheckIcon" v-bind:style="colorVars">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="156.592px"
      height="156.592px"
      viewBox="0 0 156.592 156.592"
      style="enable-background: new 0 0 156.592 156.592"
      xml:space="preserve"
    >
      <g>
        <circle cx="78.296" cy="78.296" r="72.296" />
        <polyline points="38.168,83.578 62.692,108.103 119.424,51.371 	" />
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.dialogCheckIcon {
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;

    fill: none;
    stroke: var(--main-color);
    stroke-width: 12;
    stroke-miterlimit: 10;

    circle {
      stroke-width: 12;
    }

    polyline {
      stroke-width: 6;
    }
  }
}
</style>
