<script lang="ts">
import { defineComponent, computed, ref, PropType } from "vue";
import HideAndShow from "@/common/components/hide-and-show.vue";
import NavigationIcon from "@/common/components/icons/navigation-icon.vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  components: { NavigationIcon, HideAndShow },
  props: {
    displayText: { type: String, required: true },
    justifyContent: { type: String, default: "flex-end" },
    showLeftArrow: { type: Boolean, default: false },
    showRightArrow: { type: Boolean, default: false },
    showCursor: { type: Boolean, default: false },
    maxFontSize: { type: String, default: null },
    fontSize: { type: String, default: "12px" },
    fontColor: { type: String, default: "var(--color_bg_white)" },
    disableAnimation: { type: Boolean, default: true },
    cellStyle: { type: Object as PropType<CSSProperties>, default: null },
    applyFontOpacity: { type: Boolean, default: false },
  },
  setup(props) {
    const ref_navigationCell = ref(null);

    //
    // Computeds:
    // --------------------
    const navigationCellStyle = computed<CSSProperties>(() => {
      const styles: CSSProperties = {
        "--justifyContent": props.justifyContent,
        "--fontSize": props.fontSize,
        "--maxFontSize": props.maxFontSize,
      };

      if (!props.cellStyle) {
        return styles;
      }

      return { ...styles, ...props.cellStyle };
    });

    const colorStyle = computed<CSSProperties>(() => {
      return {
        opacity: props.applyFontOpacity ? "0.6" : "1",
        color: props.fontColor,
      };
    });

    const navigationIconStyle = computed(() => {
      return {
        height: "calc(" + props.fontSize + " - 3px)",
        margin: "0 6px",
      };
    });

    const navigationIconColor = computed<string>(() => {
      return "var(--headerTextColor)";
    });

    return {
      ref_navigationCell,
      colorStyle,
      navigationCellStyle,
      navigationIconStyle,
      navigationIconColor,
    };
  },
});
</script>

<template>
  <div
    ref="ref_navigationCell"
    class="navigation-cell"
    v-bind:class="{
      'is-cursor-active': $props.showCursor,
      'is-left-arrow-active': $props.showLeftArrow,
      'is-right-arrow-active': $props.showRightArrow,
    }"
    v-bind:style="navigationCellStyle"
  >
    <NavigationIcon
      v-if="$props.showLeftArrow"
      v-bind:style="navigationIconStyle"
      v-bind:color="navigationIconColor"
      v-bind:isActive="true"
      v-bind:isHidden="false"
      v-bind:rotate="0"
    />
    <HideAndShow
      v-bind:style="colorStyle"
      v-bind:disableAnimation="$props.disableAnimation"
    >
      <span v-if="$props.maxFontSize" class="max-size-setter">|</span>
      {{ $props.displayText }}
    </HideAndShow>
    <NavigationIcon
      v-if="$props.showRightArrow"
      v-bind:style="navigationIconStyle"
      v-bind:color="navigationIconColor"
      v-bind:isActive="true"
      v-bind:isHidden="false"
      v-bind:rotate="180"
    />
  </div>
</template>

<style scoped lang="less">
.navigation-cell {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: var(--justifyContent);
  font-size: var(--fontSize);
  padding-right: 10px;
  padding-left: 5px;

  &.is-cursor-active {
    cursor: pointer;
  }

  &.is-left-arrow-active {
    padding-left: 0;
  }

  &.is-right-arrow-active {
    padding-right: 0;
  }

  .max-size-setter {
    font-size: var(--maxFontSize);
    // needed for safari:
    // spans without content are not rendered in safari
    color: transparent;
    display: inline-block;
    width: 0;
  }
}
</style>
