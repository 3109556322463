import { KpiValueFm } from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import { TimeStructureTypeFm } from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";
import { SparkBarValueVm } from "./spark-bar-value-vm";
import { BarAnimation } from "./animations/bar-animation";
import { SparkOrientation, SparklineState } from "./sparkline-common";
import { BarAnimationState } from "./animations/bar-animation-state";
import { SharedSparklineState } from "./shared-sparkline-state";

export class SparklineVm {
  private _currentValue: SparkBarValueVm;

  animations: BarAnimation[] = [];
  backingFm: KpiValueFm;
  sparkBarValues: SparkBarValueVm[] = [];
  barOrientation: SparkOrientation = "positive";
  timeStructure: TimeStructureTypeFm;
  rowUnit: string;

  getAnimations(barAnimationState: BarAnimationState): BarAnimation[] {
    const animations: BarAnimation[] = [];
    barAnimationState.sparklineState = this.getSparklineState();
    barAnimationState.barOrientation = this.barOrientation;

    for (let i = 0; i < this.sparkBarValues.length; i++) {
      barAnimationState.index =
        barAnimationState.sparklinesDisplayState.maxNumBars - 1 - i;
      barAnimationState.prevAnimation =
        i < this.animations.length ? this.animations[i] : null;
      const barAnimation = this.sparkBarValues[i].getBarAnimation(barAnimationState);
      animations.push(barAnimation);
    }

    this.animations = animations;
    return animations;
  }

  // TODO: perhaps this is builder logic
  initCurrentValue(): void {
    if (!this.sparkBarValues || !this.sparkBarValues.length) return;

    this._currentValue = new SparkBarValueVm(this.sparkBarValues[0]);
  }

  getCurrentValue(state: SharedSparklineState): SparkBarValueVm {
    const idx = this.getCurrentHistIdx(state);

    const listValue = this.sparkBarValues[idx];
    this._currentValue.period = listValue.period;

    // TODO: perhaps don't do this here. Better: within vue file and via builder?
    this._currentValue.updateFrom(listValue);

    return this._currentValue;
  }

  getCurrentHistIdx(state: SharedSparklineState): number {
    const maxPossibleIdx = this.sparkBarValues.length - 1;
    if (!state) {
      return maxPossibleIdx;
    }

    let idx = state.selection[this.timeStructure].historyIndex;
    idx = Math.max(idx, 0);
    idx = Math.min(idx, maxPossibleIdx);

    return idx;
  }

  getSparklineState(): SparklineState {
    const sparklineState = new SparklineState();
    const maxAndAvg = this._getMaxAndAvg();
    sparklineState.positiveMax = maxAndAvg.positiveMax;
    sparklineState.negativeMax = maxAndAvg.negativeMax;
    sparklineState.localSparklineAvg = maxAndAvg.avg;
    sparklineState.localSparklineAvgMaxGap = this._getMaxAvgGap(maxAndAvg.avg);
    return sparklineState;
  }

  private _getMaxAndAvg(): { avg: number; positiveMax: number; negativeMax: number } {
    if (!this.hasSparkline) {
      return { avg: 0, positiveMax: 0, negativeMax: 0 };
    }

    const historicData = this.backingFm.historicData;
    let sum = 0.0;
    let positiveMax = 0.0;
    let negativeMax = 0.0;

    for (let i = 0; i < historicData.length; i++) {
      const value = historicData[i].value;
      const valueAbs = Math.abs(value);
      sum += value;
      if (value < 0) {
        negativeMax = Math.max(valueAbs, negativeMax);
      } else {
        positiveMax = Math.max(valueAbs, positiveMax);
      }
    }
    const avg = sum / historicData.length;
    return { avg: avg, positiveMax: positiveMax, negativeMax: negativeMax };
  }

  private get hasSparkline(): boolean {
    return (
      this.backingFm.hasHistoryData &&
      this.backingFm.historicData &&
      this.backingFm.historicData.length > 0
    );
  }

  private _getMaxAvgGap(avg: number): number {
    let maxAvgGap = 0.0;

    this.backingFm.historicData.map((h) => {
      const avgGap = Math.abs(h.value - avg);
      if (avgGap > maxAvgGap) maxAvgGap = avgGap;
    });
    return maxAvgGap;
  }
}
