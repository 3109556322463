import { HistoricKpiValueFm } from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";

export class KpiValueFm {
  kpiValueId: KpiValueIdFm;
  name: string;
  value: number;
  format: string;
  hasHistoryData: boolean;
  historicData: HistoricKpiValueFm[];
  isStatus: boolean;
  statusTextsByValue: { [key: string]: string };
  dynamicColorValue: number;
  aggregationType: AggregationTypeFm;
  isPercentageAdjustmentRequired: boolean;
  isKpiSwitchActive: boolean;

  constructor(data?: KpiValueFm) {
    if (!data) {
      return;
    }

    this.kpiValueId = new KpiValueIdFm(data.kpiValueId);
    this.name = data.name;
    this.value = data.value;
    this.format = data.format;
    this.hasHistoryData = data.hasHistoryData;
    if (data.historicData) {
      this.historicData = data.historicData.map(
        (historicData) => new HistoricKpiValueFm(historicData)
      );
    } else {
      this.historicData = [];
    }
    this.isStatus = data.isStatus;
    this.statusTextsByValue = {};
    if (data.statusTextsByValue) {
      for (const key in data.statusTextsByValue) {
        this.statusTextsByValue[key] = data.statusTextsByValue[key];
      }
    }
    this.dynamicColorValue = data.dynamicColorValue;
    this.aggregationType = data.aggregationType;
    this.isPercentageAdjustmentRequired = data.isPercentageAdjustmentRequired;
    this.isKpiSwitchActive = data.isKpiSwitchActive;
  }
}

export class StructureIdFm {
  id: number;
  kpiId: KpiIdFm = new KpiIdFm();

  constructor(data?: StructureIdFm) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.kpiId = new KpiIdFm(data.kpiId);
  }

  clone(): StructureIdFm {
    const clone = new StructureIdFm();
    clone.id = this.id;
    clone.kpiId = this.kpiId.clone();
    return clone;
  }
}

export class KpiIdFm {
  id: number;
  dashboardId: DashboardIdFm = new DashboardIdFm();

  constructor(data?: KpiIdFm) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.dashboardId = new DashboardIdFm(data.dashboardId);
  }

  clone(): KpiIdFm {
    const clone = new KpiIdFm();
    clone.id = this.id;
    clone.dashboardId = this.dashboardId.clone();
    return clone;
  }
}

export class KpiValueIdFm {
  id: number;
  kpiValueGroupId = new KpiValueGroupIdFm();

  constructor(data?: KpiValueIdFm) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.kpiValueGroupId = new KpiValueGroupIdFm(data.kpiValueGroupId);
  }
}

export class DashboardIdFm {
  id: number;
  publishedApplicationId: EntityIdFm = new EntityIdFm();

  constructor(data?: DashboardIdFm) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.publishedApplicationId = new EntityIdFm(data.publishedApplicationId);
  }

  clone(): DashboardIdFm {
    const clone = new DashboardIdFm();
    clone.id = this.id;
    clone.publishedApplicationId = this.publishedApplicationId.clone();
    return clone;
  }
}

export class KpiValueGroupIdFm {
  id: number;
  kpiId = new KpiIdFm();

  constructor(data?: KpiValueGroupIdFm) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.kpiId = new KpiIdFm(data.kpiId);
  }

  clone(): KpiValueGroupIdFm {
    const clone = new KpiValueGroupIdFm();
    clone.id = this.id;
    clone.kpiId = this.kpiId.clone();
    return clone;
  }
}

export class ElementIdFm {
  id: string;
  structureId = new StructureIdFm();

  constructor(data?: ElementIdFm) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.structureId = new StructureIdFm(data.structureId);
  }

  clone(): ElementIdFm {
    const clone = new ElementIdFm();
    clone.id = this.id;
    clone.structureId = this.structureId.clone();
    return clone;
  }
}

export class EntityIdFm {
  idType: EntityTypeFm;
  internalId: string;

  constructor(data?: EntityIdFm) {
    if (!data) {
      return;
    }

    this.idType = data.idType;
    this.internalId = data.internalId;
  }

  clone(): EntityIdFm {
    const clone = new EntityIdFm();
    clone.internalId = this.internalId;
    clone.idType = this.idType;
    return clone;
  }
}

export type AggregationTypeFm =
  | "None"
  | "Sum"
  | "Count"
  | "Minimum"
  | "Maximum"
  | "Average"
  | "CountDistinct";

export type EntityTypeFm =
  | "Unknown"
  | "Tenant"
  | "System"
  | "User"
  | "UserData"
  | "UserGroup"
  | "UserGroupData"
  | "ApplicationGroup"
  | "Application"
  | "ApplicationVersion"
  | "PublishedApplicationGroup"
  | "PublishedApplication"
  | "PortalGroup"
  | "Portal"
  | "PortalPage"
  | "PortalTile"
  | "PortalTilePage"
  | "ConnectionGroup"
  | "Connection"
  | "ConnectionSetGroup"
  | "ConnectionSet"
  | "DesignatedConnection"
  | "BlobTransaction"
  | "Blob"
  | "Secret"
  | "DynamicData"
  | "LicenseData"
  | "LicenseAssignment"
  | "ScheduledTask"
  | "RoleAssignment"
  | "UrlApplicationDefinition"
  | "DataDescriptionModel"
  | "DataSource"
  | "OlapCube"
  | "OlapHierarchy"
  | "OlapAttribute"
  | "OlapAttributeSemantic"
  | "OlapHierarchyLevel"
  | "OlapMeasureGroup"
  | "OlapMeasure"
  | "RelationalDataColumn"
  | "RelationalDataColumnSemantic"
  | "RelationalDataTable"
  | "Dm7Application"
  | "ReportDefinition"
  | "ReportingItem"
  | "Axis"
  | "AxisPart"
  | "AxisItem"
  | "AxisHierarchy"
  | "AxisTupleSet"
  | "AxisTuple"
  | "ComponentElementValue"
  | "Query"
  | "Sparkline"
  | "SemanticModel"
  | "Selection"
  | "Structure"
  | "Hierarchy"
  | "HierarchyLevel"
  | "Kpi"
  | "Component"
  | "ComponentElement"
  | "DashboardPortalTilePageViewState"
  | "SparklineViewState"
  | "KpiDrillStructureFilterViewState"
  | "KpiViewState"
  | "KpiValueGroupViewState"
  | "KpiValueViewState"
  | "KpiDrillViewState"
  | "KpiDrillQueryViewState"
  | "DashboardOfflineData"
  | "Dashboard"
  | "Information"
  | "DashboardKpi"
  | "DashboardKpiValueGroup"
  | "DashboardKpiValue"
  | "Transaction"
  | "TransactionItem";
