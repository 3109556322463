<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    heightPx: { type: Number, default: null },
    heightFont: { type: String, default: null },
  },
  setup: function (props) {
    const heightStyle = computed(() => {
      if (props.heightPx !== null) {
        return {
          height: `${props.heightPx}px`,
        };
      } else if (props.heightFont !== null) {
        return {
          "font-size": props.heightFont,
        };
      }
      return undefined;
    });

    return {
      heightStyle,
    };
  },
});
</script>

<template>
  <div class="height-setter" v-bind:style="heightStyle">|</div>
</template>

<style lang="less" scoped>
.height-setter {
  // needed for safari:
  // spans without content are not rendered in safari
  color: transparent;
  display: inline-block;
  width: 0;
}
</style>
