import { DashboardStatusType } from "@/features/dashboard/status-configs/dashboard-status";

// Use any CSS grid unit (px, %, fr, auto) for widths and heights.
// Specify an integer for colSpan/rowSpan to indicate the number of columns/rows to span.
// Set colSpan/rowSpan to -1 to use all available columns/rows.

export const sharedDashboardWidths: Record<DashboardStatusType, string[]> = {
  OneValueNoSparklines: ["85px", "1px", "1fr"],
  OneValueNoSparklinesReadyHot: ["85px", "1px", "1fr"],
  ThreeValuesNoSparklinesScaleIndexGreaterZero: ["85px", "1px", "39.96%", "1px", "1fr"],
  ThreeValuesNoSparklinesScaleIndexGreaterZeroReadyHot: [
    "85px",
    "1px",
    "39.96%",
    "1px",
    "1fr",
  ],
  ThreeValuesNoSparklinesScaleIndexZero: [
    "85px",
    "1px",
    "50.6%",
    "1px",
    "calc(100% - 85px - 1px - 50.6% - 1px)",
  ],
  ThreeValuesNoSparklinesScaleIndexZeroReadyHot: [
    "85px",
    "1px",
    "50.6%",
    "1px",
    "calc(100% - 85px - 1px - 50.6% - 1px)",
  ],
  NotExtendedAndSparklines: [
    "calc((100% - 132px) / 2)",
    "132px",
    "calc((100% - 132px) / 2)",
  ],
  ExtendedOneValueNoSparklinesReadyHot: [
    "calc(100% - 17% - 1px - 26% - 1px - 30%)",
    "17%",
    "1px",
    "26%",
    "1px",
    "30%",
  ],
  ExtendedOneValueNoSparklines: [
    "calc(100% - 17% - 1px - 26% - 1px - 30%)",
    "17%",
    "1px",
    "26%",
    "1px",
    "30%",
  ],
  ExtendedNoSparklines: [
    "calc(100% - 17% - 1px - 26% - 1px - 30%)",
    "17%",
    "1px",
    "26%",
    "1px",
    "30%",
  ],
  ExtendedNoSparklinesReadyHot: [
    "calc(100% - 17% - 1px - 26% - 1px - 30%)",
    "17%",
    "1px",
    "26%",
    "1px",
    "30%",
  ],
  ExtendedAndSparklines: [
    "calc(100% - 132px - 17% - 19% - 22%)",
    "132px",
    "17%",
    "19%",
    "22%",
  ],
};
