<script lang="ts">
import { computed, defineComponent } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    mainColor: { type: String, default: "#000" },
  },
  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      const usedColor = props.mainColor;
      return {
        "--main-color": usedColor,
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="shareIcon" v-bind:style="colorVars">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="121.783px"
      height="132.526px"
      viewBox="0 0 121.783 132.526"
      style="enable-background: new 0 0 121.783 132.526"
      xml:space="preserve"
    >
      <circle cx="26.17" cy="66.265" r="19.17" />
      <circle cx="95.609" cy="106.355" r="19.17" />
      <line x1="42.886" y1="75.653" x2="79.003" y2="96.768" />
      <circle cx="95.609" cy="26.174" r="19.17" />
      <line x1="42.658" y1="56.482" x2="79.003" y2="35.762" />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.shareIcon {
  width: 100%;
  height: 100%;

  svg {
    stroke: var(--main-color);
    stroke-width: 14;
    width: 100%;
    height: 100%;
    fill: none;
    stroke-miterlimit: 10;
    stroke-linejoin: round;
  }
}
</style>
