export class ScalableValueCommon {
  static kpiValue_valueLeftClicked = "kpiValue_valueLeftClicked";
  static kpiValue_valueRightClicked = "kpiValue_valueRightClicked";
}

export class FontConfig {
  minScaledEm: number;
  maxScaledEm: number;
  scalingStepEm: number;
  defaultFontSize: number;

  constructor(data?: Partial<FontConfig>) {
    if (!data) {
      return;
    }

    this.minScaledEm = data.minScaledEm;
    this.maxScaledEm = data.maxScaledEm;
    this.scalingStepEm = data.scalingStepEm;
    this.defaultFontSize = data.defaultFontSize;
  }

  getIncrementsDuration(incrementDurationInSeconds: number): number {
    const increments = (this.maxScaledEm - this.defaultFontSize) / this.scalingStepEm;
    return incrementDurationInSeconds / increments;
  }
}
