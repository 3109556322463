<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    color: { type: String, default: null },
    sortAscending: { type: Boolean, default: false },
    sortEnabled: { type: Boolean, default: false },
  },

  setup(props) {
    const colorStyle = computed<CSSProperties>(() => {
      const hasColor = props.color && props.color !== "";
      return {
        "--color": hasColor ? props.color : "unset",
      };
    });

    return {
      colorStyle,
    };
  },
});
</script>

<template>
  <div
    class="sortIcon"
    v-bind:style="colorStyle"
    v-bind:class="{
      cursorPointer: sortEnabled,
    }"
  >
    <svg
      class="sortSvg"
      v-bind:class="{
        ascending: sortAscending || !sortEnabled,
        descending: !sortAscending || !sortEnabled,
      }"
      xmlns="http://www.w3.org/2000/svg"
      width="171.799px"
      height="43.87px"
      viewBox="0 0 171.799 43.87"
      x="0px"
      y="0px"
    >
      <polygon class="st0" points="87.74,0 43.87,43.87 0,0 " />
      <polygon class="st1" points="84.059,43.87 127.929,0 171.799,43.87 " />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.sortIcon {
  display: flex;
  align-items: flex-end;
  margin: 0 3px;

  svg {
    height: 7px;
    background-color: transparent;
    width: auto;
    fill: var(--color);
  }

  svg.ascending {
    .st0 {
      opacity: 0.1;
      fill-rule: evenodd;
      clip-rule: evenodd;
    }
  }

  svg.descending {
    .st1 {
      opacity: 0.1;
      fill-rule: evenodd;
      clip-rule: evenodd;
    }
  }
}

.sortIcon.cursorPointer {
  cursor: pointer;
}
</style>
