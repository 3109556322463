<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<template>
  <div class="application-wizard_tab-template">
    <div class="flex-wrapper">
      <div class="inputs">
        <slot name="inputFields" />
        <slot name="infotext" />
      </div>
      <slot name="navigation" />
    </div>
    <div>
      <slot name="buttons" />
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";
.application-wizard_tab-template {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
  font-size: 1rem;

  .flex-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .inputs {
      display: flex;
      flex-direction: column;

      width: 80%;
    }
  }
}
</style>
