import { exists } from "@/common/object-helper/null-helper";
import { IColorRequest } from "./color-request.interface";
import { IColor } from "./color.interface";

export function isValuePositive(value: number, factor: number) {
  return value * factor > 0 || (!value && factor > 0);
}

/**
 * Private Defaults
 */
const weatherDefinitions = {
  neutralWeather: { r: 171, g: 171, b: 171 },
  positiveMinWeather: { r: 217, g: 224, b: 234 },
  positiveMaxWeather: { r: 0, g: 45, b: 113 },
  negativeMinWeather: { r: 244, g: 212, b: 216 },
  negativeMaxWeather: { r: 189, g: 0, b: 25 },
};

export class BusinessColor {
  /**
   * Public BI-Color defaults
   */
  static readonly CssStrings = {
    neutral: "rgb(171,171,171)",
  };

  static readonly minColorIntensity = 0.4;

  static determineBusinessColor(colorRequest: IColorRequest): string {
    if (colorRequest.scaleFactor < 0) {
      colorRequest.invertSign = !colorRequest.invertSign;
    }

    if (
      exists(colorRequest.dynamicValue) &&
      BusinessColor._isDynamicColoringActive(colorRequest.dynamicValue)
    )
      return BusinessColor.determineBusinessColorByDynamicValue(
        colorRequest.dynamicValue
      );
    else if (
      colorRequest.biColorThresholds &&
      colorRequest.biColorThresholds.isAnyThresholdActive()
    )
      return BusinessColor.determineBusinessColorByThreshold(colorRequest);
    else return BusinessColor.determineBusinessColorByValue(colorRequest);
  }

  static determineBusinessColorByThreshold(request: IColorRequest): string {
    let { value, max } = request;
    const { factor, scaleFactor: scale, biColorThresholds: thresholds } = request;
    let min: number = null;

    if (thresholds.isBiColorRangeActive() && factor === 1) {
      let rangeLow = thresholds.rangeLow * (factor / scale);
      let rangeHigh = thresholds.rangeHigh * (factor / scale);

      if (Math.sign(scale) == -1) {
        const oldRangeLow = rangeLow;
        rangeLow = rangeHigh;
        rangeHigh = oldRangeLow;
      }

      if (value < rangeLow) {
        value -= rangeLow;
        min = -Math.max(rangeLow, max - rangeHigh);
        max = 0;
      } else if (value <= (rangeLow + rangeHigh) / 2) {
        value -= rangeLow;
        min = 0;
        max = (rangeLow + rangeHigh) / 2 - rangeLow;
      } else if (value <= rangeHigh) {
        value = rangeHigh - value;
        min = 0;
        max = (rangeLow + rangeHigh) / 2 - rangeLow;
      } else {
        value = rangeHigh - value;
        min = -Math.max(rangeLow - min, max - rangeHigh);
        max = 0;
      }
    } else if (thresholds.isThresholdActive()) {
      const threshold = thresholds.threshold / scale;

      value -= threshold;
      min -= threshold;
      max -= threshold;

      if (Math.sign(scale) === -1) {
        value = -value;
        const oldMin = min;
        min = -max;
        max = -oldMin;
      }
    }

    return BusinessColor.determineBusinessColorByValue({ value, max, factor }, min);
  }

  static determineBusinessColorByValue(request: IColorRequest, min = 0): string {
    const { value, max, factor, scaleFactor, invertSign } = request;

    let color: string;
    const effectiveScaleFactor = scaleFactor ?? 1;
    const ratio = BusinessColor._getRatio(value / effectiveScaleFactor, min, max);
    let isPositive = isValuePositive(value, factor);
    if (invertSign) isPositive = !isPositive;

    if (isPositive) {
      color = BusinessColor.getPositiveColor(ratio);
    } else {
      color = BusinessColor.getNegativeColor(ratio);
    }

    return color;
  }

  static determineBusinessColorByDynamicValue(dynamicValue: number): string {
    if (dynamicValue < -1 || dynamicValue > 1)
      throw new Error("Dynamic color values must be between -1 and 1");

    const sign = Math.sign(dynamicValue);
    const absValue = Math.abs(dynamicValue);

    switch (sign) {
      case 1:
        return BusinessColor.getPositiveColor(absValue);
      case -1:
        return BusinessColor.getNegativeColor(absValue);
      default:
        return BusinessColor.CssStrings.neutral;
    }
  }

  /**
   * 1:1 copy from LightApp / aka DeltaApp/ aka DeltaApp:
   *
   * reduced, gradual and forFont are legacy parameters that are also not used in the app.
   * For now they are kept to preserve the functionality, but may be removed during refactorings
   * once sufficient testing for Bi-coloring between bissantz app and web is established.
   */
  private static _getRatio(
    value: number,
    min: number,
    max: number,
    reduced = 0,
    gradual = 0,
    forFont = true
  ): number {
    let result: number;

    if (Math.abs(max - min) < Number.EPSILON) result = 0;
    else if (min > 0) result = (value - min) / (max - min);
    else if (max < 0) result = 1.0 - (value - min) / (max - min);
    else if (value < 0) result = value / Math.min(min, -max);
    else result = value / Math.max(-min, max);

    // fit ratio according to color steps
    if (gradual > 0 && result != 0) {
      result = BusinessColor._fitGradual(result, min, max, gradual);
    }

    if (reduced != 0) {
      // round up/down ratio to reduce number of colors
      result = Math.round(result * reduced) / reduced;
    }

    if (forFont) {
      result = BusinessColor.minColorIntensity + result * 0.6;
    }

    if (result < 0) result = 0;
    else if (result > 1) result = 1;

    return result;
  }

  private static _fitGradual(
    initialRatio: number,
    min: number,
    max: number,
    gradual: number
  ): number {
    let result = initialRatio;
    // mixed scale
    if (min <= 0 && max >= 0) {
      // Wertebereich bzgl. betragsmäßigem Max.
      const range = 1.0 + (-min < max ? -min / max : max / -min);
      let tmp = Math.floor((1.0 / range) * gradual);
      result = Math.floor(result / (1.0 / tmp));
      tmp = Math.floor((1.0 / range) * (gradual - 1));
      if (tmp != 0) result /= tmp;
      else result = 0;
    }
    // only pos/neg scale
    else {
      result = Math.floor(result / (1.0 / gradual));
      result /= gradual - 1;
    }

    return result;
  }

  static getPositiveColor(factor: number = BusinessColor.minColorIntensity): string {
    const interpolatedColor = BusinessColor._linearInterpolateColor(
      weatherDefinitions.positiveMinWeather,
      weatherDefinitions.positiveMaxWeather,
      factor
    );
    return BusinessColor._convertToCss(interpolatedColor);
  }

  static getNegativeColor(factor: number = BusinessColor.minColorIntensity): string {
    const interpolatedColor = BusinessColor._linearInterpolateColor(
      weatherDefinitions.negativeMinWeather,
      weatherDefinitions.negativeMaxWeather,
      factor
    );
    return BusinessColor._convertToCss(interpolatedColor);
  }

  private static _linearInterpolateColor(
    minColor: IColor,
    maxColor: IColor,
    factor: number
  ): IColor {
    return {
      r: minColor.r + Math.round(factor * (maxColor.r - minColor.r)),
      g: minColor.g + Math.round(factor * (maxColor.g - minColor.g)),
      b: minColor.b + Math.round(factor * (maxColor.b - minColor.b)),
    };
  }

  private static _convertToCss(color: IColor): string {
    if (color.a) return `rgba(${color.r},${color.g},${color.b},${color.a})`;

    return `rgb(${color.r},${color.g},${color.b})`;
  }

  private static _isDynamicColoringActive(dynamicColorValue: number): boolean {
    const dynamicColorNotActive = -2.0;
    return Math.abs(dynamicColorValue - dynamicColorNotActive) >= Number.EPSILON;
  }
}
