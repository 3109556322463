export type DashboardStatusType =
  | "OneValueNoSparklines"
  | "ThreeValuesNoSparklinesScaleIndexGreaterZero"
  | "ThreeValuesNoSparklinesScaleIndexZero"
  | "NotExtendedAndSparklines"
  | "ExtendedOneValueNoSparklinesReadyHot"
  | "ExtendedOneValueNoSparklines"
  | "ExtendedNoSparklines"
  | "ExtendedAndSparklines"
  | "OneValueNoSparklinesReadyHot"
  | "ThreeValuesNoSparklinesScaleIndexGreaterZeroReadyHot"
  | "ThreeValuesNoSparklinesScaleIndexZeroReadyHot"
  | "ExtendedNoSparklinesReadyHot";

export class DashboardStatus {
  static getConfig(
    numValues: number,
    scaleIndex: number,
    showSparklines: boolean,
    isExtended: boolean,
    isReadyHot: boolean
  ): DashboardStatusType {
    if (!isExtended && !showSparklines && numValues <= 1) {
      return isReadyHot ? "OneValueNoSparklinesReadyHot" : "OneValueNoSparklines";
    }
    if (!isExtended && !showSparklines && numValues > 1 && scaleIndex > 0) {
      return isReadyHot
        ? "ThreeValuesNoSparklinesScaleIndexGreaterZeroReadyHot"
        : "ThreeValuesNoSparklinesScaleIndexGreaterZero";
    }
    if (!isExtended && !showSparklines && numValues > 1 && scaleIndex <= 0) {
      return isReadyHot
        ? "ThreeValuesNoSparklinesScaleIndexZeroReadyHot"
        : "ThreeValuesNoSparklinesScaleIndexZero";
    }
    if (!isExtended && showSparklines) {
      return "NotExtendedAndSparklines";
    }
    if (isExtended && !showSparklines && numValues <= 1) {
      return isReadyHot
        ? "ExtendedOneValueNoSparklinesReadyHot"
        : "ExtendedOneValueNoSparklines";
    }
    if (isExtended && !showSparklines) {
      return isReadyHot ? "ExtendedNoSparklinesReadyHot" : "ExtendedNoSparklines";
    }
    if (isExtended && showSparklines) {
      return "ExtendedAndSparklines";
    }

    return "ThreeValuesNoSparklinesScaleIndexGreaterZero";
  }

  static isExtended(dashboardStatusType: DashboardStatusType): boolean {
    return (
      dashboardStatusType === "ExtendedNoSparklines" ||
      dashboardStatusType === "ExtendedNoSparklinesReadyHot" ||
      dashboardStatusType === "ExtendedAndSparklines" ||
      dashboardStatusType === "ExtendedOneValueNoSparklines" ||
      dashboardStatusType === "ExtendedOneValueNoSparklinesReadyHot"
    );
  }
}
