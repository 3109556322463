import { createCommonAxiosInstance, smartformsStore } from "@bissantz/smartforms";
import Axios, { AxiosHeaders, AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { logHttpRequest, logHttpResponse } from "./logging/logging-instance";

let axiosInstance: AxiosInstance;

/**
 * Returns the common axios instance that must be for all requests (not sent by SmartForms itself).
 */
export function getCommonAxiosInstance(): AxiosInstance {
  if (axiosInstance) return axiosInstance;

  if (!smartformsStore.application) {
    // When SmartForms is not yet initialized, just use the static Axios instance
    return Axios;
  }

  axiosInstance = createCommonAxiosInstance(
    smartformsStore.application,
    smartformsStore.application.router.options.base
  ); // generated proxies already include /api
  axiosInstance.interceptors.request.use(logHttpRequest); // TODO: logging also for requests from SmartForms data provider
  axiosInstance.interceptors.response.use(logHttpResponse);

  axiosInstance.interceptors.request.use(addRequestLocales);

  // re-map errors into problem details:
  axiosInstance.interceptors.response.use(
    (resp) => resp,
    (error) => {
      if (error?.response) {
        error.response.data = {
          status: error?.response?.status,
          detail: error?.response?.statusText,
        };
      }
      return error?.response ?? error;
    }
  );

  return axiosInstance;
}

// TODO: remove, once implemented in SmartForms: https://bc-tfs.activedirectory.bissantz.de/tfs/DefaultCollection/ExternalTeam/_workitems/edit/25371

function addRequestLocales(req: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  /*
   - X-UiLocale: 
      in case the backend creates any error messages etc., 
      not really used at the moment, since most localization should be done at client
 
   - X-ContentLocale: 
      backend uses that to load e.g. dashboard element names with a specific language 
      or potentially format numbers (not currently used)

      TODO: source should be application.contentLanguage
  */

  if (!req.headers) req.headers = new AxiosHeaders();

  if (!smartformsStore.application) return;

  req.headers.set("X-Ui-Locale", smartformsStore.application.language);
  req.headers.set("X-Content-Locale", smartformsStore.application.language);

  return req;
}
