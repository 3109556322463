<script lang="ts">
import SendMessageIcon from "@/common/components/icons/send-message-icon.vue";
import { ChatMessage } from "@/services/chat-service/chat-message";
import { IChatService } from "@/services/chat-service/chat-service.interface";
import { inject, onMounted, ref, defineComponent, reactive } from "vue";
import isNull from "lodash/isNull";

export default defineComponent({
  components: { SendMessageIcon },

  props: {
    placeHolder: { type: String, required: true },
  },

  setup: function () {
    const chatService: IChatService = inject("chatService");
    const state = reactive({ msg: "" });
    const ref_inputTextBox = ref<HTMLInputElement>();

    onMounted(() => {
      focusInputBox();
    });

    function focusInputBox(): void {
      ref_inputTextBox.value?.focus();
    }

    function onKeyEnterPress(): void {
      if (hasValidMsg()) {
        const chatMessages: ChatMessage[] = [];
        chatMessages.push(new ChatMessage("Text", state.msg));
        chatService.queueQuestion(chatMessages);
      }
      state.msg = null;
    }

    function hasValidMsg(): boolean {
      return !isNull(state.msg) && state.msg.trim().length > 0;
    }

    function onSubmitButtonPress(): void {
      focusInputBox();
      onKeyEnterPress();
    }

    function animationEnded(): void {
      if (chatService.isHidingInputMsgBox) {
        chatService.hideInputMessageBox();
      }
    }

    return {
      state,
      ref_inputTextBox,
      chatService,
      onSubmitButtonPress,
      onKeyEnterPress,
      animationEnded,
    };
  },
});
</script>

<template>
  <div
    class="inputMessageBox"
    v-bind:class="{ leave: chatService.isHidingInputMsgBox }"
    v-on:animationend="animationEnded"
  >
    <input
      ref="ref_inputTextBox"
      class="inputTextBox"
      v-bind:placeHolder="$props.placeHolder"
      v-model="state.msg"
      v-on:keyup.enter="onKeyEnterPress"
    />
    <div class="inputButton" v-on:click="onSubmitButtonPress">
      <SendMessageIcon v-bind:color="'grey'" />
    </div>
  </div>
</template>

<style lang="less">
@import "../../common/styles/media-queries.less";
.inputMessageBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--chat-input-text-box-height);
  --border-radius: 4px;
  animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);

  .inputTextBox {
    background-color: var(--color_bg_white);
    width: 100%;
    height: 30px;
    margin: 0 0 0 30px;
    padding: 0 0 0 10px;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border: 1px transparent;

    .media_isAtleastTablet({ width: 300px; });
    .media_isAtleastLaptop({ width: 380px; });
  }

  .inputButton {
    background-color: var(--color_bg_white);
    width: 30px;
    height: 30px;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    border: 1px transparent;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .sendMessageIcon {
      --iconSize: 13px;
      width: var(--iconSize);
      height: var(--iconSize);
    }
  }
}

.inputMessageBox.leave {
  animation: leave 0.5s;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(0, -1px, 0);
  }

  20%,
  80% {
    transform: translate3d(0, 2px, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(0, -4px, 0);
  }

  40%,
  60% {
    transform: translate3d(0, 4px, 0);
  }
}

@keyframes leave {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(0);
  }
}
</style>
