<script lang="ts">
import ArrowIcon from "@/common/components/icons/arrow-icon.vue";
import CloneIcon from "@/common/components/icons/clone-icon.vue";
import EditIcon from "@/common/components/icons/edit-icon.vue";
import DatabaseIcon from "@/common/components/icons/database-icon.vue";
import { DefaultStyles } from "@/common/styles/default-styles";
import { reactive, defineComponent, PropType, computed } from "vue";
import { appResources } from "@/app-resources";

export default defineComponent({
  components: {
    ArrowIcon,
    CloneIcon,
    EditIcon,
    DatabaseIcon,
  },

  props: {
    activeTab: { type: Number, required: true },
    names: { type: Array as PropType<string[]>, required: true },
    iconComponent: { type: Array as PropType<string[]>, required: true },
  },

  setup(props) {
    const state = reactive({
      bannerTexts: appResources.applicationWizardTexts,
    });

    const greaterIcon = computed<string>(() => {
      return DefaultStyles.colorConstants.headerTextHEX;
    });

    const tabIcon = computed<string>(() => {
      return DefaultStyles.colorConstants.bgWhite;
    });

    function isTabActive(index: number): boolean {
      return props.activeTab === index;
    }

    return {
      // general
      state,
      greaterIcon,
      tabIcon,

      //functions
      isTabActive,
    };
  },
});
</script>

<template>
  <div class="application-wizard_tabs-overview">
    <div class="all-tabs">
      <div
        v-for="(tab, index) in $props.names"
        v-bind:key="index"
        class="single-tab"
        v-bind:class="{ active: isTabActive(index) }"
      >
        {{ tab }}
        <ArrowIcon
          v-if="index < $props.names.length - 1"
          v-bind:initialColor="greaterIcon"
        />
      </div>
    </div>
    <div class="banner-wrapper">
      <div class="banner">
        <component
          class="tab-icon"
          v-bind:is="$props.iconComponent[activeTab]"
          v-bind:initialColor="tabIcon"
          v-bind:mainColor="tabIcon"
        />
        <div class="tab-name">{{ $props.names[activeTab] }}</div>
      </div>
      <div>
        {{ state.bannerTexts.general_banner_step }} {{ $props.activeTab + 1 }}
        {{ state.bannerTexts.general_banner_of }} {{ $props.names.length }}
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";

.application-wizard_tabs-overview {
  .all-tabs {
    box-sizing: border-box;
    display: flex;
    margin: 10px 0 30px 0;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    color: var(--color_headerText);

    .single-tab {
      display: flex;

      &.active {
        color: var(--color_Weather3);
        font-family: @fontSemi;
        letter-spacing: 2px;
      }
    }
  }

  .arrowIcon {
    height: 23px;
    padding: 0 10px;
    cursor: default;
  }

  .banner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -30px;
    background: var(--color_Weather3);
    color: white;
    padding: 10px 80px;
    font-size: 1rem;

    .banner {
      display: flex;
      align-items: center;
    }

    .tab-name {
      letter-spacing: 2px;
    }

    .tab-icon {
      height: 20px;
      cursor: default;
      margin-right: 20px;
    }
  }
}
</style>
