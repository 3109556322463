import { DashboardCommon } from "@/features/dashboard/dashboard-common";
import { computed, inject, onMounted, Ref, watch } from "vue";
import { SharedDashboardStateVm } from "@/features/dashboard/view-models/shared/shared-dashboard-state-vm";
import { IStatusBarService } from "@/services/status-bar-service.interface";
import { ValueGroupVm } from "@/features/dashboard/view-models/value-group-vm";
import { ValueVm } from "@/features/dashboard/view-models/value-vm";

export function useStructureElements(
  context,
  valueGroupVm: Ref<ValueGroupVm>,
  sharedState: SharedDashboardStateVm,
  isExtended: Ref<boolean>,
  isReadyHot: Ref<boolean>,
  isKpiSelected: Ref<boolean>
) {
  const statusBarService = inject<IStatusBarService>("statusBarService");

  //
  // Life Cycle:
  // --------------------
  onMounted(() => {
    valueGroupVm.value.deltaIndexForDrill =
      valueGroupVm.value.deltaValuesSwiperVm.activeIndex;
  });

  //
  // Computeds:
  // --------------------

  const isMovingSwiper = computed<boolean>(() =>
    valueGroupVm.value.swiperVms.some((swiperVm) => swiperVm.isMoving)
  );

  const scaleColor = computed(() => {
    return valueGroupVm.value.getWeatherColor(sharedState.kpiScaleIndex);
  });

  const canCLick = computed<boolean>(
    () => !isReadyHot.value && !isMovingSwiper.value && isKpiSelected.value
  );

  //
  // Functions:
  // --------------------
  function clickHandler(newScaleIndex: number): void {
    if (!canCLick.value) {
      return;
    }

    const structureElementsVm = valueGroupVm.value.structureElementsVm;
    const structureElementsVisibleBefore = structureElementsVm.isVisible;
    valueGroupVm.value.deltaValuesSwiperVm.swipeStarted = false;

    if (sparklinesShouldBeDisabled(newScaleIndex)) {
      context.emit(DashboardCommon.common_hideSparklines, newScaleIndex);
      return;
    }

    valueGroupVm.value.clickColumn(sharedState, newScaleIndex, isExtended.value);

    if (structureElementsVisibleBefore && !structureElementsVm.isVisible) {
      context.emit(
        DashboardCommon.valueGroup_drillClosed,
        valueGroupVm.value.kpiInfo.kpiId
      );
    }
  }

  function sparklinesShouldBeDisabled(activeColumn: number): boolean {
    if (isExtended.value && sharedState.sparklineState.showSparklines) {
      return;
    }

    return (
      activeColumn !== sharedState.kpiScaleIndex &&
      (activeColumn === 1 || activeColumn === 2) &&
      sharedState.sparklineState.showSparklines &&
      !valueGroupVm.value.valueHasSparklines(activeColumn)
    );
  }

  function onPeriodChanged(): void {
    valueGroupVm.value.setSelectedPeriodInDrill();
  }

  function onValueGroupSwiperVmChanged(): void {
    if (
      !valueGroupVm.value.valueGroupSwiperVm.isMoving &&
      !valueGroupVm.value.valueGroupSwiperVm.isSwiping
    ) {
      return;
    }

    valueGroupVm.value.structureElementsVm.hide();
  }

  function deltaSwiperVmIsSwipingChanged(): void {
    sharedState.isSwipingDeltaValues = valueGroupVm.value.deltaValuesSwiperVm.isSwiping;
    if (
      valueGroupVm.value.deltaValuesSwiperVm.isSwiping ||
      valueGroupVm.value.deltaValuesSwiperVm.isMoving ||
      valueGroupVm.value.deltaValuesSwiperVm.swipeStarted
    ) {
      return;
    }

    if (sharedState.kpiScaleIndex > 0) {
      clickHandler(valueGroupVm.value.deltaValuesSwiperVm.activeIndex);
    }

    if (
      valueGroupVm.value.deltaIndexForDrill ===
      valueGroupVm.value.deltaValuesSwiperVm.activeIndex
    ) {
      return;
    }

    valueGroupVm.value.deltaIndexForDrill =
      valueGroupVm.value.deltaValuesSwiperVm.activeIndex;
    sharedState.hiddenColumn =
      valueGroupVm.value.deltaValuesSwiperVm.activeIndex === 2 ? 1 : 2;
  }

  function showStructureElementsChanged() {
    if (valueGroupVm.value.structureElementsVm.isVisible) {
      return;
    }

    statusBarService.hideText();
  }

  function onValueLeftClicked(clickedValue: ValueVm): void {
    clickHandler(clickedValue.columnIdx);
  }

  //
  // Watcher:
  // --------------------
  watch(() => valueGroupVm.value.period, onPeriodChanged);

  watch(
    [
      () => valueGroupVm.value.valueGroupSwiperVm.isMoving,
      () => valueGroupVm.value.valueGroupSwiperVm.isSwiping,
    ],
    onValueGroupSwiperVmChanged
  );

  watch(
    [
      () => valueGroupVm.value.deltaValuesSwiperVm.isSwiping,
      () => valueGroupVm.value.deltaValuesSwiperVm.isMoving,
    ],
    () => deltaSwiperVmIsSwipingChanged()
  );

  watch(
    () => valueGroupVm.value.structureElementsVm.isVisible,
    showStructureElementsChanged
  );

  return { scaleColor, clickHandler, onValueLeftClicked };
}
