<script lang="ts">
import BissantzSpinner from "@/common/components/bissantz-spinner.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BissantzSpinner,
  },
});
</script>

<template>
  <div class="application-wizard_spinner">
    <BissantzSpinner />
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";
.application-wizard_spinner {
  height: 8em;
  width: 8em;
  font-size: 6px;
  margin: auto;
  border-color: var(--color_Weather3);
}
</style>
