import { ChatMessage } from "@/services/chat-service/chat-message";
import { BubbleDirection } from "@/services/chat-service/bubble-direction";

export class Bubble {
  chatMessages: ChatMessage[] = [];
  direction: BubbleDirection = "left";
  constructor(message: ChatMessage[] = [], direction: BubbleDirection) {
    this.chatMessages = message;
    this.direction = direction;
  }
}
