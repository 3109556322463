import { IDisposable } from "../disposable.interface";

export interface Listener<T> {
  (event: T): void;
}

export class TypedEvent<T> {
  private listeners: Listener<T>[] = [];
  private listenersOncer: Listener<T>[] = [];

  on(listener: Listener<T>): IDisposable {
    this.listeners.push(listener);

    return {
      dispose: () => this.removeListener(listener),
    };
  }

  once(listener: Listener<T>): void {
    this.listenersOncer.push(listener);
  }

  removeListener(listener: Listener<T>): void {
    const callbackIndex = this.listeners.indexOf(listener);

    if (callbackIndex > -1) {
      this.listeners.splice(callbackIndex, 1);
    }
  }

  // Not part of the interface:
  // Because clients that register listeners should never be able to emit!
  emit(event: T): void {
    /** Update any general listeners */
    this.listeners.forEach((listener) => listener(event));

    /** Clear the `once` queue */
    if (this.listenersOncer.length > 0) {
      const toCall = this.listenersOncer;
      this.listenersOncer = [];
      toCall.forEach((listener) => listener(event));
    }
  }

  removeAllListeners() {
    this.listeners.splice(0, this.listeners.length);
    this.listenersOncer.splice(0, this.listenersOncer.length);
  }
}
