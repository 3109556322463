export class SharedValuesVm {
  values: number[] = [];

  /// value 0 is information value,
  /// value 1 is first delta value(normally % value)
  /// value 2 is second delta value(normally Abs value)
  constructor(values: number[]) {
    this.values = values;
  }
}
