<script lang="ts">
import ChatIcon from "@/common/components/icons/chat-icon.vue";
import EditIcon from "@/common/components/icons/edit-icon.vue";
import BissantzSpinner from "@/common/components/bissantz-spinner.vue";

import { DefaultStyles } from "@/common/styles/default-styles";
import { LiveFeatureTogglesVm } from "@/features/live-feature-toggles/live-feature-toggles-vm";
import { IChatService } from "@/services/chat-service/chat-service.interface";
import { PortalControlsVm } from "../view-models/controls-vm";

import {
  inject,
  computed,
  defineComponent,
  reactive,
  PropType,
  onBeforeUnmount,
  watch,
} from "vue";
import { VsScopeState } from "@/services/view-state-service/vs-scope-state";
import { INotificationService } from "@/features/notifications/notification-service.interface";
import { appResources } from "@/app-resources";

export default defineComponent({
  components: {
    ChatIcon,
    EditIcon,
    BissantzSpinner,
  },

  props: {
    controlsVm: {
      type: Object as PropType<PortalControlsVm>,
      default: null,
      required: true,
    },
  },

  setup(props) {
    const chatService: IChatService = inject("chatService");
    const liveToggles = inject("liveToggles") as LiveFeatureTogglesVm;
    const notificationService = inject<INotificationService>("notificationService");

    const state = reactive({
      textResources: appResources.portalTexts,
      defaultStyles: DefaultStyles,
      isChatInputActive: false,
      isSyncing: false,
    });

    const vsScopeState = inject<VsScopeState>("vsScopeState");
    const unregisterEvent = vsScopeState.isSyncing.isUsedChangedEvent.on(() => {
      state.isSyncing = vsScopeState.isSyncing.isUsed;
    });

    const alertIfSyncing = (evt: Event) => {
      if (!state.isSyncing) {
        return;
      }

      evt.preventDefault();
      return true;
    };

    window.addEventListener("beforeunload", alertIfSyncing);

    onBeforeUnmount(() => {
      unregisterEvent.dispose();
      window.removeEventListener("beforeunload", alertIfSyncing);
    });

    // Computeds:
    // ----------------
    const showChatBot = computed<boolean>(() => {
      return liveToggles?.isEnabled && liveToggles.toggleValues?.dashboardSearch;
    });

    const showEditButton = computed<boolean>(() => {
      const canShowEditBtn = props.controlsVm.hasEditPermission;
      return canShowEditBtn;
    });

    const isEditDisabled = computed<boolean>(() => {
      return vsScopeState.isInError.value;
    });

    const showEditSpinner = computed<boolean>(() => {
      return state.isSyncing;
    });

    // Event Handler Functions:
    // ----------------
    function onChatIconClick(): void {
      state.isChatInputActive = !chatService.isInputMsgBoxVisible;
      chatService.toggleInputMessageBoxAnimated();
    }

    function onEditButtonClick(): void {
      if (isEditDisabled.value) {
        return;
      }

      if (props.controlsVm.isEditing) {
        disableEditing();
      } else {
        enableEditing();
      }
    }

    function disableEditing(): void {
      props.controlsVm.isEditing = false;
      vsScopeState.isPersistVsChangesAllowed.value = false;
      unregisterEvent.dispose();
      state.isSyncing = false;
    }

    function enableEditing(): void {
      if (!props.controlsVm.hasEditPermission) {
        return;
      }

      props.controlsVm.isEditing = true;
      vsScopeState.isPersistVsChangesAllowed.value = true;
    }

    function onVsScopeErrorChanged() {
      if (!vsScopeState.isInError.value) {
        return;
      }

      disableEditing();
      notificationService.error({
        title: state.textResources.errorVsEditNotPossible,
        message: state.textResources.errorVsOutOfSync,
      });
    }

    watch(() => vsScopeState.isInError.value, onVsScopeErrorChanged);

    return {
      state,
      chatService,

      // computeds:
      showEditButton,
      showChatBot,
      showEditSpinner,
      isEditDisabled,

      // functions
      onChatIconClick,
      onEditButtonClick,
    };
  },
});
</script>

<template>
  <div class="portal-controls-component">
    <!-- E D I T    B U T T O N -->
    <div
      v-if="showEditButton"
      class="controls-container"
      v-bind:class="{
        'is-active': $props.controlsVm.isEditing,
        'is-disabled': isEditDisabled,
      }"
    >
      <div class="button edit" v-on:click="onEditButtonClick">
        <div class="icon-wrapper">
          <BissantzSpinner v-if="showEditSpinner" />
          <EditIcon
            v-else
            v-bind:mainColor="
              $props.controlsVm.isEditing
                ? state.defaultStyles.colorConstants.darkTextHEX
                : state.defaultStyles.colorConstants.headerTextHEX
            "
          />
        </div>
      </div>
    </div>

    <!-- C H A T    B U T T O N -->
    <div
      class="controls-container"
      v-if="showChatBot"
      v-bind:class="{ isActive: state.isChatInputActive }"
      v-on:click="onChatIconClick"
    >
      <div class="button chat">
        <div class="icon-wrapper">
          <ChatIcon
            v-bind:mainColor="
              state.isChatInputActive
                ? state.defaultStyles.colorConstants.darkTextHEX
                : state.defaultStyles.colorConstants.headerTextHEX
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "../../../common/styles/base-styles.less";
@import "../../../common/styles/media-queries.less";

.portal-controls-component {
  position: absolute;
  right: 0;
  width: 40px;

  .controls-container {
    cursor: pointer;

    &.is-active {
      background-color: var(--color_bg-gray);
    }

    &.is-disabled {
      opacity: 0.4;
      cursor: default;
    }

    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;

      .icon-wrapper {
        width: 25px;
        height: 25px;
        font-size: 5px;
        border-color: var(--color_headerText);
      }
    }
  }
}
</style>
