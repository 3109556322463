﻿import {
  IPortalsServiceClient,
  ImportDashboardOfflineDataResultState,
} from "@/common/service-clients/generated-clients";
import { LongRunningWorkServiceClient } from "@/common/service-clients/long-running-work-service-client";
import { UploadServiceClient } from "@/common/service-clients/upload-service-client";

type ProgressStage = "uploading" | "processing";
type ProgressCallback = (stage: ProgressStage, percent: number) => void;

type PortalUploadMeta = {
  portalId: string;
  portalPageId: string;
};

export class UploadFacade<T = ImportDashboardOfflineDataResultState> {
  private readonly _portalServiceClient: IPortalsServiceClient;
  private readonly _longRunningWorkServiceClient: LongRunningWorkServiceClient;
  private readonly _uploadServiceClient: UploadServiceClient;

  constructor(
    portalServiceClient: IPortalsServiceClient,
    longRunningWorkServiceClient: LongRunningWorkServiceClient,
    uploadServiceClient: UploadServiceClient
  ) {
    this._portalServiceClient = portalServiceClient;
    this._longRunningWorkServiceClient = longRunningWorkServiceClient;
    this._uploadServiceClient = uploadServiceClient;
  }

  async uploadAndImport(
    file: File,
    metadata: PortalUploadMeta,
    callback?: ProgressCallback
  ): Promise<T> {
    const fileId = await this._uploadServiceClient.upload(
      file,
      metadata,
      (value) => callback && callback("uploading", value)
    );

    return await this._longRunningWorkServiceClient.complete(
      fileId,
      (fileId) => this._portalServiceClient.importOfflineData(fileId),
      (fileId, workId) => this._portalServiceClient.getOfflineDataImportStatus(workId),
      (value) => callback && callback("processing", value)
    );
  }
}
