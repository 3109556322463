<script lang="ts">
import { BcButton } from "@bissantz/controls";
import type { DesignVersion } from "@bissantz/controls";
import ToggleItem from "./toggle-item.vue";
import XIcon from "@/common/components/icons/x-icon.vue";

import {
  onBeforeUnmount,
  reactive,
  onMounted,
  inject,
  defineComponent,
  computed,
} from "vue";

import { LiveFeatureTogglesVm } from "./live-feature-toggles-vm";
import { IInputEventsService, KeyIds } from "@/services/input-events-service.interface";
import { IDisposable } from "@/common/disposable.interface";
import { DefaultStyles } from "@/common/styles/default-styles";
import { Dm7App } from "@/app";

export default defineComponent({
  components: {
    BcButton,
    ToggleItem,
    XIcon,
  },

  setup() {
    const application = inject("application") as Dm7App;
    const liveToggles = inject("liveToggles") as LiveFeatureTogglesVm;
    const inputEventsService = inject("inputEventsService") as IInputEventsService;

    const state = reactive({
      escDownEventDisposer: null as IDisposable,
      xIconColor: null as string,
    });

    const design = computed(() => {
      return (application?.design ?? "6") as DesignVersion;
    });

    onMounted(() => {
      state.escDownEventDisposer = inputEventsService
        .getkeyChangedEvent(KeyIds.esc)
        .on(onEscKeyDown);
      state.xIconColor = DefaultStyles.colorConstants.headerTextHEX;
    });

    onBeforeUnmount(() => {
      state.escDownEventDisposer?.dispose();
    });

    function onValueChanged(): void {
      liveToggles.writeValuesToSessionStorage();
    }

    function onSaveButtonPressed(): void {
      liveToggles.writeValuesToLocalstorage();
      liveToggles.deleteSessionStorage();
    }

    function onDeleteButtonPressed(): void {
      liveToggles.deleteAllStorage();
      liveToggles.readValues();

      location.reload();
    }

    function onEscKeyDown() {
      close();
    }

    function close() {
      application.content.find((c) => c.contentId == "portal").navigate();
    }
    return {
      // state & injections & computed
      state,
      liveToggles,
      design,

      // functions:
      close,
      onValueChanged,
      onSaveButtonPressed,
      onDeleteButtonPressed,
    };
  },
});
</script>

<template>
  <div class="liveFeatureToggles" v-if="liveToggles.toggleDefinitions">
    <div class="header">
      <span class="title">Features</span>
      <XIcon v-bind:initialColor="state.xIconColor" v-on:click.native="close" />
    </div>

    <div class="scrollContainer">
      <div
        class="toggleSection"
        v-for="tsect in liveToggles.toggleDefinitions"
        v-bind:key="tsect.name"
      >
        <div class="sectionTitle">{{ tsect.name }}</div>
        <div class="sectionItems">
          <ToggleItem
            v-for="titem in tsect.items"
            v-bind:design="design"
            v-bind:key="titem.title"
            v-bind:toggleItem="titem"
            v-on:liveToggle_valueChanged="onValueChanged"
          />
        </div>
      </div>
    </div>

    <div class="footer">
      <BcButton v-bind:design="design" outlined v-on:click="onSaveButtonPressed"
        >Set as default</BcButton
      >
      <BcButton v-bind:design="design" outlined v-on:click="onDeleteButtonPressed"
        >Reset</BcButton
      >
    </div>
  </div>
</template>

<style lang="less">
@import "../../common/styles/base-styles.less";

.liveFeatureToggles {
  font-family: @fontRegular;
  width: 100vw;
  height: 100vh;

  .header {
    width: calc(100% - 4em);
    height: 3em;
    padding: 2em;
    display: flex;
    justify-content: space-between;

    .title {
      font-family: @fontSemi;
      font-size: 38px;
      color: var(--color_Weather3);
    }

    .xIcon {
      height: 1em;
    }
  }

  .scrollContainer {
    height: 70%;
    overflow-y: scroll;
    padding-left: 2em;

    .toggleSection {
      .sectionTitle {
        font-size: 18px;
        color: var(--color_neutralText);
        margin-bottom: 0.5em;
      }

      .sectionItems {
        padding: 1em;
        display: flex;
        flex-flow: column;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
  }
}
</style>
