import { inject } from "vue";
import { AxiosInstance } from "axios";
import { PortalsServiceClient } from "@/common/service-clients/generated-clients";
import { UploadFacade } from "./upload-facade.js";
import { LongRunningWorkServiceClient } from "@/common/service-clients/long-running-work-service-client";
import { UploadServiceClient } from "@/common/service-clients/upload-service-client";

export function useBackendDependencies() {
  const axiosInstance = inject("axiosInstance", null) as AxiosInstance;
  const usedServiceClients = getUsedServiceClients(axiosInstance);

  let uploadFacade = inject("uploadFacade", null) as UploadFacade;
  if (!uploadFacade) {
    uploadFacade = new UploadFacade(
      usedServiceClients.portalServiceClient,
      usedServiceClients.longRunningWorkServiceClient,
      usedServiceClients.uploadServiceClient
    );
  }

  return {
    uploadFacade,
  };
}

function getUsedServiceClients(axiosInstance: AxiosInstance) {
  return {
    portalServiceClient: new PortalsServiceClient(null, axiosInstance),
    longRunningWorkServiceClient: new LongRunningWorkServiceClient({ delay: 1500 }),
    uploadServiceClient: new UploadServiceClient(axiosInstance),
  };
}
