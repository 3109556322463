import { StructureElementsVm } from "../structure-elements-vm";
import { SharedDrillInfoVm } from "../shared/shared-drill-info-vm";
import { SharedKpiInfo } from "../shared/shared-kpi-info";
import { SharedRowStateVm } from "../shared/shared-row-state-vm";
import { SwiperVm } from "@/common/components/swiper-vm";
import { StructureElementsListBuilder } from "./structure-elements-list-builder";
import { IDashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade.interface";

export class StructureElementsBuilder {
  private _structureElementsListBuilder = new StructureElementsListBuilder();

  createStructureElements(
    drillInfo: SharedDrillInfoVm,
    sharedKpiInfo: SharedKpiInfo,
    parentRowState: SharedRowStateVm = null,
    dashboardFacade: IDashboardFacade = null
  ): StructureElementsVm {
    const result = new StructureElementsVm();

    result.dashboardFacade = dashboardFacade;
    result.structureElementsSwiperVm = new SwiperVm(1, 1);
    result.sharedDrillInfo = drillInfo;
    result.sharedKpiInfo = sharedKpiInfo;
    result.parentRowState = parentRowState;

    return result;
  }

  clone(
    original: StructureElementsVm,
    cloneDrillInfo: SharedDrillInfoVm,
    cloneKpiInfo: SharedKpiInfo,
    cloneParentRowState: SharedRowStateVm
  ): StructureElementsVm {
    const clone = new StructureElementsVm();
    clone.dashboardFacade = original.dashboardFacade;
    clone.isInitialized = original.isInitialized;
    clone.structureElementsSwiperVm = new SwiperVm(
      1,
      original.structureElementsSwiperVm.activeIndex
    );
    clone.sharedDrillInfo = cloneDrillInfo;
    clone.sharedKpiInfo = cloneKpiInfo;
    clone.parentRowState = cloneParentRowState;
    clone.sortedColumn = original.sortedColumn;
    clone.sortType = original.sortType;

    // Workaround: clone["_isVisible"]
    //       Not happy with this workaround, but '_isVisible' should really be
    //       private in the VM and also should really be manually set during cloning.
    //       It would theoretically be correct, if the strcutureElementsBuilder was a
    //       FriendClass of StructureElementsVm but this is simply not possible in
    //       TypeScript.
    clone["_isVisible"] = original.isVisible;

    if (original.structureElementsListVms) {
      clone.structureElementsListVms = original.structureElementsListVms.map(
        (structureElementsListVm) =>
          this._structureElementsListBuilder.clone(
            structureElementsListVm,
            cloneDrillInfo,
            cloneKpiInfo,
            cloneParentRowState
          )
      );
    }

    return clone;
  }
}
