import { ComponentEvent } from "@/common/events/component-event";

export class PortalCommon {
  static portalTile_deleteTile: ComponentEvent = new ComponentEvent(
    "portalTile_deleteTile",
    null
  );
  static portalTile_clone: ComponentEvent = new ComponentEvent("portalTile_clone", null);
  static portal_scaledValueInitialized: ComponentEvent = new ComponentEvent(
    "portal_scaledValueInitialized",
    null
  );
  static portal_pageIndexSelected: ComponentEvent = new ComponentEvent(
    "portal_pageIndexSelected",
    null
  );
  static portal_chatViewStateChanged: ComponentEvent = new ComponentEvent(
    "portal_chatViewStateChanged",
    null
  );
  static portal_dashboardSelectionChanged: ComponentEvent = new ComponentEvent(
    "portal_dashboardSelectionChanged",
    null
  );
}
