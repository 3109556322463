import { inject, onBeforeUnmount, reactive } from "vue";
import { TransactionFacade } from "./transaction-facade";
import { ITransactionsServiceClient } from "../service-clients/generated-clients";
import { getCommonAxiosInstance } from "../common-axios-instance";
import { TransactionStorage } from "./transaction-storage";

class TransactionState {
  transactionStorage: TransactionStorage = new TransactionStorage();
  transactionInterceptor: number = null;
}

export function useTransaction() {
  //
  // Injections
  // --------------------
  const transactionsClient = inject("transactionsClient") as ITransactionsServiceClient;

  // State:
  // --------------------
  const state = reactive(new TransactionState());

  const transactionFacade = new TransactionFacade(
    transactionsClient,
    state.transactionStorage
  );

  // Lifecycle:
  // --------------------
  onBeforeUnmount(() => {
    rollbackActiveTransactionIfAny();
  });

  // Methods:
  // --------------------
  async function beginOrReuseTransaction(): Promise<void> {
    await transactionFacade.beginOrReuseTransaction();

    addTransactionInterceptor();
  }

  async function commitActiveTransactionIfAny(): Promise<void> {
    await transactionFacade.commitActiveTransactionIfAny();

    removeTransactionInterceptor();
  }

  async function rollbackActiveTransactionIfAny(): Promise<void> {
    await transactionFacade.rollbackActiveTransactionIfAny();

    removeTransactionInterceptor();
  }

  function addTransactionInterceptor() {
    if (transactionInterceptorExist()) {
      return;
    }

    const axiosInstance = getCommonAxiosInstance();

    state.transactionInterceptor = axiosInstance.interceptors.request.use(function (
      config
    ) {
      const transactionId = state.transactionStorage.activeTransactionId;

      if (TransactionFacade.transactionExist(transactionId)) {
        config.headers.set("X-TransactionId", transactionId); // keep in sync with server-side header name
      }

      return config;
    });
  }

  function removeTransactionInterceptor() {
    if (transactionInterceptorExist()) {
      const axiosInstance = getCommonAxiosInstance();
      axiosInstance.interceptors.request.eject(state.transactionInterceptor);
      state.transactionInterceptor = null;
    }
  }

  function transactionInterceptorExist(): boolean {
    return state.transactionInterceptor !== null;
  }

  return {
    // Methods:
    beginOrReuseTransaction,
    commitActiveTransactionIfAny,
    rollbackActiveTransactionIfAny,
  };
}
