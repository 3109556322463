import { existsAndNotEmpty } from "@/common/object-helper/null-helper";

export class ApplicationNameLink {
  private static readonly _dashboardPublishedApplicationNameSuffix = "-db";

  static getDashboardPublishedApplicationName(
    deltaMasterPublishedApplicationName: string
  ): string {
    if (!existsAndNotEmpty(deltaMasterPublishedApplicationName)) {
      return deltaMasterPublishedApplicationName;
    }

    return (
      deltaMasterPublishedApplicationName + this._dashboardPublishedApplicationNameSuffix
    );
  }
}
