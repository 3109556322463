export class HistoricKpiValueFm {
  value: number;
  sparklineDeviationValue: number;
  period: PeriodFm;
  dynamicColorValue: number;

  constructor(data?: HistoricKpiValueFm) {
    if (!data) {
      return;
    }

    this.value = data.value;
    this.sparklineDeviationValue = data.sparklineDeviationValue;
    this.period = data.period;
    this.dynamicColorValue = data.dynamicColorValue;
  }
}

export class PeriodFm {
  id: string;
  value: string;

  constructor(data?: PeriodFm) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.value = data.value;
  }
}

export type SparklineModeFm = "None" | "Local" | "Global";

export type TimeStructureTypeFm = "Other" | "Year" | "Quarter" | "Month" | "Week" | "Day";
