<script lang="ts">
import { BcTooltip } from "@bissantz/controls";
import DialogInfoIcon from "@/common/components/icons/dialog-info-icon.vue";
import { defineComponent } from "vue";
import { DefaultStyles } from "@/common/styles/default-styles";

// constants
const infoColor = DefaultStyles.colorConstants.weatherPlus3;

export default defineComponent({
  components: {
    BcTooltip,
    DialogInfoIcon,
  },

  props: {
    informationText: { type: String, default: "" },
  },

  setup() {
    return { infoColor };
  },
});
</script>

<template>
  <BcTooltip
    class="application-wizard_info-tooltip"
    contentDesign="7"
    v-bind:content="$props.informationText"
    v-bind:offset="[5, 15]"
    v-bind:arrow="false"
  >
    <div>
      <DialogInfoIcon v-bind:mainColor="infoColor" class="icon" />
    </div>
  </BcTooltip>
</template>

<style lang="less" scoped>
.application-wizard_info-tooltip {
  .icon {
    position: relative;
    width: 14px;
    margin-right: 7px;
    top: 2px;
  }

  &.bissantzControls.tooltipControl::v-deep {
    .content[data-design="7"][data-color="blue"] {
      max-width: 600px;
    }
  }
}
</style>
