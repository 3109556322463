<script lang="ts">
import ArrowIcon from "@/common/components/icons/arrow-icon.vue";
import { computed, defineComponent } from "vue";
import { DefaultStyles } from "@/common/styles/default-styles";

const greyArrow = DefaultStyles.colorConstants.headerTextHEX;
const blueArrow = DefaultStyles.colorConstants.baseBlueHEX;

export default defineComponent({
  components: {
    ArrowIcon,
  },

  props: {
    isRotated: { type: Boolean, required: true },
    changeColorOnRotation: { type: Boolean, required: false, default: true },
  },

  setup(props) {
    const arrowColor = computed(() => {
      if (props.changeColorOnRotation === true) {
        return props.isRotated ? blueArrow : greyArrow;
      } else {
        return greyArrow;
      }
    });

    return {
      arrowColor,

      greyArrow,
      blueArrow,
    };
  },
});
</script>

<template>
  <ArrowIcon
    class="rotatable-arrow-icon"
    v-bind:initialColor="arrowColor"
    v-bind:class="{
      'rotate-arrow': $props.isRotated,
    }"
  />
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";

.rotatable-arrow-icon {
  display: flex;
  padding: 0 3px;
  height: 20px;
  transition: all 0.15s ease-in-out;

  &.rotate-arrow {
    transform: rotate(90deg);
  }
}
</style>
