<script lang="ts">
import { computed, defineComponent } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    mainColor: {
      type: String,
      default: "#000",
    },
  },
  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      const usedColor = props.mainColor;
      return {
        "--main-color": usedColor,
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="dialogInfoIcon" v-bind:style="colorVars">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-5 -5 222 222"
      style="enable-background: new -5 -5 222 222"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M105.7,6.3c55.1,0,100,44.9,100,100s-44.9,100-100,100c-55.1,0-100-44.9-100-100S50.5,6.3,105.7,6.3 M105.7,0.3
			c-58.5,0-106,47.5-106,106s47.5,106,106,106s106-47.5,106-106S164.2,0.3,105.7,0.3L105.7,0.3z"
          />
        </g>
        <g>
          <line x1="105.7" y1="54.6" x2="105.7" y2="42" />
          <line x1="105.7" y1="73.4" x2="105.7" y2="164.3" />
        </g>
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.dialogInfoIcon {
  width: 100%;
  height: 100%;

  svg {
    stroke: var(--main-color);
    stroke-width: 12;
    width: 100%;
    height: 100%;
    fill: none;
    line {
      stroke-miterlimit: 10;
    }
  }
}
</style>
