import { ITypedEvent } from "@/common/events/ityped-event";
import { TypedEvent } from "@/common/events/typed-event";
import { IStatusBarService, PortalPageHints } from "./status-bar-service.interface";
import { SimpleEvent } from "@/common/events/simple-event";
import { ISimpleEvent } from "@/common/events/isimple-event";

export class StatusBarService implements IStatusBarService {
  //events:
  private _showTextTriggered = new TypedEvent<string>();
  private _hideTextTriggered = new SimpleEvent();
  private _pageTitlesChanged = new TypedEvent<PortalPageHints>();
  private _activePageChanged = new TypedEvent<number>();
  private _backgroundColorChanged = new TypedEvent<string>();

  private _currentPageHints: PortalPageHints = { activePageIdx: null, pageTitles: [] };
  private _currentElementStatus: string = "";

  showText(text: string, isHelpStatusText?: boolean): void {
    if (!isHelpStatusText) this._currentElementStatus = text;

    this._showTextTriggered.emit(text);
  }

  hideText(): void {
    this._currentElementStatus = "";
    this._hideTextTriggered.emit();
  }

  updatePortalPageHints(activePageIdx: number, pageTitles: string[] = null): void {
    const indexChanged = this._currentPageHints.activePageIdx !== activePageIdx;
    const pageTitlesChanged = pageTitles
      ? this._titlesDiffer(this._currentPageHints.pageTitles, pageTitles)
      : false;

    if (indexChanged && !pageTitlesChanged) {
      this._currentPageHints.activePageIdx = activePageIdx;
      this._activePageChanged.emit(activePageIdx);
    } else if (pageTitlesChanged) {
      this._currentPageHints.activePageIdx = activePageIdx;
      this._currentPageHints.pageTitles = pageTitles;
      this._pageTitlesChanged.emit(this._currentPageHints);
    }
  }

  updateBackgroundColor(color: string): void {
    this._backgroundColorChanged.emit(color);
  }

  getCurrentElementStatusText(): string {
    return this._currentElementStatus;
  }

  private _titlesDiffer(titles: string[], otherTitles: string[]): boolean {
    const sameLength = titles.length === otherTitles.length;
    if (!sameLength) {
      return true;
    }

    const sameEntries = titles.every((title, idx) => title === otherTitles[idx]);
    return !sameEntries;
  }

  get currentPageHints(): PortalPageHints {
    return this._currentPageHints;
  }

  // Events
  get backgroundColorChanged(): ITypedEvent<string> {
    return this._backgroundColorChanged;
  }

  get showTextTriggered(): ITypedEvent<string> {
    return this._showTextTriggered;
  }

  get hideTextTriggered(): ISimpleEvent {
    return this._hideTextTriggered;
  }

  get pageTitlesChanged(): ITypedEvent<PortalPageHints> {
    return this._pageTitlesChanged;
  }

  get activePageChanged(): ITypedEvent<number> {
    return this._activePageChanged;
  }
}
