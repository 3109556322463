import { DashboardFiltersListVm } from "./dashboard-filters-list-vm";
import { ValueGroupVm } from "./value-group-vm";
import { SwiperVm } from "@/common/components/swiper-vm";
import { KpiFm } from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { SharedKpiInfo } from "./shared/shared-kpi-info";
import { SharedDashboardStateVm } from "./shared/shared-dashboard-state-vm";
import { DashboardSettings } from "@/features/dashboard-shared/dashboard-settings";

export class KpiTileVm {
  private _valueGroupVms: ValueGroupVm[] = null;

  kpiTileIndex: number;
  filters: DashboardFiltersListVm = null;
  backingFm: KpiFm = null;

  // shared from this level on:
  periodSwiperVm: SwiperVm = null;
  valueGroupSwiperVm: SwiperVm = null;
  deltaValuesSwiperVm: SwiperVm = null;

  kpiInfo: SharedKpiInfo = null;
  isAnyReadyHot: boolean = false;
  private readonly _dbSettings: DashboardSettings;

  constructor(dbSettings: DashboardSettings) {
    this._dbSettings = dbSettings;
    this.periodSwiperVm = this._createPeriodSwiper();
  }

  get valueGroupVms(): ValueGroupVm[] {
    return this._valueGroupVms;
  }

  set valueGroupVms(valueGroupVms: ValueGroupVm[]) {
    valueGroupVms.map((vg) => {
      vg.kpiTileIndex = this.kpiTileIndex;
      vg.periodSwiperVm = this.periodSwiperVm;
      // TODO: no so nice init logic
      vg.setInitialPeriodSwiperActiveIndex();
    });
    this._valueGroupVms = valueGroupVms;
  }

  get currentValueGroup(): ValueGroupVm {
    const index = this.valueGroupSwiperVm.getRealIndex();
    return this.valueGroupVms[index];
  }

  get scrollGroupId(): number {
    return this.backingFm.scaleGroupId;
  }

  get dashboardId(): number {
    return this.backingFm.dashboardId;
  }

  get isShown(): boolean {
    const shownKpiIds = this._dbSettings.shownKpiIds;
    const kpiId = this.kpiInfo.kpiId;
    return shownKpiIds.findIndex((v) => v === kpiId) !== -1;
  }

  updateDrillInfoValueId(sharedState: SharedDashboardStateVm): void {
    const newKpiValueIndex = Math.min(
      Math.max(sharedState.kpiScaleIndex, 0),
      this.currentValueGroup.kpiValues.length - 1
    );
    this.currentValueGroup.drillInfo.valueId =
      this.currentValueGroup.kpiValues[newKpiValueIndex].id;
  }

  private _createPeriodSwiper(): SwiperVm {
    const numberOfItemsToDisplay = 1;
    const activeIndex = 0;
    const infiniteLoop = false;
    const jumpToOtherEndEnabled = false;
    return new SwiperVm(
      numberOfItemsToDisplay,
      activeIndex,
      infiniteLoop,
      jumpToOtherEndEnabled
    );
  }
}
