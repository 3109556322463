<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    initialColor: { type: String, default: null },
  },

  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      return {
        "--initial-color": props.initialColor,
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="xIcon" v-bind:style="colorVars">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 72.899 72.899"
      overflow="visible"
      xml:space="preserve"
    >
      <line fill="none" x1="4.95" y1="67.95" x2="67.95" y2="4.95" />
      <line fill="none" x1="4.95" y1="4.95" x2="67.95" y2="67.95" />
    </svg>
  </div>
</template>

<style lang="less">
.xIcon {
  height: 100%;
  cursor: pointer;
  svg {
    stroke: var(--initial-color);
    height: 100%;
    line {
      fill: none;
      stroke-width: 14;
      stroke-miterlimit: 10;
    }
  }
}
</style>
