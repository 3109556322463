import {
  inject,
  reactive,
  onBeforeMount,
  Ref,
  computed,
  watch,
  onBeforeUnmount,
} from "vue";
import { IMediaQueries } from "@/common/styles/media-queries.interface";
import { ClickHelper } from "@/common/events/click-helper";
import { TouchHelper } from "@/common/events/touch-helper";
import { DashboardCommon } from "@/features/dashboard/dashboard-common";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";
import { SharedSparklineState } from "@/features/dashboard-shared/sparkline";
import clamp from "lodash/clamp";
import { ValueGroupVm } from "@/features/dashboard/view-models/value-group-vm";
import { appResources } from "@/app-resources";

class ExcludeFromScalingState {
  clickHelper = new ClickHelper();
  touchHelper = new TouchHelper();
  eventsAdded = false as boolean;
  textResources = appResources.valueGroupTexts;
}

export function useExcludeFromScaling(
  context,
  showSparklines: Ref<boolean>,
  isAnyReadyHot: Ref<boolean>,
  dashboardSelection: DashboardSelection,
  kpiScaleIndex: Ref<number>,
  sparklineState: Ref<SharedSparklineState>,
  valueGroupVm: Ref<ValueGroupVm>
) {
  const mediaQueries = inject<IMediaQueries>("mediaQueries");
  const state = reactive(new ExcludeFromScalingState());

  //
  // Life Cycle:
  // --------------------
  onBeforeMount(() => {
    state.clickHelper.touchHelper = state.touchHelper;
    state.clickHelper.setOnLeftClickAction(onLeftClick);
    state.clickHelper.setOnRightClickAction(onRightClick);
    state.touchHelper.setTapAction(onTap);
    state.touchHelper.setLongTouchAction(onLongTouch);
  });

  onBeforeUnmount(() => turnReadyStateOff());

  //
  // Computeds:
  // --------------------
  const scaleIndex = computed<number>(() => {
    const currentMaxIdx = valueGroupVm.value.kpiValues.length - 1;
    return clamp(kpiScaleIndex.value, 0, currentMaxIdx);
  });

  const isExcludedFromScaling = computed<boolean>(() => {
    if (kpiScaleIndex.value < 0) {
      return false;
    }

    return valueGroupVm.value.kpiValues[scaleIndex.value]?.excludedFromScaling;
  });

  const readyHotText = computed<string>(() => {
    if (!valueGroupVm.value.isReadyHot) {
      return "";
    }

    const idx = Math.max(kpiScaleIndex.value, 0);
    if (valueGroupVm.value.kpiValues[idx]?.excludedFromScaling) {
      return state.textResources.includeForScaling;
    } else {
      return state.textResources.removeFromScaling;
    }
  });

  //
  // Functions:
  // --------------------
  function onMouseDown(event: MouseEvent): void {
    if (mediaQueries.isTouch) {
      return;
    }

    state.clickHelper.mouseDown(event);
    if (isAnyReadyHot.value) {
      event?.stopPropagation();
    }
  }

  function onMouseUp(event: MouseEvent): void {
    if (mediaQueries.isTouch) {
      return;
    }
    state.clickHelper.mouseUp(event);
  }

  function onTouchStart(event: TouchEvent): void {
    state.touchHelper.touchStart(event);
    if (isAnyReadyHot.value) {
      event?.stopPropagation();
    }
  }

  function onTouchMove(): void {
    state.touchHelper.cancelTouch();
  }

  function onTouchEnd(event: TouchEvent): void {
    state.touchHelper.touchEnd(event);
  }

  function onLeftClick(event: MouseEvent): void {
    dashboardSelection.selectedKpiTileId = valueGroupVm.value.kpiInfo.kpiId;
    if (valueGroupVm.value.isReadyHot) {
      executeToggleExcludeFromScaling(event);
    }
  }

  function onRightClick(): void {
    if (!valueGroupVm.value.isReadyHot && !sparklineState.value.showSparklines) {
      turnReadyStateOn();
    } else if (valueGroupVm.value.isReadyHot) {
      turnReadyStateOff();
    }
  }

  function onLongTouch(): void {
    if (!valueGroupVm.value.isReadyHot && !showSparklines.value) {
      turnReadyStateOn();
    }
  }

  function onTap(event: TouchEvent): void {
    dashboardSelection.selectedKpiTileId = valueGroupVm.value.kpiInfo.kpiId;
    if (valueGroupVm.value.isReadyHot) {
      executeToggleExcludeFromScaling(event);
    }
  }

  function turnReadyStateOn(): void {
    if (kpiScaleIndex.value < 0) {
      return;
    }

    context.emit(DashboardCommon.valueGroup_readyStateIsOn, valueGroupVm.value);
  }

  function turnReadyStateOffMouse(): void {
    if (!mediaQueries.isTouch) {
      turnReadyStateOff();
    }
  }

  function turnReadyStateOff(): void {
    context.emit(DashboardCommon.valueGroup_readyStateIsOff, valueGroupVm);
  }

  function executeToggleExcludeFromScaling(event: UIEvent): void {
    event.stopPropagation();
    toggleExcludeFromScaling();
    setBackgroundColor();
    context.emit(DashboardCommon.common_excludeFromScalingChanged);
    setTimeout(() => turnReadyStateOff(), 50);
  }

  function toggleExcludeFromScaling(): void {
    if (kpiScaleIndex.value < 0) {
      return;
    }
    const idx = scaleIndex.value;
    valueGroupVm.value.kpiValues[idx].excludedFromScaling =
      !valueGroupVm.value.kpiValues[idx].excludedFromScaling;
  }

  function setBackgroundColor(): void {
    if (isExcludedFromScaling.value) {
      dashboardSelection.backgroundColor = null;
    } else {
      dashboardSelection.backgroundColor =
        valueGroupVm.value.kpiValues[scaleIndex.value].weatherColor;
    }
  }

  function onIsReadyHotChanged(): void {
    if (valueGroupVm.value.isReadyHot) {
      addReadyHotEvents();
    } else {
      removeReadyHotEvents();
    }
  }

  function addReadyHotEvents(): void {
    if (state.eventsAdded) {
      return;
    }

    state.eventsAdded = true;
    document.addEventListener("touchstart", turnReadyStateOff);
    document.addEventListener("mousedown", turnReadyStateOffMouse);
  }

  function removeReadyHotEvents(): void {
    if (!state.eventsAdded) {
      return;
    }

    document.removeEventListener("touchstart", turnReadyStateOff);
    document.removeEventListener("mousedowm", turnReadyStateOffMouse);
    state.eventsAdded = false;
  }

  //
  // Watcher:
  // --------------------
  watch(() => valueGroupVm.value.isReadyHot, onIsReadyHotChanged);

  return {
    isExcludedFromScaling,
    readyHotText,
    onMouseDown,
    onMouseUp,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
}
