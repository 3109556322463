import { DashboardSettings } from "@/features/dashboard-shared/dashboard-settings";
import { DashboardVm } from "@/features/dashboard/view-models/dashboard-vm";
import { ValueVm } from "@/features/dashboard/view-models/value-vm";
import { TileConfiguration } from "@bissantz/tile-grid";
import { IScalable } from "@/common/formatting/scalable.interface";
import { TileError } from "../errors";
import { IPortalTileFm, ITilePageFm } from "../backend-wrapper/facade-models";

export class PortalTileVm {
  private _portalScaledValueReferences: ValueVm[] = [];

  // TODO: better not expose FMs!
  publishedApplicationId: string;
  tileFm: IPortalTileFm = null;
  tilePageFm: ITilePageFm = null;

  isTemporary: boolean = false;
  dbSettings: DashboardSettings = null;
  tileConfig: TileConfiguration = null;
  dashboardVm: DashboardVm = null;
  showTile: boolean = false;
  disableTransitions: boolean = true;
  portalScaledValueCopies: ValueVm[] = [];

  error: TileError = null;

  initPortalScaledValue(): void {
    const shonwKpi = this.dashboardVm.shownKpiTileVms;
    const sharedDbState = this.dashboardVm.sharedState;
    this._portalScaledValueReferences.push(
      ...shonwKpi.map((kpiTile) =>
        kpiTile.currentValueGroup.getScaledValue(sharedDbState)
      )
    );
    this.portalScaledValueCopies.push(
      ...this._portalScaledValueReferences.map((v) => {
        const partialCopy = new ValueVm();
        partialCopy.updateFrom(v);
        return partialCopy;
      })
    );
  }

  tryApplyScalingContext(scalingContext: IScalable[]): void {
    if (!this.dashboardVm || !this.dashboardVm.isInitialized) return;

    const showsDrill = this.dashboardVm.showsAnyStructureElements;
    const showsAllKpi = !this.dbSettings.applyKpiIdFilter && this.hasEnoughKpis;

    const mustUseDashboardScaling = showsDrill || showsAllKpi;
    if (mustUseDashboardScaling) {
      this.dashboardVm.fontScaler.presetScalingContext = null;
    } else if (!mustUseDashboardScaling) {
      this.dashboardVm.fontScaler.presetScalingContext = scalingContext;
    }

    this.dashboardVm.refreshColorAndScaling();
  }

  toggleKpiFilter(): void {
    this.dashboardVm.toggleKpiFilter();
  }

  get canClone(): boolean {
    return !this.error && !this.dashboardVm?.error && this.dashboardVm?.isInitialized;
  }

  get hasEnoughKpis(): boolean {
    return this.dashboardVm?.hasEnoughKpis;
  }

  get canExtendWidth(): boolean {
    return this.dashboardVm?.maxNumKpiValues > 1;
  }

  get shownTitle(): string {
    if (!this.error && !this.dashboardVm?.isInitialized) {
      return "";
    }

    return this.dashboardVm?.dashboardFm?.name ?? this.tileFm.name;
  }

  // Must be unique for a portalPage: needed by tile-grid
  get id(): string {
    return this.tileFm.id;
  }

  get key(): string {
    return this.tileFm.portalPageId + this.tileFm?.id + this.dashboardVm?.dashboardFm?.id;
  }
}
