import { CloneHelper } from "@/common/object-helper/clone-helper";
import { KpiValueFm } from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import { ValueVm } from "../value-vm";
import { SharedKpiInfo } from "../shared/shared-kpi-info";
import { SharedRowStateVm } from "../shared/shared-row-state-vm";
import { SparklineBuilder } from "./sparkline-builder";
import { PeriodVm } from "@/features/dashboard/view-models/period-vm";

export class ValueBuilder {
  private _sparklineBuilder = new SparklineBuilder();

  clone(
    original: ValueVm,
    cloneKpiInfo: SharedKpiInfo,
    cloneParentRowState: SharedRowStateVm,
    kpiValueFm: KpiValueFm = null
  ): ValueVm {
    const clone = new ValueVm();
    clone.backingFm = kpiValueFm ?? CloneHelper.clone(original.backingFm);
    clone.sparkline = original.sparkline
      ? this._sparklineBuilder.clone(original.sparkline, clone.backingFm)
      : null;
    clone.period = original.period?.clone();
    clone.value = original.value;
    clone.excludedFromScaling = original.excludedFromScaling;
    clone.valueRaw = original.valueRaw;
    clone.valueFormatted = original.valueFormatted;
    clone.unitFormatted = original.unitFormatted;
    clone.weatherColor = original.weatherColor;
    clone.rowUnit = original.rowUnit;
    clone.columnIdx = original.columnIdx;
    clone.fontSize = original.fontSize;
    clone.parentRowState = cloneParentRowState;
    clone.kpiInfo = cloneKpiInfo;

    return clone;
  }

  createValueVms(
    kpiValueFms: KpiValueFm[],
    kpiInfo: SharedKpiInfo,
    period: PeriodVm,
    unit: string = null
  ): ValueVm[] {
    if (!(kpiValueFms.length >= 1 && kpiValueFms.length <= 3))
      throw Error(
        "A KPI must have at last 1 and max. 3 values: This KPI had " +
          kpiValueFms.length +
          " values"
      );

    const result: ValueVm[] = [];
    for (let colIdx = 0; colIdx < kpiValueFms.length; colIdx++) {
      const valueVm = this.setValueData(kpiValueFms[colIdx], kpiInfo, period);
      if (unit) valueVm.rowUnit = unit;
      valueVm.columnIdx = colIdx;
      if (valueVm.hasSparkline)
        valueVm.sparkline = this._sparklineBuilder.buildSparkline(
          valueVm,
          valueVm.kpiInfo
        );
      result.push(valueVm);
    }

    return result;
  }

  setValueData(
    kpiValueFm: KpiValueFm,
    kpiInfo: SharedKpiInfo,
    period: PeriodVm
  ): ValueVm {
    const valueVm = new ValueVm();
    valueVm.kpiInfo = kpiInfo;
    valueVm.backingFm = kpiValueFm;
    valueVm.value = kpiValueFm.value;
    valueVm.period = period ? new PeriodVm(period.id, period.displayName) : null;
    return valueVm;
  }
}
