import { ValueType, getValueType, isAnyPercentageType } from "./value-type";
import { MinMaxCalculator, MinMaxInfo } from "./min-max-info";
import { BusinessColor } from "./business-color";
import { IScalable } from "@/common/formatting/scalable.interface";

// This class was orginally taken from DM6 WebClient and adepted.
// Changes were made that are more specific for DeltaApp
export class FontScaler {
  private _minSize: number;
  private _maxSize: number;
  private _intervals: number;

  private _sizeGap = 1;

  presetScalingContext: IScalable[] = null;

  constructor(defaultMinSize: number = 11.5, defaultMaxSize: number = 25.5, sizeGap = 1) {
    this._minSize = defaultMinSize;
    this._maxSize = defaultMaxSize;
    this._sizeGap = sizeGap;

    this._intervals = (this._maxSize - this._minSize) / this._sizeGap;
  }

  scaleValues(valueToBeScaled: IScalable[]): void {
    // 1. group the given list of values by type (scaling minMax values are specific for the type group)
    const valuesByType = this.groupByType(valueToBeScaled);

    this.scaleByType(valuesByType);
  }

  scaleByType(valuesByType: Map<ValueType, IScalable[]>): void {
    // 2. iterate each type group
    for (const key of valuesByType.keys()) {
      const values = valuesByType.get(key);
      // 2.1 get minmax for that group
      const minMaxInfo = this._getMinMaxInfo(values, key);

      // 2.2 scale all values in that group
      this.scaleTypedValues(values, minMaxInfo);
    }
  }

  scaleValue(value: IScalable, minMaxInfo: MinMaxInfo): void {
    const valueType = getValueType(value.format);
    const isPercent = isAnyPercentageType(valueType);
    let scaleFactor = value.kpiInfo.scaleFactor ?? 1;
    if (isPercent) {
      scaleFactor = Math.sign(scaleFactor) * 1;
    }

    const scaledValue = value.value / scaleFactor;

    const max =
      minMaxInfo.max === minMaxInfo.min ? Math.abs(scaledValue) : minMaxInfo.max;
    const fontClass = this.getFontClass(scaledValue, minMaxInfo.min, max);
    value.fontSize = this.getFontSize(fontClass) + "em";

    const color = BusinessColor.determineBusinessColor({
      value: value.value,
      max: max,
      dynamicValue: value.dynamicColorValue,
      factor: value.kpiInfo.factor,
      invertSign: value.kpiInfo.invertSign,
      biColorThresholds: isPercent ? null : value.kpiInfo.biColorThresholds,
      scaleFactor: scaleFactor,
    });

    value.weatherColor = color;
  }

  /**
   * @param value can be any number
   * @param min must be an absoltue value
   * @param max must be an absoltue value
   */
  getFontClass(value: number, min: number, max: number): number {
    if (min < 0 || max < 0 || max < min)
      throw Error(
        "Error in FontScaling: min and max have to be absolute values, with min smaller than max. Min was: " +
          min +
          ", Max was:" +
          max
      );

    let fontClass: number;
    const absValue = Math.abs(value);

    if (min === max) fontClass = Math.floor((this._intervals - 1) / 2);
    else {
      fontClass = Math.round(((absValue - min) / (max - min)) * this._intervals);
    }

    return Math.min(fontClass, this._intervals - 1);
  }

  getFontSize(fontClass: number, customMinSize: number = null): number {
    const effectiveMinimumSize: number =
      customMinSize == null ? this._minSize : customMinSize;

    return effectiveMinimumSize + Math.max(0, fontClass) * this._sizeGap;
  }

  private _getMinMaxInfo(values: IScalable[], valueType: ValueType): MinMaxInfo {
    let minMaxRelevantValues = values;
    if (this.presetScalingContext) {
      minMaxRelevantValues =
        this.groupByType(this.presetScalingContext).get(valueType) ?? [];
    }

    return MinMaxCalculator.getMax(minMaxRelevantValues);
  }

  private groupByType(scaleValues: IScalable[]): Map<ValueType, IScalable[]> {
    const valuesByType: Map<ValueType, IScalable[]> = new Map();
    scaleValues.map((val) => {
      let typeList = valuesByType.get(getValueType(val.format));
      if (!typeList) {
        typeList = [];
        valuesByType.set(getValueType(val.format), typeList);
      }

      typeList.push(val);
    });

    return valuesByType;
  }

  private scaleTypedValues(values: IScalable[], minMaxInfo: MinMaxInfo): void {
    for (let idx = 0; idx < values.length; idx++) {
      const scalableValue = values[idx];
      this.scaleValue(scalableValue, minMaxInfo);
    }
  }
}
