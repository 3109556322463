import { IViewStateDto } from "@/common/service-clients/generated-clients";
import { VsRoot } from "./vs-root";

export class VsApplicatorResult {
  isInError: boolean = false;
  isMissingViewState: boolean = false;
  isMissingModel: boolean = false;

  updateFrom(other: VsApplicatorResult): void {
    this.isInError = this.isInError || other.isInError;
    this.isMissingModel = this.isMissingModel || other.isMissingModel;
    this.isMissingViewState = this.isMissingViewState || other.isMissingViewState;
  }

  static get success(): VsApplicatorResult {
    const result = new VsApplicatorResult();

    return result;
  }

  static get failMissingViewState(): VsApplicatorResult {
    const result = new VsApplicatorResult();
    result.isInError = true;
    result.isMissingViewState = true;

    return result;
  }
}

export interface IVsApplicator {
  applyAsync(vm: VsRoot, vsToBeApplied: IViewStateDto): Promise<VsApplicatorResult>;

  /**
   * Optional:
   * If there are additional refs in the natural VM
   * which can only be set from VS
   */
  setVsRefsOnVm?(vm: VsRoot, vs: IViewStateDto): void;
}
