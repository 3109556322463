import { DashboardStatusType } from "@/features/dashboard/status-configs/dashboard-status";
import { sharedDashboardWidths } from "@/features/dashboard/status-configs/shared-widths";
import { Cell } from "@/common/components/simple-grid/contracts/cell";
import { GridIds as gridIds } from "@/features/dashboard/status-configs/grid-ids";
import { DefaultStyles } from "@/common/styles/default-styles";
import { separatorTxt } from "@/common/components/simple-grid/simple-grid.vue";

// Use any CSS grid unit (px, %, fr, auto) for widths and heights.
// Specify an integer for colSpan/rowSpan to indicate the number of columns/rows to span.
// Set colSpan/rowSpan to -1 to use all available columns/rows.

export class ValueGroupStatusConfig {
  private static _sepColorWhite = DefaultStyles.colorConstants.bgWhite;
  private static _sepColorGray = DefaultStyles.colorConstants.bgGray;
  private static readonly _heights: string[][] = [
    ["22px", "20px", "1px", "auto"],
    ["28px", "1px", "48.7px", "1px", "auto"],
  ];

  private static readonly _cells: Record<DashboardStatusType, Cell[]> = {
    OneValueNoSparklines: [
      { id: gridIds.title, row: 0, col: 0, colSpan: -1 },
      { id: gridIds.periodEmptyValue, row: 3, col: 0 },
      { id: gridIds.periodTexts, row: 1, col: 0 },
      { id: gridIds.valueGroupTexts, row: 1, col: 2 },
      { id: gridIds.valueGroupValues, row: 3, col: 2 },
      { id: separatorTxt, row: 1, col: 1, backgroundColor: this._sepColorWhite },
      {
        id: separatorTxt,
        row: 2,
        col: 0,
        colSpan: -1,
        backgroundColor: this._sepColorWhite,
      },
    ],
    OneValueNoSparklinesReadyHot: [
      { id: gridIds.title, row: 0, col: 0, colSpan: -1 },
      { id: gridIds.readyHotText, row: 1, col: 0, colSpan: 3, padding: "0 0 0 5px" },
      { id: gridIds.periodEmptyValue, row: 3, col: 0 },
      { id: gridIds.valueGroupTexts, row: 1, col: 2 },
      { id: gridIds.valueGroupValues, row: 3, col: 2 },
      {
        id: separatorTxt,
        row: 2,
        col: 0,
        colSpan: -1,
        backgroundColor: this._sepColorWhite,
      },
    ],
    ThreeValuesNoSparklinesScaleIndexGreaterZero: [
      { id: gridIds.title, row: 0, col: 0, colSpan: -1 },
      { id: gridIds.periodTexts, row: 1, col: 0 },
      { id: gridIds.periodEmptyValue, row: 3, col: 0 },
      { id: gridIds.valueGroupTexts, row: 1, col: 2 },
      { id: gridIds.valueGroupValues, row: 3, col: 2 },
      { id: gridIds.deltaTexts, row: 1, col: 4 },
      { id: gridIds.deltaValues, row: 3, col: 4 },
      { id: separatorTxt, row: 1, col: 1, backgroundColor: this._sepColorWhite },
      { id: separatorTxt, row: 1, col: 3, backgroundColor: this._sepColorWhite },
      {
        id: separatorTxt,
        row: 2,
        col: 0,
        colSpan: -1,
        backgroundColor: this._sepColorWhite,
      },
    ],
    ThreeValuesNoSparklinesScaleIndexGreaterZeroReadyHot: [
      { id: gridIds.title, row: 0, col: 0, colSpan: -1 },
      { id: gridIds.readyHotText, row: 1, col: 0, colSpan: 3, padding: "0 0 0 5px" },
      { id: gridIds.periodEmptyValue, row: 3, col: 0 },
      { id: gridIds.periodEmptyValue, row: 3, col: 0 },
      { id: gridIds.valueGroupTexts, row: 1, col: 2 },
      { id: gridIds.valueGroupValues, row: 3, col: 2 },
      { id: gridIds.deltaTexts, row: 1, col: 4 },
      { id: gridIds.deltaValues, row: 3, col: 4 },
      { id: separatorTxt, row: 1, col: 3, backgroundColor: this._sepColorWhite },
      {
        id: separatorTxt,
        row: 2,
        col: 0,
        colSpan: -1,
        backgroundColor: this._sepColorWhite,
      },
    ],
    ThreeValuesNoSparklinesScaleIndexZero: [
      { id: gridIds.title, row: 0, col: 0, colSpan: -1 },
      { id: gridIds.periodTexts, row: 1, col: 0 },
      { id: gridIds.periodEmptyValue, row: 3, col: 0 },
      { id: gridIds.valueGroupTexts, row: 1, col: 2 },
      { id: gridIds.valueGroupValues, row: 3, col: 2 },
      { id: gridIds.deltaTexts, row: 1, col: 4 },
      { id: gridIds.deltaValues, row: 3, col: 4 },
      { id: separatorTxt, row: 1, col: 1, backgroundColor: this._sepColorWhite },
      { id: separatorTxt, row: 1, col: 3, backgroundColor: this._sepColorWhite },
      {
        id: separatorTxt,
        row: 2,
        col: 0,
        colSpan: -1,
        backgroundColor: this._sepColorWhite,
      },
    ],
    ThreeValuesNoSparklinesScaleIndexZeroReadyHot: [
      { id: gridIds.title, row: 0, col: 0, colSpan: -1 },
      { id: gridIds.readyHotText, row: 1, col: 0, colSpan: 3, padding: "0 0 0 5px" },
      { id: gridIds.periodEmptyValue, row: 3, col: 0 },
      { id: gridIds.valueGroupTexts, row: 1, col: 2 },
      { id: gridIds.valueGroupValues, row: 3, col: 2 },
      { id: gridIds.deltaTexts, row: 1, col: 4 },
      { id: gridIds.deltaValues, row: 3, col: 4 },
      { id: separatorTxt, row: 1, col: 3, backgroundColor: this._sepColorWhite },
      {
        id: separatorTxt,
        row: 2,
        col: 0,
        colSpan: -1,
        backgroundColor: this._sepColorWhite,
      },
    ],
    NotExtendedAndSparklines: [
      { id: gridIds.title, row: 0, col: 0, colSpan: -1 },
      { id: gridIds.periodTexts, row: 1, col: 0 },
      { id: gridIds.periodDeviationValues, row: 3, col: 0 },
      { id: gridIds.sparkline, row: 1, col: 1, rowSpan: 3 },
      { id: gridIds.valueGroupTexts, row: 1, col: 2 },
      { id: gridIds.valueGroupValues, row: 3, col: 2 },
    ],
    ExtendedOneValueNoSparklinesReadyHot: [
      { id: gridIds.title, row: 2, col: 0, colSpan: 1, padding: "0 0 0 24px" },
      { id: gridIds.readyHotText, row: 0, col: 0, colSpan: 2, padding: "0 10px 0 19px" },
      { id: gridIds.valueGroupTexts, row: 0, col: 1, padding: "0 10px 0 5px" },
      { id: gridIds.valueGroupValues, row: 2, col: 1 },
      { id: separatorTxt, row: 0, col: 2, backgroundColor: this._sepColorGray },
      {
        id: separatorTxt,
        row: 1,
        col: 0,
        colSpan: -1,
        backgroundColor: this._sepColorGray,
      },
    ],
    ExtendedOneValueNoSparklines: [
      { id: gridIds.title, row: 2, col: 0, colSpan: 1, padding: "0 0 0 24px" },
      { id: gridIds.periodTexts, row: 0, col: 0, padding: "0 10px 0 19px" },
      { id: gridIds.valueGroupTexts, row: 0, col: 1, padding: "0 10px 0 5px" },
      { id: gridIds.valueGroupValues, row: 2, col: 1 },
      { id: separatorTxt, row: 0, col: 2, backgroundColor: this._sepColorGray },
      {
        id: separatorTxt,
        row: 1,
        col: 0,
        colSpan: -1,
        backgroundColor: this._sepColorGray,
      },
    ],
    ExtendedNoSparklines: [
      { id: gridIds.title, row: 2, col: 0, colSpan: 1, padding: "0 0 0 24px" },
      { id: gridIds.periodTexts, row: 0, col: 0, padding: "0 10px 0 19px" },
      { id: gridIds.valueGroupTexts, row: 0, col: 1, padding: "0 10px 0 5px" },
      { id: gridIds.valueGroupValues, row: 2, col: 1 },
      { id: gridIds.deltaText_1, row: 0, col: 3, padding: "0 10px 0 5px" },
      { id: gridIds.deltaText_2, row: 0, col: 5, padding: "0 24px 0 5px" },
      { id: gridIds.deltaValue_1, row: 2, col: 3, padding: "0 10px 0 5px" },
      { id: gridIds.deltaValue_2, row: 2, col: 5, padding: "0 24px 0 5px" },
      { id: separatorTxt, row: 0, col: 2, backgroundColor: this._sepColorGray },
      { id: separatorTxt, row: 0, col: 4, backgroundColor: this._sepColorGray },
      {
        id: separatorTxt,
        row: 1,
        col: 0,
        colSpan: -1,
        backgroundColor: this._sepColorGray,
      },
    ],
    ExtendedNoSparklinesReadyHot: [
      { id: gridIds.title, row: 2, col: 0, colSpan: 1, padding: "0 0 0 24px" },
      { id: gridIds.readyHotText, row: 0, col: 0, colSpan: 2, padding: "0 10px 0 19px" },
      { id: gridIds.valueGroupTexts, row: 0, col: 1, padding: "0 10px 0 5px" },
      { id: gridIds.valueGroupValues, row: 2, col: 1 },
      { id: gridIds.deltaText_1, row: 0, col: 3, padding: "0 10px 0 5px" },
      { id: gridIds.deltaText_2, row: 0, col: 5, padding: "0 24px 0 5px" },
      { id: gridIds.deltaValue_1, row: 2, col: 3, padding: "0 10px 0 5px" },
      { id: gridIds.deltaValue_2, row: 2, col: 5, padding: "0 24px 0 5px" },
      { id: separatorTxt, row: 0, col: 2, backgroundColor: this._sepColorGray },
      { id: separatorTxt, row: 0, col: 4, backgroundColor: this._sepColorGray },
      {
        id: separatorTxt,
        row: 1,
        col: 0,
        colSpan: -1,
        backgroundColor: this._sepColorGray,
      },
    ],
    ExtendedAndSparklines: [
      { id: gridIds.title, row: 2, col: 0, padding: "0 0 0 24px" },
      { id: gridIds.periodTexts, row: 0, col: 0, padding: "0 10px 0 19px" },
      { id: gridIds.sparkline, row: 0, col: 1, rowSpan: 3, padding: "17px 0 5.5px 0" },
      { id: gridIds.valueGroupTexts, row: 0, col: 2, padding: "0 10px 0 5px" },
      { id: gridIds.valueGroupValues, row: 2, col: 2 },
      { id: gridIds.deltaText_1, row: 0, col: 3, padding: "0 10px 0 5px" },
      { id: gridIds.deltaText_2, row: 0, col: 4, padding: "0 24px 0 5px" },
      { id: gridIds.deltaValue_1, row: 2, col: 3, padding: "0 10px 0 5px" },
      { id: gridIds.deltaValue_2, row: 2, col: 4, padding: "0 24px 0 5px" },
    ],
  };

  static getWidths(dashboardStatusType: DashboardStatusType): string[] {
    return sharedDashboardWidths[dashboardStatusType];
  }

  static getHeights(dashboardStatusType: DashboardStatusType): string[] {
    if (
      dashboardStatusType === "OneValueNoSparklines" ||
      dashboardStatusType === "ThreeValuesNoSparklinesScaleIndexGreaterZero" ||
      dashboardStatusType === "ThreeValuesNoSparklinesScaleIndexZero" ||
      dashboardStatusType === "NotExtendedAndSparklines" ||
      dashboardStatusType === "OneValueNoSparklinesReadyHot" ||
      dashboardStatusType === "ThreeValuesNoSparklinesScaleIndexGreaterZeroReadyHot" ||
      dashboardStatusType === "ThreeValuesNoSparklinesScaleIndexZeroReadyHot"
    ) {
      return this._heights[0];
    }
    return this._heights[1];
  }

  static getCells(dashboardStatusType: DashboardStatusType): Cell[] {
    return this._cells[dashboardStatusType];
  }
}
